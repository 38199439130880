import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaCreditCard } from 'react-icons/fa';
import billingService from '../../services/billing.service';

const PortalPaymentMethodUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const handleUpdatePaymentMethod = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Create a session for updating payment method
      const response = await billingService.updatePaymentMethod();
      
      if (response.status === 'success' && response.data && response.data.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        setError('Failed to create checkout session for payment method update');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while updating payment method');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Update Payment Method</h1>
        <button
          onClick={() => navigate('/portal/billing')}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <FaArrowLeft className="-ml-0.5 mr-2 h-4 w-4" />
          Back
        </button>
      </div>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <div className="bg-white shadow rounded-lg overflow-hidden p-6">
          {error && (
            <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
          )}
          
          <div className="flex items-center mb-6">
            <div className="flex-shrink-0 bg-blue-100 rounded-md p-2">
              <FaCreditCard className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-medium text-gray-900">Update Your Payment Method</h3>
              <p className="text-sm text-gray-500">You'll be redirected to our secure payment provider to update your card details.</p>
            </div>
          </div>
          
          <button
            onClick={handleUpdatePaymentMethod}
            disabled={loading}
            className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {loading ? 'Processing...' : 'Update Payment Method'}
          </button>
        </div>
      )}
    </div>
  );
};

export default PortalPaymentMethodUpdate;
