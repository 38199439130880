import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Divider,
  Tooltip,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Security as SecurityIcon,
  Warning as WarningIcon,
  Flag as FlagIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Sort as SortIcon,
  Devices as DevicesIcon,
  Link as LinkIcon
} from '@mui/icons-material';
import axios from 'axios';
import authHeader from '../../utils/auth-header';
import { formatDate } from '../../utils/formatters';

const EsetManagement = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [clients, setClients] = useState([]);
  const [esetCustomers, setEsetCustomers] = useState([]);
  const [licenseUsage, setLicenseUsage] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [syncingClients, setSyncingClients] = useState(false);
  
  // Search and filter state
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [showTestCompanies, setShowTestCompanies] = useState(false);
  const [markingAsTest, setMarkingAsTest] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  // Fetch clients and ESET data
  useEffect(() => {
    fetchClients();
    fetchEsetCustomers();
    fetchLicenseUsage();
  }, []);

  // Fetch clients from API
  const fetchClients = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/clients', { headers: authHeader() });
      setClients(response.data.data.clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch ESET customers
  const fetchEsetCustomers = async () => {
    try {
      const response = await axios.get('/api/eset/customers', { headers: authHeader() });
      setEsetCustomers(response.data.data.customers);
    } catch (error) {
      console.error('Error fetching ESET customers:', error);
      // Don't show error for this as it's not critical
    }
  };

  // Fetch ESET license usage
  const fetchLicenseUsage = async () => {
    try {
      const response = await axios.get('/api/eset/license-usage', { headers: authHeader() });
      setLicenseUsage(response.data.data.licenseUsage);
    } catch (error) {
      console.error('Error fetching ESET license usage:', error);
      // Don't show error for this as it's not critical
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle status filter change
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  // Handle sort change
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle direction if same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Navigate to client devices page
  const navigateToClientDevices = (clientId) => {
    navigate(`/clients/${clientId}/devices`);
  };
  
  // Test ESET connection
  const testConnection = async () => {
    setTestingConnection(true);
    try {
      const response = await axios.get('/api/eset/test-connection', { headers: authHeader() });
      setConnectionStatus(response.data.data.connected);
      showSnackbar('ESET connection test successful', 'success');
    } catch (error) {
      console.error('Error testing ESET connection:', error);
      setConnectionStatus(false);
      showSnackbar('ESET connection test failed', 'error');
    } finally {
      setTestingConnection(false);
    }
  };

  // Enable ESET for a client
  const enableEsetForClient = async (clientId) => {
    try {
      const response = await axios.post(`/api/eset/clients/${clientId}/enable`, {}, { headers: authHeader() });
      showSnackbar('ESET enabled for client', 'success');
      fetchClients();
      fetchEsetCustomers();
    } catch (error) {
      console.error('Error enabling ESET for client:', error);
      showSnackbar('Failed to enable ESET for client', 'error');
    }
  };

  // Disable ESET for a client
  const disableEsetForClient = async (clientId) => {
    try {
      const response = await axios.post(`/api/eset/clients/${clientId}/disable`, {}, { headers: authHeader() });
      showSnackbar('ESET disabled for client', 'success');
      fetchClients();
    } catch (error) {
      console.error('Error disabling ESET for client:', error);
      showSnackbar('Failed to disable ESET for client', 'error');
    }
  };

  // Get client license usage
  const getClientLicenseUsage = async (clientId) => {
    try {
      const response = await axios.get(`/api/eset/clients/${clientId}/license-usage`, { headers: authHeader() });
      const client = clients.find(c => c._id === clientId);
      setSelectedClient({
        ...client,
        licenseUsage: response.data.data.licenseUsage
      });
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching client license usage:', error);
      showSnackbar('Failed to fetch client license usage', 'error');
    }
  };

  // Sync all clients with ESET
  const syncAllClients = async () => {
    setSyncingClients(true);
    try {
      const response = await axios.post('/api/eset/sync-all-clients', {}, { headers: authHeader() });
      showSnackbar('All clients synced with ESET', 'success');
      fetchClients();
      fetchEsetCustomers();
    } catch (error) {
      console.error('Error syncing clients with ESET:', error);
      showSnackbar('Failed to sync clients with ESET', 'error');
    } finally {
      setSyncingClients(false);
    }
  };
  
  // Mark a client as a test company or unmark it
  const markAsTestCompany = async (clientId, isTest = true) => {
    setMarkingAsTest(true);
    try {
      const response = await axios.post(
        `/api/eset/clients/${clientId}/mark-test`, 
        { isTest }, 
        { headers: authHeader() }
      );
      showSnackbar(
        `Client ${isTest ? 'marked' : 'unmarked'} as a test company`, 
        'success'
      );
      fetchClients();
      fetchEsetCustomers();
    } catch (error) {
      console.error('Error marking client as test company:', error);
      showSnackbar('Failed to update test company status', 'error');
    } finally {
      setMarkingAsTest(false);
    }
  };

  // Show snackbar message
  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Find ESET customer for client
  const findEsetCustomerForClient = (client) => {
    if (!client.esetIntegration || !client.esetIntegration.companyId) return null;
    return esetCustomers.find(c => c.companyId === client.esetIntegration.companyId);
  };

  // Filter and sort clients
  const filteredClients = clients.filter(client => {
    // Filter out test companies if showTestCompanies is false
    if (!showTestCompanies && client.esetIntegration?.isTestCompany) {
      return false;
    }
    
    // Apply status filter (only if not 'all')
    if (statusFilter !== 'all') {
      if (statusFilter === 'enabled' && (!client.esetIntegration || !client.esetIntegration.enabled)) {
        return false;
      }
      if (statusFilter === 'disabled' && client.esetIntegration && client.esetIntegration.enabled) {
        return false;
      }
    }
    
    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return (
        client.name.toLowerCase().includes(searchLower) ||
        client.contactPerson?.firstName?.toLowerCase().includes(searchLower) ||
        client.contactPerson?.lastName?.toLowerCase().includes(searchLower) ||
        client.contactPerson?.email?.toLowerCase().includes(searchLower)
      );
    }
    
    return true;
  }).sort((a, b) => {
    // Apply sorting
    let aValue = a[sortField];
    let bValue = b[sortField];
    
    // Handle nested fields
    if (sortField === 'esetStatus') {
      aValue = a.esetIntegration?.enabled ? 'enabled' : 'disabled';
      bValue = b.esetIntegration?.enabled ? 'enabled' : 'disabled';
    }
    
    // Compare values
    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <Container maxWidth="lg">
      <Card sx={{ mb: 4 }}>
        <CardHeader 
          title="ESET Integration Management" 
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={testConnection}
              startIcon={<SecurityIcon />}
              disabled={testingConnection}
            >
              {testingConnection ? <CircularProgress size={24} /> : 'Test Connection'}
            </Button>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined">
                <CardHeader title="Connection Status" />
                <CardContent>
                  <Typography variant="body1">
                    Connection to ESET API: {' '}
                    {connectionStatus === null ? (
                      'Not tested'
                    ) : connectionStatus ? (
                      <Chip icon={<CheckIcon />} label="Connected" color="success" />
                    ) : (
                      <Chip icon={<CloseIcon />} label="Disconnected" color="error" />
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card variant="outlined">
                <CardHeader title="License Usage" />
                <CardContent>
                  {licenseUsage ? (
                    <Typography variant="body1">
                      Total Licenses: {licenseUsage.totalLicenses}<br />
                      Used Licenses: {licenseUsage.usedLicenses}<br />
                      Available Licenses: {licenseUsage.availableLicenses}
                    </Typography>
                  ) : (
                    <Typography variant="body1">License usage not available</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardHeader 
          title="Client ESET Integration Directory" 
          subheader="Select a client to manage ESET installations on their devices"
        />
        <CardContent>
          {/* Search and Filter Controls */}
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Search Clients"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>ESET Status</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    label="ESET Status"
                  >
                    <MenuItem value="all">All Clients</MenuItem>
                    <MenuItem value="enabled">ESET Enabled</MenuItem>
                    <MenuItem value="disabled">ESET Disabled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showTestCompanies}
                          onChange={(e) => setShowTestCompanies(e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      }
                      label="Show Test Companies"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={syncAllClients}
                      startIcon={syncingClients ? <CircularProgress size={20} /> : <RefreshIcon />}
                      disabled={syncingClients}
                      size="medium"
                    >
                      {syncingClients ? 'Syncing...' : 'Sync All Clients'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredClients.length === 0 ? (
            <Box sx={{ my: 4, p: 4, textAlign: 'center', bgcolor: 'grey.100', borderRadius: 1 }}>
              <Typography variant="h6">No clients found</Typography>
              <Typography variant="body1" color="textSecondary">
                {searchTerm || statusFilter !== 'all' ? 
                  'Try adjusting your search or filter criteria' : 
                  'There are no clients in the system yet'}
              </Typography>
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('name')} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Client Name
                        {sortField === 'name' && (
                          <SortIcon sx={{ ml: 0.5, fontSize: '1rem', transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none' }} />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell onClick={() => handleSort('esetStatus')} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        ESET Status
                        {sortField === 'esetStatus' && (
                          <SortIcon sx={{ ml: 0.5, fontSize: '1rem', transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none' }} />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>License Count</TableCell>
                    <TableCell>Last Sync</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredClients.map((client) => (
                    <TableRow 
                      key={client._id}
                      hover
                      sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {client.name}
                          {client.esetIntegration?.isTestCompany && (
                            <Tooltip title="Test Company">
                              <Chip
                                icon={<FlagIcon />}
                                label="Test"
                                size="small"
                                color="warning"
                                sx={{ ml: 1 }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {client.esetIntegration && client.esetIntegration.enabled ? (
                          <Chip icon={<CheckIcon />} label="Enabled" color="success" />
                        ) : (
                          <Chip icon={<CloseIcon />} label="Disabled" color="default" />
                        )}
                      </TableCell>
                      <TableCell>
                        {client.esetIntegration && client.esetIntegration.licenseCount ? (
                          client.esetIntegration.licenseCount
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>
                        {client.esetIntegration && client.esetIntegration.lastSync ? (
                          formatDate(client.esetIntegration.lastSync)
                        ) : (
                          'Never'
                        )}
                      </TableCell>
                      <TableCell>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => navigateToClientDevices(client._id)}
                              startIcon={<DevicesIcon />}
                            >
                              Manage Devices
                            </Button>
                          </Grid>
                          <Grid item>
                            {client.esetIntegration && client.esetIntegration.enabled ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => getClientLicenseUsage(client._id)}
                                startIcon={<SecurityIcon />}
                              >
                                View Licenses
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="success"
                                size="small"
                                onClick={() => enableEsetForClient(client._id)}
                              >
                                Enable ESET
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      {/* Client License Usage Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedClient?.name} - ESET License Usage
        </DialogTitle>
        <DialogContent>
          {selectedClient?.licenseUsage ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>License Key</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Available</TableCell>
                    <TableCell>Expiration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedClient.licenseUsage.licenses.map((license) => (
                    <TableRow key={license.licenseKey}>
                      <TableCell>{license.productName}</TableCell>
                      <TableCell>{license.licenseKey}</TableCell>
                      <TableCell>{license.totalSeats}</TableCell>
                      <TableCell>{license.usedSeats}</TableCell>
                      <TableCell>{license.availableSeats}</TableCell>
                      <TableCell>{formatDate(license.expirationDate)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No license usage data available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EsetManagement;
