import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEdit, FaSync, FaArrowLeft, FaLink, FaTicketAlt, FaServer, FaClock, FaChartBar, FaSave, FaShieldAlt } from 'react-icons/fa';
import clientService from '../../services/client.service';
import ticketService from '../../services/ticket.service';
import esetService from '../../services/eset.service';
import LoadingSpinner from '../common/LoadingSpinner';
import ClientTimeUsage from './ClientTimeUsage';
import ClientTickets from './ClientTickets';
import ClientDevices from './ClientDevices';

const ClientDetail = ({ isNew = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [originalClient, setOriginalClient] = useState(null);

  // Load client data on component mount or initialize new client
  useEffect(() => {
    if (isNew) {
      // Initialize a new client template
      setClient({
        name: '',
        status: 'prospect',
        contactPerson: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          position: ''
        },
        address: {
          street: '',
          city: '',
          state: '',
          postalCode: '',
          country: ''
        },
        supportContract: {
          type: 'none',
          startDate: null,
          endDate: null,
          minutesIncluded: 0
        },
        billingInfo: {
          companyNumber: '',
          vatNumber: '',
          billingEmail: ''
        },
        notes: '',
        tacticalRMM: {},
        stats: {
          ticketStats: [],
          totalTimeSpent: 0,
          remainingTime: 0,
          usagePercentage: 0
        }
      });
      setLoading(false);
    } else {
      loadClient();
    }
  }, [id, isNew]);

  // Load client data from API
  const loadClient = async () => {
    if (!id) return;
    
    setLoading(true);
    try {
      const response = await clientService.getClient(id);
      setClient(response.data.data.client);
      setOriginalClient(response.data.data.client);
    } catch (error) {
      console.error('Error loading client:', error);
      toast.error('Failed to load client details. Please try again.');
      navigate('/clients');
    } finally {
      setLoading(false);
    }
  };

  // Save client (new or updated)
  const handleSaveClient = async () => {
    // Validate required fields
    if (!client.name) {
      toast.error('Client name is required');
      return;
    }
    
    setSaving(true);
    try {
      let response;
      if (isNew) {
        // Create new client
        response = await clientService.createClient(client);
        toast.success('Client created successfully!');
        // Navigate to the new client's detail page
        navigate(`/clients/${response.data.data.client._id}`);
      } else {
        // Update existing client
        response = await clientService.updateClient(id, client);
        toast.success('Client updated successfully!');
        setEditMode(false);
        // Refresh client data
        setClient(response.data.data.client);
        setOriginalClient(response.data.data.client);
      }
    } catch (error) {
      console.error('Error saving client:', error);
      toast.error(error.response?.data?.message || 'Failed to save client. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  // Cancel editing and revert changes
  const handleCancelEdit = () => {
    setClient(originalClient);
    setEditMode(false);
  };

  // Sync client with TacticalRMM
  const handleSyncWithRMM = async () => {
    if (!client.tacticalRMM?.clientId) {
      toast.warning('This client is not linked to TacticalRMM yet.');
      return;
    }

    try {
      toast.info('Syncing with TacticalRMM...');
      const response = await clientService.syncWithTacticalRMM(id);
      setClient(response.data.data.client);
      toast.success('Successfully synced with TacticalRMM.');
    } catch (error) {
      console.error('Error syncing with TacticalRMM:', error);
      toast.error('Failed to sync with TacticalRMM. Please try again.');
    }
  };

  // Handle client password reset
  const handlePasswordReset = async () => {
    if (window.confirm('Are you sure you want to send a password reset email to this client?')) {
      try {
        await clientService.resetClientPassword(client._id);
        toast.success('Password reset email sent successfully!');
      } catch (error) {
        console.error('Error sending password reset:', error);
        toast.error(error.response?.data?.message || 'Failed to send password reset email');
      }
    }
  };

  // Handle client password change
  const handlePasswordChange = async () => {
    const newPassword = prompt('Enter new password for this client:');
    if (newPassword) {
      if (newPassword.length < 8) {
        toast.error('Password must be at least 8 characters long');
        return;
      }
      
      try {
        await clientService.changeClientPassword(client._id, newPassword);
        toast.success('Client password changed successfully!');
      } catch (error) {
        console.error('Error changing password:', error);
        toast.error(error.response?.data?.message || 'Failed to change client password');
      }
    }
  };

  // Handle client deletion
  const handleDeleteClient = async () => {
    if (window.confirm(`Are you absolutely sure you want to permanently delete ${client.name}? This action cannot be undone.`)) {
      const confirmText = prompt(`Type the client name '${client.name}' to confirm deletion:`);
      
      if (confirmText === client.name) {
        try {
          await clientService.deleteClient(client._id);
          toast.success('Client deleted successfully!');
          navigate('/clients');
        } catch (error) {
          console.error('Error deleting client:', error);
          toast.error(error.response?.data?.message || 'Failed to delete client');
        }
      } else {
        toast.error('Client name did not match. Deletion cancelled.');
      }
    }
  };
  
  // Render status badge
  const renderStatusBadge = (status) => {
    const statusClasses = {
      active: 'bg-green-100 text-green-800',
      inactive: 'bg-gray-100 text-gray-800',
      prospect: 'bg-blue-100 text-blue-800',
      archived: 'bg-red-100 text-red-800',
    };
    
    return (
      <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusClasses[status] || 'bg-gray-100 text-gray-800'}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  // Format time (minutes to hours and minutes)
  const formatTime = (minutes) => {
    if (!minutes && minutes !== 0) return 'N/A';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };
  
  // Handle enabling ESET for client
  const handleEnableEset = async () => {
    if (!client._id) return;
    
    try {
      const response = await esetService.enableForClient(client._id);
      toast.success('ESET Protect has been enabled for this client');
      
      // Update client data with ESET information
      setClient({
        ...client,
        esetIntegration: response.data.data.esetIntegration
      });
    } catch (error) {
      console.error('Error enabling ESET:', error);
      toast.error(error.response?.data?.message || 'Failed to enable ESET Protect. Please try again.');
    }
  };
  
  // Handle disabling ESET for client
  const handleDisableEset = async () => {
    if (!client._id) return;
    
    if (!window.confirm('Are you sure you want to disable ESET Protect for this client?')) {
      return;
    }
    
    try {
      const response = await esetService.disableForClient(client._id);
      toast.success('ESET Protect has been disabled for this client');
      
      // Update client data with ESET information
      setClient({
        ...client,
        esetIntegration: response.data.data.esetIntegration
      });
    } catch (error) {
      console.error('Error disabling ESET:', error);
      toast.error(error.response?.data?.message || 'Failed to disable ESET Protect. Please try again.');
    }
  };
  
  // Handle syncing ESET licenses for client
  const handleSyncEsetLicenses = async () => {
    if (!client._id || !client.esetIntegration?.enabled) return;
    
    try {
      const response = await esetService.getClientLicenseUsage(client._id);
      toast.success('ESET license information has been updated');
      
      // Update client data with ESET information
      setClient({
        ...client,
        esetIntegration: {
          ...client.esetIntegration,
          licenseCount: response.data.data.licenseCount,
          lastSync: new Date()
        }
      });
    } catch (error) {
      console.error('Error syncing ESET licenses:', error);
      toast.error(error.response?.data?.message || 'Failed to sync ESET license information. Please try again.');
    }
  };

  if (loading) {
    return <LoadingSpinner message="Loading client details..." />;
  }

  if (!client) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          Client not found. Please check the URL and try again.
        </div>
        <div className="mt-4">
          <Link to="/clients" className="text-blue-600 hover:underline flex items-center">
            <FaArrowLeft className="mr-2" /> Back to Clients
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6">
        <div className="flex items-center mb-4 md:mb-0">
          <Link to="/clients" className="text-blue-600 hover:underline mr-4">
            <FaArrowLeft className="inline mr-1" /> Back
          </Link>
          <h1 className="text-2xl font-bold text-gray-800">{isNew ? 'New Client' : client.name}</h1>
          {!isNew && renderStatusBadge(client.status)}
        </div>
        {!isNew && (
          <div className="flex space-x-2">
            {client.tacticalRMM?.clientId && (
              <button
                onClick={handleSyncWithRMM}
                className="bg-green-600 hover:bg-green-700 text-white px-3 py-2 rounded-md flex items-center"
              >
                <FaSync className="mr-2" /> Sync with RMM
              </button>
            )}
            {!client.tacticalRMM?.clientId && (
              <Link
                to={`/clients/${id}/link-rmm`}
                className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 rounded-md flex items-center"
              >
                <FaLink className="mr-2" /> Link to RMM
              </Link>
            )}
            <button
              onClick={() => setEditMode(true)}
              className="bg-gray-600 hover:bg-gray-700 text-white px-3 py-2 rounded-md flex items-center"
            >
              <FaEdit className="mr-2" /> Edit
            </button>
          </div>
        )}
      </div>

      {/* Tabs - Only show for existing clients */}
      {!isNew && (
        <div className="border-b border-gray-200 mb-6">
          <nav className="-mb-px flex space-x-8">
            <button
              className={`${activeTab === 'overview' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              onClick={() => setActiveTab('overview')}
            >
              Overview
            </button>
            <button
              className={`${activeTab === 'tickets' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              onClick={() => setActiveTab('tickets')}
            >
              <FaTicketAlt className="mr-2" /> Tickets
            </button>
            <button
              className={`${activeTab === 'devices' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              onClick={() => setActiveTab('devices')}
            >
              <FaServer className="mr-2" /> Devices
            </button>
            <button
              className={`${activeTab === 'timeUsage' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
              onClick={() => setActiveTab('timeUsage')}
            >
              <FaClock className="mr-2" /> Time Usage
            </button>
          </nav>
        </div>
      )}

<>
  {/* Tab Content */}
  {activeTab === 'overview' && !editMode && (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* Client Information */}
      <div className="bg-white rounded-lg shadow-md p-6 col-span-2">
        <h2 className="text-xl font-semibold mb-4">Client Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Contact Person</h3>
            <p className="mt-1">
              {client.contactPerson?.firstName} {client.contactPerson?.lastName}
            </p>
            <p className="text-blue-600">
              <a href={`mailto:${client.contactPerson?.email}`}>{client.contactPerson?.email}</a>
            </p>
            {client.contactPerson?.phone && (
              <p>
                <a href={`tel:${client.contactPerson?.phone}`}>{client.contactPerson?.phone}</a>
              </p>
            )}
            {client.contactPerson?.position && <p className="text-gray-600">{client.contactPerson?.position}</p>}
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Address</h3>
            <p className="mt-1">{client.address?.street}</p>
            <p>
              {client.address?.city}{client.address?.state ? `, ${client.address?.state}` : ''} {client.address?.postalCode}
            </p>
            <p>{client.address?.country}</p>
          </div>
          {client.billingInfo && (
            <div>
              <h3 className="text-sm font-medium text-gray-500">Billing Information</h3>
              {client.billingInfo.companyNumber && <p className="mt-1">Company No: {client.billingInfo.companyNumber}</p>}
              {client.billingInfo.vatNumber && <p>VAT No: {client.billingInfo.vatNumber}</p>}
              {client.billingInfo.billingEmail && (
                <p>
                  <a href={`mailto:${client.billingInfo.billingEmail}`} className="text-blue-600">
                    {client.billingInfo.billingEmail}
                  </a>
                </p>
              )}
            </div>
          )}
          <div>
            <h3 className="text-sm font-medium text-gray-500">Support Contract</h3>
            <p className="mt-1 capitalize">{client.supportContract?.type || 'None'}</p>
            {client.supportContract?.startDate && (
              <p>
                <span className="text-gray-600">Start Date:</span> {formatDate(client.supportContract.startDate)}
              </p>
            )}
            {client.supportContract?.endDate && (
              <p>
                <span className="text-gray-600">End Date:</span> {formatDate(client.supportContract.endDate)}
              </p>
            )}
            {client.supportContract?.minutesIncluded > 0 && (
              <p>
                <span className="text-gray-600">Time Included:</span> {formatTime(client.supportContract.minutesIncluded)}
              </p>
            )}
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500">Client ID</h3>
            <div className="flex items-center mt-1">
              <p className="font-mono bg-gray-100 px-2 py-1 rounded">{client.clientId || 'Not assigned'}</p>
              {client.clientId && (
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(client.clientId);
                    toast.success('Client ID copied to clipboard');
                  }}
                  className="ml-2 text-blue-600 hover:text-blue-800"
                  title="Copy Client ID"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
        {client.notes && (
          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-500">Notes</h3>
            <p className="mt-1 whitespace-pre-line">{client.notes}</p>
          </div>
        )}
      </div>

      {/* Support Stats */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Support Statistics</h2>
        
        {/* Time Usage */}
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-500 mb-2">Time Usage</h3>
          <div className="flex justify-between mb-1">
            <span>Used: {formatTime(client.stats?.totalTimeSpent || 0)}</span>
            <span>Remaining: {formatTime(client.stats?.remainingTime || 0)}</span>
          </div>
        </div>
        
        {/* Account Management */}
        <div className="mt-8">
          <h3 className="text-sm font-medium text-gray-500 mb-3">Account Management</h3>
          <div className="space-y-2">
            <button
              onClick={handlePasswordReset}
              className="w-full flex items-center justify-between px-4 py-2 text-sm text-blue-600 hover:bg-blue-50 rounded-md"
            >
              <span>Send Password Reset Email</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </button>
            
            <button
              onClick={handlePasswordChange}
              className="w-full flex items-center justify-between px-4 py-2 text-sm text-green-600 hover:bg-green-50 rounded-md"
            >
              <span>Change Password</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </button>
            
            <button
              onClick={handleDeleteClient}
              className="w-full flex items-center justify-between px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md"
            >
              <span>Delete Client</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        </div>
        
        {/* Usage Bar */}
        <div className="mt-4">
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className={`h-2.5 rounded-full ${client.stats?.usagePercentage > 90 ? 'bg-red-600' : client.stats?.usagePercentage > 75 ? 'bg-yellow-500' : 'bg-green-600'}`}
              style={{ width: `${Math.min(client.stats?.usagePercentage || 0, 100)}%` }}
            ></div>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {client.stats?.usagePercentage || 0}% of allocated time used
          </p>
        </div>

        {/* Ticket Stats */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-2">Ticket Status</h3>
          <div className="space-y-2">
            {client.stats?.ticketStats?.map((stat) => (
              <div key={stat._id} className="flex justify-between">
                <span className="capitalize">{stat._id}</span>
                <span className="font-medium">{stat.count}</span>
              </div>
            )) || (
              <p className="text-gray-500">No ticket data available</p>
            )}
          </div>
        </div>

        {/* TacticalRMM Integration */}
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-500 mb-2">TacticalRMM Integration</h3>
          {client.tacticalRMM?.clientId ? (
            <div>
              <p className="text-green-600 font-medium">Linked to TacticalRMM</p>
              <p className="text-sm text-gray-600 mt-1">Company: {client.tacticalRMM.companyName || 'N/A'}</p>
              <p className="text-sm text-gray-600">Devices: {client.tacticalRMM.deviceCount || 0}</p>
              <p className="text-sm text-gray-600">Last Sync: {formatDate(client.tacticalRMM.lastSync)}</p>
            </div>
          ) : (
            <div>
              <p className="text-gray-600">Not linked to TacticalRMM</p>
              <Link
                to={`/clients/${id}/link-rmm`}
                className="text-blue-600 hover:underline text-sm flex items-center mt-2"
              >
                <FaLink className="mr-1" /> Link to TacticalRMM
              </Link>
            </div>
          )}
        </div>
        
        {/* ESET Integration */}
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-500 mb-2">ESET Protect Integration</h3>
          {client.esetIntegration?.enabled ? (
            <div>
              <p className="text-green-600 font-medium">ESET Protect Enabled</p>
              <p className="text-sm text-gray-600 mt-1">Company ID: {client.esetIntegration.companyId || 'N/A'}</p>
              {client.esetIntegration.licenseCount > 0 && (
                <p className="text-sm text-gray-600">Licenses: {client.esetIntegration.licenseCount}</p>
              )}
              {client.esetIntegration.lastSync && (
                <p className="text-sm text-gray-600">Last Sync: {formatDate(client.esetIntegration.lastSync)}</p>
              )}
              <div className="flex mt-2 space-x-4">
                <button
                  onClick={handleSyncEsetLicenses}
                  className="text-blue-600 hover:underline text-sm flex items-center"
                >
                  <FaSync className="mr-1" /> Sync Licenses
                </button>
                <button
                  onClick={handleDisableEset}
                  className="text-red-600 hover:underline text-sm flex items-center"
                >
                  <FaShieldAlt className="mr-1" /> Disable ESET Protect
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-gray-600">ESET Protect not enabled</p>
              <button
                onClick={handleEnableEset}
                className="text-blue-600 hover:underline text-sm flex items-center mt-2"
              >
                <FaShieldAlt className="mr-1" /> Enable ESET Protect
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )}
</> 
      {/* For new clients, show a message about creating the client first */}
      {isNew && (
        <div className="bg-blue-50 border border-blue-200 text-blue-700 px-4 py-3 rounded mb-6">
          <p className="font-medium">Create a new client</p>
          <p className="mt-1">Fill out the client details form below and click Save to create a new client.</p>
        </div>
      )}

      {/* New client form */}
      {isNew && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Client Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Basic Information */}
            <div>
              <h3 className="text-md font-medium mb-3">Basic Information</h3>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Client Name*
                </label>
                <input
                  type="text"
                  value={client.name}
                  onChange={(e) => setClient({...client, name: e.target.value})}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Status
                </label>
                <select
                  value={client.status}
                  onChange={(e) => setClient({...client, status: e.target.value})}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="prospect">Prospect</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Notes
                </label>
                <textarea
                  value={client.notes}
                  onChange={(e) => setClient({...client, notes: e.target.value})}
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
            </div>
            
            {/* Contact Person */}
            <div>
              <h3 className="text-md font-medium mb-3">Contact Person</h3>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={client.contactPerson.firstName}
                    onChange={(e) => setClient({
                      ...client, 
                      contactPerson: {
                        ...client.contactPerson,
                        firstName: e.target.value
                      }
                    })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={client.contactPerson.lastName}
                    onChange={(e) => setClient({
                      ...client, 
                      contactPerson: {
                        ...client.contactPerson,
                        lastName: e.target.value
                      }
                    })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={client.contactPerson.email}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      email: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  value={client.contactPerson.phone}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      phone: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Position
                </label>
                <input
                  type="text"
                  value={client.contactPerson.position}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      position: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          
          {/* Business Address */}
          <div className="mt-6">
            <h3 className="text-md font-medium mb-3">Business Address</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Street Address
                </label>
                <input
                  type="text"
                  value={client.address.street || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      street: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City
                </label>
                <input
                  type="text"
                  value={client.address.city || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      city: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State/County
                </label>
                <input
                  type="text"
                  value={client.address.state || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      state: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Postal Code
                </label>
                <input
                  type="text"
                  value={client.address.postalCode || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      postalCode: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Country
                </label>
                <input
                  type="text"
                  value={client.address.country || 'United Kingdom'}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      country: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => navigate('/clients')}
              className="mr-3 px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClient}
              disabled={saving || !client.name}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {saving ? 'Saving...' : <><FaSave className="mr-2" /> Save Client</>}
            </button>
          </div>
        </div>
      )}

      {/* Edit mode form for existing clients */}
      {!isNew && editMode && activeTab === 'overview' && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Edit Client Information</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Basic Information */}
            <div>
              <h3 className="text-md font-medium mb-3">Basic Information</h3>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Client Name*
                </label>
                <input
                  type="text"
                  value={client.name}
                  onChange={(e) => setClient({...client, name: e.target.value})}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Status
                </label>
                <select
                  value={client.status}
                  onChange={(e) => setClient({...client, status: e.target.value})}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="prospect">Prospect</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Notes
                </label>
                <textarea
                  value={client.notes || ''}
                  onChange={(e) => setClient({...client, notes: e.target.value})}
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
            </div>
            
            {/* Contact Person */}
            <div>
              <h3 className="text-md font-medium mb-3">Contact Person</h3>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={client.contactPerson?.firstName || ''}
                    onChange={(e) => setClient({
                      ...client, 
                      contactPerson: {
                        ...client.contactPerson,
                        firstName: e.target.value
                      }
                    })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={client.contactPerson?.lastName || ''}
                    onChange={(e) => setClient({
                      ...client, 
                      contactPerson: {
                        ...client.contactPerson,
                        lastName: e.target.value
                      }
                    })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={client.contactPerson?.email || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      email: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  value={client.contactPerson?.phone || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      phone: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Position
                </label>
                <input
                  type="text"
                  value={client.contactPerson?.position || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    contactPerson: {
                      ...client.contactPerson,
                      position: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          
          {/* Business Address */}
          <div className="mt-6">
            <h3 className="text-md font-medium mb-3">Business Address</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Street Address
                </label>
                <input
                  type="text"
                  value={client.address?.street || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      street: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City
                </label>
                <input
                  type="text"
                  value={client.address?.city || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      city: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State/County
                </label>
                <input
                  type="text"
                  value={client.address?.state || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      state: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Postal Code
                </label>
                <input
                  type="text"
                  value={client.address?.postalCode || ''}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      postalCode: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Country
                </label>
                <input
                  type="text"
                  value={client.address?.country || 'United Kingdom'}
                  onChange={(e) => setClient({
                    ...client, 
                    address: {
                      ...client.address,
                      country: e.target.value
                    }
                  })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleCancelEdit}
              className="mr-3 px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClient}
              disabled={saving || !client.name}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {saving ? 'Saving...' : <><FaSave className="mr-2" /> Save Changes</>}
            </button>
          </div>
        </div>
      )}

      {/* Only show tabs for existing clients */}
      {!isNew && activeTab === 'tickets' && <ClientTickets clientId={id} />}
      {!isNew && activeTab === 'devices' && <ClientDevices clientId={id} />}
      {!isNew && activeTab === 'timeUsage' && <ClientTimeUsage clientId={id} />}
    </div>
  );
};

export default ClientDetail;
