import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { signupService, clientService } from '../../services/api.service';
import authService from '../../services/auth.service';

// Styled components
const SignupContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(28, 54, 85, 0.9), rgba(30, 41, 59, 0.9));
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  border: 1px solid rgba(58, 134, 255, 0.2);
  color: white;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-50%);
    z-index: 1;
  }
`;

const Step = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.active ? 'linear-gradient(to right, #ffd60a, #ff9e2c)' : 'rgba(255, 255, 255, 0.1)'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  z-index: 2;
  color: ${props => props.active ? '#333' : 'white'};
  box-shadow: ${props => props.active ? '0 0 15px rgba(255, 214, 10, 0.5)' : 'none'};
  transition: all 0.3s ease;
  
  &::after {
    content: '${props => props.label}';
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${props => props.active ? '#ffd60a' : 'rgba(255, 255, 255, 0.7)'};
  }
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: white;
`;

const FormSection = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.3rem;
  color: #ffd60a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 0.5rem;
`;

const FormRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormGroup = styled.div`
  flex: ${props => props.fullWidth ? '1 0 100%' : '1 0 calc(50% - 0.5rem)'};
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    flex: 1 0 100%;
  }
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: rgba(255, 214, 10, 0.8);
    box-shadow: 0 0 0 2px rgba(255, 214, 10, 0.3);
    background: rgba(255, 255, 255, 0.15);
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  
  &:focus {
    outline: none;
    border-color: rgba(255, 214, 10, 0.8);
    box-shadow: 0 0 0 2px rgba(255, 214, 10, 0.3);
    background-color: rgba(255, 255, 255, 0.15);
  }
  
  option {
    background: #1c3655;
    color: white;
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: rgba(255, 214, 10, 0.8);
    box-shadow: 0 0 0 2px rgba(255, 214, 10, 0.3);
    background: rgba(255, 255, 255, 0.15);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.8rem 2rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(to right, #ffd60a, #ff9e2c);
  color: #333;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  
  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
  }
  
  &:active:not(:disabled) {
    transform: translateY(1px);
  }
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  
  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const VerificationContainer = styled.div`
  text-align: center;
  padding: 2rem;
`;

const VerificationCode = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0;
`;

const CodeInput = styled.input`
  width: 3rem;
  height: 4rem;
  text-align: center;
  font-size: 1.8rem;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  
  &:focus {
    outline: none;
    border-color: rgba(255, 214, 10, 0.8);
    box-shadow: 0 0 0 2px rgba(255, 214, 10, 0.3);
  }
`;

const TermsContainer = styled.div`
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border-left: 3px solid #ffd60a;
`;

const TermsList = styled.ul`
  margin-left: 1.5rem;
  
  li {
    margin-bottom: 0.8rem;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1.5rem 0;
  
  input {
    margin-top: 0.3rem;
    margin-right: 0.8rem;
  }
`;

const PaymentForm = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`;

const SuccessContainer = styled.div`
  text-align: center;
  padding: 3rem 1rem;
  
  svg {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
    color: #4BB543;
  }
`;

const WarningModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.3s ease;
`;

const WarningContent = styled.div`
  background: linear-gradient(135deg, rgba(28, 54, 85, 0.95), rgba(30, 41, 59, 0.95));
  border-radius: 12px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(58, 134, 255, 0.2);
  transform: ${props => props.show ? 'scale(1)' : 'scale(0.9)'};
  transition: transform 0.3s ease;
`;

const WarningTitle = styled.h3`
  color: #ffd60a;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const WarningText = styled.p`
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const ErrorMessage = styled.div`
  background-color: rgba(255, 107, 107, 0.2);
  border: 1px solid #ff6b6b;
  color: #ff6b6b;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
`;

const SuccessMessage = styled.div`
  background-color: rgba(87, 187, 138, 0.2);
  border: 1px solid #57bb8a;
  color: #57bb8a;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12px;
  color: white;
  font-size: 1.2rem;
`;

const SignupForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [warningType, setWarningType] = useState(null); // 'support' or 'microsoft365'
  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    // Personal/Company Information
    firstName: '',
    lastName: '',
    companyName: '',
    address: '',
    city: '',
    postcode: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    
    // Technical Information
    pcCount: '',
    hasMicrosoft365: '',
    existingITSupport: '',
    existingITSupportDetails: '',
    
    // Terms agreement
    agreeToTerms: false,
    agreeToRemoteAccess: false,
    acknowledgeExistingSupport: false,
    acknowledgeMicrosoft365: false,
    
    // Payment
    cardName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });
  
  // For email verification
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    // Show warning immediately if user selects 'yes' for existing IT support
    if (name === 'existingITSupport' && value === 'yes') {
      setWarningType('support');
      setShowWarning(true);
    }
    
    // Show warning immediately if user selects 'yes' for Microsoft 365
    if (name === 'hasMicrosoft365' && value === 'yes') {
      setWarningType('microsoft365');
      setShowWarning(true);
    }
  };
  
  const handleCodeChange = (index, value) => {
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
    
    // Auto-focus next input
    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };
  
  const handleNextStep = async () => {
    // Reset error and success messages
    setError('');
    setSuccess('');
    
    // Validate the current step before proceeding to the next one
    if (currentStep === 1) {
      // Check all required fields for step 1
      const requiredFields = [
        'firstName', 'lastName', 'companyName', 'address', 'city', 'postcode',
        'phone', 'email', 'password', 'confirmPassword', 'pcCount', 'hasMicrosoft365', 'existingITSupport'
      ];
      
      // Check if any required fields are empty
      const missingFields = requiredFields.filter(field => !formData[field]);
      
      if (missingFields.length > 0) {
        setError('Please fill in all required fields marked with *');
        return;
      }
      
      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(formData.email)) {
        setError('Please enter a valid email address');
        return;
      }
      
      // Validate password length
      if (formData.password.length < 8) {
        setError('Password must be at least 8 characters long');
        return;
      }
      
      // Validate password match
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      
      // Check if user has existing IT support and hasn't acknowledged the warning yet
      if (formData.existingITSupport === 'yes' && !formData.acknowledgeExistingSupport) {
        setWarningType('support');
        setShowWarning(true);
        return; // Stop here until they acknowledge the warning
      }
      
      // Check if user has Microsoft 365 and hasn't acknowledged the warning yet
      if (formData.hasMicrosoft365 === 'yes' && !formData.acknowledgeMicrosoft365) {
        setWarningType('microsoft365');
        setShowWarning(true);
        return; // Stop here until they acknowledge the warning
      }
      
      // Send verification code without registering the user yet
      try {
        setIsSubmitting(true);
        const userData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          company: formData.companyName,
          phone: formData.phone
        };
        
        // Only send verification code without registering
        console.log('Sending verification code from SignupForm.js');
        await authService.sendVerificationCode(userData.email);
        setSuccess('Verification email sent to ' + formData.email);
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        setError(error.message || 'Failed to send verification code. Please try again.');
        return;
      }
    }
    
    if (currentStep === 2) {
      // Verify all 6 digits are entered
      if (verificationCode.some(digit => !digit)) {
        setError('Please enter the complete verification code');
        return;
      }
      
      // Verify the email with the entered code
      try {
        setIsSubmitting(true);
        const code = verificationCode.join('');
        await authService.verifyEmail(formData.email, code);
        setSuccess('Email verified successfully');
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        setError(error.message || 'Invalid verification code. Please try again.');
        return;
      }
    }
    
    if (currentStep === 3) {
      // Check required fields for payment
      const paymentFields = ['cardName', 'cardNumber', 'expiryDate', 'cvv'];
      const missingPaymentFields = paymentFields.filter(field => !formData[field]);
      
      if (missingPaymentFields.length > 0) {
        setError('Please fill in all payment details');
        return;
      }
      
      // Check agreement to terms
      if (!formData.agreeToTerms || !formData.agreeToRemoteAccess) {
        setError('You must agree to the terms and conditions to proceed');
        return;
      }
      
      // Process payment and register the user
      try {
        setIsSubmitting(true);
        
        // First, process the payment
        // This is a placeholder for your actual payment processing code
        console.log('Processing payment with details:', {
          cardName: formData.cardName,
          cardNumber: '****' + formData.cardNumber.slice(-4),
          expiryDate: formData.expiryDate
        });
        
        // After successful payment, register the user
        const registerResponse = await authService.registerAfterPayment({
          email: formData.email
        });
        
        // Log the response structure for debugging
        console.log('Registration response structure:', registerResponse);
        console.log('Registration data:', registerResponse.data);
        
        // Get the user from localStorage instead of relying on the response
        const userStr = localStorage.getItem('user');
        if (!userStr) {
          throw new Error('User data not found in localStorage after registration');
        }
        
        const user = JSON.parse(userStr);
        console.log('User from localStorage:', user);
        
        // Now create the client record
        const clientData = {
          name: formData.companyName,
          contactPerson: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            position: formData.position || 'Primary Contact'
          },
          address: {
            street: formData.address,
            city: formData.city,
            state: formData.county || '',
            postalCode: formData.postcode,
            country: 'United Kingdom'
          },
          supportContract: {
            type: 'monthly', // Default to monthly subscription
            startDate: new Date(),
            notes: `Initial signup - PC Count: ${formData.pcCount}`
          },
          notes: `Microsoft 365: ${formData.hasMicrosoft365}, Existing IT Support: ${formData.existingITSupport}`,
          tags: ['new-client', 'web-signup'],
          status: 'active',
          users: [user._id] // Link the newly created user from localStorage
        };
        
        // If they have Microsoft 365, add it to notes
        if (formData.hasMicrosoft365 === 'yes') {
          clientData.notes += '\nClient has Microsoft 365 and has acknowledged they will provide admin access.';
        }
        
        // If they have existing IT support, add it to notes
        if (formData.existingITSupport === 'yes') {
          clientData.notes += '\nClient has existing IT support and has acknowledged they may need to contact them to remove software.';
        }
        
        // Create client record using the signup-specific endpoint
        const clientResponse = await clientService.createClientDuringSignup(clientData);
        
        setSuccess('Signup completed successfully! Redirecting to dashboard...');
        
        // Redirect to dashboard after successful signup
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
        
        return; // Don't proceed to next step, we're redirecting
      } catch (err) {
        console.error('Signup error:', err);
        setError(err.message || 'An error occurred during signup. Please try again.');
        setIsSubmitting(false);
        return;
      }
    }
    
    // If all validations pass, move to the next step
    setCurrentStep(prev => prev + 1);
  };
  
  const handleAcknowledgeWarning = () => {
    if (warningType === 'support') {
      setFormData(prev => ({
        ...prev,
        acknowledgeExistingSupport: true
      }));
    } else if (warningType === 'microsoft365') {
      setFormData(prev => ({
        ...prev,
        acknowledgeMicrosoft365: true
      }));
    }
    
    setShowWarning(false);
  };
  
  const handlePrevStep = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      setIsSubmitting(true);
      await authService.resendVerification(formData.email);
      setSuccess('Verification code resent to ' + formData.email);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setError(error.message || 'Failed to resend verification code. Please try again.');
    }
  };
  
  const renderStepContent = () => {
    switch(currentStep) {
      case 1:
        return renderPersonalInfoForm();
      case 2:
        return renderEmailVerification();
      case 3:
        return renderTermsAndPayment();
      case 4:
        return renderSuccess();
      default:
        return null;
    }
  };
  
  const renderPersonalInfoForm = () => (
    <>
      <FormTitle>Create Your Account</FormTitle>
      
      <FormSection>
        <SectionTitle>Personal Information</SectionTitle>
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="firstName">First Name *</FormLabel>
            <FormInput
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="lastName">Last Name *</FormLabel>
            <FormInput
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
        </FormRow>
        
        <FormGroup fullWidth>
          <FormLabel htmlFor="companyName">Company Name *</FormLabel>
          <FormInput
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        
        <FormGroup fullWidth>
          <FormLabel htmlFor="address">Address *</FormLabel>
          <FormInput
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="city">City *</FormLabel>
            <FormInput
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="postcode">Postcode *</FormLabel>
            <FormInput
              type="text"
              id="postcode"
              name="postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
        </FormRow>
        
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="phone">Phone Number *</FormLabel>
            <FormInput
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="email">Email Address *</FormLabel>
            <FormInput
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            {formData.email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email) && (
              <div style={{ color: '#ff6b6b', fontSize: '0.8rem', marginTop: '0.5rem' }}>
                Please enter a valid email address
              </div>
            )}
          </FormGroup>
        </FormRow>
        
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="password">New Password *</FormLabel>
            <FormInput
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              minLength="8"
            />
            {formData.password && formData.password.length < 8 && (
              <div style={{ color: '#ff6b6b', fontSize: '0.8rem', marginTop: '0.5rem' }}>
                Password must be at least 8 characters long
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="confirmPassword">Confirm Password *</FormLabel>
            <FormInput
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
            />
            {formData.confirmPassword && formData.password !== formData.confirmPassword && (
              <div style={{ color: '#ff6b6b', fontSize: '0.8rem', marginTop: '0.5rem' }}>
                Passwords do not match
              </div>
            )}
          </FormGroup>
        </FormRow>
      </FormSection>
      
      <FormSection>
        <SectionTitle>Technical Information</SectionTitle>
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="pcCount">Number of PCs to Cover *</FormLabel>
            <FormInput
              type="number"
              id="pcCount"
              name="pcCount"
              min="1"
              value={formData.pcCount}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="hasMicrosoft365">Do you have Microsoft 365? *</FormLabel>
            <FormSelect
              id="hasMicrosoft365"
              name="hasMicrosoft365"
              value={formData.hasMicrosoft365}
              onChange={handleInputChange}
              required
            >
              <option value="">Please select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </FormSelect>
          </FormGroup>
        </FormRow>
        
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="existingITSupport">Do you have existing IT support? *</FormLabel>
            <FormSelect
              id="existingITSupport"
              name="existingITSupport"
              value={formData.existingITSupport}
              onChange={handleInputChange}
              required
            >
              <option value="">Please select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </FormSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="existingITSupportDetails">If yes, please provide details</FormLabel>
            <FormInput
              type="text"
              id="existingITSupportDetails"
              name="existingITSupportDetails"
              value={formData.existingITSupportDetails}
              onChange={handleInputChange}
              disabled={formData.existingITSupport !== 'yes'}
            />
          </FormGroup>
        </FormRow>
      </FormSection>
      
      <ButtonGroup>
        <SecondaryButton type="button" onClick={() => window.history.back()}>Cancel</SecondaryButton>
        <PrimaryButton type="button" onClick={handleNextStep}>Continue</PrimaryButton>
      </ButtonGroup>
    </>
  );
  
  const renderEmailVerification = () => (
    <VerificationContainer>
      <FormTitle>Verify Your Email</FormTitle>
      <p>We've sent a verification code to <strong>{formData.email}</strong></p>
      <p>Please enter the 6-digit code below:</p>
      
      <VerificationCode>
        {verificationCode.map((digit, index) => (
          <CodeInput
            key={index}
            id={`code-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleCodeChange(index, e.target.value)}
          />
        ))}
      </VerificationCode>
      
      <p>
        Didn't receive a code? <a href="#" style={{ color: '#ffd60a' }} onClick={handleResendCode}>Resend code</a>
      </p>
      
      <ButtonGroup>
        <SecondaryButton type="button" onClick={handlePrevStep}>Back</SecondaryButton>
        <PrimaryButton 
          type="button" 
          onClick={handleNextStep}
          disabled={verificationCode.some(digit => !digit)}
        >
          Verify & Continue
        </PrimaryButton>
      </ButtonGroup>
    </VerificationContainer>
  );
  
  const renderTermsAndPayment = () => (
    <>
      <FormTitle>Terms & Payment</FormTitle>
      
      <TermsContainer>
        <SectionTitle>Service Terms</SectionTitle>
        <TermsList>
          <li>Payment is required upfront before service begins.</li>
          <li>System integration will be scheduled upon payment confirmation.</li>
          <li>Support is available Monday to Friday during working hours only (9 AM - 5 PM).</li>
          <li>Our service does not include site visits; all support is provided remotely.</li>
          <li>The software installed will provide our team with full access to your PCs for troubleshooting and support purposes.</li>
          <li>We confirm that no unauthorized connections will be made to your systems.</li>
        </TermsList>
        
        <CheckboxGroup>
          <input
            type="checkbox"
            id="agreeToTerms"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={handleInputChange}
            required
          />
          <FormLabel htmlFor="agreeToTerms">I agree to the terms and conditions of service *</FormLabel>
        </CheckboxGroup>
        
        <CheckboxGroup>
          <input
            type="checkbox"
            id="agreeToRemoteAccess"
            name="agreeToRemoteAccess"
            checked={formData.agreeToRemoteAccess}
            onChange={handleInputChange}
            required
          />
          <FormLabel htmlFor="agreeToRemoteAccess">I understand and consent to remote access software being installed on covered PCs *</FormLabel>
        </CheckboxGroup>
      </TermsContainer>
      
      <PaymentForm>
        <SectionTitle>Payment Details</SectionTitle>
        <p style={{ marginBottom: '1.5rem' }}>
          Your subscription: <strong>£55/month</strong> for <strong>{formData.pcCount} PC(s)</strong>
        </p>
        
        <FormGroup fullWidth>
          <FormLabel htmlFor="cardName">Name on Card *</FormLabel>
          <FormInput
            type="text"
            id="cardName"
            name="cardName"
            value={formData.cardName}
            onChange={handleInputChange}
            required
          />
        </FormGroup>
        
        <FormGroup fullWidth>
          <FormLabel htmlFor="cardNumber">Card Number *</FormLabel>
          <FormInput
            type="text"
            id="cardNumber"
            name="cardNumber"
            value={formData.cardNumber}
            onChange={handleInputChange}
            placeholder="XXXX XXXX XXXX XXXX"
            required
          />
        </FormGroup>
        
        <FormRow>
          <FormGroup>
            <FormLabel htmlFor="expiryDate">Expiry Date *</FormLabel>
            <FormInput
              type="text"
              id="expiryDate"
              name="expiryDate"
              value={formData.expiryDate}
              onChange={handleInputChange}
              placeholder="MM/YY"
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="cvv">CVV *</FormLabel>
            <FormInput
              type="text"
              id="cvv"
              name="cvv"
              value={formData.cvv}
              onChange={handleInputChange}
              maxLength="4"
              required
            />
          </FormGroup>
        </FormRow>
      </PaymentForm>
      
      <ButtonGroup>
        <SecondaryButton type="button" onClick={handlePrevStep}>Back</SecondaryButton>
        <PrimaryButton 
          type="button" 
          onClick={handleNextStep}
          disabled={!formData.agreeToTerms || !formData.agreeToRemoteAccess}
        >
          Complete Payment
        </PrimaryButton>
      </ButtonGroup>
    </>
  );
  
  const renderSuccess = () => (
    <SuccessContainer>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
      <FormTitle>Welcome to Is IT Support!</FormTitle>
      <p>Your account has been created successfully and your payment has been processed.</p>
      <p style={{ margin: '1rem 0 2rem' }}>You will receive a confirmation email shortly with next steps.</p>
      <PrimaryButton type="button" onClick={() => window.location.href = '/client-portal'}>Go to Client Portal</PrimaryButton>
    </SuccessContainer>
  );
  
  return (
    <SignupContainer>
      {/* Loading Overlay */}
      {isSubmitting && (
        <LoadingOverlay>
          <div>
            <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
              <svg width="50" height="50" viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="20" fill="none" stroke="#3A86FF" strokeWidth="5" strokeLinecap="round">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    dur="1s"
                    from="0 25 25"
                    to="360 25 25"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
            <div>Processing your signup...</div>
          </div>
        </LoadingOverlay>
      )}
      
      {/* Warning Modal for Existing IT Support */}
      <WarningModal show={showWarning && warningType === 'support'} onClick={() => setShowWarning(false)}>
        <WarningContent show={showWarning} onClick={(e) => e.stopPropagation()}>
          <WarningTitle>Important Notice: Existing IT Support</WarningTitle>
          <WarningText>
            As you have indicated you currently have support with another company, you may need to contact them to arrange removal of all software they may have installed - most IT Companies password protect their software, and we cannot install ours until this has been removed.
          </WarningText>
          <WarningText>
            You are welcome to proceed with joining, but be aware it may delay us offering PC remote support until this has been done.
          </WarningText>
          <CheckboxGroup>
            <input
              type="checkbox"
              id="acknowledgeExistingSupport"
              name="acknowledgeExistingSupport"
              checked={formData.acknowledgeExistingSupport}
              onChange={handleInputChange}
              required
            />
            <FormLabel htmlFor="acknowledgeExistingSupport">I acknowledge and understand this requirement</FormLabel>
          </CheckboxGroup>
          <ButtonGroup>
            <PrimaryButton onClick={handleAcknowledgeWarning} disabled={!formData.acknowledgeExistingSupport}>
              Continue
            </PrimaryButton>
          </ButtonGroup>
        </WarningContent>
      </WarningModal>
      
      {/* Warning Modal for Microsoft 365 */}
      <WarningModal show={showWarning && warningType === 'microsoft365'} onClick={() => setShowWarning(false)}>
        <WarningContent show={showWarning} onClick={(e) => e.stopPropagation()}>
          <WarningTitle>Important Notice: Microsoft 365</WarningTitle>
          <WarningText>
            As you have indicated you currently have a Microsoft 365 Account, we should make you aware that as part of the service we provide, and for enhanced security, we prefer to handle administration of this account ourselves.
          </WarningText>
          <WarningText>
            We would require your Microsoft Admin Account details. If you have these to hand, we'll ask for them once signup is complete. If they are handled by a 3rd party (an IT company for example) you MUST request they relinquish the admin account details to you before we can support 365.
          </WarningText>
          <WarningText>
            If you prefer we not do this, please select No to this question.
          </WarningText>
          <CheckboxGroup>
            <input
              type="checkbox"
              id="acknowledgeMicrosoft365"
              name="acknowledgeMicrosoft365"
              checked={formData.acknowledgeMicrosoft365}
              onChange={handleInputChange}
              required
            />
            <FormLabel htmlFor="acknowledgeMicrosoft365">I acknowledge and understand this requirement</FormLabel>
          </CheckboxGroup>
          <ButtonGroup>
            <PrimaryButton onClick={handleAcknowledgeWarning} disabled={!formData.acknowledgeMicrosoft365}>
              Continue
            </PrimaryButton>
          </ButtonGroup>
        </WarningContent>
      </WarningModal>
      
      <StepIndicator>
        <Step active={currentStep >= 1} label="Account">
          1
        </Step>
        <Step active={currentStep >= 2} label="Verify">
          2
        </Step>
        <Step active={currentStep >= 3} label="Payment">
          3
        </Step>
        <Step active={currentStep >= 4} label="Done">
          4
        </Step>
      </StepIndicator>
      {/* Error and Success Messages */}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
      
      {renderStepContent()}
    </SignupContainer>
  );
};

export default SignupForm;
