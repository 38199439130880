import React from 'react';
import { Card, Collapse, Typography, Divider, Button } from 'antd';
import { FaQuestionCircle, FaTicketAlt, FaDownload, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import branding from '../../config/branding';

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const PortalHelp = () => {
  // Get dark mode preference
  const darkMode = localStorage.getItem('tacticalRmmDarkMode') === 'true';

  const faqs = [
    {
      question: 'How do I create a new support ticket?',
      answer: (
        <>
          <Paragraph>
            To create a new support ticket, navigate to the Tickets section in the sidebar menu and click on the "Create New Support Ticket" button. Fill out the form with details about your issue, including a clear title and description.
          </Paragraph>
          <Button type="primary" size="small">
            <Link to="/portal/tickets/new">Create a Ticket Now</Link>
          </Button>
        </>
      )
    },
    {
      question: 'How do I download the remote support tools?',
      answer: (
        <>
          <Paragraph>
            To download our remote support tools, go to the Downloads section in the sidebar menu. Click on the "Download Installer" button to get our comprehensive support package that includes TacticalRMM and Splashtop for remote assistance.
          </Paragraph>
          <Button type="primary" size="small">
            <Link to="/portal/downloads">Go to Downloads</Link>
          </Button>
        </>
      )
    },
    {
      question: 'What information should I include in a support ticket?',
      answer: (
        <Paragraph>
          When creating a support ticket, please include the following information:
          <ul className="list-disc pl-5 mt-2">
            <li>A clear, concise title that summarizes the issue</li>
            <li>Detailed description of the problem you're experiencing</li>
            <li>When the issue started or when you first noticed it</li>
            <li>Any error messages you've received (exact text is helpful)</li>
            <li>Steps you've already taken to try to resolve the issue</li>
            <li>How the issue is affecting your work</li>
          </ul>
        </Paragraph>
      )
    },
    {
      question: 'How quickly will my ticket be addressed?',
      answer: (
        <Paragraph>
          Our response times depend on the priority and complexity of the issue:
          <ul className="list-disc pl-5 mt-2">
            <li><strong>High Priority</strong>: Critical issues affecting multiple users or preventing work - typically addressed within 1-2 hours during business hours</li>
            <li><strong>Medium Priority</strong>: Important issues affecting productivity but with workarounds available - typically addressed within 4-8 business hours</li>
            <li><strong>Low Priority</strong>: Minor issues or feature requests - typically addressed within 1-3 business days</li>
          </ul>
          For urgent after-hours support, please call our emergency support line.
        </Paragraph>
      )
    },
    {
      question: 'How do I update an existing ticket?',
      answer: (
        <Paragraph>
          To update an existing ticket with new information:
          <ol className="list-decimal pl-5 mt-2">
            <li>Navigate to the Tickets section</li>
            <li>Find and click on the ticket you want to update</li>
            <li>Scroll to the bottom of the ticket details</li>
            <li>Use the comment field to add new information</li>
            <li>Click "Add Comment" to submit your update</li>
          </ol>
          All updates are time-stamped and will notify our support team.
        </Paragraph>
      )
    }
  ];

  return (
    <div className="portal-help">
      <div className="page-header mb-6">
        <Title level={2} className={darkMode ? 'text-white' : ''}>
          Help & Documentation
        </Title>
        <Paragraph className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
          Find answers to common questions and learn how to use the {branding.company.name} client portal.
        </Paragraph>
      </div>

      <Card 
        className={`mb-6 shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
        bordered={!darkMode}
      >
        <Title level={4} className={darkMode ? 'text-white' : ''}>
          Quick Links
        </Title>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <Link to="/portal/tickets/new">
            <Card 
              className={`h-full hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''}`}
              bordered={!darkMode}
            >
              <div className="flex flex-col items-center text-center">
                <FaTicketAlt className="text-3xl mb-2 text-green-500" />
                <Text strong className={darkMode ? 'text-white' : ''}>
                  Create Support Ticket
                </Text>
                <Text className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
                  Submit a new support request
                </Text>
              </div>
            </Card>
          </Link>
          <Link to="/portal/downloads">
            <Card 
              className={`h-full hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''}`}
              bordered={!darkMode}
            >
              <div className="flex flex-col items-center text-center">
                <FaDownload className="text-3xl mb-2 text-blue-500" />
                <Text strong className={darkMode ? 'text-white' : ''}>
                  Download Support Tools
                </Text>
                <Text className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
                  Get remote support software
                </Text>
              </div>
            </Card>
          </Link>
          <a href="mailto:support@isitsupport.co.uk">
            <Card 
              className={`h-full hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-700 text-white border-gray-600' : ''}`}
              bordered={!darkMode}
            >
              <div className="flex flex-col items-center text-center">
                <FaEnvelope className="text-3xl mb-2 text-yellow-500" />
                <Text strong className={darkMode ? 'text-white' : ''}>
                  Email Support
                </Text>
                <Text className={darkMode ? 'text-gray-300' : 'text-gray-500'}>
                  Contact us directly
                </Text>
              </div>
            </Card>
          </a>
        </div>
      </Card>

      <Card 
        title="Frequently Asked Questions" 
        className={`mb-6 shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
        bordered={!darkMode}
        headStyle={{ borderBottom: darkMode ? '1px solid #4B5563' : undefined, color: darkMode ? '#fff' : undefined }}
      >
        <Collapse 
          accordion 
          bordered={false} 
          className={darkMode ? 'bg-gray-800' : ''}
        >
          {faqs.map((faq, index) => (
            <Panel 
              header={
                <div className="flex items-center">
                  <FaQuestionCircle className="mr-2 text-green-500" />
                  <span className={darkMode ? 'text-white' : ''}>{faq.question}</span>
                </div>
              } 
              key={index}
              className={darkMode ? 'bg-gray-700 border-0 mb-2 rounded' : 'bg-gray-50 border-0 mb-2 rounded'}
            >
              <div className={darkMode ? 'text-gray-300' : ''}>
                {faq.answer}
              </div>
            </Panel>
          ))}
        </Collapse>
      </Card>

      <Card 
        title="Contact Information" 
        className={`shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
        bordered={!darkMode}
        headStyle={{ borderBottom: darkMode ? '1px solid #4B5563' : undefined, color: darkMode ? '#fff' : undefined }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <Title level={5} className={darkMode ? 'text-white' : ''}>
              Support Hours
            </Title>
            <Paragraph className={darkMode ? 'text-gray-300' : ''}>
              <strong>Monday - Friday:</strong> 9:00 AM - 5:30 PM<br />
              <strong>Saturday - Sunday:</strong> Closed (Emergency support only)
            </Paragraph>
            <Paragraph className={darkMode ? 'text-gray-300' : ''}>
              <strong>Emergency After-Hours Support:</strong> Available for critical issues
            </Paragraph>
          </div>
          <div>
            <Title level={5} className={darkMode ? 'text-white' : ''}>
              Contact Methods
            </Title>
            <Paragraph className={darkMode ? 'text-gray-300' : ''}>
              <div className="flex items-center mb-2">
                <FaEnvelope className="mr-2 text-green-500" />
                <a href="mailto:support@isitsupport.co.uk" className={darkMode ? 'text-blue-400' : 'text-blue-600'}>
                  support@isitsupport.co.uk
                </a>
              </div>
              <div className="flex items-center mb-2">
                <FaPhone className="mr-2 text-green-500" />
                <a href="tel:+441234567890" className={darkMode ? 'text-blue-400' : 'text-blue-600'}>
                  +44 (0) 1234 567890
                </a>
              </div>
              <div className="flex items-center">
                <FaTicketAlt className="mr-2 text-green-500" />
                <Link to="/portal/tickets/new" className={darkMode ? 'text-blue-400' : 'text-blue-600'}>
                  Create a Support Ticket
                </Link>
              </div>
            </Paragraph>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PortalHelp;
