import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  max-width: ${props => props.width || '500px'};
  width: 100%;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(28, 54, 85, 0.95), rgba(30, 41, 59, 0.95));
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  border: 1px solid rgba(58, 134, 255, 0.2);
  color: white;
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
`;

const ModalTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
`;

const ModalBody = styled.div`
  margin-bottom: 1.5rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: ${props => props.justify || 'flex-end'};
  gap: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1rem;
`;

const PrimaryButton = styled.button`
  background: linear-gradient(to right, #ffd60a, #ff9e2c);
  color: #333;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 12px rgba(255, 214, 10, 0.3);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(255, 214, 10, 0.4);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(255, 214, 10, 0.3);
  }
`;

const SecondaryButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const SecureModal = ({ 
  isOpen, 
  title, 
  children, 
  width,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryClick,
  onSecondaryClick,
  footerJustify,
  preventOutsideClick = false
}) => {
  const modalRef = useRef(null);

  // Prevent scrolling of the body when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (preventOutsideClick) return;
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onSecondaryClick && onSecondaryClick();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContainer ref={modalRef} width={width}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter justify={footerJustify}>
          {secondaryButtonText && (
            <SecondaryButton onClick={onSecondaryClick}>
              {secondaryButtonText}
            </SecondaryButton>
          )}
          {primaryButtonText && (
            <PrimaryButton onClick={onPrimaryClick}>
              {primaryButtonText}
            </PrimaryButton>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export { SecureModal, PrimaryButton, SecondaryButton };
