import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash, FaClock } from 'react-icons/fa';
import { SecureModal, PrimaryButton } from './SecureModal';

const PasswordContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${props => props.timeLeft < 30 ? '#ff6b6b' : '#ffd60a'};
`;

const PasswordField = styled.div`
  position: relative;
  margin: 1rem 0;
`;

const PasswordInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  padding-right: 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  letter-spacing: ${props => props.showPassword ? 'normal' : '0.25em'};
  font-family: monospace;
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding: 0.5rem;
  
  &:hover {
    color: white;
  }
`;

const WarningText = styled.p`
  color: #ff9e2c;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const InfoText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.5;
`;

const CopyButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 1rem;
`;

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const SecurePasswordModal = ({ 
  isOpen, 
  onClose, 
  password, 
  serviceName,
  onPasswordRetrieved
 }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
  const [passwordRevealed, setPasswordRevealed] = useState(false);
  const [passwordCopied, setPasswordCopied] = useState(false);

  // Handle timer countdown
  useEffect(() => {
    if (!isOpen || timeLeft <= 0) return;
    
    const timer = setInterval(() => {
      setTimeLeft(prev => prev - 1);
    }, 1000);
    
    return () => clearInterval(timer);
  }, [isOpen, timeLeft]);

  // Auto-close when timer reaches 0
  useEffect(() => {
    if (timeLeft <= 0 && isOpen) {
      handlePasswordRetrieved();
    }
  }, [timeLeft, isOpen]);

  const handleUnderstand = useCallback(() => {
    setPasswordRevealed(true);
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(password);
    setPasswordCopied(true);
    
    // Reset the copied state after 3 seconds
    setTimeout(() => {
      setPasswordCopied(false);
    }, 3000);
  }, [password]);

  const handlePasswordRetrieved = useCallback(() => {
    if (onPasswordRetrieved) {
      onPasswordRetrieved();
    }
    onClose();
  }, [onClose, onPasswordRetrieved]);

  return (
    <SecureModal
      isOpen={isOpen}
      title={`${serviceName} Password`}
      primaryButtonText={passwordRevealed ? "Password Retrieved" : "I Understand"}
      onPrimaryClick={passwordRevealed ? handlePasswordRetrieved : handleUnderstand}
      preventOutsideClick={true}
      width="550px"
    >
      {!passwordRevealed ? (
        <>
          <InfoText>
            For security reasons, this password will only be displayed once. The window will automatically close after 3 minutes, and the password will not be available again after closing this window.
          </InfoText>
          <WarningText>
            <FaClock /> This window will close in {formatTime(timeLeft)}
          </WarningText>
          <TimerContainer timeLeft={timeLeft}>
            <FaClock style={{ marginRight: '0.5rem' }} /> {formatTime(timeLeft)}
          </TimerContainer>
        </>
      ) : (
        <>
          <InfoText>
            Please copy this password now. It will not be displayed again after you close this window.
          </InfoText>
          <PasswordContainer>
            <PasswordField>
              <PasswordInput 
                type={showPassword ? "text" : "password"} 
                value={password} 
                readOnly 
                showPassword={showPassword}
              />
              <ToggleButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </ToggleButton>
            </PasswordField>
            <CopyButton onClick={handleCopyToClipboard}>
              {passwordCopied ? "Copied!" : "Copy Password"}
            </CopyButton>
          </PasswordContainer>
          <WarningText>
            <FaClock /> This window will close in {formatTime(timeLeft)}
          </WarningText>
          <TimerContainer timeLeft={timeLeft}>
            <FaClock style={{ marginRight: '0.5rem' }} /> {formatTime(timeLeft)}
          </TimerContainer>
        </>
      )}
    </SecureModal>
  );
};

export default SecurePasswordModal;
