import axios from 'axios';
import authService from './auth.service';

const API_URL = process.env.REACT_APP_API_URL || '/api';

class BillingService {
  async getBillingHistory() {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.get(`${API_URL}/billing/history`, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async getBillingDetails() {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.get(`${API_URL}/billing/details`, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async updateBillingInfo(billingData) {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.put(`${API_URL}/billing/update`, billingData, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async processPayment(paymentData) {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.post(`${API_URL}/billing/payment`, paymentData, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async createCheckoutSession(planData) {
    // Get token directly from localStorage for consistency
    const token = localStorage.getItem('token');
    console.log('Token for checkout session:', token);
    
    // Create headers with token
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    console.log('Headers for checkout session:', headers);
    
    try {
      const response = await axios.post(`${API_URL}/billing/create-checkout-session`, planData, {
        withCredentials: true,
        headers: headers
      });
      return response.data;
    } catch (error) {
      console.error('Checkout session error:', error.response?.data);
      throw error;
    }
  }

  async createAdditionalMinutesSession(minutesData) {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.post(`${API_URL}/billing/additional-minutes`, minutesData, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async getSubscription() {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.get(`${API_URL}/billing/subscription`, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async cancelSubscription() {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.post(`${API_URL}/billing/cancel-subscription`, {}, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async updatePaymentMethod() {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    
    const response = await axios.post(`${API_URL}/billing/update-payment-method`, {}, {
      withCredentials: true,
      headers: headers
    });
    return response.data;
  }

  async getBillingInfo() {
    try {
      // Try to get subscription info
      return await this.getSubscription();
    } catch (error) {
      // If 404 error (no subscription), return default billing info
      if (error.response && error.response.status === 404) {
        return {
          data: {
            status: 'success',
            data: {
              currentPlan: 'Free Trial',
              price: 0,
              billingCycle: 'monthly',
              nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
              paymentMethod: {
                type: 'none',
                last4: '',
                brand: '',
                expiryMonth: '',
                expiryYear: ''
              }
            }
          }
        };
      }
      // Re-throw other errors
      throw error;
    }
  }

  async getInvoices() {
    try {
      // Try to get billing history
      return await this.getBillingHistory();
    } catch (error) {
      // If 404 error (no billing history), return empty array
      if (error.response && error.response.status === 404) {
        return {
          data: {
            status: 'success',
            data: []
          }
        };
      }
      // Re-throw other errors
      throw error;
    }
  }
}

export default new BillingService();
