import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:5000'}/api`;
const TACTICAL_URL = process.env.REACT_APP_TACTICAL_URL;

class MonitoringService {
  /**
   * Get system health overview
   * @returns {Promise<Object>} - System health data
   */
  getSystemHealth() {
    return axios.get(`${API_URL}/monitoring/health`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get device health metrics for the current user
   * @returns {Promise<Object>} - Device health metrics
   */
  getDeviceHealthMetrics() {
    return axios.get(`${API_URL}/monitoring/devices`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get system metrics for dashboard
   * @returns {Promise<Object>} - System metrics data
   */
  getSystemMetrics() {
    return axios.get(`${API_URL}/monitoring/metrics`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get container status
   * @returns {Promise<Array>} - Container status data
   */
  getContainerStatus() {
    return axios.get(`${API_URL}/monitoring/containers`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get disk space usage
   * @returns {Promise<Array>} - Disk space data
   */
  getDiskSpace() {
    return axios.get(`${API_URL}/monitoring/disk`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get device issues summary from TacticalRMM
   * @returns {Promise<Object>} - Device issues summary
   */
  getDeviceIssues() {
    // Get authentication headers
    const headers = authHeader();
    
    // Check if we have authentication headers before making the request
    if (!headers.Authorization) {
      console.warn('No authentication token available for getDeviceIssues request');
      // Return default structure immediately without making the API call
      return Promise.resolve({
        totalDevices: 0,
        offlineDevices: 0,
        needsPatching: 0,
        diskSpaceIssues: 0,
        highCpuIssues: 0,
        highMemoryIssues: 0,
        alerts: {
          total: 0,
          critical: 0,
          warning: 0,
          info: 0
        },
        recentAlerts: [],
        error: 'Authentication required'
      });
    }
    
    // Make the API request with authentication
    return axios.get(`${API_URL}/monitoring/device-issues`, { headers })
      .then(response => {
        if (response.data && response.data.data) {
          return response.data.data;
        } else {
          console.error('Unexpected response format:', response);
          throw new Error('Unexpected response format');
        }
      })
      .catch(error => {
        console.error('Error fetching device issues:', error);
        
        // Handle authentication errors specifically
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          console.error('Authentication error - clearing user session');
          localStorage.removeItem('user');
        }
        
        // Return a default structure if there's an error
        return {
          totalDevices: 0,
          offlineDevices: 0,
          needsPatching: 0,
          diskSpaceIssues: 0,
          highCpuIssues: 0,
          highMemoryIssues: 0,
          alerts: {
            total: 0,
            critical: 0,
            warning: 0,
            info: 0
          },
          recentAlerts: [],
          error: error.response?.data?.message || error.message
        };
      });
  }

  /**
   * Get alerts for all devices or a specific device
   * @param {String} deviceId - Optional device ID to filter alerts
   * @returns {Promise<Array>} - List of alerts
   */
  getAlerts(deviceId = null) {
    const url = deviceId
      ? `${API_URL}/monitoring/alerts/${deviceId}`
      : `${API_URL}/monitoring/alerts`;
      
    return axios.get(url, { headers: authHeader() })
      .then(response => response.data.data.alerts);
  }

  /**
   * Get critical port status
   * @returns {Promise<Array>} - Port status data
   */
  getPortStatus() {
    return axios.get(`${API_URL}/monitoring/ports`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get API availability status
   * @returns {Promise<Object>} - API status data
   */
  getApiStatus() {
    return axios.get(`${API_URL}/monitoring/api`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get backup status
   * @returns {Promise<Object>} - Backup status data
   */
  getBackupStatus() {
    return axios.get(`${API_URL}/monitoring/backup`, { headers: authHeader() })
      .then(response => response.data.data);
  }

  /**
   * Get SSL certificate status
   * @returns {Promise<Object>} - SSL certificate status data
   */
  getSSLStatus() {
    return axios.get(`${API_URL}/monitoring/ssl`, { headers: authHeader() })
      .then(response => response.data.data);
  }
}

export default new MonitoringService();
