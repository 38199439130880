import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCreditCard, FaFileInvoiceDollar, FaHistory, FaDownload, FaExclamationTriangle } from 'react-icons/fa';
import billingService from '../../services/billing.service';

const PortalBillingDashboard = () => {
  const [billingInfo, setBillingInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      try {
        setLoading(true);
        
        // Set default billing info
        const defaultBillingInfo = {
          planName: 'Is IT Support Plan',
          planPrice: 55,
          renewalDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          minutesRemaining: 60,
          paymentMethod: {
            brand: 'Visa',
            last4: '4242'
          },
          features: [
            'Remote monitoring and management',
            'Email and phone support',
            'Security updates and patches',
            'Remote access with RustDesk'
          ]
        };
        
        try {
          // Try to get subscription details
          const subscriptionResponse = await billingService.getSubscription();
          console.log('Subscription response:', subscriptionResponse);
          
          if (subscriptionResponse && subscriptionResponse.data && subscriptionResponse.data.status === 'success') {
            const subData = subscriptionResponse.data.data;
            
            // Transform the subscription data into the format expected by the component
            setBillingInfo({
              planName: subData.plan?.name || defaultBillingInfo.planName,
              planPrice: subData.plan?.amount ? (subData.plan.amount / 100) : defaultBillingInfo.planPrice,
              renewalDate: subData.currentPeriodEnd || defaultBillingInfo.renewalDate,
              minutesRemaining: subData.minutesRemaining || defaultBillingInfo.minutesRemaining,
              paymentMethod: subData.paymentMethod || defaultBillingInfo.paymentMethod,
              features: defaultBillingInfo.features
            });
          } else {
            // Use default values
            setBillingInfo(defaultBillingInfo);
          }
        } catch (subscriptionError) {
          console.log('Error fetching subscription, using defaults:', subscriptionError);
          // If endpoint returns 404 or any error, use default values
          setBillingInfo(defaultBillingInfo);
        }
        
        // Set default invoices
        const defaultInvoices = [
          {
            id: 'inv_001',
            number: '2025-001',
            date: new Date().toISOString(),
            amount: 49.99,
            status: 'Paid',
            downloadUrl: '#'
          }
        ];
        
        try {
          // Try to fetch billing history for invoices
          const historyResponse = await billingService.getBillingHistory();
          console.log('Billing history response:', historyResponse);
          
          if (historyResponse && historyResponse.data && historyResponse.data.status === 'success') {
            setInvoices(historyResponse.data.data || defaultInvoices);
          } else {
            setInvoices(defaultInvoices);
          }
        } catch (historyError) {
          console.log('Error fetching history, using defaults:', historyError);
          // If endpoint returns 404 or any error, use default values
          setInvoices(defaultInvoices);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching billing info:', err);
        setError('Failed to load billing information. Please try again later.');
        setLoading(false);
      }
    };

    fetchBillingInfo();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <FaExclamationTriangle className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!billingInfo) {
    return (
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <FaExclamationTriangle className="h-5 w-5 text-yellow-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">No billing information available. Please set up a subscription.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Current Plan */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Current Plan</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="flex justify-between">
                  <h3 className="text-lg font-medium text-gray-900">{billingInfo.planName}</h3>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Active
                  </span>
                </div>
                <div className="mt-2">
                  <span className="text-2xl font-bold text-gray-900">£{billingInfo.planPrice}</span>
                  <span className="text-gray-500 ml-1">/month</span>
                </div>
                <div className="mt-1 text-sm text-gray-500">
                  Renews on {new Date(billingInfo.renewalDate).toLocaleDateString()}
                </div>
                <div className="flex justify-between text-sm mt-2">
                  <span className="text-gray-500">Support minutes:</span>
                  <span className="font-medium">{billingInfo.minutesRemaining} remaining</span>
                </div>
                <div className="flex justify-between text-sm mt-2">
                  <span className="text-gray-500">Payment method:</span>
                  <span className="font-medium">{billingInfo.paymentMethod.brand} ending in {billingInfo.paymentMethod.last4}</span>
                </div>
              </div>
              <div className="mt-4">
                <button
                  onClick={async () => {
                    try {
                      const response = await billingService.updatePaymentMethod();
                      if (response && response.data && response.data.url) {
                        window.location.href = response.data.url;
                      } else {
                        alert('Unable to update payment method. Please try again later.');
                      }
                    } catch (error) {
                      console.error('Error updating payment method:', error);
                      alert('Unable to update payment method. Please try again later.');
                    }
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Update Payment Method
                </button>
              </div>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-3">Plan Features</h4>
              <ul className="space-y-2">
                {billingInfo.features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-sm text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
              {/* Change Plan button removed as there is only one plan */}
            </div>
          </div>
        </div>
      </div>

      {/* Additional Minutes */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Purchase Additional Support Minutes</h2>
          <p className="text-sm text-gray-500 mb-4">
            Need more support time? Purchase additional minutes to add to your current plan.
          </p>
          
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            <button
              onClick={async () => {
                try {
                  const response = await billingService.createAdditionalMinutesSession({ minutes: 15 });
                  if (response && response.data && response.data.url) {
                    window.location.href = response.data.url;
                  } else {
                    alert('Unable to purchase additional minutes. Please try again later.');
                  }
                } catch (error) {
                  console.error('Error purchasing additional minutes:', error);
                  alert('Unable to purchase additional minutes. Please try again later.');
                }
              }}
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              15 Minutes (£10)
            </button>
            <button
              onClick={async () => {
                try {
                  const response = await billingService.createAdditionalMinutesSession({ minutes: 30 });
                  if (response && response.data && response.data.url) {
                    window.location.href = response.data.url;
                  } else {
                    alert('Unable to purchase additional minutes. Please try again later.');
                  }
                } catch (error) {
                  console.error('Error purchasing additional minutes:', error);
                  alert('Unable to purchase additional minutes. Please try again later.');
                }
              }}
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              30 Minutes (£20)
            </button>
            <button
              onClick={async () => {
                try {
                  const response = await billingService.createAdditionalMinutesSession({ minutes: 60 });
                  if (response && response.data && response.data.url) {
                    window.location.href = response.data.url;
                  } else {
                    alert('Unable to purchase additional minutes. Please try again later.');
                  }
                } catch (error) {
                  console.error('Error purchasing additional minutes:', error);
                  alert('Unable to purchase additional minutes. Please try again later.');
                }
              }}
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              60 Minutes (£40)
            </button>
          </div>
        </div>
      </div>

      {/* Recent Invoices */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium text-gray-900">Recent Invoices</h2>
            <Link
              to="/portal/billing/history"
              className="text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              <div className="flex items-center">
                <FaHistory className="mr-1 h-4 w-4" />
                View All
              </div>
            </Link>
          </div>
        </div>
        <div className="bg-gray-50 overflow-hidden">
          {(!invoices || invoices.length === 0) ? (
            <div className="text-center py-6">
              <p className="text-sm text-gray-500">Your payment history will appear here after your first payment</p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {invoices.slice(0, 5).map((invoice) => (
                <li key={invoice.id || invoice._id} className="px-6 py-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        Invoice #{invoice.number || invoice.invoiceNumber || 'N/A'}
                      </p>
                      <p className="text-sm text-gray-500">
                        {new Date(invoice.date || invoice.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mr-4">
                        {invoice.status || 'Paid'}
                      </span>
                      <span className="text-sm font-medium text-gray-900">
                        £{invoice.amount}
                      </span>
                      <a
                        href={invoice.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-4 text-blue-600 hover:text-blue-500"
                      >
                        <FaDownload className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Payment Method */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-900">Payment Method</h2>
            <Link to="/portal/billing/payment-method" className="text-sm font-medium text-blue-600 hover:text-blue-500">Edit</Link>
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <div className="h-12 w-12 rounded-md bg-gray-200 flex items-center justify-center">
                <FaCreditCard className="h-6 w-6 text-gray-500" />
              </div>
            </div>
            <div className="ml-4">
              <div className="flex items-center">
                <h3 className="text-sm font-medium text-gray-900">{billingInfo.paymentMethod.brand} •••• {billingInfo.paymentMethod.last4}</h3>
                <div className="ml-4 flex-shrink-0">
                  <span className="text-gray-300">|</span>
                  <button className="text-sm font-medium text-red-600 hover:text-red-500 ml-2">Remove</button>
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-1">Expires {billingInfo.paymentMethod.expiryMonth}/{billingInfo.paymentMethod.expiryYear}</p>
              <div className="mt-2 flex items-center">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Default
                </span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Link to="/portal/billing/payment-method" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add Payment Method
            </Link>
          </div>
        </div>
      </div>

      {/* Billing Address */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-900">Billing Address</h2>
            <button
              onClick={async () => {
                try {
                  const response = await billingService.updatePaymentMethod();
                  if (response && response.data && response.data.url) {
                    window.location.href = response.data.url;
                  } else {
                    alert('Unable to update billing address. Please try again later.');
                  }
                } catch (error) {
                  console.error('Error updating billing address:', error);
                  alert('Unable to update billing address. Please try again later.');
                }
              }}
              className="text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              Edit
            </button>
          </div>
          {!billingInfo.billingAddress || (!billingInfo.billingAddress.line1 && !billingInfo.billingAddress.city) ? (
            <div className="text-center py-4">
              <p className="text-sm text-gray-500">No billing address on file. Please update your payment method to add a billing address.</p>
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              <p>{billingInfo.billingAddress.name}</p>
              <p>{billingInfo.billingAddress.line1}</p>
              {billingInfo.billingAddress.line2 && <p>{billingInfo.billingAddress.line2}</p>}
              <p>
                {billingInfo.billingAddress.city}
                {billingInfo.billingAddress.state && `, ${billingInfo.billingAddress.state}`} 
                {billingInfo.billingAddress.postalCode}
              </p>
              <p>{billingInfo.billingAddress.country === 'GB' ? 'United Kingdom' : billingInfo.billingAddress.country}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortalBillingDashboard;
