import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCreditCard, FaArrowLeft } from 'react-icons/fa';
import billingService from '../../services/billing.service';
import { useAuth } from '../../context/auth.context';

const StripeCheckout = () => {
  const [selectedPlan, setSelectedPlan] = useState('basic');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  // Check if user is a client and redirect to portal if needed
  useEffect(() => {
    if (user && user.role === 'client') {
      // Get the current path and replace /billing with /portal/billing
      const currentPath = location.pathname;
      const portalPath = currentPath.replace('/billing', '/portal/billing');
      
      // Preserve any query parameters
      const queryParams = location.search;
      
      // Redirect to the portal path
      navigate(`${portalPath}${queryParams}`);
    }
  }, [user, navigate, location]);
  
  useEffect(() => {
    // Check for success or cancel query params
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const minutes = queryParams.get('minutes');
    
    if (sessionId) {
      setSuccess(true);
    }
    
    // If minutes parameter is present, automatically process additional minutes purchase
    if (minutes && !sessionId) {
      handleAdditionalMinutes(parseInt(minutes, 10));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
  // We're intentionally not including handleAdditionalMinutes in the dependency array to avoid infinite loops
  
  const plans = [
    {
      id: 'basic',
      name: 'Basic Support Plan',
      price: '£50',
      cycle: 'monthly',
      minutes: 90,
      features: [
        '90 minutes of support per month',
        'Email support',
        'Remote access',
        'Basic maintenance',
        'Standard reporting'
      ]
    },
    {
      id: 'standard',
      name: 'Standard Support Plan',
      price: '£90',
      cycle: 'monthly',
      minutes: 180,
      features: [
        '180 minutes of support per month',
        'Priority email support',
        'Remote access',
        'Automated maintenance',
        'Advanced reporting'
      ]
    },
    {
      id: 'premium',
      name: 'Premium Support Plan',
      price: '£140',
      cycle: 'monthly',
      minutes: 300,
      features: [
        '300 minutes of support per month',
        '24/7 dedicated support',
        'Remote access',
        'Automated maintenance',
        'Custom reporting',
        'Dedicated account manager'
      ]
    }
  ];
  
  const handlePlanSelect = async (planId) => {
    console.log('Plan selected:', planId);
    setSelectedPlan(planId);
    setLoading(true);
    setError(null);
    
    try {
      console.log('About to create checkout session for plan:', planId);
      console.log('Authentication token:', localStorage.getItem('token'));
      
      // Create checkout session
      const response = await billingService.createCheckoutSession({ plan: planId });
      console.log('Checkout session response:', response);
      
      if (response.status === 'success' && response.data && response.data.url) {
        console.log('Redirecting to Stripe Checkout URL:', response.data.url);
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        console.error('Invalid checkout session response:', response);
        setError('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Checkout session error:', err);
      console.error('Error status:', err.response?.status);
      console.error('Error data:', err.response?.data);
      
      // Check if this is an authentication error
      if (err.response?.status === 401) {
        console.error('Authentication error - token may be invalid or missing');
        setError('Authentication error. Please try logging in again.');
      } else {
        setError(err.response?.data?.message || 'An error occurred during checkout');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleAdditionalMinutes = async (minutes) => {
    setLoading(true);
    setError(null);
    
    try {
      // Create checkout session for additional minutes
      const response = await billingService.createAdditionalMinutesSession({ minutes });
      
      if (response.status === 'success' && response.data && response.data.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        setError('Failed to create checkout session');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred during checkout');
    } finally {
      setLoading(false);
    }
  };
  
  if (success) {
    return (
      <div className="bg-white shadow rounded-lg overflow-hidden p-6 text-center">
        <div className="rounded-full bg-green-100 p-3 mx-auto w-16 h-16 flex items-center justify-center mb-4">
          <svg className="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">Payment Successful!</h2>
        <p className="text-gray-600 mb-6">Your subscription has been processed successfully.</p>
        <button
          onClick={() => navigate('/billing')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Go to Billing Dashboard
        </button>
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Choose a Support Plan</h1>
        <button
          onClick={() => navigate(user && user.role === 'client' ? '/portal/billing' : '/billing')}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <FaArrowLeft className="-ml-0.5 mr-2 h-4 w-4" />
          Back
        </button>
      </div>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <>
          {error && (
            <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
          )}
          
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
            {plans.map((plan) => (
              <div key={plan.id} className="bg-white shadow rounded-lg overflow-hidden border border-gray-200">
                <div className="p-6">
                  <h2 className="text-xl font-semibold text-gray-900">{plan.name}</h2>
                  <p className="mt-4 text-3xl font-bold text-gray-900">{plan.price}<span className="text-sm font-normal text-gray-500">/{plan.cycle}</span></p>
                  
                  <ul className="mt-6 space-y-4">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <svg className="h-5 w-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  
                  <button
                    onClick={() => handlePlanSelect(plan.id)}
                    className="mt-8 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    disabled={loading}
                  >
                    Select Plan
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          <div className="bg-white shadow rounded-lg overflow-hidden mt-8">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Need Additional Support Minutes?</h2>
              <p className="text-gray-600 mb-4">Purchase additional support minutes if you need more than what's included in your plan.</p>
              
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <button
                  onClick={() => handleAdditionalMinutes(15)}
                  className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  disabled={loading}
                >
                  <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
                  15 Minutes (£10)
                </button>
                <button
                  onClick={() => handleAdditionalMinutes(30)}
                  className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  disabled={loading}
                >
                  <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
                  30 Minutes (£20)
                </button>
                <button
                  onClick={() => handleAdditionalMinutes(60)}
                  className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  disabled={loading}
                >
                  <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
                  60 Minutes (£40)
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StripeCheckout;
