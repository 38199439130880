import React from 'react';

/**
 * LoadingSpinner component for displaying a loading state
 * @param {Object} props - Component props
 * @param {string} props.message - Message to display while loading
 * @param {string} props.size - Size of the spinner (sm, md, lg)
 * @param {string} props.color - Color of the spinner (blue, green, red, yellow, gray)
 */
const LoadingSpinner = ({
  message = 'Loading...',
  size = 'md',
  color = 'blue',
}) => {
  // Size classes
  const sizeClasses = {
    sm: 'h-4 w-4',
    md: 'h-8 w-8',
    lg: 'h-12 w-12',
  };

  // Color classes
  const colorClasses = {
    blue: 'text-blue-600',
    green: 'text-green-600',
    red: 'text-red-600',
    yellow: 'text-yellow-600',
    gray: 'text-gray-600',
  };

  return (
    <div className="flex flex-col items-center justify-center p-8">
      <div className={`animate-spin rounded-full border-t-2 border-b-2 border-current
        ${sizeClasses[size] || sizeClasses.md} ${colorClasses[color] || colorClasses.blue}`}>
      </div>
      {message && <p className="mt-4 text-gray-600">{message}</p>}
    </div>
  );
};

export default LoadingSpinner;
