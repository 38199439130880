import React, { useState } from 'react';
import axios from 'axios';

const TestApi = () => {
  const [email, setEmail] = useState('test@example.com');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult('Sending request...');

    try {
      // Test the API directly
      const response = await axios.post('/api/auth/send-verification-code', { email });
      setResult(`Success: ${JSON.stringify(response.data)}`);
    } catch (error) {
      setResult(`Error: ${error.message}`);
      if (error.response) {
        setResult(prev => `${prev}\nStatus: ${error.response.status}\nData: ${JSON.stringify(error.response.data)}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Test API</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input 
            type="email" 
            id="email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{ marginLeft: '10px' }}
          />
        </div>
        <button 
          type="submit" 
          disabled={loading}
          style={{ marginTop: '10px', padding: '5px 10px' }}
        >
          {loading ? 'Sending...' : 'Send Verification Code'}
        </button>
      </form>
      <div style={{ marginTop: '20px' }}>
        <h2>Result:</h2>
        <pre style={{ whiteSpace: 'pre-wrap', backgroundColor: '#f5f5f5', padding: '10px' }}>
          {result}
        </pre>
      </div>
    </div>
  );
};

export default TestApi;
