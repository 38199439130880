import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Button, Spin, Alert } from 'antd';
import { 
  FaTicketAlt, 
  FaDesktop, 
  FaDownload, 
  FaCalendarAlt,
  FaExclamationTriangle
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import branding from '../../config/branding';

const PortalDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    openTickets: 0,
    devices: 0,
    lastLogin: new Date().toLocaleDateString(),
    alerts: 0
  });

  // Mock data for demonstration
  useEffect(() => {
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setStats({
        openTickets: 2,
        devices: 5,
        lastLogin: new Date().toLocaleDateString(),
        alerts: 1
      });
      setLoading(false);
    }, 1000);
  }, []);

  // Get dark mode preference
  const darkMode = localStorage.getItem('tacticalRmmDarkMode') === 'true';

  return (
    <div className={`portal-dashboard ${darkMode ? 'dark-mode' : ''}`}>
      <div className="page-header mb-6">
        <h1 className="text-3xl font-bold">
          Welcome to {branding.company.name} Client Portal
        </h1>
        <p className="text-gray-500 dark:text-gray-400 mt-2">
          View your support tickets, devices, and download support tools
        </p>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spin size="large" tip="Loading dashboard..." />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]} className="mb-6">
            <Col xs={24} sm={12} md={6}>
              <Card 
                className={`h-full shadow-md hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
              >
                <Statistic 
                  title={<span className={darkMode ? 'text-gray-300' : ''}>Open Tickets</span>}
                  value={stats.openTickets} 
                  prefix={<FaTicketAlt className="mr-2 text-yellow-500" />} 
                  valueStyle={{ color: darkMode ? '#fff' : '#1890ff' }}
                />
                <Button type="link" className="p-0 mt-2">
                  <Link to="/portal/tickets">View Tickets</Link>
                </Button>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card 
                className={`h-full shadow-md hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
              >
                <Statistic 
                  title={<span className={darkMode ? 'text-gray-300' : ''}>Managed Devices</span>}
                  value={stats.devices} 
                  prefix={<FaDesktop className="mr-2 text-blue-500" />} 
                  valueStyle={{ color: darkMode ? '#fff' : '#1890ff' }}
                />
                <Button type="link" className="p-0 mt-2" disabled>
                  View Devices
                </Button>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card 
                className={`h-full shadow-md hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
              >
                <Statistic 
                  title={<span className={darkMode ? 'text-gray-300' : ''}>Last Login</span>}
                  value={stats.lastLogin} 
                  prefix={<FaCalendarAlt className="mr-2 text-green-500" />} 
                  valueStyle={{ color: darkMode ? '#fff' : '#1890ff' }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card 
                className={`h-full shadow-md hover:shadow-lg transition-shadow ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
              >
                <Statistic 
                  title={<span className={darkMode ? 'text-gray-300' : ''}>Alerts</span>}
                  value={stats.alerts} 
                  prefix={<FaExclamationTriangle className="mr-2 text-red-500" />} 
                  valueStyle={{ color: darkMode ? '#fff' : stats.alerts > 0 ? '#ff4d4f' : '#1890ff' }}
                />
                <Button type="link" className="p-0 mt-2" disabled={stats.alerts === 0}>
                  View Alerts
                </Button>
              </Card>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className="mb-6">
            <Col xs={24}>
              <Card 
                title="Quick Actions" 
                className={`shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
                headStyle={{ borderBottom: darkMode ? '1px solid #4B5563' : undefined, color: darkMode ? '#fff' : undefined }}
              >
                <div className="flex flex-wrap gap-4">
                  <Button type="primary" size="large" icon={<FaTicketAlt className="mr-2" />}>
                    <Link to="/portal/tickets/new">Create Support Ticket</Link>
                  </Button>
                  <Button type="default" size="large" icon={<FaDownload className="mr-2" />}>
                    <Link to="/portal/downloads">Download Support Tools</Link>
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>

          {stats.alerts > 0 && (
            <Alert
              message="System Alert"
              description="There is an issue with one of your devices that requires attention. Please contact support for assistance."
              type="warning"
              showIcon
              className="mb-6"
            />
          )}

          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card 
                title="Recent Activity" 
                className={`shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
                bordered={!darkMode}
                headStyle={{ borderBottom: darkMode ? '1px solid #4B5563' : undefined, color: darkMode ? '#fff' : undefined }}
              >
                <p className={darkMode ? 'text-gray-300' : ''}>
                  No recent activity to display.
                </p>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PortalDashboard;
