import React, { useState, useEffect } from 'react';
import { 
  FaUsersCog, 
  FaBuilding, 
  FaSearch, 
  FaSync,
  FaServer,
  FaDesktop,
  FaExclamationTriangle,
  FaCheck,
  FaPlus,
  FaEdit,
  FaTrash,
  FaLink,
  FaUnlink
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import clientService from '../../services/client.service';
import deviceService from '../../services/device.service';

// No mock data - we'll only use real data from the API

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    website: '',
    notes: ''
  });
  const [tacticalRmmStatus, setTacticalRmmStatus] = useState({
    connected: false,
    lastSync: null,
    error: null
  });

  // Listen for changes to dark mode from the Layout component
  useEffect(() => {
    const handleDarkModeChange = (event) => {
      setDarkMode(event.detail.darkMode);
    };
    
    document.addEventListener('darkModeChange', handleDarkModeChange);
    
    return () => {
      document.removeEventListener('darkModeChange', handleDarkModeChange);
    };
  }, []);

  // Check TacticalRMM connection status
  useEffect(() => {
    const checkTacticalRmmStatus = async () => {
      try {
        const response = await deviceService.getTacticalRMMSettings();
        if (response.data && response.data.data && response.data.data.settings) {
          const { settings } = response.data.data;
          setTacticalRmmStatus({
            connected: settings.enabled && settings.status === 'connected',
            lastSync: settings.lastSync,
            error: null
          });
        }
      } catch (error) {
        console.error('Error checking TacticalRMM status:', error);
        setTacticalRmmStatus({
          connected: false,
          lastSync: null,
          error: 'Failed to check TacticalRMM connection status'
        });
      }
    };
    
    checkTacticalRmmStatus();
  }, []);

  // Load clients on component mount
  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = async () => {
    setLoading(true);
    try {
      const response = await clientService.getClients();
      if (response.data && response.data.data) {
        setClients(response.data.data);
      } else {
        // API response is not as expected
        console.warn('API response format unexpected');
        setClients([]);
        toast.warning('No clients found or unexpected API response format');
      }
    } catch (error) {
      console.error('Error loading clients:', error);
      toast.error('Failed to load clients. Please check your connection and try again.');
      // Set empty array instead of mock data
      setClients([]);
    } finally {
      setLoading(false);
    }
  };

  const syncWithTacticalRMM = async () => {
    setSyncing(true);
    try {
      const response = await clientService.syncClients();
      console.log('Sync response:', response.data);
      
      // Check if we got a response with status success or if data.success is true
      if (response.data && (response.data.status === 'success' || response.data.success)) {
        // Check if we have results data
        const results = response.data.data?.syncResults || response.data.results;
        
        if (results) {
          // Even if there were some errors, if we got any devices, consider it a partial success
          if (results.created > 0 || results.updated > 0) {
            toast.success(`Successfully synced with TacticalRMM: ${results.created} devices created, ${results.updated} devices updated`);
          } else if (results.errors > 0) {
            toast.warning(`Sync completed with issues: ${results.errors} errors occurred`);
          } else {
            toast.info('Sync completed. No devices were added or updated.');
          }
        } else {
          toast.success('Successfully synced with TacticalRMM');
        }
        
        // Reload clients to get updated data
        await loadClients();
        
        // Update last sync time
        setTacticalRmmStatus(prev => ({
          ...prev,
          lastSync: new Date().toISOString()
        }));
      } else {
        // If we have an error message in the response, display it
        const errorMessage = response.data?.message || response.data?.error || 'Failed to sync with TacticalRMM';
        console.error('Sync failed with message:', errorMessage);
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error syncing with TacticalRMM:', error);
      
      // Extract the error message from the error response if available
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error || 
                          error.message || 
                          'Failed to sync with TacticalRMM';
      
      toast.error(errorMessage);
    } finally {
      setSyncing(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddClient = () => {
    setFormData({
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      email: '',
      website: '',
      notes: ''
    });
    setShowAddModal(true);
  };

  const handleEditClient = (client) => {
    setCurrentClient(client);
    setFormData({
      name: client.name || '',
      address: client.address || '',
      city: client.city || '',
      state: client.state || '',
      zip: client.zip || '',
      phone: client.phone || '',
      email: client.email || '',
      website: client.website || '',
      notes: client.notes || ''
    });
    setShowEditModal(true);
  };

  const handleDeleteClient = (client) => {
    setCurrentClient(client);
    setShowDeleteModal(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const submitAddClient = async (e) => {
    e.preventDefault();
    try {
      const response = await clientService.createClient(formData);
      if (response.data && response.data.success) {
        toast.success('Client added successfully');
        setShowAddModal(false);
        await loadClients();
      } else {
        toast.error('Failed to add client');
      }
    } catch (error) {
      console.error('Error adding client:', error);
      toast.error('Failed to add client');
    }
  };

  const submitEditClient = async (e) => {
    e.preventDefault();
    try {
      const response = await clientService.updateClient(currentClient.id, formData);
      if (response.data && response.data.success) {
        toast.success('Client updated successfully');
        setShowEditModal(false);
        await loadClients();
      } else {
        toast.error('Failed to update client');
      }
    } catch (error) {
      console.error('Error updating client:', error);
      toast.error('Failed to update client');
    }
  };

  const submitDeleteClient = async () => {
    try {
      const response = await clientService.deleteClient(currentClient.id);
      if (response.data && response.data.success) {
        toast.success('Client deleted successfully');
        setShowDeleteModal(false);
        await loadClients();
      } else {
        toast.error('Failed to delete client');
      }
    } catch (error) {
      console.error('Error deleting client:', error);
      toast.error('Failed to delete client');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-800'}`}>
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="mb-4 md:mb-0">
          <h1 className="text-2xl font-bold flex items-center">
            <FaUsersCog className="mr-2" /> Clients
          </h1>
          <p className="mt-1 text-sm opacity-75">
            View and manage your clients
          </p>
        </div>
        
        <div className="flex space-x-2">
          <button
            onClick={handleAddClient}
            className={`px-4 py-2 rounded-md flex items-center ${
              darkMode 
                ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            } transition-colors duration-200`}
          >
            <FaPlus className="mr-2" /> Add Client
          </button>
          
          <button
            onClick={syncWithTacticalRMM}
            disabled={syncing || !tacticalRmmStatus.connected}
            className={`px-4 py-2 rounded-md flex items-center ${
              darkMode 
                ? (tacticalRmmStatus.connected 
                  ? 'bg-green-600 hover:bg-green-700 text-white' 
                  : 'bg-gray-600 text-gray-400 cursor-not-allowed') 
                : (tacticalRmmStatus.connected 
                  ? 'bg-green-500 hover:bg-green-600 text-white' 
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed')
            } transition-colors duration-200`}
          >
            {syncing ? (
              <>
                <FaSync className="mr-2 animate-spin" /> Syncing...
              </>
            ) : (
              <>
                <FaSync className="mr-2" /> Sync with TacticalRMM
              </>
            )}
          </button>
        </div>
      </div>
      
      {/* TacticalRMM Status Banner */}
      <div className={`p-4 rounded-lg mb-6 ${
        darkMode 
          ? (tacticalRmmStatus.connected 
            ? 'bg-green-900 border-l-4 border-green-500' 
            : 'bg-red-900 border-l-4 border-red-500') 
          : (tacticalRmmStatus.connected 
            ? 'bg-green-100 border-l-4 border-green-500' 
            : 'bg-red-100 border-l-4 border-red-500')
      }`}>
        <div className="flex items-center">
          {tacticalRmmStatus.connected ? (
            <>
              <FaLink className={`text-xl mr-3 ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
              <div>
                <p className="font-medium">Connected to TacticalRMM</p>
                <p className="text-sm opacity-75">
                  Last sync: {formatDate(tacticalRmmStatus.lastSync)}
                </p>
              </div>
            </>
          ) : (
            <>
              <FaUnlink className={`text-xl mr-3 ${darkMode ? 'text-red-400' : 'text-red-600'}`} />
              <div>
                <p className="font-medium">Not connected to TacticalRMM</p>
                <p className="text-sm opacity-75">
                  Please configure TacticalRMM integration in settings
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`p-4 rounded-lg mb-6 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-500" />
          </div>
          <input
            type="text"
            placeholder="Search clients..."
            className={`pl-10 pr-4 py-2 w-full rounded-md ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'} border focus:outline-none focus:ring-2 focus:ring-blue-500`}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-12">
          <FaSync className="animate-spin text-3xl mr-3" />
          <span className="text-xl">Loading clients...</span>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredClients.length === 0 ? (
            <div className={`col-span-full flex flex-col items-center justify-center py-12 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow`}>
              <FaExclamationTriangle className="text-5xl mb-4 text-yellow-500" />
              <p className="text-xl mb-2">No clients found</p>
              <p className="text-center opacity-75">No clients matching your search criteria were found.</p>
            </div>
          ) : (
            filteredClients.map((client) => (
              <div 
                key={client.id}
                className={`rounded-lg shadow overflow-hidden ${darkMode ? 'bg-gray-800' : 'bg-white'}`}
              >
                <div className={`p-5 border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className={`flex-shrink-0 h-12 w-12 rounded-full flex items-center justify-center ${darkMode ? 'bg-gray-700' : 'bg-blue-100'}`}>
                        <FaBuilding className={`text-xl ${darkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-semibold">{client.name}</h3>
                        <div className={`mt-1 flex items-center ${client.status === 'active' ? (darkMode ? 'text-green-400' : 'text-green-600') : (darkMode ? 'text-red-400' : 'text-red-600')}`}>
                          {client.status === 'active' ? (
                            <>
                              <FaCheck className="mr-1" /> Active
                            </>
                          ) : (
                            <>
                              <FaExclamationTriangle className="mr-1" /> Inactive
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex space-x-2">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleEditClient(client);
                        }}
                        className={`p-2 rounded-full ${
                          darkMode 
                            ? 'bg-gray-700 hover:bg-gray-600 text-blue-400' 
                            : 'bg-gray-100 hover:bg-gray-200 text-blue-600'
                        }`}
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDeleteClient(client);
                        }}
                        className={`p-2 rounded-full ${
                          darkMode 
                            ? 'bg-gray-700 hover:bg-gray-600 text-red-400' 
                            : 'bg-gray-100 hover:bg-gray-200 text-red-600'
                        }`}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                </div>
                
                <Link to={`/clients/${client.id}`} className="block">
                  <div className="p-5">
                    {/* TacticalRMM Integration Status */}
                    <div className={`mb-4 p-2 rounded-md ${
                      client.tacticalRmmId 
                        ? (darkMode ? 'bg-green-900 bg-opacity-30' : 'bg-green-100') 
                        : (darkMode ? 'bg-gray-700' : 'bg-gray-100')
                    }`}>
                      <div className="flex items-center">
                        {client.tacticalRmmId ? (
                          <>
                            <FaLink className={`mr-2 ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
                            <span className="text-sm">Linked with TacticalRMM</span>
                          </>
                        ) : (
                          <>
                            <FaUnlink className={`mr-2 ${darkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                            <span className="text-sm">Not linked with TacticalRMM</span>
                          </>
                        )}
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col items-center p-3 rounded-lg bg-opacity-10 bg-blue-500">
                        <div className="flex items-center mb-2">
                          <FaServer className="mr-2" />
                          <span className="text-lg font-semibold">{client.siteCount}</span>
                        </div>
                        <span className="text-sm opacity-75">Sites</span>
                      </div>
                      <div className="flex flex-col items-center p-3 rounded-lg bg-opacity-10 bg-green-500">
                        <div className="flex items-center mb-2">
                          <FaDesktop className="mr-2" />
                          <span className="text-lg font-semibold">{client.deviceCount}</span>
                        </div>
                        <span className="text-sm opacity-75">Devices</span>
                      </div>
                    </div>
                    
                    {client.lastSync && (
                      <div className="mt-4 text-xs text-center opacity-75">
                        Last synced: {formatDate(client.lastSync)}
                      </div>
                    )}
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      )}
      {/* Add Client Modal */}
      {showAddModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowAddModal(false)}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className={`inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FaPlus className="text-blue-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium" id="modal-title">
                      Add New Client
                    </h3>
                    <div className="mt-4">
                      <form onSubmit={submitAddClient}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          <div>
                            <label className="block text-sm font-medium mb-1">Client Name*</label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleFormChange}
                              required
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Phone</label>
                            <input
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Email</label>
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Website</label>
                            <input
                              type="url"
                              name="website"
                              value={formData.website}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Address</label>
                            <input
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">City</label>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">State</label>
                            <input
                              type="text"
                              name="state"
                              value={formData.state}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">ZIP</label>
                            <input
                              type="text"
                              name="zip"
                              value={formData.zip}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block text-sm font-medium mb-1">Notes</label>
                          <textarea
                            name="notes"
                            value={formData.notes}
                            onChange={handleFormChange}
                            rows="3"
                            className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                          ></textarea>
                        </div>
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            onClick={() => setShowAddModal(false)}
                            className={`px-4 py-2 rounded-md ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="px-4 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white"
                          >
                            Add Client
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Client Modal */}
      {showEditModal && currentClient && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowEditModal(false)}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className={`inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FaEdit className="text-blue-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium" id="modal-title">
                      Edit Client: {currentClient.name}
                    </h3>
                    <div className="mt-4">
                      <form onSubmit={submitEditClient}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          <div>
                            <label className="block text-sm font-medium mb-1">Client Name*</label>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleFormChange}
                              required
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Phone</label>
                            <input
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Email</label>
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Website</label>
                            <input
                              type="url"
                              name="website"
                              value={formData.website}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">Address</label>
                            <input
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">City</label>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">State</label>
                            <input
                              type="text"
                              name="state"
                              value={formData.state}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium mb-1">ZIP</label>
                            <input
                              type="text"
                              name="zip"
                              value={formData.zip}
                              onChange={handleFormChange}
                              className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block text-sm font-medium mb-1">Notes</label>
                          <textarea
                            name="notes"
                            value={formData.notes}
                            onChange={handleFormChange}
                            rows="3"
                            className={`w-full rounded-md ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} border p-2`}
                          ></textarea>
                        </div>
                        
                        {/* TacticalRMM Integration Status */}
                        {currentClient.tacticalRmmId && (
                          <div className={`p-3 rounded-md mb-4 ${darkMode ? 'bg-green-900 bg-opacity-30' : 'bg-green-100'}`}>
                            <div className="flex items-center">
                              <FaLink className={`mr-2 ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
                              <span>Linked with TacticalRMM (ID: {currentClient.tacticalRmmId})</span>
                            </div>
                            {currentClient.lastSync && (
                              <div className="mt-1 text-xs opacity-75">
                                Last synced: {formatDate(currentClient.lastSync)}
                              </div>
                            )}
                          </div>
                        )}
                        
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            onClick={() => setShowEditModal(false)}
                            className={`px-4 py-2 rounded-md ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'}`}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="px-4 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white"
                          >
                            Update Client
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Client Modal */}
      {showDeleteModal && currentClient && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowDeleteModal(false)}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className={`inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FaTrash className="text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-title">
                      Delete Client
                    </h3>
                    <div className="mt-2">
                      <p>
                        Are you sure you want to delete <strong>{currentClient.name}</strong>? This action cannot be undone.
                      </p>
                      
                      {currentClient.tacticalRmmId && (
                        <div className={`p-3 rounded-md mt-4 ${darkMode ? 'bg-yellow-900 bg-opacity-30' : 'bg-yellow-100'}`}>
                          <div className="flex items-center">
                            <FaExclamationTriangle className={`mr-2 ${darkMode ? 'text-yellow-400' : 'text-yellow-600'}`} />
                            <span>This client is linked with TacticalRMM. Deleting it will remove the link but not delete the client from TacticalRMM.</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={submitDeleteClient}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setShowDeleteModal(false)}
                  className={`mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 ${darkMode ? 'bg-gray-700 hover:bg-gray-600 border-gray-600' : 'bg-white hover:bg-gray-50 border-gray-300'} text-base font-medium focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Clients;
