import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaDesktop, FaServer, FaLaptop, FaMobileAlt, FaQuestion, FaSync, FaTerminal, FaPowerOff, FaScroll, FaShieldAlt } from 'react-icons/fa';
import deviceService from '../../services/device.service';
import { toast } from 'react-toastify';

const DeviceDetail = () => {
  const { id } = useParams();
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [command, setCommand] = useState('');
  const [shell, setShell] = useState('cmd');
  const [commandResult, setCommandResult] = useState(null);
  const [executing, setExecuting] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [rebooting, setRebooting] = useState(false);
  const [showScriptModal, setShowScriptModal] = useState(false);
  const [scripts, setScripts] = useState([]);
  const [selectedScript, setSelectedScript] = useState('');
  const [loadingScripts, setLoadingScripts] = useState(false);
  const [runningScript, setRunningScript] = useState(false);
  const [installingEset, setInstallingEset] = useState(false);

  // Fetch device details from API
  const fetchDeviceDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await deviceService.getDevice(id);
      setDevice(response.data.data.device);
      setError(null);
    } catch (err) {
      setError('Failed to load device details. Please try again later.');
      console.error('Error fetching device details:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  // Load device details on component mount
  useEffect(() => {
    fetchDeviceDetails();
  }, [id, fetchDeviceDetails]);

  // Get appropriate icon for device type
  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 'desktop':
        return <FaDesktop className="text-blue-500" />;
      case 'laptop':
        return <FaLaptop className="text-green-500" />;
      case 'server':
        return <FaServer className="text-purple-500" />;
      case 'mobile':
        return <FaMobileAlt className="text-orange-500" />;
      default:
        return <FaQuestion className="text-gray-500" />;
    }
  };

  // Get status badge class
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'healthy':
        return 'bg-green-100 text-green-800';
      case 'warning':
        return 'bg-yellow-100 text-yellow-800';
      case 'critical':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Format bytes to human-readable format
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  // Handle remote session
  const handleRemoteSession = async () => {
    try {
      console.log('Starting remote session for device ID:', id);
      
      // First, check if the device is fully synced and has all required data
      if (!device.tacticalRMM || !device.tacticalRMM.agentId) {
        toast.warning('This device may not be fully synced with TacticalRMM. Attempting to sync first...');
        await handleDeviceSync();
        // Wait a moment for the sync to complete
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      
      const response = await deviceService.startRemoteSession(id);
      
      // Log the full response to help debug
      console.log('Remote session response:', response);
      
      // Handle the case where we get a response but there might be WMI issues
      if (response?.data?.error && response.data.error.includes('wmi_detail')) {
        console.warn('WMI detail issue detected in response');
        toast.warning('WMI service issue detected. The remote session may not work correctly.');
      }
      
      // Check for null WMI details in the response data
      if (response?.data?.data?.sessionData?.wmi_detail === null) {
        console.warn('Null WMI details detected in session data');
        toast.warning('WMI details are missing. The remote session may have limited functionality.');
      }
      
      // Check if we have the expected data structure
      if (response?.data?.data?.sessionData?.url) {
        console.log('Opening remote session URL:', response.data.data.sessionData.url);
        toast.info('Opening remote session in a new tab');
        window.open(response.data.data.sessionData.url, '_blank');
      } else {
        console.error('Invalid response structure:', response);
        toast.error('Invalid remote session response structure');
        
        // Try to find the URL in a different location in the response
        const possibleUrls = [];
        const findUrls = (obj, path = '') => {
          if (!obj || typeof obj !== 'object') return;
          
          Object.entries(obj).forEach(([key, value]) => {
            const newPath = path ? `${path}.${key}` : key;
            
            if (key === 'url' && typeof value === 'string' && value.includes('http')) {
              possibleUrls.push({ path: newPath, url: value });
            }
            
            if (typeof value === 'object') {
              findUrls(value, newPath);
            }
          });
        };
        
        findUrls(response);
        
        if (possibleUrls.length > 0) {
          console.log('Found possible URLs in response:', possibleUrls);
          const firstUrl = possibleUrls[0].url;
          console.log('Attempting to open URL:', firstUrl);
          window.open(firstUrl, '_blank');
          toast.info('Attempting to connect with alternative URL');
        } else if (response?.data?.url) {
          // Direct URL in the response data
          console.log('Found direct URL in response:', response.data.url);
          window.open(response.data.url, '_blank');
          toast.info('Opening remote session using direct URL');
        } else if (response?.data?.connecturl) {
          // Some versions of TacticalRMM use connecturl instead of url
          console.log('Found connecturl in response:', response.data.connecturl);
          window.open(response.data.connecturl, '_blank');
          toast.info('Opening remote session using connect URL');
        } else {
          toast.error('Could not find a valid remote session URL in the response');
        }
      }
    } catch (err) {
      console.error('Error starting remote session:', err);
      
      // Handle specific error cases
      if (err.response) {
        const { status, data } = err.response;
        console.error('Error response:', data);
        
        // Handle case where device was synced but session still failed
        if (status === 400 && data.action && data.action.includes('sync')) {
          toast.info(data.message);
          toast.info(data.action);
          
          // Refresh the device data after sync
          await fetchDeviceDetails();
          return;
        }
        
        // Handle WMI-related errors
        if (data.error && (data.error.includes('wmi_detail') || data.error.includes('WMI'))) {
          toast.error('WMI service issue detected. Please ensure WMI is running on the target device.');
          
          // Offer to sync the device
          if (window.confirm('Would you like to sync the device to try to resolve WMI issues?')) {
            await handleDeviceSync();
            // Wait a moment for the sync to complete
            await new Promise(resolve => setTimeout(resolve, 2000));
            // Refresh device details
            await fetchDeviceDetails();
          }
          return;
        }
        
        // Handle other error cases
        toast.error(`Error: ${data.message || data.error || 'Unknown error'}`);
      } else {
        // Check for specific error patterns related to WMI
        if (err.message && (err.message.includes('wmi_detail') || err.message.includes('WMI'))) {
          toast.error('WMI service issue detected. Please ensure WMI is running on the target device.');
        } else {
          toast.error(`Failed to start remote session: ${err.message}`);
        }
      }
    }
  };

  // Handle device sync
  const handleDeviceSync = async () => {
    setSyncing(true);
    try {
      const response = await deviceService.syncDevice(id);
      if (response.data && response.data.success) {
        toast.success('Device synced successfully');
        // Refresh device details
        fetchDeviceDetails();
      } else {
        toast.error(response.data?.message || 'Failed to sync device');
      }
    } catch (err) {
      console.error('Error syncing device:', err);
      toast.error('Failed to sync device');
    } finally {
      setSyncing(false);
    }
  };

  // Handle device reboot
  const handleReboot = async () => {
    if (window.confirm('Are you sure you want to reboot this device?')) {
      setRebooting(true);
      try {
        const response = await deviceService.rebootDevice(id);
        if (response.data && response.data.success) {
          toast.success('Reboot command sent successfully');
        } else {
          toast.error(response.data?.message || 'Failed to reboot device');
        }
      } catch (err) {
        console.error('Error rebooting device:', err);
        toast.error('Failed to reboot device');
      } finally {
        setRebooting(false);
      }
    }
  };

  // Handle ESET installation
  const handleInstallEset = async () => {
    if (window.confirm('Are you sure you want to install ESET on this device?')) {
      setInstallingEset(true);
      try {
        const response = await deviceService.installEset(id);
        if (response.data && response.data.success) {
          toast.success('ESET installation started successfully');
          // Update the device data to reflect that installation has started
          setDevice(prev => ({
            ...prev,
            esetInstallation: {
              ...prev.esetInstallation,
              installing: true,
              startTime: new Date().toISOString()
            }
          }));
        } else {
          toast.error(response.data?.message || 'Failed to start ESET installation');
        }
      } catch (err) {
        console.error('Error installing ESET:', err);
        toast.error(err.response?.data?.message || 'Failed to install ESET');
      } finally {
        setInstallingEset(false);
      }
    }
  };

  // Handle script execution
  const handleScriptExecution = async () => {
    if (!selectedScript) {
      toast.error('Please select a script');
      return;
    }

    setRunningScript(true);
    try {
      const response = await deviceService.runScript(id, selectedScript);
      if (response.data && response.data.success) {
        toast.success('Script executed successfully');
        setShowScriptModal(false);
        // If the script returns output, show it in the command result area
        if (response.data.data && response.data.data.scriptResult) {
          setCommandResult({
            stdout: response.data.data.scriptResult.output,
            stderr: response.data.data.scriptResult.error
          });
        }
      } else {
        toast.error(response.data?.message || 'Failed to execute script');
      }
    } catch (err) {
      console.error('Error executing script:', err);
      toast.error('Failed to execute script');
    } finally {
      setRunningScript(false);
    }
  };

  // Load available scripts
  const loadScripts = async () => {
    setLoadingScripts(true);
    try {
      const response = await deviceService.getAvailableScripts(id);
      if (response.data && response.data.data && response.data.data.scripts) {
        setScripts(response.data.data.scripts);
      } else {
        // No scripts available
        setScripts([]);
        toast.info('No scripts available for this device');
      }
    } catch (err) {
      console.error('Error loading scripts:', err);
      toast.error('Failed to load available scripts');
      setScripts([]);
    } finally {
      setLoadingScripts(false);
    }
  };

  // Open script modal
  const openScriptModal = () => {
    loadScripts();
    setShowScriptModal(true);
  };

  // Handle command execution
  const handleCommandExecution = async (e) => {
    e.preventDefault();
    if (!command.trim()) return;

    setExecuting(true);
    try {
      const response = await deviceService.sendCommand(id, command, shell);
      setCommandResult(response.data.data.commandResult);
      toast.success('Command executed successfully');
    } catch (err) {
      toast.error('Failed to execute command');
      console.error('Error executing command:', err);
    } finally {
      setExecuting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>;
  }

  if (!device) {
    return <div className="text-center py-8">Device not found.</div>;
  }

  return (
    <div className="bg-white shadow rounded-lg">
      {/* Device Header */}
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-12 w-12 flex items-center justify-center bg-gray-100 rounded-full">
              {getDeviceIcon(device.deviceType)}
            </div>
            <div className="ml-4">
              <h1 className="text-2xl font-semibold text-gray-900">{device.name}</h1>
              <div className="flex items-center mt-1">
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    device.isOnline ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  } mr-2`}
                >
                  {device.isOnline ? 'Online' : 'Offline'}
                </span>
                {device.tacticalRMM && device.tacticalRMM.status && (
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(
                      device.tacticalRMM.status
                    )}`}
                  >
                    {device.tacticalRMM.status.charAt(0).toUpperCase() + device.tacticalRMM.status.slice(1)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex space-x-2">
            <Link
              to="/devices"
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Back to Devices
            </Link>
            <button
              onClick={handleDeviceSync}
              disabled={syncing}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              <FaSync className={`inline mr-1 ${syncing ? 'animate-spin' : ''}`} />
              {syncing ? 'Syncing...' : 'Sync'}
            </button>
            {device.isOnline && (
              <>
                <button
                  onClick={handleRemoteSession}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Remote Session
                </button>
                <button
                  onClick={openScriptModal}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaScroll className="inline mr-1" />
                  Run Script
                </button>
                <button
                  onClick={handleReboot}
                  disabled={rebooting}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                >
                  <FaPowerOff className="inline mr-1" />
                  {rebooting ? 'Rebooting...' : 'Reboot'}
                </button>
                <button
                  onClick={handleInstallEset}
                  disabled={installingEset || !device.isOnline || (device.esetInstallation && device.esetInstallation.installed)}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                >
                  <FaShieldAlt className="inline mr-1" />
                  {installingEset ? 'Installing...' : 'Install ESET'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Device Details */}
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* System Information */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <h2 className="text-lg font-medium text-gray-900 mb-4">System Information</h2>
          <div className="space-y-3">
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-500">Operating System</span>
              <span className="text-sm text-gray-900">{device.operatingSystem || 'Unknown'}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-500">IP Address</span>
              <span className="text-sm text-gray-900">{device.ipAddress || 'Unknown'}</span>
            </div>
            {device.tacticalRMM && device.tacticalRMM.publicIp && (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Public IP</span>
                <span className="text-sm text-gray-900">{device.tacticalRMM.publicIp}</span>
              </div>
            )}
            <div className="flex justify-between">
              <span className="text-sm font-medium text-gray-500">Last Seen</span>
              <span className="text-sm text-gray-900">
                {device.lastSeen ? new Date(device.lastSeen).toLocaleString() : 'Never'}
              </span>
            </div>
            {device.tacticalRMM && device.tacticalRMM.installDate && (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Agent Installed</span>
                <span className="text-sm text-gray-900">
                  {new Date(device.tacticalRMM.installDate).toLocaleDateString()}
                </span>
              </div>
            )}
            {device.tacticalRMM && device.tacticalRMM.agentVersion && (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Agent Version</span>
                <span className="text-sm text-gray-900">{device.tacticalRMM.agentVersion}</span>
              </div>
            )}
          </div>
        </div>

        {/* Hardware Information */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Hardware Information</h2>
          <div className="space-y-3">
            {device.tacticalRMM && device.tacticalRMM.totalRam && device.tacticalRMM.totalRam > 0 && device.tacticalRMM.usedRam != null && (
              <div>
                <div className="flex justify-between mb-1">
                  <span className="text-sm font-medium text-gray-500">Memory Usage</span>
                  <span className="text-sm text-gray-900">
                    {formatBytes(device.tacticalRMM.usedRam)} / {formatBytes(device.tacticalRMM.totalRam)}
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{
                      width: `${Math.min(
                        100,
                        (device.tacticalRMM.usedRam / device.tacticalRMM.totalRam) * 100
                      )}%`,
                    }}
                  ></div>
                </div>
              </div>
            )}
            {device.tacticalRMM && device.tacticalRMM.cpuLoad !== undefined && (
              <div>
                <div className="flex justify-between mb-1">
                  <span className="text-sm font-medium text-gray-500">CPU Load</span>
                  <span className="text-sm text-gray-900">{device.tacticalRMM.cpuLoad}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className={`h-2.5 rounded-full ${
                      device.tacticalRMM.cpuLoad > 80
                        ? 'bg-red-600'
                        : device.tacticalRMM.cpuLoad > 50
                        ? 'bg-yellow-600'
                        : 'bg-green-600'
                    }`}
                    style={{ width: `${device.tacticalRMM.cpuLoad}%` }}
                  ></div>
                </div>
              </div>
            )}
            {device.tacticalRMM &&
              device.tacticalRMM.diskSpace &&
              device.tacticalRMM.diskSpace.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Disk Usage</h3>
                  <div className="space-y-3">
                    {device.tacticalRMM.diskSpace.map((disk, index) => (
                      <div key={index}>
                        <div className="flex justify-between mb-1">
                          <span className="text-sm font-medium text-gray-500">{disk.drive}</span>
                          <span className="text-sm text-gray-900">
                            {formatBytes(disk.used)} / {formatBytes(disk.total)}
                          </span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div
                            className={`h-2.5 rounded-full ${
                              disk.used / disk.total > 0.9
                                ? 'bg-red-600'
                                : disk.used / disk.total > 0.7
                                ? 'bg-yellow-600'
                                : 'bg-green-600'
                            }`}
                            style={{ width: `${Math.min(100, (disk.used / disk.total) * 100)}%` }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>

        {/* Status Information */}
        <div className="bg-gray-50 p-4 rounded-lg">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Status Information</h2>
          <div className="space-y-3">
            {device.tacticalRMM && device.tacticalRMM.needsReboot !== undefined && (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Needs Reboot</span>
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    device.tacticalRMM.needsReboot
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-green-100 text-green-800'
                  }`}
                >
                  {device.tacticalRMM.needsReboot ? 'Yes' : 'No'}
                </span>
              </div>
            )}
            {device.tacticalRMM && device.tacticalRMM.pendingActions !== undefined && (
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Pending Actions</span>
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    device.tacticalRMM.pendingActions > 5
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-green-100 text-green-800'
                  }`}
                >
                  {device.tacticalRMM.pendingActions}
                </span>
              </div>
            )}
            {device.tacticalRMM &&
              device.tacticalRMM.antivirus &&
              device.tacticalRMM.antivirus.status && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">Antivirus Status</span>
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      device.tacticalRMM.antivirus.status.toLowerCase() === 'ok'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}
                  >
                    {device.tacticalRMM.antivirus.status}
                  </span>
                </div>
              )}
            {/* ESET Installation Status */}
            {device.esetInstallation && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">ESET Status</span>
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      device.esetInstallation.installed
                        ? 'bg-green-100 text-green-800'
                        : device.esetInstallation.installing
                        ? 'bg-blue-100 text-blue-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {device.esetInstallation.installed
                      ? 'Installed'
                      : device.esetInstallation.installing
                      ? 'Installing'
                      : 'Not Installed'}
                  </span>
                </div>
              )}
            {device.tacticalRMM && device.tacticalRMM.patches && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-700 mb-2">Windows Updates</h3>
                <div className="grid grid-cols-3 gap-2">
                  <div className="bg-green-50 p-2 rounded text-center">
                    <span className="text-sm font-medium text-green-800">
                      {device.tacticalRMM.patches.installed || 0}
                    </span>
                    <p className="text-xs text-green-600">Installed</p>
                  </div>
                  <div className="bg-yellow-50 p-2 rounded text-center">
                    <span className="text-sm font-medium text-yellow-800">
                      {device.tacticalRMM.patches.pending || 0}
                    </span>
                    <p className="text-xs text-yellow-600">Pending</p>
                  </div>
                  <div className="bg-red-50 p-2 rounded text-center">
                    <span className="text-sm font-medium text-red-800">
                      {device.tacticalRMM.patches.failed || 0}
                    </span>
                    <p className="text-xs text-red-600">Failed</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* ESET Information */}
        {device.esetInstallation && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-lg font-medium text-gray-900 mb-4">ESET Information</h2>
            <div className="space-y-3">
              <div className="flex justify-between">
                <span className="text-sm font-medium text-gray-500">Installation Status</span>
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    device.esetInstallation.installed
                      ? 'bg-green-100 text-green-800'
                      : device.esetInstallation.installing
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}
                >
                  {device.esetInstallation.installed
                    ? 'Installed'
                    : device.esetInstallation.installing
                    ? 'Installing'
                    : 'Not Installed'}
                </span>
              </div>
              
              {device.esetInstallation.startTime && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">Installation Started</span>
                  <span className="text-sm text-gray-900">
                    {new Date(device.esetInstallation.startTime).toLocaleString()}
                  </span>
                </div>
              )}
              
              {device.esetInstallation.completionTime && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">Installation Completed</span>
                  <span className="text-sm text-gray-900">
                    {new Date(device.esetInstallation.completionTime).toLocaleString()}
                  </span>
                </div>
              )}
              
              {device.esetInstallation.licenseKey && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">License Key</span>
                  <span className="text-sm text-gray-900">
                    {device.esetInstallation.licenseKey}
                  </span>
                </div>
              )}
              
              {device.esetInstallation.version && (
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-gray-500">Version</span>
                  <span className="text-sm text-gray-900">
                    {device.esetInstallation.version}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Remote Command */}
        {device.isOnline && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Remote Command</h2>
            <form onSubmit={handleCommandExecution}>
              <div className="mb-4">
                <label htmlFor="shell" className="block text-sm font-medium text-gray-700 mb-1">
                  Shell
                </label>
                <select
                  id="shell"
                  value={shell}
                  onChange={(e) => setShell(e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="cmd">CMD</option>
                  <option value="powershell">PowerShell</option>
                  {device.operatingSystem && device.operatingSystem.toLowerCase().includes('linux') && (
                    <option value="bash">Bash</option>
                  )}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="command" className="block text-sm font-medium text-gray-700 mb-1">
                  Command
                </label>
                <div className="flex">
                  <input
                    type="text"
                    id="command"
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    placeholder={
                      shell === 'powershell'
                        ? 'Get-ComputerInfo'
                        : shell === 'bash'
                        ? 'uname -a'
                        : 'systeminfo'
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                  <button
                    type="submit"
                    disabled={executing || !command.trim()}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-r-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                  >
                    <FaTerminal className="mr-2" />
                    {executing ? 'Executing...' : 'Execute'}
                  </button>
                </div>
              </div>
            </form>

            {commandResult && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-700 mb-2">Command Output</h3>
                <pre className="bg-black text-green-400 p-3 rounded-md text-sm overflow-x-auto whitespace-pre-wrap max-h-60 overflow-y-auto">
                  {commandResult.stdout || commandResult.output || 'No output'}
                  {commandResult.stderr && (
                    <span className="text-red-400">{'\n\nErrors:\n' + commandResult.stderr}</span>
                  )}
                </pre>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Script Modal */}
      {showScriptModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setShowScriptModal(false)}></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Run Script on {device.name}
                    </h3>
                    <div className="mt-4">
                      {loadingScripts ? (
                        <div className="flex justify-center">
                          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600"></div>
                        </div>
                      ) : (
                        <>
                          <label htmlFor="script" className="block text-sm font-medium text-gray-700 mb-1">
                            Select Script
                          </label>
                          <select
                            id="script"
                            value={selectedScript}
                            onChange={(e) => setSelectedScript(e.target.value)}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          >
                            <option value="">-- Select a script --</option>
                            {scripts.map((script) => (
                              <option key={script.id} value={script.id}>
                                {script.name}
                              </option>
                            ))}
                          </select>

                          {selectedScript && (
                            <div className="mt-4">
                              <h4 className="text-sm font-medium text-gray-700">Script Description</h4>
                              <p className="text-sm text-gray-500">
                                {scripts.find((s) => s.id === selectedScript)?.description || 'No description available'}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleScriptExecution}
                  disabled={!selectedScript || runningScript}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                >
                  {runningScript ? 'Running...' : 'Run Script'}
                </button>
                <button
                  type="button"
                  onClick={() => setShowScriptModal(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceDetail;