import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';
import { 
  FaDesktop, 
  FaTicketAlt, 
  FaCreditCard, 
  FaExclamationTriangle, 
  FaServer, 
  FaLaptop,
  FaNetworkWired,
  FaShieldAlt,
  FaChartLine,
  FaCalendarCheck,
  FaBell,
  FaClipboardCheck
} from 'react-icons/fa';
import deviceService from '../../services/device.service';
import monitoringService from '../../services/monitoring.service';
import RMMSummary from './RMMSummary';

// Simple component for displaying a circular progress indicator
const CircularProgress = ({ value, max, size = 120, strokeWidth = 10, color = 'blue' }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (value / max) * circumference;
  
  // Map color names to Tailwind classes to avoid string interpolation
  const colorClasses = {
    blue: 'text-blue-600',
    green: 'text-green-600',
    red: 'text-red-600',
    yellow: 'text-yellow-600',
    purple: 'text-purple-600',
    gray: 'text-gray-600'
  };
  
  // Default to blue if color not found
  const progressColorClass = colorClasses[color] || colorClasses.blue;
  
  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg className="w-full h-full" viewBox={`0 0 ${size} ${size}`}>
        {/* Background circle */}
        <circle
          className="text-gray-200"
          stroke="currentColor"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        {/* Progress circle */}
        <circle
          className={progressColorClass}
          stroke="currentColor"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-2xl font-bold text-gray-700">{value}/{max}</span>
      </div>
    </div>
  );
};

// Status card component for displaying device status
const StatusCard = ({ title, count, icon: Icon, color, description }) => {
  // Map color names to Tailwind classes to avoid string interpolation
  // which doesn't work well with Tailwind's purge process
  const colorClasses = {
    blue: {
      border: 'border-blue-500',
      bg: 'bg-blue-100',
      text: 'text-blue-600'
    },
    green: {
      border: 'border-green-500',
      bg: 'bg-green-100',
      text: 'text-green-600'
    },
    red: {
      border: 'border-red-500',
      bg: 'bg-red-100',
      text: 'text-red-600'
    },
    yellow: {
      border: 'border-yellow-500',
      bg: 'bg-yellow-100',
      text: 'text-yellow-600'
    },
    purple: {
      border: 'border-purple-500',
      bg: 'bg-purple-100',
      text: 'text-purple-600'
    },
    gray: {
      border: 'border-gray-500',
      bg: 'bg-gray-100',
      text: 'text-gray-600'
    }
  };
  
  // Default to gray if color not found
  const classes = colorClasses[color] || colorClasses.gray;
  
  return (
    <div className={`bg-white rounded-lg shadow-md p-4 border-l-4 ${classes.border} hover:shadow-lg transition-shadow duration-300`}>
      <div className="flex items-center">
        <div className={`p-3 rounded-full ${classes.bg} ${classes.text}`}>
          <Icon className="h-6 w-6" />
        </div>
        <div className="ml-4 flex-1">
          <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
          <p className="text-gray-500 text-sm">{description}</p>
        </div>
        <div className={`text-2xl font-bold ${classes.text}`}>{count}</div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState({
    devices: {
      total: 0,
      online: 0,
      offline: 0,
      critical: 0,
      types: {
        workstations: 0,
        servers: 0,
        network: 0
      }
    },
    tickets: {
      total: 0,
      open: 0,
      inProgress: 0,
      closed: 0,
      priority: {
        low: 0,
        medium: 0,
        high: 0,
        critical: 0
      }
    },
    billing: {
      currentPlan: 'Free Trial',
      nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
      invoicesDue: 0,
      daysRemaining: 30
    },
    updates: {
      pending: 0,
      critical: 0
    },
    monitoring: {
      alerts: 0,
      warnings: 0
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentActivity, setRecentActivity] = useState([]);
  const [rmmData, setRmmData] = useState(null);
  const [rmmLoading, setRmmLoading] = useState(true);
  const [rmmError, setRmmError] = useState(null);

  useEffect(() => {
    // Fetch actual stats from the API
    const fetchStats = async () => {
      setLoading(true);
      try {
        // Fetch all required data in parallel
        const [
          deviceMetrics,
          systemHealth,
          systemMetrics,
          containerStatus,
          diskSpace
        ] = await Promise.all([
          monitoringService.getDeviceHealthMetrics(),
          monitoringService.getSystemHealth(),
          monitoringService.getSystemMetrics(),
          monitoringService.getContainerStatus(),
          monitoringService.getDiskSpace()
        ]);

        // Process device metrics
        const devices = {
          total: deviceMetrics.totalDevices || 0,
          online: deviceMetrics.onlineDevices || 0,
          offline: deviceMetrics.offlineDevices || 0,
          critical: deviceMetrics.criticalDevices || 0,
          types: {
            workstations: deviceMetrics.workstations || 0,
            servers: deviceMetrics.servers || 0,
            network: deviceMetrics.networkDevices || 0
          }
        };

        // Process system health data
        const monitoring = {
          alerts: systemHealth.alerts?.length || 0,
          warnings: systemHealth.alerts?.filter(alert => alert.level === 'warning')?.length || 0
        };

        // Process system metrics
        const updates = {
          pending: systemMetrics.pendingUpdates || 0,
          critical: systemMetrics.criticalUpdates || 0
        };

        // Get billing info from user context
        const billing = {
          currentPlan: user.subscription?.plan || 'Free Trial',
          nextBillingDate: user.subscription?.nextBillingDate || new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString(),
          invoicesDue: user.subscription?.unpaidInvoices || 0,
          daysRemaining: user.subscription?.daysRemaining || 30
        };

        // Process ticket metrics from system metrics
        const tickets = {
          total: systemMetrics.totalTickets || 0,
          open: systemMetrics.openTickets || 0,
          inProgress: systemMetrics.inProgressTickets || 0,
          closed: systemMetrics.closedTickets || 0,
          priority: {
            low: systemMetrics.lowPriorityTickets || 0,
            medium: systemMetrics.mediumPriorityTickets || 0,
            high: systemMetrics.highPriorityTickets || 0,
            critical: systemMetrics.criticalPriorityTickets || 0
          }
        };

        // Update stats with real data
        const newStats = {
          devices,
          tickets,
          billing,
          updates,
          monitoring
        };
          setStats(newStats);

          // Fetch TacticalRMM device issues data
          try {
            setRmmLoading(true);
            const rmmResponse = await monitoringService.getDeviceIssues();
            setRmmData(rmmResponse);
          } catch (rmmErr) {
            console.error('Error fetching RMM data:', rmmErr);
            setRmmError(rmmErr.message || 'Failed to load TacticalRMM data');
          } finally {
            setRmmLoading(false);
          }

          // Set empty array for recent activity
          // TODO: Implement getRecentActivity method in monitoringService
          setRecentActivity([]);
        } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffMs = now - date;
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffHours > 0) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffMins > 0) {
      return `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600"></div>
          <p className="mt-4 text-gray-600">Loading dashboard data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border-l-4 border-red-500 text-red-800 p-4 rounded-md mb-4 shadow-md">
        <div className="flex items-center">
          <FaExclamationTriangle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>

      {/* TacticalRMM Summary */}
      <div className="mb-6">
        <RMMSummary loading={rmmLoading} data={rmmData} error={rmmError} />
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Device Stats */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-blue-100 text-blue-600">
              <FaDesktop className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-900">Devices</h2>
              <p className="text-gray-500">{stats.devices.total} total</p>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-3 gap-2">
            <div className="text-center">
              <div className="text-lg font-semibold text-green-600">{stats.devices.online}</div>
              <div className="text-xs text-gray-500">Online</div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold text-red-600">{stats.devices.offline}</div>
              <div className="text-xs text-gray-500">Offline</div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold text-yellow-600">{stats.devices.critical}</div>
              <div className="text-xs text-gray-500">Critical</div>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to="/devices"
              className="text-blue-600 hover:text-blue-800 text-sm font-medium"
            >
              View all devices →
            </Link>
          </div>
        </div>

        {/* Ticket Stats */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-purple-100 text-purple-600">
              <FaTicketAlt className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-900">Support Tickets</h2>
              <p className="text-gray-500">{stats.tickets.total} total</p>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-3 gap-2">
            <div className="text-center">
              <div className="text-lg font-semibold text-blue-600">{stats.tickets.open}</div>
              <div className="text-xs text-gray-500">Open</div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold text-yellow-600">{stats.tickets.inProgress}</div>
              <div className="text-xs text-gray-500">In Progress</div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold text-green-600">{stats.tickets.closed}</div>
              <div className="text-xs text-gray-500">Closed</div>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to="/tickets"
              className="text-blue-600 hover:text-blue-800 text-sm font-medium"
            >
              View all tickets →
            </Link>
          </div>
        </div>

        {/* Billing Stats */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-green-100 text-green-600">
              <FaCreditCard className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <h2 className="text-lg font-semibold text-gray-900">Billing</h2>
              <p className="text-gray-500">{stats.billing.currentPlan} Plan</p>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-gray-500">Next billing date:</span>
              <span className="text-sm font-medium">{stats.billing.nextBillingDate}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-500">Invoices due:</span>
              <span className="text-sm font-medium">{stats.billing.invoicesDue}</span>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to={user && user.role === 'client' ? '/portal/billing' : '/billing'}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium"
            >
              Manage billing →
            </Link>
          </div>
        </div>
      </div>

      {/* Device Types and System Health */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Device Types Distribution */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Device Types</h2>
          <div className="flex items-center justify-around">
            <div className="flex flex-col items-center">
              <CircularProgress 
                value={stats.devices.types.workstations} 
                max={stats.devices.total} 
                color="blue" 
                size={100} 
              />
              <p className="mt-2 text-sm font-medium text-gray-700">Workstations</p>
            </div>
            <div className="flex flex-col items-center">
              <CircularProgress 
                value={stats.devices.types.servers} 
                max={stats.devices.total} 
                color="green" 
                size={100} 
              />
              <p className="mt-2 text-sm font-medium text-gray-700">Servers</p>
            </div>
            <div className="flex flex-col items-center">
              <CircularProgress 
                value={stats.devices.types.network} 
                max={stats.devices.total} 
                color="purple" 
                size={100} 
              />
              <p className="mt-2 text-sm font-medium text-gray-700">Network</p>
            </div>
          </div>
        </div>

        {/* System Health */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">System Health</h2>
          <div className="space-y-4">
            <StatusCard 
              title="Updates Pending" 
              count={stats.updates.pending} 
              icon={FaShieldAlt} 
              color="yellow" 
              description="Devices needing updates" 
            />
            <StatusCard 
              title="Critical Updates" 
              count={stats.updates.critical} 
              icon={FaExclamationTriangle} 
              color="red" 
              description="Security patches required" 
            />
            <StatusCard 
              title="Monitoring Alerts" 
              count={stats.monitoring.alerts + stats.monitoring.warnings} 
              icon={FaBell} 
              color="blue" 
              description="Active alerts and warnings" 
            />
          </div>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
        <div className="space-y-4">
          {recentActivity.length > 0 ? (
            recentActivity.map((activity) => {
              // Map color names to Tailwind classes
              const activityColorClasses = {
                blue: 'text-blue-500',
                green: 'text-green-500',
                red: 'text-red-500',
                yellow: 'text-yellow-500',
                purple: 'text-purple-500',
                gray: 'text-gray-500'
              };
              const iconColorClass = activityColorClasses[activity.color] || activityColorClasses.blue;
              
              return (
                <div key={activity.id} className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <activity.icon className={`h-5 w-5 ${iconColorClass}`} />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-gray-900">{activity.message}</p>
                    <p className="text-xs text-gray-500">{formatTimeAgo(activity.timestamp)}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center py-4">
              <p className="text-gray-500 text-sm">No recent activity</p>
            </div>
          )}
        </div>
        <div className="mt-4 text-center">
          <button className="text-blue-600 hover:text-blue-800 text-sm font-medium">
            View all activity
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
