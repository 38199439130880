// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Toggle Switch Styles */
.toggle-checkbox {
  right: 0;
  z-index: 5;
  opacity: 0;
  top: 0;
}

.toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #4f46e5;
}

.toggle-checkbox:checked + .toggle-label:before {
  right: 0px; 
}

.toggle-checkbox:focus + .toggle-label {
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.25);
}

.toggle-label:before {
  position: absolute;
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  top: 0;
  left: 0;
  right: auto;
  content: "";
  transition: all 0.3s ease;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.toggle-checkbox:checked + .toggle-label:before {
  transform: translateX(100%);
  transition: transform 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/profile/profile.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,QAAQ;EACR,UAAU;EACV,UAAU;EACV,MAAM;AACR;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,mBAAmB;EACnB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,aAAa;EACb,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,+BAA+B;AACjC","sourcesContent":["/* Toggle Switch Styles */\n.toggle-checkbox {\n  right: 0;\n  z-index: 5;\n  opacity: 0;\n  top: 0;\n}\n\n.toggle-label {\n  display: block;\n  overflow: hidden;\n  cursor: pointer;\n  border: 0 solid #bbb;\n  border-radius: 20px;\n  transition: background-color 0.3s ease;\n}\n\n.toggle-checkbox:checked + .toggle-label {\n  background-color: #4f46e5;\n}\n\n.toggle-checkbox:checked + .toggle-label:before {\n  right: 0px; \n}\n\n.toggle-checkbox:focus + .toggle-label {\n  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.25);\n}\n\n.toggle-label:before {\n  position: absolute;\n  display: block;\n  height: 1.5rem;\n  width: 1.5rem;\n  top: 0;\n  left: 0;\n  right: auto;\n  content: \"\";\n  transition: all 0.3s ease;\n  border-radius: 50%;\n  background-color: white;\n  border: 1px solid #e5e7eb;\n  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);\n}\n\n.toggle-checkbox:checked + .toggle-label:before {\n  transform: translateX(100%);\n  transition: transform 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
