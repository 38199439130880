import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api/v1';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests if available
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const authService = {
  register: async (userData) => {
    try {
      const response = await apiClient.post('/auth/register', userData);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.data.user));
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred during registration' };
    }
  },
  
  login: async (email, password) => {
    try {
      const response = await apiClient.post('/auth/login', { email, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.data.user));
      }
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred during login' };
    }
  },
  
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },
  
  verifyEmail: async (email, verificationCode) => {
    try {
      const response = await apiClient.post('/auth/verify-email', { email, verificationCode });
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred during email verification' };
    }
  },
  
  resendVerification: async (email) => {
    try {
      const response = await apiClient.post('/auth/resend-verification', { email });
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred while resending verification code' };
    }
  },
  
  getCurrentUser: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },
};

export const clientService = {
  createClient: async (clientData) => {
    try {
      const response = await apiClient.post('/clients', clientData);
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred while creating client' };
    }
  },

  createClientDuringSignup: async (clientData) => {
    try {
      console.log('Creating client during signup with data:', clientData);
      const response = await apiClient.post('/clients/signup', clientData);
      console.log('Client creation during signup response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating client during signup:', error);
      throw error.response?.data || { message: 'An error occurred while creating client during signup' };
    }
  },
  
  getClients: async () => {
    try {
      const response = await apiClient.get('/clients');
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred while fetching clients' };
    }
  },
  
  getClient: async (id) => {
    try {
      const response = await apiClient.get(`/clients/${id}`);
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: 'An error occurred while fetching client' };
    }
  },
};

export const signupService = {
  // This function handles the complete signup process
  // It creates both a user account and a client record
  completeSignup: async (formData) => {
    try {
      // Step 1: Register the user
      const userData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        company: formData.companyName,
        phone: formData.phone
      };
      
      console.log('Registering user with data:', { ...userData, password: '***REDACTED***' });
      const userResponse = await authService.register(userData);
      console.log('User registration response:', userResponse.data);
      
      // Step 2: Create the client record with more detailed information
      const clientData = {
        name: formData.companyName,
        contactPerson: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          position: formData.position || 'Primary Contact'
        },
        address: {
          street: formData.address,
          city: formData.city,
          state: formData.county || '',
          postalCode: formData.postcode,
          country: 'United Kingdom'
        },
        supportContract: {
          type: 'monthly', // Default to monthly subscription
          startDate: new Date(),
          notes: `Initial signup - PC Count: ${formData.pcCount}`
        },
        notes: `Microsoft 365: ${formData.hasMicrosoft365}, Existing IT Support: ${formData.existingITSupport}`,
        tags: ['new-client', 'web-signup'],
        status: 'active',
        users: [userResponse.data.user._id] // Link the newly created user
      };
      
      // If they have Microsoft 365, add it to notes
      if (formData.hasMicrosoft365 === 'yes') {
        clientData.notes += '\nClient has Microsoft 365 and has acknowledged they will provide admin access.';
      }
      
      // If they have existing IT support, add it to notes
      if (formData.existingITSupport === 'yes') {
        clientData.notes += '\nClient has existing IT support and has acknowledged they may need to contact them to remove software.';
      }
      
      const clientResponse = await clientService.createClient(clientData);
      
      return {
        user: userResponse.data.user,
        client: clientResponse.data.client,
        token: userResponse.token
      };
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }
};

export default {
  auth: authService,
  client: clientService,
  signup: signupService
};
