import axios from 'axios';
import authHeader from '../utils/auth-header';

const API_URL = '/api/eset';

/**
 * Service for ESET integration
 */
class EsetService {
  /**
   * Test connection to ESET API
   * @returns {Promise} Promise with connection test result
   */
  testConnection() {
    return axios.get(`${API_URL}/test-connection`, { headers: authHeader() });
  }

  /**
   * Get all ESET customers
   * @returns {Promise} Promise with customers data
   */
  getCustomers() {
    return axios.get(`${API_URL}/customers`, { headers: authHeader() });
  }

  /**
   * Get ESET license usage
   * @returns {Promise} Promise with license usage data
   */
  getLicenseUsage() {
    return axios.get(`${API_URL}/license-usage`, { headers: authHeader() });
  }

  /**
   * Enable ESET for a client
   * @param {string} clientId - Client ID
   * @returns {Promise} Promise with result
   */
  enableForClient(clientId) {
    return axios.post(`${API_URL}/clients/${clientId}/enable`, {}, { headers: authHeader() });
  }

  /**
   * Disable ESET for a client
   * @param {string} clientId - Client ID
   * @returns {Promise} Promise with result
   */
  disableForClient(clientId) {
    return axios.post(`${API_URL}/clients/${clientId}/disable`, {}, { headers: authHeader() });
  }
  
  /**
   * Get license usage for a specific client
   * @param {string} clientId - Client ID
   * @returns {Promise} Promise with client license usage data
   */
  getClientLicenseUsage(clientId) {
    return axios.get(`${API_URL}/clients/${clientId}/license-usage`, { headers: authHeader() });
  }
  
  /**
   * Sync all clients with ESET to update license usage
   * @returns {Promise} Promise with sync results
   */
  syncAllClients() {
    return axios.post(`${API_URL}/sync-all-clients`, {}, { headers: authHeader() });
  }
}

export default new EsetService();
