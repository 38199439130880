// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-device-manager .device-icon {
  font-size: 1.2rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
}

.trusted-device-manager .card-header {
  background-color: #f8f9fa;
}

.trusted-device-manager .table th {
  font-weight: 600;
  color: #495057;
}

.trusted-device-manager .badge {
  font-size: 0.8rem;
  padding: 0.35em 0.65em;
}

.trusted-device-manager .modal-body h5 {
  color: #343a40;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.trusted-device-manager .modal-body h5:first-child {
  margin-top: 0;
}

.trusted-device-manager .modal-body ul,
.trusted-device-manager .modal-body ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.trusted-device-manager .modal-body li {
  margin-bottom: 0.5rem;
}

.trusted-device-manager .device-limit-info {
  display: flex;
  justify-content: flex-end;
  font-size: 0.9rem;
  color: #6c757d;
}

.trusted-device-manager .device-limit-info .text-warning {
  color: #ffc107 !important;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/TrustedDevices/TrustedDevices.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".trusted-device-manager .device-icon {\n  font-size: 1.2rem;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #495057;\n}\n\n.trusted-device-manager .card-header {\n  background-color: #f8f9fa;\n}\n\n.trusted-device-manager .table th {\n  font-weight: 600;\n  color: #495057;\n}\n\n.trusted-device-manager .badge {\n  font-size: 0.8rem;\n  padding: 0.35em 0.65em;\n}\n\n.trusted-device-manager .modal-body h5 {\n  color: #343a40;\n  margin-top: 1.5rem;\n  margin-bottom: 0.75rem;\n  font-size: 1.1rem;\n  font-weight: 600;\n}\n\n.trusted-device-manager .modal-body h5:first-child {\n  margin-top: 0;\n}\n\n.trusted-device-manager .modal-body ul,\n.trusted-device-manager .modal-body ol {\n  padding-left: 1.5rem;\n  margin-bottom: 1rem;\n}\n\n.trusted-device-manager .modal-body li {\n  margin-bottom: 0.5rem;\n}\n\n.trusted-device-manager .device-limit-info {\n  display: flex;\n  justify-content: flex-end;\n  font-size: 0.9rem;\n  color: #6c757d;\n}\n\n.trusted-device-manager .device-limit-info .text-warning {\n  color: #ffc107 !important;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
