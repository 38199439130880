import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

/**
 * EmptyState component for displaying when no data is available
 * @param {Object} props - Component props
 * @param {string} props.title - Title to display
 * @param {string} props.message - Message to display
 * @param {string} props.actionText - Text for the action button
 * @param {string} props.actionLink - Link for the action button
 * @param {Function} props.actionOnClick - Function to call when action button is clicked
 * @param {React.ReactNode} props.icon - Icon to display
 */
const EmptyState = ({
  title = 'No data available',
  message = 'There are no items to display at this time.',
  actionText,
  actionLink,
  actionOnClick,
  icon,
}) => {
  return (
    <div className="text-center py-12 px-4">
      <div className="flex justify-center mb-4">
        {icon || (
          <div className="rounded-full bg-gray-100 p-3">
            <FaPlus className="h-6 w-6 text-gray-400" />
          </div>
        )}
      </div>
      <h3 className="text-lg font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
      {actionText && (
        <div className="mt-6">
          {actionLink ? (
            <Link
              to={actionLink}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {actionText}
            </Link>
          ) : actionOnClick ? (
            <button
              type="button"
              onClick={actionOnClick}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {actionText}
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default EmptyState;
