import React, { useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { FaUser, FaHandshake, FaCheck } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import LoginPopup from '../auth/LoginPopup';
import logo from '../../IsIT Logo.png';
import backgroundImage from '../../assets/background.jpg';

// Use images from the public folder
const timerImage = '/landingImages/90support.jpg';
const monitoringImage = '/landingImages/pcmonitoring.jpg';
const securityImage = '/landingImages/pcsecurity.jpg';
const scanningImage = '/landingImages/cybersecurity.jpg';
const invoiceImage = '/landingImages/value.jpg';
const portalImage = '/landingImages/access.jpg';
const supportImage = '/landingImages/experienced.jpg';
const flexibleImage = '/landingImages/flexible.jpg';


// Styled components
const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
  
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
  }
`;

const MainPanel = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
  background: linear-gradient(to bottom, rgba(28, 54, 85, 0.7), rgba(30, 41, 59, 0.7));
  backdrop-filter: blur(10px);
  border-radius: 12px;
  position: relative;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(58, 134, 255, 0.1);
  
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  
  @media (max-width: 768px) {
    flex-direction: column-reverse; /* Reversed to put login button on top */
    gap: 1rem;
  }
`;

const ClientLoginButton = styled.button`
  background: linear-gradient(135deg, #3a86ff 0%, #0967d2 100%);
  color: white;
  border: none;
  padding: 0.5rem 1rem; /* Reduced padding */
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem; /* Reduced gap */
  font-size: 0.9rem; /* Reduced font size */
  max-width: 125px; /* 50% of original size */
  
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    width: auto; /* Changed from 100% to auto */
    max-width: 150px; /* Reduced from 250px */
    font-size: 0.9rem;
    margin-top: 0.5rem; /* Added top margin for mobile */
  }
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #0967d2 0%, #0550a0 100%);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Section = styled.section`
  margin-bottom: 3rem;
  position: relative;
  padding: 0 1rem;
  
  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding: 0 0.5rem;
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    bottom: -1.5rem;
    background: linear-gradient(90deg, 
      rgba(0,0,0,0) 0%, 
      rgba(0,0,0,0.15) 25%, 
      rgba(0,0,0,0.15) 75%, 
      rgba(0,0,0,0) 100%
    );
    
    @media (max-width: 768px) {
      bottom: -1rem;
    }
  }
  
  &:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
`;

const HeroSection = styled(Section)`
  text-align: center;
  padding: 0 2rem 3rem;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 0 1rem 2rem;
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: -0.5px;
  line-height: 1.3;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: nowrap; /* Prevent wrapping on small screens */
  width: 100%;
  
  @media (max-width: 480px) {
    margin-bottom: 0.75rem;
  }
`;

const AnimatedTextContainer = styled.div`
  margin-bottom: 2rem;
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
  font-size: 2.5rem;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow: visible;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
    height: 2.5rem;
  }
`;

const StaticText = styled.span`
  margin-right: 0.5rem;
  font-weight: 800;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

// Styled components for the carousel
const StaticTextStyled = styled.div`
  font-weight: 800;
  margin-right: 0.5rem;
  font-size: 2.5rem;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  
  @media (max-width: 480px) {
    font-size: 1.1rem;
    margin-right: 0.3rem; /* Slightly reduce margin on very small screens */
  }
`;

const CarouselWrapperStyled = styled.div`
  overflow: hidden;
  position: relative;
  min-width: 240px; /* Increased from 220px to accommodate longer words */
  height: 3.5rem;
  
  @media (max-width: 768px) {
    min-width: 200px; /* Increased from 180px for mobile */
    height: 2.5rem;
  }
  
  @media (max-width: 480px) {
    min-width: 180px; /* Adjust for very small screens */
  }
`;


const WordStyled = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffd60a;
  font-weight: 600;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 2.5rem;
  width: 100%;
  white-space: nowrap;
  padding: 0 5px; /* Add padding to prevent text from touching the edges */
  
  @media (max-width: 768px) {
    height: 2.5rem;
    font-size: 1.2rem;
  }
  
  @media (max-width: 480px) {
    font-size: 1.1rem; /* Slightly smaller font for very small screens */
  }
`;

// Create keyframes animations
const slideDesktop = keyframes`
  0%, 15% {
    transform: translateY(0);
  }
  20%, 35% {
    transform: translateY(-3.5rem);
  }
  40%, 55% {
    transform: translateY(-7rem);
  }
  60%, 75% {
    transform: translateY(-10.5rem);
  }
  80%, 95% {
    transform: translateY(-14rem);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideMobile = keyframes`
  0%, 15% {
    transform: translateY(0);
  }
  20%, 35% {
    transform: translateY(-2.5rem);
  }
  40%, 55% {
    transform: translateY(-5rem);
  }
  60%, 75% {
    transform: translateY(-7.5rem);
  }
  80%, 95% {
    transform: translateY(-10rem);
  }
  100% {
    transform: translateY(0);
  }
`;

// Update WordSlideStyled to use the keyframes
const WordSlideStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${slideDesktop} 10s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  
  @media (max-width: 768px) {
    animation: ${slideMobile} 10s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  }
`;

// WordCarousel component
const WordCarousel = () => {
  return (
    <CarouselContainer>
      <StaticTextStyled>Is IT</StaticTextStyled>
      <CarouselWrapperStyled>
        <WordSlideStyled>
          <WordStyled>Broken?</WordStyled>
          <WordStyled>Printing?</WordStyled>
          <WordStyled>Connecting?</WordStyled>
          <WordStyled>Working?</WordStyled>
          <WordStyled>Confusing?</WordStyled>
        </WordSlideStyled>
      </CarouselWrapperStyled>
    </CarouselContainer>
  );
};

const Subtitle = styled.h2`
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 1rem; /* Reduced from 1.5rem */
  font-weight: 700;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  padding-top: 1rem; /* Reduced from 1.5rem */
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 0.8rem; /* Reduced from 1.2rem */
    padding-top: 0.7rem; /* Reduced from 1rem */
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.6;
  }
`;

const PriceTag = styled.div`
  font-size: 1.8rem;
  color: #ffd60a;
  margin: 1.5rem auto 0.8rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 1.2rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
    padding: 0.3rem 1rem;
    margin: 1.2rem auto 0.6rem;
  }
  
  span {
    font-size: 0.8rem;
    color: #ffffff;
    margin-left: 0.5rem;
    
    @media (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
`;

const PartnershipBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  padding: 0.6rem 1.2rem;
  margin: 1rem auto 1.2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    max-width: 250px;
    padding: 0.5rem 1rem;
    margin: 0.8rem auto 1rem;
  }
`;

const PartnerText = styled.span`
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
  margin-right: 0.5rem;
  white-space: nowrap;
`;

const PartnerLogo = styled.img`
  height: 120px;
  position: relative;
  @media (max-width: 768px) {
    height: 240px;
  }
`;

const CTAButton = styled.button`
  background-color: #3a86ff;
  color: white;
  border: none;
  padding: 0.9rem 2.5rem; /* Reduced padding to make button smaller */
  font-size: 1.2rem; /* Kept the same text size */
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 1.2rem; /* Reduced top margin */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%; /* Make button 15% smaller */
  max-width: 220px; /* Reduced from original size */
  
  @media (max-width: 768px) {
    padding: 0.8rem 1.8rem;
    font-size: 1.1rem;
    width: 85%;
    max-width: 240px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255,255,255,0.2), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }
  
  &:hover {
    background-color: #0967d2;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(58, 134, 255, 0.3);
    
    &::before {
      transform: translateX(100%);
    }
  }
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
  
  @media (max-width: 1200px) and (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const Logo = styled.img`
  height: 110px;
  display: block;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  
  @media (max-width: 768px) {
    margin-top: 0.5rem; /* Added margin for mobile view */
  }
`;

const Icon = styled.svg`
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: transform 0.3s ease;

  path, circle, rect, line {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: drawLine 1.5s ease forwards;
  }

  @keyframes drawLine {
    to {
      stroke-dashoffset: 0;
    }
  }
`;


const CardHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  
  @media (max-width: 768px) {
    height: 110px;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom, 
      rgba(58, 134, 255, 0) 30%, 
      rgba(9, 103, 210, 0.8) 80%,
      rgba(9, 103, 210, 1) 100%
    );
  }
`;

const BenefitCard = styled.div`
  background: linear-gradient(135deg, #3a86ff 0%, #0967d2 100%);
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    box-shadow:
      inset 0 2px 2px rgba(255,255,255,0.4),
      inset 0 -2px 2px rgba(0,0,0,0.3);
    pointer-events: none;
  }
  padding: 1.5rem;
  padding-top: 130px;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 1.25rem;
    padding-top: 120px;
    min-height: 200px;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(58, 134, 255, 0.3);
    z-index: 1;

    &::before {
      opacity: 1;
    }
  }

  h3 {
    color: #ffffff;
    margin-top: 1rem;
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    font-weight: 600;
    
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
  
  p {
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.4;
    font-size: 0.75rem;
    
    @media (max-width: 768px) {
      font-size: 0.85rem;
      line-height: 1.5;
    }
  }
`;

const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(5px);
`;

const FormContainer = styled.div`
  background: rgba(25, 55, 100, 0.85);
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  transform: ${props => props.show ? 'scale(1)' : 'scale(0.9)'};
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, #FFD700, #FFC000);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const FormTitle = styled.h3`
  color: white;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  text-align: center;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const FormField = styled.div`
  margin-bottom: 1.2rem;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  color: #1e293b;
  font-size: 0.875rem;
  transition: all 0.3s;
  
  &:focus {
    outline: none;
    border-color: #FFD700;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.3);
  }
  
  &::placeholder {
    color: #94a3b8;
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  color: #1e293b;
  font-size: 0.875rem;
  min-height: 120px;
  resize: vertical;
  transition: all 0.3s;
  
  &:focus {
    outline: none;
    border-color: #FFD700;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.3);
  }
  
  &::placeholder {
    color: #94a3b8;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  background: linear-gradient(135deg, #FFD700 0%, #FFC000 100%);
  color: #1e293b;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  
  &:hover {
    background: linear-gradient(135deg, #FFC000 0%, #FFB000 100%);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  &:hover::after {
    opacity: 1;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  color: white;
  font-size: 1.2rem;
  margin: 2rem 0;
  padding: 1rem;
  background: rgba(0, 200, 83, 0.2);
  border-radius: 6px;
  border: 1px solid rgba(0, 200, 83, 0.4);
  display: ${props => props.show ? 'block' : 'none'};
`;

const ContactInfo = styled.div`
  text-align: left;
  margin-top: 3rem;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(28, 54, 85, 0.8), rgba(30, 41, 59, 0.8));
  border-radius: 8px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(58, 134, 255, 0.15);
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-top: 2rem;
  }
  
  h3 {
    color: #ffffff;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.8rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    
    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .contact-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-bottom: 2.5rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
    
    @media (max-width: 768px) {
      font-size: 1rem;
      gap: 0.75rem;
      flex-wrap: wrap;
    }
    
    span:first-child {
      font-size: 1.5rem;
      
      @media (max-width: 768px) {
        font-size: 1.25rem;
      }
    }
  }
  
  a {
    color: #3a86ff;
    text-decoration: none;
    font-weight: 600;
    word-break: break-word;
    
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
    
    &:hover {
      text-decoration: underline;
      color: #0967d2;
    }
  }
  
  .button-container {
    text-align: center;
    margin-top: 2rem;
    
    @media (max-width: 768px) {
      margin-top: 1.5rem;
    }
  }
`;

const Landing = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });


  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
    error: null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({ submitting: true, submitted: false, error: null });
    
    try {
      // Use axios with relative URL - will work in both dev and prod
      const response = await axios.post('/api/contact/submit', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data || !response.data.success) {
        throw new Error('Failed to submit form');
      }

      setFormStatus({ submitting: false, submitted: true, error: null });
      setFormData({ name: '', email: '', phone: '', message: '' });
      
      // Close form after 3 seconds
      setTimeout(() => {
        setShowContactForm(false);
        setFormStatus({ submitting: false, submitted: false, error: null });
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormStatus({ 
        submitting: false, 
        submitted: false, 
        error: 'Failed to submit form. Please try again later.'
      });
    }
  };



  // Handle keyboard events (close on Escape key)
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        if (showContactForm) setShowContactForm(false);
        if (showLoginForm) setShowLoginForm(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showContactForm, showLoginForm]);

  return (
    <PageContainer>
      {/* Toast Container for notifications */}
      <ToastContainer position="top-right" autoClose={5000} />
      
      {/* Login Form Popup */}
      <LoginPopup show={showLoginForm} onClose={() => setShowLoginForm(false)} />
      
      {/* Contact Form Overlay */}
      <FormOverlay show={showContactForm} onClick={() => setShowContactForm(false)}>
        <FormContainer show={showContactForm} onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={() => setShowContactForm(false)}>✕</CloseButton>
          <FormTitle>Get in Touch</FormTitle>
          
          {formStatus.submitted ? (
            <SuccessMessage show={true}>
              <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
                <div style={{ fontSize: '3rem', marginBottom: '1rem', color: '#4CAF50' }}>
                  <FaCheck />
                </div>
                <div>Thank you for your message!</div>
              </div>
              <div style={{ fontSize: '0.9rem', opacity: '0.8', textAlign: 'center' }}>
                We'll get back to you as soon as possible.
              </div>
            </SuccessMessage>
          ) : (
            <form onSubmit={handleSubmit}>
              <FormField>
                <FormLabel htmlFor="name">Name</FormLabel>
                <FormInput
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your name"
                  required
                />
              </FormField>
              
              <FormField>
                <FormLabel htmlFor="email">Email</FormLabel>
                <FormInput
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your email address"
                  required
                />
              </FormField>
              
              <FormField>
                <FormLabel htmlFor="phone">Phone (optional)</FormLabel>
                <FormInput
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Your phone number"
                />
              </FormField>
              
              <FormField>
                <FormLabel htmlFor="message">Message</FormLabel>
                <FormTextarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="How can we help you?"
                  required
                />
              </FormField>
              
              <SubmitButton type="submit" disabled={formStatus.submitting}>
                {formStatus.submitting ? 'Sending...' : 'Send Message'}
              </SubmitButton>
            </form>
          )}
        </FormContainer>
      </FormOverlay>
      
      <MainPanel>
        <HeaderContainer>
          <Logo src={logo} alt="Is IT Support Logo" />
          <ClientLoginButton onClick={() => setShowLoginForm(true)}>
            <FaUser /> Client Login
          </ClientLoginButton>
        </HeaderContainer>
        <HeroSection>
          <Title style={{ fontSize: '1.5rem', marginTop: '0' }}><strong style={{ fontWeight: 800 }}>Is IT Support</strong> - Technical help for your business</Title>
          <AnimatedTextContainer>
            <WordCarousel />
          </AnimatedTextContainer>
          <Subtitle>
            <span>Professional IT support tailored for small businesses in Dumfries & Galloway.</span>
          </Subtitle>
          
          
            <PartnerLogo src="/landingImages/ESET-Partner-L.png" alt="ESET Partner Logo" />
          
          
          <PriceTag>£55 <span>Per Month <p>No contract</p></span></PriceTag>
          <CTAButton onClick={() => window.location.href = '/signup'}>Get Started</CTAButton>
          <span>(Places limited - apply now!)</span>
        </HeroSection>

        <Section>
          <Title style={{ fontSize: '1.5rem' }}>Everything You Need</Title>
          <BenefitsGrid>
            <BenefitCard>
              <CardHeader image={timerImage} />

              <h3>90 Minutes Support Included</h3>
              <p>Monthly support time for any IT query - from quick how-to questions to complex troubleshooting.</p>
            </BenefitCard>
            <BenefitCard>
              <CardHeader image={monitoringImage} />

              <h3>Complete PC Monitoring</h3>
              <p>24/7 monitoring for all your business PCs, ensuring they run at their best.</p>
            </BenefitCard>
            <BenefitCard>
              <CardHeader image={securityImage} />

              <h3>Internet Security</h3>
              <p>Free Internet Security for one PC, keeping your business protected.</p>
              <p>Protect the rest at discounted cost.</p>
            </BenefitCard>
            <BenefitCard>
              <CardHeader image={scanningImage} />

              <h3>Cyber Security Check</h3>
              <p>Complimentary assessment to ensure your systems are safe from cyber threats.</p>
            </BenefitCard>
          </BenefitsGrid>
        </Section>

        <Section>
          <Title style={{ fontSize: '1.5rem' }}>Simple & Transparent</Title>
          <BenefitsGrid>
          <BenefitCard>
            <CardHeader image={invoiceImage} />

            <h3>Fixed Monthly Fee</h3>
            <p>Predictable costs with no hidden charges. Cancel anytime - no long-term contracts.</p>
          </BenefitCard>
          <BenefitCard>
            <CardHeader image={portalImage} />

            <h3>Easy Portal Access</h3>
            <p>Track your support time, manage subscriptions, and submit support tickets all in one place.</p>
          </BenefitCard>
          <BenefitCard>
            <CardHeader image={supportImage} />

            <h3>Experienced Support</h3>
            <p>30 years of IT experience, from basic software questions to complex system migrations.</p>
          </BenefitCard>
          <BenefitCard>
            <CardHeader image={flexibleImage} />

            <h3>Flexible Support Options</h3>
            <p>Need more time? Additional support available in 15-minute blocks when you need it.</p>
          </BenefitCard>
        </BenefitsGrid>
      </Section>

        <Section>
          <ContactInfo>
            <h3 style={{ textAlign: 'center' }}>Get in Touch</h3>
            <div className="contact-list">
              <div className="contact-item">
                <span>📞</span>
                <a href="tel:+441387406006">01387 406006</a>
              </div>
              <div className="contact-item">
                <span>✉️</span>
                <a href="mailto:support@isitsupport.co.uk">enquiries@isitsupport.co.uk</a>
              </div>
              <div className="contact-item">
                <span>📍</span>
                <span>Serving Small Businesses in Dumfries & Galloway</span>
              </div>
              <div className="contact-item">
                <span>🕒</span>
                <span>Available Monday - Friday, 9 AM - 5 PM</span>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <div className="button-container">
              <CTAButton 
                onClick={() => setShowContactForm(true)}
                style={{ backgroundColor: '#3a86ff', boxShadow: '0 0 15px rgba(58, 134, 255, 0.5)', fontSize: '1.3rem', padding: '1.2rem 2.8rem' }}
              >
                Contact Us Today
              </CTAButton>
            </div>
            </div>
          </ContactInfo>
        </Section>
      </MainPanel>
    </PageContainer>
  );
};

export default Landing;
