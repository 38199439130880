import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import backgroundImage from '../../assets/background.jpg';

const Container = styled.div`
  padding: 20px;
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
`;

const BackgroundTest = styled.div`
  width: 300px;
  height: 200px;
  margin-top: 20px;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
`;

const ImageTest = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    // Convert imported image to data URL for testing
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
      setImageSrc(backgroundImage);
    };
    img.onerror = (e) => {
      setImageError(`Error loading image: ${e.type}`);
    };
    img.src = backgroundImage;
  }, []);

  return (
    <Container>
      <h1>Image Loading Test</h1>
      
      <div>
        <h2>Image Import Information:</h2>
        <pre>{JSON.stringify({ backgroundImage }, null, 2)}</pre>
      </div>

      <ImageContainer>
        <h2>Direct Image Tag Test:</h2>
        {imageLoaded ? (
          <div>
            <p>Image loaded successfully!</p>
            <img src={imageSrc} alt="Background" style={{ maxWidth: '300px' }} />
          </div>
        ) : (
          <p>{imageError || 'Loading image...'}</p>
        )}
      </ImageContainer>

      <div>
        <h2>CSS Background Image Test:</h2>
        <BackgroundTest />
        <p>If you can see an image above, the CSS background-image is working.</p>
      </div>
    </Container>
  );
};

export default ImageTest;
