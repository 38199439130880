import axios from 'axios';
import authHeader from '../utils/auth-header';

const API_URL = '/api/clients';

/**
 * Normalize a TacticalRMM API URL to handle standard ports correctly
 * @param {string} url - The URL to normalize
 * @returns {string} - The normalized URL
 */
const normalizeApiUrl = (url) => {
  if (!url) return url;
  
  try {
    const parsedUrl = new URL(url);
    // Remove explicit port specifications if they're the standard ports
    if (parsedUrl.protocol === 'http:' && parsedUrl.port === '80') {
      parsedUrl.port = ''; // Standard HTTP port, remove it from URL
    } else if (parsedUrl.protocol === 'https:' && parsedUrl.port === '443') {
      parsedUrl.port = ''; // Standard HTTPS port, remove it from URL
    }
    return parsedUrl.toString();
  } catch (error) {
    console.error('Invalid URL format:', error);
    return url; // Return original URL if parsing fails
  }
};

/**
 * Service for client management and TacticalRMM integration
 */
class ClientService {
  /**
   * Get all clients
   * @param {Object} params - Query parameters for filtering and pagination
   * @returns {Promise} - Promise with clients data
   */
  getClients(params = {}) {
    // Check if user is logged in by verifying token exists
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. User must be logged in to fetch clients.');
      return Promise.reject(new Error('Authentication required. Please log in to view clients.'));
    }
    
    return axios.get(API_URL, {
      headers: authHeader(),
      params,
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        console.error('Authentication failed when fetching clients. Token may be invalid or expired.');
        // Clear invalid token and user data
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
      return Promise.reject(error);
    });
  }

  /**
   * Get a specific client by ID
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with client data
   */
  getClient(id) {
    // Check if user is logged in by verifying token exists
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. User must be logged in to fetch client details.');
      return Promise.reject(new Error('Authentication required. Please log in to view client details.'));
    }
    
    return axios.get(`${API_URL}/${id}`, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          console.error('Authentication failed when fetching client details. Token may be invalid or expired.');
          // Clear invalid token and user data
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Create a new client
   * @param {Object} client - Client data
   * @returns {Promise} - Promise with created client data
   */
  createClient(client) {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to create clients.'));
    }
    
    return axios.post(API_URL, client, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Create a new client during signup (doesn't require admin role)
   * @param {Object} client - Client data
   * @returns {Promise} - Promise with created client data
   */
  createClientDuringSignup(client) {
    return axios.post(`${API_URL}/signup`, client, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Update an existing client
   * @param {string} id - Client ID
   * @param {Object} client - Updated client data
   * @returns {Promise} - Promise with updated client data
   */
  updateClient(id, client) {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to update clients.'));
    }
    
    return axios.patch(`${API_URL}/${id}`, client, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Delete a client
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with deletion result
   */
  deleteClient(id) {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to delete clients.'));
    }
    
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Get all TacticalRMM clients for linking
   * @returns {Promise} - Promise with TacticalRMM clients
   */
  getTacticalRMMClients() {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to view TacticalRMM clients.'));
    }
    
    return axios.get(`${API_URL}/tactical-rmm-clients`, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Link client to TacticalRMM client
   * @param {string} id - Client ID
   * @param {Object} data - TacticalRMM client and site IDs
   * @returns {Promise} - Promise with linked client data
   */
  linkToTacticalRMM(id, data) {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to link clients to TacticalRMM.'));
    }
    
    return axios.post(`${API_URL}/${id}/link-tactical-rmm`, data, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Sync client with TacticalRMM
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with sync results
   */
  syncWithTacticalRMM(id) {
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required. Please log in to sync clients with TacticalRMM.'));
    }
    
    return axios.post(`${API_URL}/${id}/sync-tactical-rmm`, {}, { headers: authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Get client time usage statistics
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with client time usage statistics
   */
  getClientTimeUsage(id) {
    return axios.get(`${API_URL}/${id}/time-usage`, { headers: authHeader() });
  }

  /**
   * Get devices for a specific client
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with client devices
   */
  getClientDevices(id) {
    return axios.get(`${API_URL}/${id}/devices`, { headers: authHeader() });
  }

  /**
   * Get client statistics
   * @returns {Promise} - Promise with client statistics
   */
  getClientStats() {
    return axios.get(`${API_URL}/stats`, { headers: authHeader() });
  }

  /**
   * Sync devices from TacticalRMM for a specific client
   * @param {string} id - Client ID
   * @returns {Promise} - Promise with synced devices
   */
  syncClientDevices(id) {
    return axios.post(`${API_URL}/${id}/sync-devices`, {}, { headers: authHeader() });
  }
  
  /**
   * Reset a client's password
   * @param {string} id - The client ID
   * @returns {Promise} - Promise with reset result
   */
  resetClientPassword(id) {
    return axios.post(`${API_URL}/${id}/reset-password`, {}, {
      headers: authHeader()
    });
  }

  /**
   * Change a client's password
   * @param {string} id - The client ID
   * @param {string} newPassword - The new password
   * @returns {Promise} - Promise with change result
   */
  changeClientPassword(id, newPassword) {
    return axios.post(`${API_URL}/${id}/change-password`, { newPassword }, {
      headers: authHeader()
    });
  }

  /**
   * Delete a client
   * @param {string} id - The client ID
   * @returns {Promise} - Promise with deletion result
   */
  deleteClient(id) {
    return axios.delete(`${API_URL}/${id}`, {
      headers: authHeader()
    });
  }
}

export default new ClientService();
