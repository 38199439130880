import React from 'react';
import { Card, Divider, Typography, Space, List, Tag } from 'antd';
import { 
  FaDownload, 
  FaDesktop, 
  FaWindows
} from 'react-icons/fa';
import branding from '../../config/branding';

const { Title, Paragraph, Text } = Typography;

const PortalDownloads = () => {
  // Get dark mode preference
  const darkMode = localStorage.getItem('tacticalRmmDarkMode') === 'true';
  
  // Get user from localStorage
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  return (
    <div className={`portal-downloads ${darkMode ? 'dark-mode' : ''}`}>
      <div className="page-header mb-6">
        <Title level={2} className={darkMode ? 'text-white' : ''}>
          Download Support Tools
        </Title>
        <Paragraph className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
          Download the necessary tools to allow {branding.company.name} to provide remote support for your systems.
        </Paragraph>
      </div>

      <Card 
        className={`mb-6 shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
        bordered={!darkMode}
      >
        <Title level={4} className={darkMode ? 'text-white' : ''}>
          Remote Support Tools
        </Title>
        <Paragraph className={darkMode ? 'text-gray-300' : 'text-gray-600'}>
          Our remote support tools package includes:
        </Paragraph>
        
        <List
          itemLayout="horizontal"
          dataSource={[
            {
              title: 'TacticalRMM Agent',
              description: 'For proactive monitoring and management of your systems',
              icon: <FaDesktop className={`text-xl ${darkMode ? 'text-blue-400' : 'text-blue-600'}`} />
            },
            {
              title: 'RustDesk',
              description: 'For secure remote access when you need assistance',
              icon: <FaWindows className={`text-xl ${darkMode ? 'text-green-400' : 'text-green-600'}`} />
            }
          ]}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={item.icon}
                title={<Text className={darkMode ? 'text-white' : ''}>{item.title}</Text>}
                description={<Text className={darkMode ? 'text-gray-300' : ''}>{item.description}</Text>}
              />
            </List.Item>
          )}
        />

        <Divider className={darkMode ? 'border-gray-600' : ''} />
        
        <div className="download-section">
          <Space direction="vertical" size="middle" className="w-full">
            <div className="flex items-center">
              <a 
                href="/Is IT Onboarding Setup.exe" 
                target="_blank" 
                rel="noopener noreferrer"
                className="ant-btn ant-btn-primary ant-btn-lg" 
                style={{backgroundColor: '#1890ff', color: 'white', fontWeight: 'bold', display: 'flex', alignItems: 'center', padding: '10px 20px', borderRadius: '8px'}}
              >
                <FaDownload className="mr-2" /> Download Installer
              </a>
              <Tag color="blue">Windows Only</Tag>
            </div>
          </Space>
        </div>
      </Card>

      {/* Bottom section with Available Support Tools removed as requested */}
    </div>
  );
};

export default PortalDownloads;
