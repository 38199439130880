import React from 'react';
import { Card, Row, Col, Alert, Badge, Table, Typography, Statistic, Spin, Tooltip } from 'antd';
import { 
  CheckCircleOutlined, 
  ExclamationCircleOutlined, 
  CloseCircleOutlined, 
  WarningOutlined,
  InfoCircleOutlined,
  HddOutlined,
  ApiOutlined,
  DesktopOutlined,
  CloudSyncOutlined
} from '@ant-design/icons';

const { Title, Text } = Typography;

const DeviceIssues = ({ loading, issuesData }) => {
  // If no data is provided, show a loading state
  if (!issuesData && !loading) {
    return (
      <Card title="Device Issues" className="dashboard-card">
        <Alert
          message="No device data available"
          description="TacticalRMM integration may not be configured or there was an error connecting to the service."
          type="info"
          showIcon
        />
      </Card>
    );
  }

  // Columns for the recent alerts table
  const alertColumns = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      render: severity => {
        if (severity === 'critical') {
          return <Badge status="error" text="Critical" />;
        } else if (severity === 'warning') {
          return <Badge status="warning" text="Warning" />;
        } else {
          return <Badge status="info" text="Info" />;
        }
      },
    },
    {
      title: 'Device',
      dataIndex: 'hostname',
      key: 'hostname',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: time => new Date(time).toLocaleString(),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Card title="Device Issues" className="dashboard-card">
        {issuesData?.error ? (
          <Alert
            message="Error fetching device issues"
            description={issuesData.error}
            type="error"
            showIcon
          />
        ) : (
          <>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Statistic 
                  title="Total Devices" 
                  value={issuesData?.totalDevices || 0} 
                  prefix={<DesktopOutlined />} 
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Statistic 
                  title="Offline Devices" 
                  value={issuesData?.offlineDevices || 0} 
                  valueStyle={{ color: issuesData?.offlineDevices > 0 ? '#cf1322' : '#3f8600' }}
                  prefix={<CloseCircleOutlined />} 
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Statistic 
                  title="Needs Patching" 
                  value={issuesData?.needsPatching || 0} 
                  valueStyle={{ color: issuesData?.needsPatching > 0 ? '#faad14' : '#3f8600' }}
                  prefix={<CloudSyncOutlined />} 
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Statistic 
                  title="Total Alerts" 
                  value={issuesData?.alerts?.total || 0} 
                  valueStyle={{ color: issuesData?.alerts?.total > 0 ? '#cf1322' : '#3f8600' }}
                  prefix={<ExclamationCircleOutlined />} 
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Tooltip title="Devices with disk space usage over 90%">
                  <Statistic 
                    title="Disk Space Issues" 
                    value={issuesData?.diskSpaceIssues || 0} 
                    valueStyle={{ color: issuesData?.diskSpaceIssues > 0 ? '#faad14' : '#3f8600' }}
                    prefix={<HddOutlined />} 
                  />
                </Tooltip>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Tooltip title="Devices with CPU usage over 80%">
                  <Statistic 
                    title="High CPU Usage" 
                    value={issuesData?.highCpuIssues || 0} 
                    valueStyle={{ color: issuesData?.highCpuIssues > 0 ? '#faad14' : '#3f8600' }}
                    prefix={<ApiOutlined />} 
                  />
                </Tooltip>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Tooltip title="Devices with memory usage over 85%">
                  <Statistic 
                    title="High Memory Usage" 
                    value={issuesData?.highMemoryIssues || 0} 
                    valueStyle={{ color: issuesData?.highMemoryIssues > 0 ? '#faad14' : '#3f8600' }}
                    prefix={<ApiOutlined />} 
                  />
                </Tooltip>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Row>
                  <Col span={8}>
                    <Statistic 
                      title="Critical" 
                      value={issuesData?.alerts?.critical || 0} 
                      valueStyle={{ color: '#cf1322' }}
                      prefix={<CloseCircleOutlined />} 
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic 
                      title="Warning" 
                      value={issuesData?.alerts?.warning || 0} 
                      valueStyle={{ color: '#faad14' }}
                      prefix={<WarningOutlined />} 
                    />
                  </Col>
                  <Col span={8}>
                    <Statistic 
                      title="Info" 
                      value={issuesData?.alerts?.info || 0} 
                      valueStyle={{ color: '#1890ff' }}
                      prefix={<InfoCircleOutlined />} 
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {issuesData?.recentAlerts && issuesData.recentAlerts.length > 0 && (
              <div style={{ marginTop: 24 }}>
                <Title level={4}>Recent Alerts</Title>
                <Table 
                  dataSource={issuesData.recentAlerts} 
                  columns={alertColumns} 
                  rowKey="id"
                  pagination={false}
                  size="small"
                />
              </div>
            )}
          </>
        )}
      </Card>
    </Spin>
  );
};

export default DeviceIssues;
