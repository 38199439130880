import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaPaperPlane } from 'react-icons/fa';

const CreateTicket = () => {
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'medium',
    deviceId: ''
  });

  // Import services
  const deviceService = require('../../services/device.service').default;
  const ticketService = require('../../services/ticket.service').default;

  useEffect(() => {
    // Fetch devices from the API
    const fetchDevices = async () => {
      try {
        const response = await deviceService.getMyDevices();
        if (response.data && response.data.data && response.data.data.devices) {
          setDevices(response.data.data.devices);
        } else {
          setDevices([]);
          console.warn('No devices found or unexpected API response format');
        }
      } catch (err) {
        console.error('Error fetching devices:', err);
        setDevices([]);
      }
    };
    
    fetchDevices();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Add state for error message display
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear any previous error messages

    try {
      // Validate form data before submission
      if (!formData.title.trim()) {
        setErrorMessage('Please enter a ticket title');
        setLoading(false);
        return;
      }

      if (!formData.description.trim()) {
        setErrorMessage('Please enter a ticket description');
        setLoading(false);
        return;
      }

      // Send the form data to the API
      const response = await ticketService.createTicket({
        title: formData.title,
        description: formData.description,
        priority: formData.priority,
        deviceId: formData.deviceId || null
      });
      
      // Log the entire response for debugging
      console.log('Ticket creation response:', response);
      
      // Check for success indicators in the response
      if (response.data && (response.data.success || response.data.status === 'success')) {
        console.log('Ticket created successfully!', response.data);
        // Show success toast or notification here if you have a notification system
        
        // Redirect to the tickets list after successful submission
        navigate('/tickets');
      } else {
        // Handle unexpected response format
        const errorMsg = response.data?.message || 
                       (response.data?.status === 'error' ? response.data.details || 'Server validation error' : 'Unknown server error');
        console.error('Failed to create ticket:', errorMsg, response.data);
        setErrorMessage(`Failed to create ticket: ${errorMsg}`);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
      // Display the specific error message from the service if available
      const errorMsg = error.message || 'Unknown error occurred';
      setErrorMessage(`Failed to create ticket: ${errorMsg}`);
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={() => navigate('/tickets')}
          className="mr-4 text-gray-500 hover:text-gray-700"
        >
          <FaArrowLeft className="h-5 w-5" />
        </button>
        <h1 className="text-2xl font-semibold text-gray-900">Create New Support Ticket</h1>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Error message display */}
          {errorMessage && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    {errorMessage}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Brief description of the issue"
              required
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="6"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Please provide detailed information about your issue..."
              required
            ></textarea>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                Priority
              </label>
              <select
                id="priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>

            <div>
              <label htmlFor="deviceId" className="block text-sm font-medium text-gray-700">
                Related Device (Optional)
              </label>
              <select
                id="deviceId"
                name="deviceId"
                value={formData.deviceId}
                onChange={handleChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="">-- Select a device --</option>
                {devices.map(device => (
                  <option key={device._id} value={device._id}>
                    {device.name} ({device.operatingSystem || 'Unknown OS'})
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => navigate('/tickets')}
              className="mr-3 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {loading ? (
                <>
                  <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                  Submitting...
                </>
              ) : (
                <>
                  <FaPaperPlane className="mr-2 h-4 w-4" />
                  Submit Ticket
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateTicket;
