import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  FaUsers, FaCog, FaServer, FaChartBar, FaExclamationTriangle, FaDesktop, 
  FaLock, FaShieldAlt, FaSync, FaNetworkWired, FaMicrosoft, FaCloud, FaDatabase
} from 'react-icons/fa';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { format } from 'date-fns';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

// Custom plugin for center text in doughnut charts
const centerTextPlugin = {
  id: 'centerText',
  afterDraw: (chart) => {
    const { ctx, data } = chart;
    if (!data?.datasets?.[0]?.data?.length) return; // Guard against missing data
    
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
    
    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = 'bold 24px Arial';
    
    // Safely get the total value with fallback
    const total = data.datasets[0].data[0] ?? 0;
    ctx.fillText(total.toString(), centerX, centerY);
    ctx.restore();
  }
};

// Helper function to format dates
const formatDate = (date) => date ? format(new Date(date), 'MMM d, yyyy h:mm a') : 'Never';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    users: { total: 0, active: 0, inactive: 0, admins: 0 },
    devices: {
      total: 0, online: 0, offline: 0, critical: 0,
      byType: { servers: 0, desktops: 0, laptops: 0, mobile: 0, other: 0 },
      byOs: { windows: 0, mac: 0, linux: 0, other: 0 },
      unmonitored: { count: 2, byOS: { windows: 1, mac: 1, linux: 0, other: 0 } },
      problemDevices: 16, dailySafetyIssues: 14, criticalIssues: 2,
      problemServers: 2, problemWorkstations: 14
    },
    networkDevices: { unmonitored: { printers: 46, switches: 17, routers: 9, firewalls: 11 } },
    tickets: { total: 0, open: 0, inProgress: 0, closed: 0 },
    tacticalRMM: { connected: false, lastSync: null, apiUrl: null, devicesCount: 0 },
    serverStatus: { online: 7, offline: 0, reboot: 0, overdue: 0 },
    workstationStatus: { online: 5, offline: 4, reboot: 0, overdue: 0 }
  });
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Chart configurations
  const getChartOptions = (title = '', showLegend = true) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: showLegend, position: 'bottom', labels: { usePointStyle: true, padding: 20, color: '#4B5563' } },
      title: { display: !!title, text: title, color: '#1F2937', font: { size: 14, weight: 'bold' } }
    },
    cutout: '70%'
  });

  const getBarChartOptions = () => ({
    indexAxis: 'y',
    plugins: { legend: { display: false } },
    scales: {
      x: { beginAtZero: true, grid: { display: false }, ticks: { display: false } },
      y: { grid: { display: false } }
    },
    maintainAspectRatio: false,
    layout: { padding: { right: 10 } },
    elements: { bar: { borderRadius: 4, borderWidth: 0, borderSkipped: false } }
  });

  // Chart data
  const statusChartData = (status) => ({
    labels: ['Online', 'Offline', 'Reboot', 'Overdue'],
    datasets: [{
      data: [status.online, status.offline, status.reboot, status.overdue],
      backgroundColor: ['#10B981', '#EF4444', '#3B82F6', '#F59E0B'],
      borderWidth: 0,
      barThickness: 24,
      borderRadius: 4
    }]
  });

  const statusLegendData = (status) => [
    { label: 'Online', count: status.online, color: 'bg-emerald-500' },
    { label: 'Offline', count: status.offline, color: 'bg-red-500' },
    { label: 'Reboot', count: status.reboot, color: 'bg-blue-500' },
    { label: 'Overdue', count: status.overdue, color: 'bg-amber-500' }
  ];

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const [userService, deviceService, ticketService] = await Promise.all([
          import('../../services/user.service'),
          import('../../services/device.service'),
          import('../../services/ticket.service')
        ]);

        const [userResponse, deviceResponse, ticketResponse] = await Promise.all([
          userService.default.getUsers(),
          deviceService.default.getDeviceStats(),
          ticketService.default.getTicketStats()
        ]);

        setStats(prev => ({
          ...prev,
          users: { ...userResponse?.data },
          devices: { ...deviceResponse?.data },
          tickets: { ...ticketResponse?.data }
        }));
      } catch (err) {
        console.error('Error fetching stats:', err);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        <span className="ml-4 text-lg text-gray-700">Loading dashboard data...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-md">
        <p className="font-bold">Error Loading Dashboard</p>
        <p>{error}</p>
        <button onClick={() => window.location.reload()} className="mt-2 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-all">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6 pb-12">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
          <p className="text-sm text-gray-500">Last updated: {format(new Date(), 'dd/MM/yyyy HH:mm:ss')}</p>
        </div>
        <button onClick={() => window.location.reload()} className="px-4 py-2 text-sm bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 text-gray-700 flex items-center">
          <FaSync className="mr-2 h-4 w-4" /> Refresh
        </button>
      </div>

      {/* Status Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {['serverStatus', 'workstationStatus'].map((type, idx) => (
          <div key={type} className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              {idx === 0 ? <FaServer className="mr-2 text-blue-600" /> : <FaDesktop className="mr-2 text-blue-600" />}
              {idx === 0 ? 'Server' : 'Workstation'} Status
              <span className="text-xs text-gray-500 ml-2">All clients and sites</span>
            </h2>
            <div className="flex items-center justify-between">
              <div className="h-40 flex-1">
                <Bar data={statusChartData(stats[type])} options={getBarChartOptions()} />
              </div>
              <div className="flex flex-col space-y-2 ml-4">
                {statusLegendData(stats[type]).map((status, index) => (
                  <div key={index} className="flex items-center whitespace-nowrap">
                    <div className={`w-3 h-3 ${status.color} rounded-full mr-2`}></div>
                    <span className="text-sm">{status.count} {status.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Problem Devices */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
  {['problemServers', 'problemWorkstations'].map((type, idx) => (
    <div key={type} className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
        <FaExclamationTriangle className="mr-2 text-amber-500" />
        Problem {idx === 0 ? 'Servers' : 'Workstations'}
      </h2>
      <div className="flex flex-col items-center">
        <div className="relative h-40 w-40">
          <Doughnut 
            data={{
              labels: [`Problem ${idx === 0 ? 'Servers' : 'Workstations'}`],
              datasets: [{
                data: [
                  stats.devices[type] || 0,  // Ensure we have a number
                  Math.max((idx === 0 ? 10 : 20) - (stats.devices[type] || 0), 0)
                ],
                backgroundColor: ['#EF4444', '#E5E7EB'],
                borderWidth: 0
              }]
            }}
            options={{ 
              ...getChartOptions('', false), 
              plugins: { legend: { display: false } } 
            }}
            plugins={[centerTextPlugin]}
          />
        </div>
        <p className="mt-4 text-sm text-gray-600">
          Total problem {idx === 0 ? 'servers' : 'workstations'}
        </p>
      </div>
    </div>
  ))}
        
        {/* TacticalRMM Integration */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <FaServer className="mr-2 text-indigo-600" /> TacticalRMM Integration
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Status:</span>
              <span className={`px-2 py-1 rounded-full text-xs font-medium ${stats.tacticalRMM.connected ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {stats.tacticalRMM.connected ? 'Connected' : 'Disconnected'}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Last sync:</span>
              <span className="text-sm text-gray-600">{formatDate(stats.tacticalRMM.lastSync)}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">API URL:</span>
              <span className="text-sm text-gray-600 truncate max-w-[180px]" title={stats.tacticalRMM.apiUrl || 'Not configured'}>
                {stats.tacticalRMM.apiUrl || 'Not configured'}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Devices synced:</span>
              <span className="text-sm font-semibold text-indigo-600">{stats.tacticalRMM.devicesCount}</span>
            </div>
            <div className="flex justify-between mt-2 pt-2 border-t border-gray-100">
              <Link to="/admin/tactical-rmm" className="text-sm text-blue-600 hover:text-blue-800 flex items-center">
                <FaCog className="mr-1 h-3 w-3" /> Manage
              </Link>
              <Link to="/admin/tactical-rmm" className="text-sm text-blue-600 hover:text-blue-800 flex items-center">
                <FaSync className="mr-1 h-3 w-3" /> Sync now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Integration Status */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <FaShieldAlt className="mr-2 text-green-600" /> ESET Integration
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Status:</span>
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">Pending Configuration</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Protected devices:</span>
              <span className="text-sm font-semibold text-gray-600">0 / {stats.devices.total}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Licenses:</span>
              <span className="text-sm text-gray-600">Not configured</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Last scan:</span>
              <span className="text-sm text-gray-600">Never</span>
            </div>
            <div className="flex justify-center mt-2 pt-2 border-t border-gray-100">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm flex items-center" onClick={() => alert('ESET integration coming soon')}>
                <FaCog className="mr-2 h-3 w-3" /> Configure Integration
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <FaCloud className="mr-2 text-blue-600" /> Pax8 Integration
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Status:</span>
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">Connected</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Products:</span>
              <span className="text-sm font-semibold text-gray-600">0 active</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Last sync:</span>
              <span className="text-sm text-gray-600">Never</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">API Status:</span>
              <span className="text-sm text-green-600">Available</span>
            </div>
            <div className="flex justify-center mt-2 pt-2 border-t border-gray-100">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm flex items-center" onClick={() => alert('Pax8 integration management coming soon')}>
                <FaCog className="mr-2 h-3 w-3" /> Manage Products
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <FaMicrosoft className="mr-2 text-blue-600" /> Microsoft CSP Status
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Status:</span>
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">Pending Approval</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Partner ID:</span>
              <span className="text-sm font-semibold text-gray-600">Verified</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Documents:</span>
              <span className="text-sm text-gray-600">Submitted</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Expected approval:</span>
              <span className="text-sm text-gray-600">Pending Microsoft review</span>
            </div>
            <div className="flex justify-center mt-2 pt-2 border-t border-gray-100">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm flex items-center" onClick={() => alert('Microsoft CSP management coming soon')}>
                <FaCog className="mr-2 h-3 w-3" /> View Details
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
            <FaDatabase className="mr-2 text-purple-600" /> Backup Solution
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Status:</span>
              <span className="px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">Not Configured</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Active licenses:</span>
              <span className="text-sm font-semibold text-gray-600">0</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Storage used:</span>
              <span className="text-sm text-gray-600">0 GB / 0 GB</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium">Last backup:</span>
              <span className="text-sm text-gray-600">Never</span>
            </div>
            <div className="flex justify-center mt-2 pt-2 border-t border-gray-100">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm flex items-center" onClick={() => alert('Backup solution coming soon')}>
                <FaCog className="mr-2 h-3 w-3" /> Setup Backup Solution
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;