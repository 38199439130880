import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Table, Modal, Form, Alert, Badge } from 'react-bootstrap';
import { FaCheck, FaTimes, FaTrash, FaPencilAlt, FaShieldAlt, FaMobileAlt, FaDesktop, FaTabletAlt, FaQuestionCircle } from 'react-icons/fa';
import './TrustedDevices.css';
import deviceTokenManager from '../../utils/deviceTokenManager';

// Import bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const TrustedDeviceManager = () => {
  const [devices, setDevices] = useState([]);
  const [pendingDevices, setPendingDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [newDeviceName, setNewDeviceName] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentDeviceToken, setCurrentDeviceToken] = useState('');
  const [deviceLimit, setDeviceLimit] = useState(3);
  const [deviceCount, setDeviceCount] = useState(0);

  // Fetch all devices on component mount
  useEffect(() => {
    // Add a small delay to ensure authentication is properly set up
    setTimeout(() => {
      // Get the current device token from localStorage or cookies
      const token = deviceTokenManager.getToken();
      console.log('Current device token for trusted device identification:', token ? `${token.substring(0, 10)}...` : 'No token found');
      setCurrentDeviceToken(token || '');
      
      // Fetch devices after ensuring we have the token
      fetchDevices();
    }, 500);
  }, []);

  // Create an axios instance with authentication headers
  const getAuthAxios = () => {
    const token = localStorage.getItem('token');
    const deviceToken = deviceTokenManager.getToken();
    
    const instance = axios.create({
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (token) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      console.log('Added auth token to request headers');
    } else {
      console.warn('No auth token available for trusted devices request');
    }
    
    if (deviceToken) {
      instance.defaults.headers.common['X-Device-Token'] = deviceToken;
      console.log(`Added device token to request headers: ${deviceToken.substring(0, 10)}...`);
    } else {
      console.warn('No device token available for trusted devices request');
    }
    
    return instance;
  };

  const fetchDevices = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    
    try {
      const authAxios = getAuthAxios();
      console.log('Fetching trusted devices with auth token:', !!authAxios.defaults.headers.common['Authorization']);
      console.log('Device token included in request:', !!authAxios.defaults.headers.common['X-Device-Token']);
      
      // Get current device token again to ensure we have the latest
      const currentToken = deviceTokenManager.getToken();
      if (currentToken !== currentDeviceToken) {
        console.log('Device token updated since component mounted, updating state');
        setCurrentDeviceToken(currentToken || '');
      }
      
      // Get trusted devices
      const { data: trustedData } = await authAxios.get('/api/trusted-devices/devices');
      console.log('Trusted devices response:', trustedData);
      
      // Process devices to identify current device
      const processedDevices = (trustedData.data.devices || []).map(device => ({
        ...device,
        isCurrentDevice: device.token === currentToken || 
                        (device.fingerprint && device.fingerprint.userAgent === navigator.userAgent)
      }));
      
      console.log('Processed devices with current device identification:', 
        processedDevices.map(d => ({ 
          id: d._id, 
          name: d.deviceName || d.name,
          isCurrentDevice: d.isCurrentDevice,
          status: d.status
        }))
      );
      
      setDevices(processedDevices);
      setDeviceLimit(trustedData.data.deviceLimit || 3);
      setDeviceCount(trustedData.data.deviceCount || 0);

      // Get pending devices
      const { data: pendingData } = await authAxios.get('/api/trusted-devices/pending');
      console.log('Pending devices response:', pendingData);
      setPendingDevices(pendingData.data.devices || []);
    } catch (err) {
      setError('Failed to load devices. Please try again later.');
      console.error('Error fetching devices:', err);
      if (err.response) {
        console.error('Response status:', err.response.status);
        console.error('Response data:', err.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const approveDevice = async (deviceId) => {
    try {
      const authAxios = getAuthAxios();
      const response = await authAxios.post(`/api/trusted-devices/approve/${deviceId}`);
      fetchDevices(); // Refresh the list
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to approve device');
      console.error('Error approving device:', err);
    }
  };

  const rejectDevice = async (deviceId) => {
    try {
      const authAxios = getAuthAxios();
      const response = await authAxios.delete(`/api/trusted-devices/reject/${deviceId}`);
      fetchDevices(); // Refresh the list
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to reject device');
      console.error('Error rejecting device:', err);
    }
  };

  const removeDevice = async (deviceId) => {
    if (window.confirm('Are you sure you want to remove this device? You will need to verify with 2FA next time you log in from it.')) {
      try {
        const authAxios = getAuthAxios();
        const response = await authAxios.delete(`/api/trusted-devices/devices/${deviceId}`);
        fetchDevices(); // Refresh the list
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to remove device');
        console.error('Error removing device:', err);
      }
    }
  };

  const openRenameModal = (device) => {
    setCurrentDevice(device);
    setNewDeviceName(device.deviceName || device.name);
    setShowRenameModal(true);
  };

  const handleRename = async () => {
    try {
      const authAxios = getAuthAxios();
      const response = await authAxios.patch(`/api/trusted-devices/devices/${currentDevice._id}/rename`, {
        name: newDeviceName
      });
      fetchDevices(); // Refresh the list
      setShowRenameModal(false);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to rename device');
      console.error('Error renaming device:', err);
    }
  };

  const getDeviceIcon = (device) => {
    // Determine icon based on device info if available
    if (device.deviceType === 'mobile') return <FaMobileAlt />;
    if (device.deviceType === 'desktop') return <FaDesktop />;
    if (device.deviceType === 'tablet') return <FaTabletAlt />;
    return <FaQuestionCircle />;
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'primary':
        return <Badge bg="success">Primary</Badge>;
      case 'trusted':
        return <Badge bg="primary">Trusted</Badge>;
      case 'pending':
        return <Badge bg="warning">Pending</Badge>;
      default:
        return <Badge bg="secondary">{status}</Badge>;
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (loading) return <div className="text-center mt-4">Loading your devices...</div>;

  return (
    <div className="trusted-device-manager">
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">
            <FaShieldAlt className="me-2" /> Trusted Devices
          </h5>
          <Button variant="info" size="sm" onClick={() => setShowInfoModal(true)}>
            <FaQuestionCircle /> How it works
          </Button>
        </Card.Header>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          {/* Pending Devices Section */}
          {pendingDevices.length > 0 && (
            <div className="mb-4">
              <h6 className="border-bottom pb-2">Devices Awaiting Approval</h6>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>Last Used</th>
                    <th>IP Address</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingDevices.map(device => (
                    <tr key={device._id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="device-icon me-2">{getDeviceIcon(device)}</span>
                          <div>
                            <div>{device.name}</div>
                            <small className="text-muted">{device.browser} on {device.os}</small>
                          </div>
                        </div>
                      </td>
                      <td>{formatDate(device.lastUsed)}</td>
                      <td>{device.lastIp}</td>
                      <td>
                        <Button 
                          variant="success" 
                          size="sm" 
                          className="me-2"
                          onClick={() => approveDevice(device._id)}
                        >
                          <FaCheck /> Approve
                        </Button>
                        <Button 
                          variant="danger" 
                          size="sm"
                          onClick={() => rejectDevice(device._id)}
                        >
                          <FaTimes /> Reject
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {/* Trusted Devices Section */}
          <h6 className="border-bottom pb-2">Your Trusted Devices</h6>
          <div className="device-limit-info mb-3">
            <span className={deviceCount >= deviceLimit - 1 ? 'text-warning' : ''}>
              <strong>{deviceCount}</strong> of <strong>{deviceLimit}</strong> trusted devices used
            </span>
          </div>
          {devices.length === 0 ? (
            <Alert variant="info">
              You don't have any trusted devices yet. When you log in from a new device and complete verification, 
              you'll be able to add it to your trusted devices.
            </Alert>
          ) : (
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Device</th>
                  <th>Status</th>
                  <th>Last Used</th>
                  <th>IP Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {devices.map(device => (
                  <tr key={device._id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="device-icon me-2">{getDeviceIcon(device)}</span>
                        <div>
                          <div>{device.deviceName || device.name}</div>
                          <small className="text-muted">{device.browser || device.deviceType} on {device.os || device.platform}</small>
                        </div>
                      </div>
                    </td>
                    <td>{getStatusBadge(device.status)}</td>
                    <td>{formatDate(device.lastUsed)}</td>
                    <td>{device.lastIp}</td>
                    <td>
                      <Button 
                        variant="outline-secondary" 
                        size="sm" 
                        className="me-2"
                        onClick={() => openRenameModal(device)}
                      >
                        <FaPencilAlt /> Rename
                      </Button>
                      {device.status !== 'primary' && !device.isCurrentDevice && (
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => removeDevice(device._id)}
                        >
                          <FaTrash /> Remove
                        </Button>
                      )}
                      {device.isCurrentDevice && (
                        <Badge bg="info">Current Device</Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>

      {/* Rename Device Modal */}
      <Modal show={showRenameModal} onHide={() => setShowRenameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Rename Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Device Name</Form.Label>
              <Form.Control 
                type="text" 
                value={newDeviceName} 
                onChange={(e) => setNewDeviceName(e.target.value)}
                placeholder="Enter a recognizable name for this device"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRenameModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRename}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Info Modal */}
      <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>About Device Trust Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>What is the Device Trust Chain?</h5>
          <p>
            The Device Trust Chain is a security feature that helps protect your account by recognizing 
            the devices you regularly use to access your account. This adds an extra layer of security 
            while making the login process more convenient for you.
          </p>

          <h5>How it works:</h5>
          <ol>
            <li><strong>Primary Device</strong>: Your first verified device becomes your primary device. This device has special privileges for approving other devices.</li>
            <li><strong>Trusted Devices</strong>: Devices you've approved can skip the two-factor authentication (2FA) step when logging in.</li>
            <li><strong>Pending Devices</strong>: When you log in from a new device, it will be marked as pending until approved from your primary or another trusted device.</li>
          </ol>

          <h5>Security Benefits:</h5>
          <ul>
            <li>Reduces the risk of unauthorized access even if your password is compromised</li>
            <li>Provides visibility into which devices are accessing your account</li>
            <li>Allows you to quickly revoke access for lost or compromised devices</li>
            <li>Limits the number of trusted devices to prevent abuse</li>
          </ul>

          <h5>Tips:</h5>
          <ul>
            <li>Give your devices recognizable names to easily identify them</li>
            <li>Regularly review your trusted devices and remove any you no longer use</li>
            <li>If you lose a device, remove it from your trusted devices immediately</li>
            <li>You can have a maximum of 3 trusted devices (including your primary device)</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowInfoModal(false)}>
            Got it
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrustedDeviceManager;
