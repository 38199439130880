import React from 'react';
import { Link } from 'react-router-dom';
import { FaDesktop, FaExclamationTriangle, FaServer } from 'react-icons/fa';
import { Card, Statistic, Row, Col, Spin, Alert, Progress, Badge } from 'antd';

/**
 * RMMSummary component displays a summary of all devices in TacticalRMM
 * including total count, online/offline status, and critical issues
 */
const RMMSummary = ({ loading, data, error }) => {
  if (loading) {
    return (
      <Card title="TacticalRMM Devices" className="h-full">
        <div className="flex justify-center items-center h-32">
          <Spin tip="Loading device data..." />
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Card title="TacticalRMM Devices" className="h-full">
        <Alert
          message="Error loading device data"
          description={error}
          type="error"
          showIcon
        />
      </Card>
    );
  }

  // Default values if data is not available
  const {
    totalDevices = 0,
    offlineDevices = 0,
    alerts = { critical: 0, warning: 0, info: 0 }
  } = data || {};

  const onlineDevices = totalDevices - offlineDevices;
  const onlinePercentage = totalDevices > 0 ? Math.round((onlineDevices / totalDevices) * 100) : 0;
  
  // Determine status color based on offline devices and critical alerts
  let statusColor = 'success';
  let statusText = 'Healthy';
  
  if (offlineDevices > 0 || alerts.critical > 0) {
    statusColor = 'error';
    statusText = 'Critical Issues';
  } else if (alerts.warning > 0) {
    statusColor = 'warning';
    statusText = 'Warnings';
  }

  return (
    <Card 
      title={
        <div className="flex items-center">
          <FaServer className="mr-2 text-blue-600" />
          <span>TacticalRMM Devices</span>
        </div>
      } 
      className="h-full"
      extra={<Link to="/monitoring">View Details</Link>}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Statistic 
            title="Total Devices" 
            value={totalDevices} 
            suffix={
              <Badge 
                status={statusColor} 
                text={statusText} 
              />
            }
          />
        </Col>
        
        <Col span={12}>
          <Statistic 
            title="Online" 
            value={onlineDevices} 
            valueStyle={{ color: '#3f8600' }} 
          />
        </Col>
        
        <Col span={12}>
          <Statistic 
            title="Offline" 
            value={offlineDevices} 
            valueStyle={{ color: '#cf1322' }} 
          />
        </Col>
        
        <Col span={24}>
          <div className="mt-2">
            <div className="flex justify-between mb-1">
              <span className="text-sm">Online Status</span>
              <span className="text-sm">{onlinePercentage}%</span>
            </div>
            <Progress 
              percent={onlinePercentage} 
              status={offlineDevices > 0 ? "exception" : "success"} 
              showInfo={false} 
            />
          </div>
        </Col>
        
        {(alerts.critical > 0 || alerts.warning > 0) && (
          <Col span={24}>
            <div className="mt-2">
              <div className="flex justify-between">
                <span className="text-sm font-medium text-red-600">
                  <FaExclamationTriangle className="inline mr-1" />
                  {alerts.critical} Critical Alerts
                </span>
                <span className="text-sm font-medium text-yellow-600">
                  {alerts.warning} Warnings
                </span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default RMMSummary;
