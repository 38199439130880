/**
 * Configuration for the password system integration
 */

const passwordSystemConfig = {
  // API endpoint for the password system
  apiUrl: process.env.REACT_APP_PASSWORD_SYSTEM_API_URL || 'http://localhost:5001/api',
  
  // JWT token expiration time in minutes
  tokenExpirationMinutes: 30,
  
  // TOTP settings
  totp: {
    issuer: 'Is IT Support',
    period: 30, // seconds
    digits: 6
  },
  
  // Password visibility settings
  passwordVisibility: {
    autoHideSeconds: 60,
    clipboardClearSeconds: 60
  },
  
  // Security settings
  security: {
    maxLoginAttempts: 5,
    lockoutDurationMinutes: 15,
    passwordExpiryDays: 30
  }
};

export default passwordSystemConfig;
