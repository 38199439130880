import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCreditCard, FaFileInvoiceDollar, FaHistory, FaDownload, FaExclamationTriangle } from 'react-icons/fa';

const BillingDashboard = () => {
  const [billingInfo, setBillingInfo] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Default feature sets for different plans
  const planFeatures = {
    'Free Trial': [
      'Limited devices',
      'Basic support',
      'Remote access',
      'Manual maintenance',
      'Basic reporting'
    ],
    'Basic': [
      '10 devices',
      'Email support',
      'Remote access',
      'Basic maintenance',
      'Standard reporting'
    ],
    'Professional': [
      'Unlimited devices',
      'Priority support',
      'Remote access',
      'Automated maintenance',
      'Advanced reporting'
    ],
    'Enterprise': [
      'Unlimited devices',
      '24/7 dedicated support',
      'Remote access',
      'Automated maintenance',
      'Custom reporting',
      'Dedicated account manager'
    ]
  };

  useEffect(() => {
    // Fetch billing info and invoices from the API
    const fetchBillingData = async () => {
      setLoading(true);
      try {
        // Import billing service
        const billingService = await import('../../services/billing.service');
        
        // Fetch billing information
        const billingResponse = await billingService.default.getBillingInfo();
        
        if (billingResponse.data && billingResponse.data.data) {
          const data = billingResponse.data.data;
          
          // Format the billing info
          const formattedBillingInfo = {
            currentPlan: data.currentPlan || 'Free Trial',
            price: data.price || 0,
            billingCycle: data.billingCycle || 'monthly',
            nextBillingDate: data.nextBillingDate || new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            paymentMethod: data.paymentMethod || {
              type: 'none',
              last4: '',
              brand: '',
              expiryMonth: '',
              expiryYear: ''
            },
            features: planFeatures[data.currentPlan] || planFeatures['Free Trial']
          };
          
          setBillingInfo(formattedBillingInfo);
          
          // Fetch invoices if available
          try {
            const invoicesResponse = await billingService.default.getInvoices();
            if (invoicesResponse.data && invoicesResponse.data.data) {
              setInvoices(invoicesResponse.data.data);
            } else {
              setInvoices([]);
            }
          } catch (invoiceErr) {
            console.error('Error fetching invoices:', invoiceErr);
            setInvoices([]);
          }
          
          setError(null);
        } else {
          // If no billing data, set default values
          setBillingInfo({
            currentPlan: 'Free Trial',
            price: 0,
            billingCycle: 'monthly',
            nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            paymentMethod: {
              type: 'none',
              last4: '',
              brand: '',
              expiryMonth: '',
              expiryYear: ''
            },
            features: planFeatures['Free Trial']
          });
          setInvoices([]);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching billing data:', err);
        setError('Failed to load billing information. Please try again later.');
        
        // Set default values on error
        setBillingInfo({
          currentPlan: 'Free Trial',
          price: 0,
          billingCycle: 'monthly',
          nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          paymentMethod: {
            type: 'none',
            last4: '',
            brand: '',
            expiryMonth: '',
            expiryYear: ''
          },
          features: planFeatures['Free Trial']
        });
        setInvoices([]);
        setLoading(false);
      }
    };

    fetchBillingData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'paid':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'overdue':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Billing & Subscription</h1>
      </div>

      {/* Current Plan */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Current Plan</h2>
          <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FaCreditCard className="h-6 w-6 text-blue-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-gray-900">{billingInfo.currentPlan} Plan</h3>
                  <p className="text-sm text-gray-500">{formatCurrency(billingInfo.price)} / {billingInfo.billingCycle}</p>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-500">Next billing date:</span>
                  <span className="font-medium">{formatDate(billingInfo.nextBillingDate)}</span>
                </div>
                <div className="flex justify-between text-sm mt-2">
                  <span className="text-gray-500">Payment method:</span>
                  <span className="font-medium">{billingInfo.paymentMethod.brand} ending in {billingInfo.paymentMethod.last4}</span>
                </div>
              </div>
              <div className="mt-4">
                <Link to="/billing/payment-method" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Update Payment Method
                </Link>
              </div>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-3">Plan Features</h4>
              <ul className="space-y-2">
                {billingInfo.features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-sm text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                <Link to="/billing/checkout" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  Change Plan
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Invoices */}
      {/* Additional Minutes */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Need Additional Support Minutes?</h2>
          <p className="text-gray-600 mt-2 mb-4">Purchase additional support minutes if you need more than what's included in your plan.</p>
          
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            <Link
              to="/billing/checkout/additional?minutes=15"
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              15 Minutes (£10)
            </Link>
            <Link
              to="/billing/checkout/additional?minutes=30"
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              30 Minutes (£20)
            </Link>
            <Link
              to="/billing/checkout/additional?minutes=60"
              className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <FaCreditCard className="mr-2 h-4 w-4 text-gray-500" />
              60 Minutes (£40)
            </Link>
          </div>
        </div>
      </div>

      {/* Recent Invoices */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium text-gray-900">Recent Invoices</h2>
            <Link
              to="/billing/history"
              className="text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              <div className="flex items-center">
                <FaHistory className="mr-1 h-4 w-4" />
                View All
              </div>
            </Link>
          </div>
          <div className="mt-4 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Invoice
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invoices.map((invoice) => (
                  <tr key={invoice.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {invoice.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(invoice.date)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatCurrency(invoice.amount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getStatusColor(invoice.status)}`}>
                        {invoice.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href={invoice.pdfUrl}
                        className="text-blue-600 hover:text-blue-900 inline-flex items-center"
                      >
                        <FaDownload className="mr-1 h-4 w-4" />
                        PDF
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Payment Methods</h2>
          <div className="bg-gray-50 border border-gray-200 rounded-md p-4 flex items-start">
            <div className="flex-shrink-0">
              <FaCreditCard className="h-6 w-6 text-gray-400" />
            </div>
            <div className="ml-3 flex-1">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900">{billingInfo.paymentMethod.brand} •••• {billingInfo.paymentMethod.last4}</h3>
                <div className="flex space-x-2">
                  <button className="text-sm font-medium text-blue-600 hover:text-blue-500">Edit</button>
                  <span className="text-gray-300">|</span>
                  <button className="text-sm font-medium text-red-600 hover:text-red-500">Remove</button>
                </div>
              </div>
              <p className="text-sm text-gray-500 mt-1">Expires {billingInfo.paymentMethod.expiryMonth}/{billingInfo.paymentMethod.expiryYear}</p>
              <div className="mt-2 flex items-center">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Default
                </span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add Payment Method
            </button>
          </div>
        </div>
      </div>

      {/* Billing Address */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium text-gray-900">Billing Address</h2>
            <button className="text-sm font-medium text-blue-600 hover:text-blue-500">Edit</button>
          </div>
          <div className="text-sm text-gray-500">
            <p>John Doe</p>
            <p>123 Main Street</p>
            <p>Suite 101</p>
            <p>San Francisco, CA 94103</p>
            <p>United States</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDashboard;
