/**
 * Utility for managing device tokens in the client
 */

const DEVICE_TOKEN_KEY = 'deviceToken';

class DeviceTokenManager {
  // Track the source of the token for debugging
  static tokenSource = null;
  
  /**
   * Store the device token in localStorage and cookies with multiple variations
   * @param {string} token - The device token to store
   * @param {string} source - The source of the token (optional)
   */
  static storeToken(token, source = 'manual') {
    if (!token) {
      console.warn('Attempted to store empty device token');
      return;
    }
    
    try {
      // Store in localStorage with multiple attempts
      try {
        localStorage.setItem(DEVICE_TOKEN_KEY, token);
        // Also store with a different key as backup
        localStorage.setItem('device_token_backup', token);
      } catch (storageError) {
        console.warn('Error storing in localStorage, trying sessionStorage:', storageError);
        // Try sessionStorage as fallback
        try {
          sessionStorage.setItem(DEVICE_TOKEN_KEY, token);
          sessionStorage.setItem('device_token_backup', token);
        } catch (sessionError) {
          console.error('Failed to store token in sessionStorage:', sessionError);
        }
      }
      
      this.tokenSource = source;
      try {
        localStorage.setItem(`${DEVICE_TOKEN_KEY}_source`, source);
        localStorage.setItem(`${DEVICE_TOKEN_KEY}_timestamp`, Date.now().toString());
      } catch (metaError) {
        console.warn('Error storing token metadata:', metaError);
      }
      
      console.log(`Device token stored: ${token.substring(0, 10)}... (source: ${source})`);
      
      // Store in cookies with multiple variations for better compatibility
      const secure = window.location.protocol === 'https:';
      const maxAge = 365 * 24 * 60 * 60; // 1 year
      const cookieOptions = `path=/; max-age=${maxAge}; ${secure ? 'secure;' : ''} samesite=lax`;
      
      // Try multiple cookie variations to ensure at least one works
      try {
        // 1. Standard camelCase version (primary)
        document.cookie = `${DEVICE_TOKEN_KEY}=${token}; ${cookieOptions}`;
        
        // 2. Lowercase version (for case-insensitive compatibility)
        document.cookie = `${DEVICE_TOKEN_KEY.toLowerCase()}=${token}; ${cookieOptions}`;
        
        // 3. Capitalized version (for case-insensitive compatibility)
        document.cookie = `${DEVICE_TOKEN_KEY.charAt(0).toUpperCase() + DEVICE_TOKEN_KEY.slice(1)}=${token}; ${cookieOptions}`;
        
        // 4. Simple 'dt' shorthand (more compatible with some browsers)
        document.cookie = `dt=${token}; ${cookieOptions}`;
        
        console.log('Device token stored in multiple cookie variations for compatibility');
      } catch (cookieError) {
        console.error('Error setting cookies:', cookieError);
      }
    } catch (error) {
      console.error('Error storing device token:', error);
    }
  }

  /**
   * Get the token source (for debugging)
   * @returns {string} - The source of the current token
   */
  static getTokenSource() {
    if (this.tokenSource) {
      return this.tokenSource;
    }
    
    // Try to get from localStorage
    const source = localStorage.getItem(`${DEVICE_TOKEN_KEY}_source`);
    if (source) {
      this.tokenSource = source;
      return source;
    }
    
    return 'unknown';
  }
  
  /**
   * Get the device token from all possible sources
   * @returns {string|null} - The stored device token or null
   */
  static getToken() {
    try {
      // Try multiple storage locations in order of reliability
      let token = null;
      let source = null;
      
      // 1. First try localStorage with primary key
      try {
        token = localStorage.getItem(DEVICE_TOKEN_KEY);
        if (token) {
          source = 'localStorage-primary';
          console.log(`Retrieved device token from localStorage primary: ${token.substring(0, 10)}...`);
        }
      } catch (e) {
        console.warn('Error accessing localStorage primary:', e);
      }
      
      // 2. Try localStorage with backup key if primary failed
      if (!token) {
        try {
          token = localStorage.getItem('device_token_backup');
          if (token) {
            source = 'localStorage-backup';
            console.log(`Retrieved device token from localStorage backup: ${token.substring(0, 10)}...`);
          }
        } catch (e) {
          console.warn('Error accessing localStorage backup:', e);
        }
      }
      
      // 3. Try sessionStorage as fallback
      if (!token) {
        try {
          token = sessionStorage.getItem(DEVICE_TOKEN_KEY) || sessionStorage.getItem('device_token_backup');
          if (token) {
            source = 'sessionStorage';
            console.log(`Retrieved device token from sessionStorage: ${token.substring(0, 10)}...`);
          }
        } catch (e) {
          console.warn('Error accessing sessionStorage:', e);
        }
      }
      
      // 4. If still no token, try cookies with various patterns
      if (!token) {
        try {
          const cookies = document.cookie.split(';').map(cookie => cookie.trim());
          console.log(`Checking cookies for device token, found ${cookies.length} cookies`);
          
          // Try multiple cookie name variations
          const cookieVariations = [
            DEVICE_TOKEN_KEY,                                                // deviceToken
            DEVICE_TOKEN_KEY.toLowerCase(),                                  // devicetoken
            DEVICE_TOKEN_KEY.charAt(0).toUpperCase() + DEVICE_TOKEN_KEY.slice(1), // DeviceToken
            'dt' // Simple shorthand version
          ];
          
          // Check each variation
          for (const variation of cookieVariations) {
            const deviceTokenCookie = cookies.find(cookie => 
              cookie.startsWith(`${variation}=`));
            
            if (deviceTokenCookie) {
              const cookieToken = deviceTokenCookie.split('=')[1];
              if (cookieToken && cookieToken.length > 10) { // Basic validation
                token = cookieToken;
                source = `cookie-${variation}`;
                console.log(`Found device token in '${variation}' cookie: ${token.substring(0, 10)}...`);
                break;
              }
            }
          }
          
          // Try case-insensitive approach as fallback
          if (!token) {
            for (const cookie of cookies) {
              const cookieName = cookie.split('=')[0].toLowerCase();
              if (cookieName.includes('device') && cookieName.includes('token') || cookieName === 'dt') {
                console.log('Found potential device token cookie with fuzzy match:', cookie);
                const parts = cookie.split('=');
                if (parts.length >= 2) {
                  const fuzzyToken = parts.slice(1).join('='); // Handle values that might contain '='
                  if (fuzzyToken && fuzzyToken.length > 10) {
                    token = fuzzyToken;
                    source = 'cookie-fuzzy';
                    console.log(`Extracted device token using fuzzy match: ${token.substring(0, 10)}...`);
                    break;
                  }
                }
              }
            }
          }
          
          // As a last resort, check if there's only one cookie and try to use it
          if (!token && cookies.length === 1 && cookies[0].includes('=')) {
            const parts = cookies[0].split('=');
            if (parts.length >= 2) {
              const singleToken = parts.slice(1).join('=');
              if (singleToken && singleToken.length > 10) { // Basic validation
                token = singleToken;
                source = 'cookie-single';
                console.log(`Using single available cookie as device token: ${token.substring(0, 10)}...`);
              }
            }
          }
          
          // Log all cookies for debugging if no token found
          if (!token && cookies.length > 0) {
            console.log('All available cookies:', cookies);
          }
        } catch (e) {
          console.warn('Error processing cookies:', e);
        }
      }
      
      // If we found a token, store it in all storage mechanisms for future use
      if (token) {
        this.tokenSource = source;
        // Store it back to ensure it's in all storage locations
        this.storeToken(token, `retrieved-from-${source}`);
        return token;
      }
      
      console.log('No device token found in any storage location');
      return null;
    } catch (error) {
      console.error('Error retrieving device token:', error);
      return null;
    }
  }

  /**
   * Remove the device token from localStorage and cookies
   */
  static removeToken() {
    try {
      localStorage.removeItem(DEVICE_TOKEN_KEY);
      console.log('Device token removed from localStorage');
      
      // Also try to clear the cookie by setting an expired date
      document.cookie = `${DEVICE_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      console.log('Device token cookie expired');
    } catch (error) {
      console.error('Error removing device token:', error);
    }
  }

  /**
   * Add the device token to an axios request config
   * @param {Object} config - Axios request config
   * @returns {Object} - Updated config with device token header
   */
  static addTokenToRequest(config) {
    try {
      const token = this.getToken();
      if (token) {
        config.headers = config.headers || {};
        config.headers['X-Device-Token'] = token;
        console.log(`Added device token to request headers: ${token.substring(0, 10)}...`);
        console.log('Request URL:', config.url);
      } else {
        console.log('No device token available for request to:', config.url);
      }
      return config;
    } catch (error) {
      console.error('Error adding token to request:', error);
      return config;
    }
  }
  
  /**
   * Verify if a token appears to be valid
   * @param {string} token - The token to verify
   * @returns {boolean} - Whether the token appears valid
   */
  static isValidToken(token) {
    if (!token) return false;
    
    // Check if token has minimum length
    if (token.length < 20) return false;
    
    // Check if token has expected format (alphanumeric with possible special chars)
    const validTokenRegex = /^[a-zA-Z0-9+/=_-]+$/;
    return validTokenRegex.test(token);
  }
  
  /**
   * Check if the current device token is fresh (recently set)
   * @returns {boolean} - Whether the token is fresh
   */
  static isTokenFresh() {
    try {
      const timestampStr = localStorage.getItem(`${DEVICE_TOKEN_KEY}_timestamp`);
      if (!timestampStr) return false;
      
      const timestamp = parseInt(timestampStr, 10);
      const now = Date.now();
      const ageInMinutes = (now - timestamp) / (1000 * 60);
      
      // Consider tokens set in the last 5 minutes as fresh
      return ageInMinutes < 5;
    } catch (error) {
      console.error('Error checking token freshness:', error);
      return false;
    }
  }
  
  /**
   * Extract device token from HTTP response and store it
   * @param {Object} response - Axios response object
   * @returns {string|null} - The extracted token or null if none found
   */
  static extractTokenFromResponse(response) {
    try {
      if (!response) {
        console.warn('Attempted to extract token from undefined response');
        return null;
      }
      
      // Log response URL for debugging
      const url = response.config?.url || 'unknown';
      console.log(`Processing response from: ${url}`);
      
      // Check if this is a login or verification response
      const isAuthResponse = url.includes('/login') || url.includes('/verify');
      if (isAuthResponse) {
        console.log('This is an authentication response - checking thoroughly for device token');
      }
      
      // Log headers for debugging (safely)
      try {
        console.log('Response headers:', Object.keys(response.headers || {}));
      } catch (e) {
        console.log('Could not stringify response headers:', e.message);
      }
      
      // Check response headers for device token (case insensitive)
      const headerNames = Object.keys(response.headers || {});
      const deviceTokenHeader = headerNames.find(h => h.toLowerCase() === 'x-device-token');
      
      if (deviceTokenHeader) {
        const headerToken = response.headers[deviceTokenHeader];
        console.log(`Found device token in response headers: ${headerToken.substring(0, 10)}...`);
        this.storeToken(headerToken, 'response-header');
        return headerToken;
      }
      
      // Check response data for device token
      if (response.data && response.data.deviceToken) {
        console.log(`Found device token in response data: ${response.data.deviceToken.substring(0, 10)}...`);
        this.storeToken(response.data.deviceToken, 'response-body');
        return response.data.deviceToken;
      }
      
      // Check for Set-Cookie header (case insensitive)
      const setCookieHeaderName = headerNames.find(h => h.toLowerCase() === 'set-cookie');
      const setCookieHeader = setCookieHeaderName ? response.headers[setCookieHeaderName] : null;
      
      if (setCookieHeader) {
        console.log('Response contains Set-Cookie header:', setCookieHeader);
        
        // Try to extract token from Set-Cookie header
        if (Array.isArray(setCookieHeader)) {
          for (const cookie of setCookieHeader) {
            if (cookie.toLowerCase().includes(DEVICE_TOKEN_KEY.toLowerCase())) {
              console.log('Found device token in Set-Cookie header array');
              // Extract the token value from the cookie string
              const match = cookie.match(new RegExp(`${DEVICE_TOKEN_KEY}=([^;]+)`));
              if (match && match[1]) {
                console.log(`Extracted token from Set-Cookie: ${match[1].substring(0, 10)}...`);
                this.storeToken(match[1], 'set-cookie-array');
                return match[1];
              }
            }
          }
        } else if (typeof setCookieHeader === 'string') {
          if (setCookieHeader.toLowerCase().includes(DEVICE_TOKEN_KEY.toLowerCase())) {
            console.log('Found device token in Set-Cookie header string');
            // Extract the token value from the cookie string
            const match = setCookieHeader.match(new RegExp(`${DEVICE_TOKEN_KEY}=([^;]+)`));
            if (match && match[1]) {
              console.log(`Extracted token from Set-Cookie: ${match[1].substring(0, 10)}...`);
              this.storeToken(match[1], 'set-cookie-string');
              return match[1];
            }
          }
        }
      }
      
      // Check cookies after response with a longer timeout to ensure cookies are set
      setTimeout(() => {
        console.log('Checking for cookies after response processing...');
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        console.log('Cookies after response:', cookies);
        
        const token = this.getToken();
        if (token) {
          console.log(`Device token available after response processing: ${token.substring(0, 10)}...`);
        } else {
          console.log('No device token found after response processing');
        }
      }, 500); // Increased timeout to ensure cookies are set
    } catch (error) {
      console.error('Error extracting token from response:', error);
    }
  }
}

export default DeviceTokenManager;
