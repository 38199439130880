import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaPlus, FaSync, FaSearch, FaFilter, FaSort, FaEdit, FaTrash, FaLink, FaUserShield, FaShieldAlt } from 'react-icons/fa';
import clientService from '../../services/client.service';
import esetService from '../../services/eset.service';
import LoadingSpinner from '../common/LoadingSpinner';
import EmptyState from '../common/EmptyState';
import Pagination from '../common/Pagination';

const ClientAddressBook = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [verificationFilter, setVerificationFilter] = useState('all'); // Add verification filter
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  // Load clients on component mount
  useEffect(() => {
    loadClients();
  }, []);

  // Load clients from API
  const loadClients = async () => {
    setLoading(true);
    try {
      const response = await clientService.getClients();
      setClients(response.data.data.clients);
    } catch (error) {
      console.error('Error loading clients:', error);
      
      // Handle authentication errors specifically
      if (error.message === 'Authentication required. Please log in to view clients.') {
        toast.error('Please log in to view clients.', {
          autoClose: 5000,
          onClose: () => {
            // Redirect to login page
            window.location.href = '/login';
          }
        });
      } else if (error.response && error.response.status === 401) {
        toast.error('Your session has expired. Please log in again.', {
          autoClose: 5000,
          onClose: () => {
            // Redirect to login page
            window.location.href = '/login';
          }
        });
      } else {
        toast.error(`Failed to load clients: ${error.message || 'Unknown error'}`);
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle status filter change
  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Handle verification filter change
  const handleVerificationFilterChange = (e) => {
    setVerificationFilter(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Handle sort change
  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle direction if same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Handle client deletion
  const handleDeleteClient = async (id, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}? This action cannot be undone.`)) {
      try {
        await clientService.deleteClient(id);
        toast.success(`${name} has been deleted.`);
        loadClients(); // Reload clients after deletion
      } catch (error) {
        console.error('Error deleting client:', error);
        toast.error('Failed to delete client. Please try again.');
      }
    }
  };
  
  // Handle enabling ESET for a client
  const handleEnableEset = async (clientId) => {
    try {
      const response = await esetService.enableForClient(clientId);
      toast.success('ESET Protect has been enabled for this client');
      loadClients(); // Reload clients to show updated ESET status
    } catch (error) {
      console.error('Error enabling ESET:', error);
      toast.error(error.response?.data?.message || 'Failed to enable ESET Protect. Please try again.');
    }
  };
  
  // Handle syncing all ESET clients
  const handleSyncAllEsetClients = async () => {
    try {
      const response = await esetService.syncAllClients();
      toast.success(`Successfully synced ${response.data.results} ESET clients`);
      loadClients(); // Reload clients to show updated ESET status
    } catch (error) {
      console.error('Error syncing ESET clients:', error);
      toast.error(error.response?.data?.message || 'Failed to sync ESET clients. Please try again.');
    }
  };

  // Filter and sort clients
  const filteredClients = clients.filter(client => {
    // Apply status filter (only if not 'all')
    if (statusFilter !== 'all' && client.status !== statusFilter) {
      return false;
    }
    
    // Apply verification filter (only if not 'all')
    if (verificationFilter !== 'all') {
      // Check if any users associated with this client match the verification filter
      if (verificationFilter === 'verified') {
        // If no users or no verified users, filter out
        if (!client.users || !client.users.some(user => user.isEmailVerified === true)) {
          return false;
        }
      } else if (verificationFilter === 'unverified') {
        // For unverified, include clients with no users or with unverified users
        // This is considered unverified since no verified user is associated
        if (client.users && client.users.length > 0 && client.users.every(user => user.isEmailVerified === true)) {
          return false;
        }
      }
    }
    
    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return (
        client.name.toLowerCase().includes(searchLower) ||
        client.contactPerson?.firstName?.toLowerCase().includes(searchLower) ||
        client.contactPerson?.lastName?.toLowerCase().includes(searchLower) ||
        client.contactPerson?.email?.toLowerCase().includes(searchLower) ||
        client.address?.city?.toLowerCase().includes(searchLower)
      );
    }
    
    return true;
  }).sort((a, b) => {
    // Apply sorting
    let aValue = a[sortField];
    let bValue = b[sortField];
    
    // Handle nested fields
    if (sortField === 'contactPerson.firstName') {
      aValue = a.contactPerson?.firstName || '';
      bValue = b.contactPerson?.firstName || '';
    } else if (sortField === 'contactPerson.email') {
      aValue = a.contactPerson?.email || '';
      bValue = b.contactPerson?.email || '';
    } else if (sortField === 'supportContract.type') {
      aValue = a.supportContract?.type || '';
      bValue = b.supportContract?.type || '';
    }
    
    // Compare values
    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Pagination logic
  const indexOfLastClient = currentPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(filteredClients.length / itemsPerPage);

  // Render sort indicator
  const renderSortIndicator = (field) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? ' ↑' : ' ↓';
    }
    return '';
  };

  // Render status badge
  const renderStatusBadge = (status) => {
    const statusClasses = {
      active: 'bg-green-100 text-green-800',
      inactive: 'bg-gray-100 text-gray-800',
      prospect: 'bg-blue-100 text-blue-800',
      archived: 'bg-red-100 text-red-800',
    };
    
    return (
      <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusClasses[status] || 'bg-gray-100 text-gray-800'}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  // Render verification badge
  const renderVerificationBadge = (client) => {
    const isVerified = client.users && client.users.some(user => user.isEmailVerified === true);
    
    return isVerified ? (
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
        <FaUserShield className="mr-1" /> Verified
      </span>
    ) : (
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
        <FaUserShield className="mr-1" /> Unverified
      </span>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Client Address Book</h1>
        <Link
          to="/clients/new"
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center"
        >
          <FaPlus className="mr-2" /> Add New Client
        </Link>
      </div>

      {/* Search and filter controls */}
      <div className="bg-white rounded-lg shadow-md p-4 mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
          <div className="flex items-center space-x-2 w-full md:w-1/2">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Search clients..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          
          <div className="flex items-center space-x-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaFilter className="text-gray-400" />
              </div>
              <select
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <option value="all">All Statuses</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="prospect">Prospect</option>
                <option value="archived">Archived</option>
              </select>
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaUserShield className="text-gray-400" />
              </div>
              <select
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={verificationFilter}
                onChange={handleVerificationFilterChange}
              >
                <option value="all">All Verification</option>
                <option value="verified">Verified</option>
                <option value="unverified">Unverified</option>
              </select>
            </div>
            
            <button
              className="bg-gray-100 hover:bg-gray-200 text-gray-700 px-4 py-2 rounded-md flex items-center"
              onClick={loadClients}
            >
              <FaSync className="mr-2" /> Refresh
            </button>
            
            <button
              className="bg-blue-100 hover:bg-blue-200 text-blue-700 px-4 py-2 rounded-md flex items-center"
              onClick={handleSyncAllEsetClients}
              title="Sync all ESET clients to update license information"
            >
              <FaShieldAlt className="mr-2" /> Sync ESET
            </button>
          </div>
        </div>
      </div>

      {/* Client list */}
      {loading ? (
        <LoadingSpinner message="Loading clients..." />
      ) : filteredClients.length === 0 ? (
        <EmptyState
          title="No clients found"
          message="There are no clients matching your search criteria. Try adjusting your filters or add a new client."
          actionText="Add New Client"
          actionLink="/clients/new"
          icon={<FaPlus className="w-12 h-12 text-gray-400" />}
        />
      ) : (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('clientId')}
                  >
                    <div className="flex items-center">
                      <span>Client ID</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('clientId')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('name')}
                  >
                    <div className="flex items-center">
                      <span>Client Name</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('name')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('contactPerson.firstName')}
                  >
                    <div className="flex items-center">
                      <span>Contact</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('contactPerson.firstName')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('contactPerson.email')}
                  >
                    <div className="flex items-center">
                      <span>Email</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('contactPerson.email')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('supportContract.type')}
                  >
                    <div className="flex items-center">
                      <span>Support Plan</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('supportContract.type')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('status')}
                  >
                    <div className="flex items-center">
                      <span>Status</span>
                      <FaSort className="ml-1" />
                      {renderSortIndicator('status')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="flex items-center">
                      <span>Verification</span>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    RMM
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ESET
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentClients.map((client) => (
                  <tr key={client._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-mono text-sm bg-gray-100 px-2 py-1 rounded">{client.clientId || 'Not Set'}</span>
                      <button
                        onClick={() => {
                          // Copy Client ID to clipboard
                          if (client.clientId) {
                            navigator.clipboard.writeText(client.clientId);
                            toast.success('Client ID copied to clipboard');
                          }
                        }}
                        className="ml-2 text-gray-500 hover:text-blue-600"
                        title="Copy Client ID"
                        disabled={!client.clientId}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                        </svg>
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Link
                        to={`/clients/${client._id}`}
                        className="text-blue-600 hover:text-blue-900 font-medium"
                      >
                        {client.name}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {client.contactPerson?.firstName} {client.contactPerson?.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a
                        href={`mailto:${client.contactPerson?.email}`}
                        className="text-gray-600 hover:text-blue-600"
                      >
                        {client.contactPerson?.email}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="capitalize">{client.supportContract?.type || 'None'}</span>
                        {client.supportContract?.minutesIncluded > 0 && (
                          <span className="ml-2 text-sm text-gray-500">
                            ({Math.floor(client.supportContract.minutesIncluded / 60)}h)
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderStatusBadge(client.status)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderVerificationBadge(client)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {client.tacticalRMM?.clientId ? (
                        <div className="flex items-center">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Linked
                          </span>
                          {client.tacticalRMM?.deviceCount > 0 && (
                            <span className="ml-2 text-xs text-gray-500">
                              ({client.tacticalRMM.deviceCount} devices)
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Not Linked
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {client.esetIntegration?.enabled ? (
                        <div className="flex items-center">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Enabled
                          </span>
                          {client.esetIntegration?.licenseCount > 0 && (
                            <span className="ml-2 text-xs text-gray-500">
                              ({client.esetIntegration.licenseCount} licenses)
                            </span>
                          )}
                        </div>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Not Enabled
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <Link
                          to={`/clients/${client._id}/edit`}
                          className="text-blue-600 hover:text-blue-900"
                          title="Edit Client"
                        >
                          <FaEdit />
                        </Link>
                        {!client.tacticalRMM?.clientId && (
                          <Link
                            to={`/clients/${client._id}/link-rmm`}
                            className="text-green-600 hover:text-green-900"
                            title="Link to TacticalRMM"
                          >
                            <FaLink />
                          </Link>
                        )}
                        {!client.esetIntegration?.enabled && (
                          <button
                            onClick={() => handleEnableEset(client._id)}
                            className="text-blue-600 hover:text-blue-900"
                            title="Enable ESET Protect"
                          >
                            <FaShieldAlt />
                          </button>
                        )}
                        <button
                          onClick={() => handleDeleteClient(client._id, client.name)}
                          className="text-red-600 hover:text-red-900"
                          title="Delete Client"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination */}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              totalItems={filteredClients.length}
              itemsPerPage={itemsPerPage}
              showingText="clients"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ClientAddressBook;
