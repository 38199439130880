import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Alert, Badge, Progress, Spin, Tabs, Table, Typography, Statistic } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import monitoringService from '../../services/monitoring.service';
import DeviceIssues from './DeviceIssues';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const MonitoringDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [systemHealth, setSystemHealth] = useState(null);
  const [deviceMetrics, setDeviceMetrics] = useState(null);
  const [deviceIssues, setDeviceIssues] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const [refreshInterval, setRefreshInterval] = useState(null);

  // Load data on component mount
  useEffect(() => {
    loadData();
    
    // Set up auto-refresh every 60 seconds
    const interval = setInterval(() => {
      loadData();
    }, 60000);
    
    setRefreshInterval(interval);
    
    // Clean up interval on component unmount
    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      // Load system health data
      const healthData = await monitoringService.getSystemHealth();
      setSystemHealth(healthData);
      
      // Load device metrics
      const metrics = await monitoringService.getDeviceHealthMetrics();
      setDeviceMetrics(metrics);
      
      // Load device issues from TacticalRMM
      try {
        const issues = await monitoringService.getDeviceIssues();
        setDeviceIssues(issues);
      } catch (issuesError) {
        console.error('Error loading device issues:', issuesError);
        // Don't fail the entire dashboard if just the issues can't be loaded
        setDeviceIssues({ error: issuesError.message });
      }
    } catch (error) {
      console.error('Error loading monitoring data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Get status color based on health
  const getStatusColor = (healthy) => {
    return healthy ? 'success' : 'error';
  };

  // Get status icon based on health
  const getStatusIcon = (healthy) => {
    return healthy ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
  };

  // Get severity color
  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'critical':
        return 'red';
      case 'warning':
        return 'orange';
      case 'info':
        return 'blue';
      default:
        return 'green';
    }
  };

  // Render system overview
  const renderSystemOverview = () => {
    if (!systemHealth) return <Spin size="large" />;
    
    const alerts = systemHealth.alerts || [];
    const criticalAlerts = alerts.filter(alert => alert.level === 'critical').length;
    const warningAlerts = alerts.filter(alert => alert.level === 'warning').length;
    
    return (
      <div>
        <Row gutter={[16, 16]} className="mb-4">
          <Col span={24}>
            <Title level={4}>System Health Overview</Title>
            <Text type="secondary">Last updated: {new Date(systemHealth.timestamp).toLocaleString()}</Text>
          </Col>
        </Row>
        
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="TacticalRMM Status" 
                value={systemHealth.api?.status === 'available' ? 'Online' : 'Offline'}
                valueStyle={{ color: systemHealth.api?.healthy ? '#3f8600' : '#cf1322' }}
                prefix={systemHealth.api?.healthy ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
              />
              {systemHealth.api?.responseTime && (
                <Text type="secondary">Response time: {systemHealth.api.responseTime}ms</Text>
              )}
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Containers" 
                value={`${systemHealth.containers?.filter(c => c.healthy).length || 0}/${systemHealth.containers?.length || 0}`}
                valueStyle={{ color: systemHealth.containers?.every(c => c.healthy) ? '#3f8600' : '#cf1322' }}
                prefix={systemHealth.containers?.every(c => c.healthy) ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
              />
              <Text type="secondary">Healthy containers</Text>
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="SSL Certificate" 
                value={systemHealth.ssl?.daysUntilExpiry || 'Unknown'}
                valueStyle={{ color: systemHealth.ssl?.healthy ? '#3f8600' : '#cf1322' }}
                suffix="days"
              />
              <Text type="secondary">Days until expiry</Text>
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Backup Status" 
                value={systemHealth.backup?.status === 'available' ? 'Available' : 'Unavailable'}
                valueStyle={{ color: systemHealth.backup?.healthy ? '#3f8600' : '#cf1322' }}
                prefix={systemHealth.backup?.healthy ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
              />
              {systemHealth.backup?.ageInHours && (
                <Text type="secondary">Age: {Math.round(systemHealth.backup.ageInHours)} hours</Text>
              )}
            </Card>
          </Col>
        </Row>
        
        {alerts.length > 0 && (
          <Row gutter={[16, 16]} className="mt-4">
            <Col span={24}>
              <Alert
                message={`System Alerts (${alerts.length})`}
                description={
                  <div>
                    {criticalAlerts > 0 && (
                      <div className="mb-2">
                        <Badge status="error" text={`${criticalAlerts} critical alerts`} />
                      </div>
                    )}
                    {warningAlerts > 0 && (
                      <div>
                        <Badge status="warning" text={`${warningAlerts} warning alerts`} />
                      </div>
                    )}
                    <ul className="mt-2">
                      {alerts.map((alert, index) => (
                        <li key={index}>
                          <Badge 
                            status={alert.level === 'critical' ? 'error' : 'warning'} 
                            text={`${alert.message} (${alert.component})`} 
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                }
                type="error"
                showIcon
              />
            </Col>
          </Row>
        )}
      </div>
    );
  };

  // Render container status
  const renderContainerStatus = () => {
    if (!systemHealth || !systemHealth.containers) return <Spin size="large" />;
    
    const columns = [
      {
        title: 'Container',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <Badge 
            status={record.healthy ? 'success' : 'error'} 
            text={status} 
          />
        ),
      },
      {
        title: 'Running For',
        dataIndex: 'runningFor',
        key: 'runningFor',
      },
    ];
    
    return (
      <div>
        <Title level={4}>Container Status</Title>
        <Table 
          dataSource={systemHealth.containers.map((container, index) => ({ ...container, key: index }))} 
          columns={columns} 
          pagination={false}
        />
      </div>
    );
  };

  // Render disk space
  const renderDiskSpace = () => {
    if (!systemHealth || !systemHealth.diskSpace) return <Spin size="large" />;
    
    return (
      <div>
        <Title level={4}>Disk Space</Title>
        {systemHealth.diskSpace.map((disk, index) => (
          <div key={index} className="mb-3">
            <div className="d-flex justify-content-between">
              <Text strong>{disk.mountpoint}</Text>
              <Text type={disk.healthy ? 'success' : 'danger'}>
                {disk.used} / {disk.size} ({disk.usedPercent}%)
              </Text>
            </div>
            <Progress 
              percent={disk.usedPercent} 
              status={disk.healthy ? 'normal' : 'exception'} 
              showInfo={false}
            />
          </div>
        ))}
      </div>
    );
  };

  // Render port status
  const renderPortStatus = () => {
    if (!systemHealth || !systemHealth.ports) return <Spin size="large" />;
    
    const columns = [
      {
        title: 'Port',
        dataIndex: 'port',
        key: 'port',
      },
      {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <Badge 
            status={record.healthy ? 'success' : 'error'} 
            text={status} 
          />
        ),
      },
    ];
    
    return (
      <div>
        <Title level={4}>Port Status</Title>
        <Table 
          dataSource={systemHealth.ports.map((port, index) => ({ ...port, key: index }))} 
          columns={columns} 
          pagination={false}
        />
      </div>
    );
  };

  // Render device metrics
  const renderDeviceMetrics = () => {
    if (!deviceMetrics) return <Spin size="large" />;
    
    return (
      <div>
        <Row gutter={[16, 16]} className="mb-4">
          <Col span={24}>
            <Title level={4}>Device Health Overview</Title>
          </Col>
        </Row>
        
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Total Devices" 
                value={deviceMetrics.totalDevices}
                prefix={<SyncOutlined />}
              />
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Online Devices" 
                value={`${deviceMetrics.onlineDevices}/${deviceMetrics.totalDevices}`}
                valueStyle={{ color: '#3f8600' }}
                suffix={`(${deviceMetrics.onlinePercentage}%)`}
              />
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Healthy Devices" 
                value={`${deviceMetrics.healthyDevices}/${deviceMetrics.totalDevices}`}
                valueStyle={{ color: '#3f8600' }}
                suffix={`(${deviceMetrics.healthyPercentage}%)`}
              />
            </Card>
          </Col>
          
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Statistic 
                title="Average Health Score" 
                value={deviceMetrics.averageHealthScore}
                valueStyle={{ 
                  color: deviceMetrics.averageHealthScore > 80 ? '#3f8600' : 
                         deviceMetrics.averageHealthScore > 60 ? '#faad14' : '#cf1322'
                }}
                suffix="/ 100"
              />
            </Card>
          </Col>
        </Row>
        
        {deviceMetrics.devicesNeedingAttention && deviceMetrics.devicesNeedingAttention.length > 0 && (
          <Row gutter={[16, 16]} className="mt-4">
            <Col span={24}>
              <Alert
                message={`Devices Needing Attention (${deviceMetrics.devicesNeedingAttention.length})`}
                description={
                  <Table 
                    dataSource={deviceMetrics.devicesNeedingAttention.map((device, index) => ({ ...device, key: index }))} 
                    columns={[
                      {
                        title: 'Device',
                        dataIndex: 'name',
                        key: 'name',
                      },
                      {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => (
                          <Badge 
                            status={status === 'critical' ? 'error' : 'warning'} 
                            text={status} 
                          />
                        ),
                      },
                      {
                        title: 'Health Score',
                        dataIndex: 'healthScore',
                        key: 'healthScore',
                        render: (score) => (
                          <Progress 
                            percent={score} 
                            size="small" 
                            status={score < 60 ? 'exception' : 'normal'}
                          />
                        ),
                      },
                      {
                        title: 'Issues',
                        dataIndex: 'issues',
                        key: 'issues',
                        render: (issues) => (
                          <ul className="m-0 p-0" style={{ listStyleType: 'none' }}>
                            {issues.map((issue, idx) => (
                              <li key={idx}>
                                <Badge 
                                  color={getSeverityColor(issue.severity)} 
                                  text={issue.message} 
                                />
                              </li>
                            ))}
                          </ul>
                        ),
                      },
                    ]} 
                    pagination={false}
                    size="small"
                  />
                }
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        )}
      </div>
    );
  };

  return (
    <div className="monitoring-dashboard">
      <Title level={2}>TacticalRMM Monitoring</Title>
      
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="System Overview" key="1">
          {renderSystemOverview()}
        </TabPane>
        <TabPane tab="Device Issues" key="2">
          <DeviceIssues loading={loading} issuesData={deviceIssues} />
        </TabPane>
        <TabPane tab="Device Health" key="3">
          {renderDeviceMetrics()}
        </TabPane>
        <TabPane tab="Containers" key="4">
          {renderContainerStatus()}
        </TabPane>
        <TabPane tab="Disk Space" key="5">
          {renderDiskSpace()}
        </TabPane>
        <TabPane tab="Services" key="6">
          {renderPortStatus()}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MonitoringDashboard;
