import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import { useAuth } from '../../context/auth.context';

const PaymentCancel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  useEffect(() => {
    // Optionally log the cancellation or perform other actions
  }, []);
  
  return (
    <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow rounded-lg overflow-hidden p-6 text-center">
        <div className="rounded-full bg-red-100 p-3 mx-auto w-16 h-16 flex items-center justify-center mb-4">
          <FaTimesCircle className="h-8 w-8 text-red-600" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">Payment Cancelled</h2>
        <p className="text-gray-600 mb-6">Your payment process was cancelled. No charges have been made.</p>
        
        <div className="bg-gray-50 p-4 rounded-md mb-6">
          <p className="text-sm text-gray-600 mb-2">What happens now?</p>
          <ul className="text-sm text-gray-700 list-disc list-inside space-y-1 text-left">
            {location.search.includes('additional') ? (
              <>
                <li>No additional minutes have been added to your account</li>
                <li>No charges have been made</li>
                <li>You can try again at any time</li>
                <li>If you're experiencing issues, please contact support</li>
              </>
            ) : location.search.includes('payment-method') ? (
              <>
                <li>Your payment method has not been updated</li>
                <li>Your current payment method remains active</li>
                <li>You can try again at any time</li>
                <li>If you're experiencing issues, please contact support</li>
              </>
            ) : (
              <>
                <li>Your current subscription remains unchanged</li>
                <li>No charges have been made</li>
                <li>You can try again at any time</li>
                <li>If you're experiencing issues, please contact support</li>
              </>
            )}
          </ul>
        </div>
        
        <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
          <button
            onClick={() => {
              if (location.search.includes('additional')) {
                const minutes = new URLSearchParams(location.search).get('minutes');
                navigate(user && user.role === 'client' ? `/portal/billing/checkout/additional${minutes ? `?minutes=${minutes}` : ''}` : `/billing/checkout/additional${minutes ? `?minutes=${minutes}` : ''}`);
              } else if (location.search.includes('payment-method')) {
                navigate(user && user.role === 'client' ? '/portal/billing/payment-method' : '/billing/payment-method');
              } else {
                navigate(user && user.role === 'client' ? '/portal/billing/checkout' : '/billing/checkout');
              }
            }}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Try Again
          </button>
          <button
            onClick={() => navigate(user && user.role === 'client' ? '/portal/billing' : '/billing')}
            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Return to Billing
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancel;
