import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  FaTicketAlt, 
  FaDesktop, 
  FaCreditCard, 
  FaSignOutAlt,
  FaUsersCog,
  FaBars,
  FaTimes,
  FaChartLine,
  FaServer,
  FaCog,
  FaQuestionCircle,
  FaBell,
  FaSync,
  FaLock,
  FaShieldAlt
} from 'react-icons/fa';
import branding from '../../config/branding';

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationsCount] = useState(3); // Mock notification count
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');
  const location = useLocation();
  const navigate = useNavigate();
  
  // Check if user is a client and redirect to portal if needed
  useEffect(() => {
    // Check for proper authentication first
    const hasUser = localStorage.getItem('user') !== null;
    const hasToken = localStorage.getItem('token') !== null;
    const isAuthenticated = hasUser && hasToken;
    
    if (!isAuthenticated) {
      // Don't handle authentication here - let the ProtectedRoute component handle it
      return;
    }
    
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const isClient = user && user.role === 'client';
    
    // If user is a client, they should be using the portal layout instead
    if (isClient && !location.pathname.startsWith('/portal')) {
      // Map current path to portal equivalent if possible
      let portalPath = '/portal';
      
      // Special case for billing routes
      if (location.pathname.startsWith('/billing')) {
        portalPath = location.pathname.replace('/billing', '/portal/billing');
      }
      
      navigate(portalPath);
    }
  }, [location.pathname, navigate]);
  
  // Effect to save dark mode preference to localStorage and apply styles
  useEffect(() => {
    // Save to localStorage
    localStorage.setItem('tacticalRmmDarkMode', darkMode);
    
    // Apply dark mode to the body element
    if (darkMode) {
      document.documentElement.classList.add('dark-mode');
      document.body.classList.add('dark-mode');
      document.body.style.backgroundColor = '#111827';
      document.body.style.color = '#f3f4f6';
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.body.classList.remove('dark-mode');
      document.body.style.backgroundColor = '#f9fafb';
      document.body.style.color = '';
    }
    
    console.log('Dark mode toggled:', darkMode ? 'ON' : 'OFF');
  }, [darkMode]);
  
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    // Dispatch a custom event that other components can listen for
    document.dispatchEvent(new CustomEvent('darkModeChange', { detail: { darkMode: newMode } }));
  };
  
  // Get user from localStorage
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const isAdmin = user && user.role === 'admin';

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const navigation = [
    { name: 'Dashboard', href: isAdmin ? '/admin' : '/dashboard', icon: FaChartLine, description: 'Overview of your system' },
    { name: 'Client Address Book', href: '/clients', icon: FaUsersCog, description: 'Client address book with TacticalRMM integration' },
    { name: 'Devices', href: '/devices', icon: FaDesktop, description: 'Manage your devices' },
    { name: 'Tickets', href: '/tickets', icon: FaTicketAlt, description: 'Support tickets' },
    { name: 'Billing', href: '/billing', icon: FaCreditCard, description: 'Manage your subscription and billing' },
    { name: 'Reports', href: '/reports', icon: FaChartLine, description: 'System reports and analytics' },
  ];

  const adminNavigation = [
    { name: 'TacticalRMM Settings', href: '/admin/tactical-rmm', icon: FaServer, description: 'TacticalRMM integration settings' },
    { name: 'Client Management', href: '/admin/clients', icon: FaUsersCog, description: 'Advanced client management' },
    { name: 'System Settings', href: '/admin/settings', icon: FaCog, description: 'Configure system' },
    { name: 'Sync Status', href: '/admin/sync', icon: FaSync, description: 'TacticalRMM synchronization status' },
    { name: 'System Monitoring', href: '/admin/monitoring', icon: FaDesktop, description: 'TacticalRMM monitoring dashboard' },
    { name: 'Password Management', href: '/admin/passwords', icon: FaLock, description: 'Manage secure client passwords' },
    { name: 'ESET Management', href: '/admin/eset', icon: FaShieldAlt, description: 'Manage ESET security solutions' },
  ];

  return (
    <div className={`flex h-screen overflow-hidden transition-colors duration-300 ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Mobile sidebar backdrop */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-gray-600 bg-opacity-75 md:hidden"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 w-72 shadow-xl transform transition-transform duration-300 ease-in-out md:relative md:translate-x-0 ${
          darkMode 
            ? 'bg-gradient-to-b from-gray-800 to-gray-900' 
            : 'bg-gradient-to-b from-blue-700 to-blue-900'
        } ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
      >
        <div className="flex items-center justify-between h-20 px-6 border-b border-blue-800">
          <div className="flex items-center">
            <img src="/images/isit-logo.png" alt="Is IT Support Logo" className="h-10 mr-3" />
            <span className="text-xl font-bold text-white">{branding.company.shortName}</span>
          </div>
          <button
            className="text-white md:hidden"
            onClick={() => setSidebarOpen(false)}
          >
            <FaTimes className="w-6 h-6" />
          </button>
        </div>

        <div className="flex flex-col flex-1 overflow-y-auto">
          <div className="px-6 py-4">
            <div className="flex items-center p-3 mb-6 bg-blue-800 bg-opacity-50 rounded-lg">
              <div className="flex-shrink-0">
                <div className="w-10 h-10 rounded-full bg-blue-600 flex items-center justify-center">
                  <span className="text-white font-bold">{user.name ? user.name.charAt(0).toUpperCase() : 'U'}</span>
                </div>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">{user.name || 'User'}</p>
                <p className="text-xs text-blue-200">{user.email || 'user@example.com'}</p>
              </div>
            </div>
          </div>
          
          <nav className="flex-1 px-4 pb-4">
            <div className="mb-2">
              <h3 className="px-3 text-xs font-semibold text-blue-200 uppercase tracking-wider">
                Main Menu
              </h3>
              <div className="mt-2 space-y-1">
                {navigation.map((item) => {
                  const isActive = location.pathname === item.href || location.pathname.startsWith(`${item.href}/`);
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`flex items-center px-3 py-2.5 text-sm font-medium rounded-lg group transition-colors duration-200 ${
                        isActive 
                          ? 'bg-white text-blue-700' 
                          : 'text-blue-100 hover:bg-blue-800 hover:text-white'
                      }`}
                    >
                      <item.icon
                        className={`mr-3 flex-shrink-0 h-5 w-5 ${
                          isActive ? 'text-blue-700' : 'text-blue-300 group-hover:text-white'
                        }`}
                      />
                      <span className="flex-1">{item.name}</span>
                      {item.name === 'Tickets' && notificationsCount > 0 && (
                        <span className="ml-3 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {notificationsCount}
                        </span>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>

            {isAdmin && (
              <div className="mt-6">
                <h3 className="px-3 text-xs font-semibold text-blue-200 uppercase tracking-wider">
                  Administration
                </h3>
                <div className="mt-2 space-y-1">
                  {adminNavigation.map((item) => {
                    // More specific active state detection to prevent overlap
                    const isActive = 
                      location.pathname === item.href || 
                      (location.pathname.startsWith(`${item.href}/`) && 
                       // Ensure we don't match /admin/users when on /admin
                       (item.href !== '/admin' || location.pathname === '/admin'));
                    const isTacticalRMM = item.name === 'TacticalRMM';
                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={`flex items-center px-3 py-2.5 text-sm font-medium rounded-lg group transition-colors duration-200 ${
                          isActive 
                            ? 'bg-white text-blue-700' 
                            : isTacticalRMM
                              ? 'text-yellow-100 bg-blue-800 hover:bg-yellow-600 hover:text-blue-900 border border-yellow-400'
                              : 'text-blue-100 hover:bg-blue-800 hover:text-white'
                        }`}
                      >
                        <item.icon
                          className={`mr-3 flex-shrink-0 h-5 w-5 ${
                            isActive ? 'text-blue-700' : 'text-blue-300 group-hover:text-white'
                          }`}
                        />
                        <span className="flex-1">{item.name}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
            
            <div className="mt-6">
              <h3 className="px-3 text-xs font-semibold text-blue-200 uppercase tracking-wider">
                Support
              </h3>
              <div className="mt-2 space-y-1">
                <Link
                  to="/help"
                  className="flex items-center px-3 py-2.5 text-sm font-medium rounded-lg text-blue-100 hover:bg-blue-800 hover:text-white group transition-colors duration-200"
                >
                  <FaQuestionCircle className="mr-3 flex-shrink-0 h-5 w-5 text-blue-300 group-hover:text-white" />
                  <span>Help & Documentation</span>
                </Link>
              </div>
            </div>
          </nav>

          <div className="p-4 mt-auto border-t border-blue-800">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-3 py-2.5 text-sm font-medium text-blue-100 rounded-lg hover:bg-blue-800 hover:text-white group transition-colors duration-200"
            >
              <FaSignOutAlt className="mr-3 h-5 w-5 text-blue-300 group-hover:text-white" />
              Sign out
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        {/* Top navigation bar */}
        <div className={`relative z-10 flex items-center justify-between h-16 shadow-sm px-4 md:px-6 transition-colors duration-300 ${
          darkMode ? 'bg-gray-800 text-gray-100' : 'bg-white text-gray-800'
        }`}>
          <button
            className={`p-2 rounded-md md:hidden ${
              darkMode ? 'text-gray-300 hover:text-white hover:bg-gray-700' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <FaBars className="h-6 w-6" />
          </button>
          
          <div className="flex-1 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              {/* Dark Mode Toggle - PROMINENT */}
              <button
                onClick={toggleDarkMode}
                className="flex items-center p-3 mr-4 rounded-lg transition-all duration-300 border-2 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 transform hover:-translate-y-1"
                style={{
                  backgroundColor: darkMode ? '#1F2937' : '#FFFFFF',
                  color: darkMode ? '#F3F4F6' : '#374151',
                  borderColor: darkMode ? '#F59E0B' : '#3B82F6',
                  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.1)'
                }}
              >
                {darkMode ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-400 animate-pulse" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    <span className="text-sm font-bold">LIGHT MODE</span>
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-indigo-600 animate-pulse" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                    <span className="text-sm font-bold">DARK MODE</span>
                  </>
                )}
              </button>
              
              {/* Notification bell */}
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 relative">
                <span className="sr-only">View notifications</span>
                <FaBell className="h-6 w-6" />
                {notificationsCount > 0 && (
                  <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500 ring-2 ring-white"></span>
                )}
              </button>
              
              {/* Profile dropdown - we could expand this later */}
              <div className="ml-3 relative">
                <div>
                  <button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 rounded-full bg-blue-600 flex items-center justify-center text-white">
                      {user.name ? user.name.charAt(0).toUpperCase() : 'U'}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className={`relative flex-1 overflow-y-auto focus:outline-none transition-colors duration-300 ${
          darkMode ? 'bg-gray-900' : 'bg-white'
        }`}>
          <div className="py-6">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
