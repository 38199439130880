import axios from 'axios';
import authHeader from '../utils/auth-header';

const API_URL = '/api/tickets';

// Development mode flag - set to true to force using mock data even if API is available
const DEV_MODE = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEV_MODE === 'true';

/**
 * Service for ticket management
 */
class TicketService {
  /**
   * Get all tickets for the current user (or all tickets for admin)
   * @param {Object} params - Query parameters for filtering and pagination
   * @returns {Promise} - Promise with tickets data
   */
  getTickets(params = {}) {
    console.log('Ticket service getTickets called with params:', params);
    
    // Check if we should force using mock data (only for testing)
    const forceMock = process.env.REACT_APP_FORCE_MOCK === 'true';
    if (forceMock) {
      console.log('Forcing mock tickets due to FORCE_MOCK setting');
      return this.getMockTickets();
    }
    
    // Try to get the real tickets from the API
    return axios.get(API_URL, {
      headers: authHeader(),
      params,
    })
    .catch(error => {
      console.error('Error fetching tickets from API:', error);
      
      // If in development mode and API fails, return mock tickets
      if (DEV_MODE) {
        console.log('API unavailable, using mock tickets for development');
        return this.getMockTickets();
      }
      
      // Otherwise, rethrow the error
      throw error;
    });
  }

  /**
   * Get mock tickets for development and testing
   * @returns {Promise} - Promise with mock tickets data
   */
  getMockTickets() {
    // Generate some mock tickets for development
    const mockTickets = [
      {
        id: 'mock-1',
        _id: 'mock-1', // Add _id field for compatibility with real tickets
        title: 'Computer not starting',
        description: 'My computer won\'t turn on after a power outage',
        status: 'open',
        priority: 'high',
        createdAt: new Date(Date.now() - 3600000).toISOString(),
        updatedAt: new Date(Date.now() - 1800000).toISOString(),
        client: { id: 'client-1', name: 'John Doe' },
        assignedTo: null,
        comments: [
          {
            _id: 'comment-1',
            content: 'I\'ll look into this right away.',
            createdAt: new Date(Date.now() - 3000000).toISOString(),
            user: { _id: 'admin-1', name: 'Admin User' }
          }
        ],
        attachments: [],
        timeSpent: 0,
        specialistFees: []
      },
      {
        id: 'mock-2',
        _id: 'mock-2',
        title: 'Email configuration issues',
        description: 'Cannot send or receive emails in Outlook',
        status: 'in-progress',
        priority: 'medium',
        createdAt: new Date(Date.now() - 86400000).toISOString(),
        updatedAt: new Date(Date.now() - 43200000).toISOString(),
        client: { id: 'client-2', name: 'Jane Smith' },
        assignedTo: { id: 'admin-1', name: 'Admin User' },
        comments: [
          {
            _id: 'comment-2',
            content: 'I\'ve checked your email settings and found some configuration issues.',
            createdAt: new Date(Date.now() - 50000000).toISOString(),
            user: { _id: 'admin-1', name: 'Admin User' }
          },
          {
            _id: 'comment-3',
            content: 'Thank you for looking into this.',
            createdAt: new Date(Date.now() - 40000000).toISOString(),
            user: { _id: 'client-2', name: 'Jane Smith' }
          }
        ],
        attachments: [
          {
            _id: 'attachment-1',
            fileName: 'email_settings.pdf',
            fileSize: '1.2 MB',
            fileType: 'application/pdf',
            uploadedAt: new Date(Date.now() - 60000000).toISOString(),
            url: '#mock-attachment'
          }
        ],
        timeSpent: 45,
        specialistFees: []
      },
      {
        id: 'mock-3',
        _id: 'mock-3',
        title: 'Software installation request',
        description: 'Need Adobe Creative Suite installed on my workstation',
        status: 'closed',
        priority: 'low',
        createdAt: new Date(Date.now() - 172800000).toISOString(),
        updatedAt: new Date(Date.now() - 86400000).toISOString(),
        client: { id: 'client-1', name: 'John Doe' },
        assignedTo: { id: 'admin-1', name: 'Admin User' },
        comments: [
          {
            _id: 'comment-4',
            content: 'Software has been installed successfully.',
            createdAt: new Date(Date.now() - 100000000).toISOString(),
            user: { _id: 'admin-1', name: 'Admin User' }
          }
        ],
        attachments: [],
        timeSpent: 30,
        specialistFees: [
          {
            _id: 'fee-1',
            amount: 50,
            description: 'Software installation fee',
            createdAt: new Date(Date.now() - 90000000).toISOString()
          }
        ]
      },
      {
        id: 'mock-4',
        _id: 'mock-4',
        title: 'Network connectivity issues',
        description: 'Cannot connect to the company network',
        status: 'open',
        priority: 'high',
        createdAt: new Date(Date.now() - 43200000).toISOString(),
        updatedAt: new Date(Date.now() - 21600000).toISOString(),
        client: { id: 'client-3', name: 'Bob Johnson' },
        assignedTo: null,
        comments: [],
        attachments: [],
        timeSpent: 0,
        specialistFees: []
      },
      {
        id: 'mock-5',
        _id: 'mock-5',
        title: 'Printer not working',
        description: 'Office printer showing error code E502',
        status: 'in-progress',
        priority: 'medium',
        createdAt: new Date(Date.now() - 129600000).toISOString(),
        updatedAt: new Date(Date.now() - 64800000).toISOString(),
        client: { id: 'client-2', name: 'Jane Smith' },
        assignedTo: { id: 'admin-1', name: 'Admin User' },
        comments: [
          {
            _id: 'comment-5',
            content: 'I\'ll need to order a replacement part for the printer.',
            createdAt: new Date(Date.now() - 80000000).toISOString(),
            user: { _id: 'admin-1', name: 'Admin User' }
          }
        ],
        attachments: [],
        timeSpent: 15,
        specialistFees: []
      }
    ];
    
    // Return a resolved promise with the mock data in the expected format
    return Promise.resolve({
      data: {
        status: 'success',
        data: {
          tickets: mockTickets
        }
      }
    });
  }
  
  /**
   * Get a specific ticket by ID
   * @param {string} id - Ticket ID
   * @returns {Promise} - Promise with ticket data
   */
  getTicket(id) {
    // If it's a mock ticket ID or we're forcing mock data, return the mock ticket directly
    const forceMock = process.env.REACT_APP_FORCE_MOCK === 'true';
    if (id.startsWith('mock-') || forceMock) {
      console.log(`Using mock ticket for development (ID: ${id})`);
      
      // Get all mock tickets and find the one with matching ID
      return this.getMockTickets()
        .then(response => {
          const mockTickets = response.data.data.tickets;
          const mockTicket = mockTickets.find(ticket => ticket.id === id || ticket._id === id);
          
          if (mockTicket) {
            return {
              data: {
                status: 'success',
                data: {
                  ticket: mockTicket
                }
              }
            };
          } else {
            throw new Error('Mock ticket not found');
          }
        });
    }
    
    // For real tickets, try to get from the API
    return axios.get(`${API_URL}/${id}`, { headers: authHeader() })
    .catch(error => {
      console.error(`Error fetching ticket ${id} from API:`, error);
      
      // If in development mode and API fails, check if we can use a mock ticket with this ID
      if (DEV_MODE) {
        console.log(`API unavailable, trying to find a mock ticket (ID: ${id})`);
        
        // Get all mock tickets and find the one with matching ID
        return this.getMockTickets()
          .then(response => {
            const mockTickets = response.data.data.tickets;
            const mockTicket = mockTickets.find(ticket => ticket.id === id || ticket._id === id);
            
            if (mockTicket) {
              return {
                data: {
                  status: 'success',
                  data: {
                    ticket: mockTicket
                  }
                }
              };
            } else {
              throw new Error('Ticket not found');
            }
          });
      }
      
      // Otherwise, rethrow the error
      throw error;
    });
  }

  /**
   * Create a new ticket
   * @param {Object} ticketData - Ticket data
   * @returns {Promise} - Promise with created ticket data
   */
  createTicket(ticketData) {
    return axios.post(API_URL, ticketData, { headers: authHeader() })
      .catch(error => {
        // Enhanced error handling to provide more specific error messages
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const errorMessage = error.response.data?.message || 
                             error.response.data?.error || 
                             `Server error: ${error.response.status}`;
          console.error('Ticket creation error:', errorMessage, error.response.data);
          
          // If in development mode and API fails, create a mock ticket
          if (DEV_MODE) {
            console.log('Creating mock ticket for development');
            
            // Create a new mock ticket with the provided data
            const mockTicket = {
              id: 'mock-' + Date.now(),
              ...ticketData,
              status: ticketData.status || 'open',
              priority: ticketData.priority || 'medium',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              client: ticketData.client || { 
                id: 'client-dev', 
                name: 'Development Client' 
              },
              assignedTo: ticketData.assignedTo || null
            };
            
            return Promise.resolve({
              data: {
                status: 'success',
                data: {
                  ticket: mockTicket
                }
              }
            });
          }
          
          throw new Error(errorMessage);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Ticket creation error: No response received', error.request);
          
          // If in development mode, create a mock ticket
          if (DEV_MODE) {
            console.log('Creating mock ticket due to network error');
            
            // Create a new mock ticket with the provided data
            const mockTicket = {
              id: 'mock-' + Date.now(),
              ...ticketData,
              status: ticketData.status || 'open',
              priority: ticketData.priority || 'medium',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              client: ticketData.client || { 
                id: 'client-dev', 
                name: 'Development Client' 
              },
              assignedTo: ticketData.assignedTo || null
            };
            
            return Promise.resolve({
              data: {
                status: 'success',
                data: {
                  ticket: mockTicket
                }
              }
            });
          }
          
          throw new Error('No response from server. Please check your network connection.');
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Ticket creation error:', error.message);
          throw new Error(`Error creating ticket: ${error.message}`);
        }
      });
  }

  /**
   * Update a ticket
   * @param {string} id - Ticket ID
   * @param {Object} ticketData - Updated ticket data
   * @returns {Promise} - Promise with updated ticket data
   */
  updateTicket(id, ticketData) {
    console.log('Updating ticket:', id, ticketData);
    return axios.patch(`${API_URL}/${id}`, ticketData, { headers: authHeader() })
      .catch(error => {
        // Enhanced error handling
        if (error.response) {
          const errorMessage = error.response.data?.message || 
                            error.response.data?.error || 
                            `Server error: ${error.response.status}`;
          console.error('Ticket update error:', errorMessage, error.response.data);
          
          // If in development mode and API fails, simulate a successful update
          if (DEV_MODE && id.startsWith('mock-')) {
            console.log(`Simulating successful update for mock ticket (ID: ${id})`);
            return this.getTicket(id)
              .then(response => {
                // Merge the updated data with the existing mock ticket
                const updatedTicket = {
                  ...response.data.data.ticket,
                  ...ticketData,
                  updatedAt: new Date().toISOString()
                };
                
                return {
                  data: {
                    status: 'success',
                    data: {
                      ticket: updatedTicket
                    }
                  }
                };
              });
          }
          
          throw new Error(errorMessage);
        } else if (error.request) {
          console.error('Ticket update error: No response received', error.request);
          throw new Error('No response from server. Please check your network connection.');
        } else {
          console.error('Ticket update error:', error.message);
          throw new Error(`Error updating ticket: ${error.message}`);
        }
      });
  }

  /**
   * Delete a ticket (admin only)
   * @param {string} id - Ticket ID
   * @returns {Promise} - Promise with deletion result
   */
  deleteTicket(id) {
    console.log('Deleting ticket:', id);
    return axios.delete(`${API_URL}/${id}`, { headers: authHeader() })
      .catch(error => {
        // Enhanced error handling
        if (error.response) {
          const errorMessage = error.response.data?.message || 
                            error.response.data?.error || 
                            `Server error: ${error.response.status}`;
          console.error('Ticket deletion error:', errorMessage, error.response.data);
          
          // If in development mode and API fails, simulate a successful deletion
          if (DEV_MODE && id.startsWith('mock-')) {
            console.log(`Simulating successful deletion for mock ticket (ID: ${id})`);
            return Promise.resolve({
              data: {
                status: 'success',
                message: 'Ticket deleted successfully',
                data: null
              }
            });
          }
          
          throw new Error(errorMessage);
        } else if (error.request) {
          console.error('Ticket deletion error: No response received', error.request);
          throw new Error('No response from server. Please check your network connection.');
        } else {
          console.error('Ticket deletion error:', error.message);
          throw new Error(`Error deleting ticket: ${error.message}`);
        }
      });
  }

  /**
   * Add a comment to a ticket
   * @param {string} id - Ticket ID
   * @param {Object} commentData - Comment data
   * @returns {Promise} - Promise with updated ticket data
   */
  addComment(id, commentData) {
    return axios.post(`${API_URL}/${id}/comments`, commentData, { headers: authHeader() })
      .catch(error => {
        console.error(`Error adding comment to ticket ${id}:`, error);
        
        // If in development mode and it's a mock ticket, simulate a successful comment addition
        if (DEV_MODE && id.startsWith('mock-')) {
          console.log(`Simulating successful comment addition for mock ticket (ID: ${id})`);
          
          // Create a mock comment
          const mockComment = {
            _id: 'comment-' + Date.now(),
            content: commentData.content,
            createdAt: new Date().toISOString(),
            user: {
              _id: 'admin-dev',
              name: 'Development Admin'
            }
          };
          
          return Promise.resolve({
            data: {
              status: 'success',
              success: true,
              message: 'Comment added successfully',
              data: {
                comment: mockComment
              }
            }
          });
        }
        
        throw error;
      });
  }

  /**
   * Get ticket statistics (admin only)
   * @returns {Promise} - Promise with ticket statistics
   */
  getTicketStats() {
    return axios.get(`${API_URL}/stats/summary`, { headers: authHeader() });
  }

  /**
   * Update time spent on a ticket (admin only)
   * @param {string} id - Ticket ID
   * @param {number} minutes - Minutes spent
   * @returns {Promise} - Promise with updated ticket data
   */
  updateTimeSpent(id, minutes) {
    return axios.patch(
      `${API_URL}/${id}`,
      { timeSpent: minutes },
      { headers: authHeader() }
    ).catch(error => {
      console.error(`Error updating time spent for ticket ${id}:`, error);
      
      // If in development mode and it's a mock ticket, simulate a successful update
      if (DEV_MODE && id.startsWith('mock-')) {
        console.log(`Simulating successful time update for mock ticket (ID: ${id})`);
        
        return Promise.resolve({
          data: {
            status: 'success',
            success: true,
            message: 'Time spent updated successfully',
            data: {
              timeSpent: minutes
            }
          }
        });
      }
      
      throw error;
    });
  }

  /**
   * Add specialist fees to a ticket (admin only)
   * @param {string} id - Ticket ID
   * @param {Object} feeData - Fee data (amount, description)
   * @returns {Promise} - Promise with updated ticket data
   */
  addSpecialistFee(id, feeData) {
    return axios.post(
      `${API_URL}/${id}/fees`,
      feeData,
      { headers: authHeader() }
    ).catch(error => {
      console.error(`Error adding specialist fee to ticket ${id}:`, error);
      
      // If in development mode and it's a mock ticket, simulate a successful fee addition
      if (DEV_MODE && id.startsWith('mock-')) {
        console.log(`Simulating successful fee addition for mock ticket (ID: ${id})`);
        
        const mockFee = {
          _id: 'fee-' + Date.now(),
          amount: feeData.amount,
          description: feeData.description,
          createdAt: new Date().toISOString()
        };
        
        return Promise.resolve({
          data: {
            status: 'success',
            success: true,
            message: 'Specialist fee added successfully',
            data: {
              fee: mockFee
            }
          }
        });
      }
      
      throw error;
    });
  }

  /**
   * Upload attachment to a ticket
   * @param {string} id - Ticket ID
   * @param {FormData} formData - Form data with file
   * @returns {Promise} - Promise with updated ticket data
   */
  uploadAttachment(id, formData) {
    return axios.post(
      `${API_URL}/${id}/attachments`,
      formData,
      { 
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data'
        }
      }
    ).catch(error => {
      console.error(`Error uploading attachment to ticket ${id}:`, error);
      
      // If in development mode and it's a mock ticket, simulate a successful upload
      if (DEV_MODE && id.startsWith('mock-')) {
        console.log(`Simulating successful attachment upload for mock ticket (ID: ${id})`);
        
        // Try to get the file name from the FormData
        let fileName = 'mock-file.pdf';
        try {
          for (let pair of formData.entries()) {
            if (pair[0] === 'file' && pair[1] instanceof File) {
              fileName = pair[1].name;
              break;
            }
          }
        } catch (e) {
          console.log('Could not extract filename from FormData:', e);
        }
        
        const mockAttachment = {
          _id: 'attachment-' + Date.now(),
          fileName: fileName,
          fileSize: '1.2 MB',
          fileType: 'application/pdf',
          uploadedAt: new Date().toISOString(),
          url: '#mock-attachment'
        };
        
        return Promise.resolve({
          data: {
            status: 'success',
            success: true,
            message: 'Attachment uploaded successfully',
            data: {
              attachment: mockAttachment
            }
          }
        });
      }
      
      throw error;
    });
  }

  /**
   * Get AI triage for a ticket (admin only)
   * @param {string} id - Ticket ID
   * @returns {Promise} - Promise with AI triage result
   */
  getAITriage(id) {
    return axios.get(
      `${API_URL}/${id}/ai-triage`,
      { headers: authHeader() }
    ).catch(error => {
      console.error(`Error getting AI triage for ticket ${id}:`, error);
      
      // If in development mode and it's a mock ticket, simulate a successful AI triage
      if (DEV_MODE && id.startsWith('mock-')) {
        console.log(`Simulating successful AI triage for mock ticket (ID: ${id})`);
        
        return Promise.resolve({
          data: {
            status: 'success',
            data: {
              category: 'Hardware Issue',
              priority: 'medium',
              estimatedTimeToResolve: '2-4 hours',
              suggestedActions: [
                'Check hardware connections',
                'Run diagnostics',
                'Verify power supply'
              ],
              analysis: 'This appears to be a standard hardware issue that can be resolved with basic troubleshooting steps.'
            }
          }
        });
      }
      
      throw error;
    });
  }
  
  /**
   * Get AI summary for a ticket (admin only)
   * @param {string} id - Ticket ID
   * @returns {Promise} - Promise with AI summary result
   */
  getAISummary(id) {
    return axios.get(
      `${API_URL}/${id}/ai-summary`,
      { headers: authHeader() }
    ).catch(error => {
      console.error(`Error getting AI summary for ticket ${id}:`, error);
      
      // If in development mode and it's a mock ticket, simulate a successful AI summary
      if (DEV_MODE && id.startsWith('mock-')) {
        console.log(`Simulating successful AI summary for mock ticket (ID: ${id})`);
        
        return Promise.resolve({
          data: {
            status: 'success',
            data: {
              summary: 'This ticket involves a hardware issue that requires basic troubleshooting. The user reported that their device is not functioning properly after a recent power outage. Initial assessment suggests this could be related to power supply issues or hardware damage from the surge.',
              keyPoints: [
                'Device not functioning after power outage',
                'Likely hardware-related issue',
                'May require physical inspection',
                'Estimated resolution time: 2-4 hours'
              ],
              nextSteps: [
                'Check power connections',
                'Inspect hardware for visible damage',
                'Run hardware diagnostics',
                'Consider replacement parts if needed'
              ]
            }
          }
        });
      }
      
      throw error;
    });
  }

  /**
   * Get AI summary for a closed ticket (admin only)
   * @param {string} id - Ticket ID
   * @returns {Promise} - Promise with AI summary result
   */
  getAISummary(id) {
    return axios.get(
      `${API_URL}/${id}/ai-summary`,
      { headers: authHeader() }
    );
  }
}

export default new TicketService();
