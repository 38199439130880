import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaQrcode, FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import portalPasswordService from '../../services/portal-password.service';
import SecurePasswordModal from '../common/SecurePasswordModal';

const PortalPasswordSystem = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [clientId, setClientId] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwords, setPasswords] = useState([]);
  const [selectedPassword, setSelectedPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');
  const [clientName, setClientName] = useState('');
  const [isRegistered, setIsRegistered] = useState(true);
  const [otpauthUrl, setOtpauthUrl] = useState('');

  // Listen for dark mode changes
  useEffect(() => {
    const handleDarkModeChange = (e) => {
      setDarkMode(e.detail.darkMode);
    };

    document.addEventListener('darkModeChange', handleDarkModeChange);
    return () => {
      document.removeEventListener('darkModeChange', handleDarkModeChange);
    };
  }, []);

  // Load passwords when authenticated
  useEffect(() => {
    // Only load passwords when authenticated (step 3)
    if (step === 3) {
      fetchPasswords();
    }
  }, [step]);
  
  // Fetch passwords from the API
  const fetchPasswords = async () => {
    try {
      setLoading(true);
      // Get the password token from localStorage
      const passwordToken = localStorage.getItem('passwordToken');
      
      if (!passwordToken) {
        setError('Authentication token not found. Please login again.');
        setStep(1);
        return;
      }
      
      // Make the actual API call to get client passwords
      const response = await portalPasswordService.getClientPasswords(clientId, passwordToken);
      
      // Process the response based on its format
      if (response.data && Array.isArray(response.data)) {
        setPasswords(response.data);
      } else if (response.data && response.data.passwords && Array.isArray(response.data.passwords)) {
        setPasswords(response.data.passwords);
      } else if (response.data && response.data.data && response.data.data.passwords && Array.isArray(response.data.data.passwords)) {
        setPasswords(response.data.data.passwords);
      } else {
        console.warn('Unexpected password response format:', response.data);
        setPasswords([]);
      }
      toast.success('Passwords loaded successfully');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Your session has expired. Please login again.');
        setStep(1);
      } else {
        setError('Failed to load passwords. Please try again.');
        console.error('Error fetching passwords:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (step === 1) {
      if (!clientId.trim()) {
        setError('Please enter your Client ID');
        return;
      }
      
      setLoading(true);
      try {
        // Verify the client ID with the server
        const response = await portalPasswordService.verifyClientId(clientId);
        
        // For demo purposes, still check the format
        const clientIdRegex = /^CL-[A-Z0-9]{5}$/;
        if (!clientIdRegex.test(clientId)) {
          setError('Invalid Client ID format. Please check and try again.');
          setLoading(false);
          return;
        }
        
        // Check if client is registered for TOTP
        if (response.data && response.data.isRegistered !== undefined) {
          setIsRegistered(response.data.isRegistered);
          setClientName(response.data.clientName || '');
          
          // If client has an otpauthUrl (not registered), save it for QR code
          if (response.data.otpauthUrl) {
            setOtpauthUrl(response.data.otpauthUrl);
          }
        }
        
        setError('');
        setStep(2);
      } catch (error) {
        setError('Failed to verify Client ID. Please check and try again.');
        console.error('Error verifying client ID:', error);
      } finally {
        setLoading(false);
      }
    } else if (step === 2) {
      if (!totpCode.trim() || totpCode.length !== 6) {
        setError('Please enter a valid 6-digit TOTP code');
        return;
      }
      
      setLoading(true);
      try {
        // Verify the TOTP code with the server
        const response = await portalPasswordService.verifyTotpCode(clientId, totpCode);
        
        // Store the token for future requests
        localStorage.setItem('passwordToken', response.data.token);
        
        // For demo purposes, still check if it's a 6-digit number
        const totpRegex = /^\d{6}$/;
        if (!totpRegex.test(totpCode)) {
          setError('Invalid TOTP code format. Please check and try again.');
          setLoading(false);
          return;
        }
        
        // In production, this would verify the TOTP code with the API
        setError('');
        setStep(3);
      } catch (error) {
        setError('Failed to verify TOTP code. Please check and try again.');
        console.error('Error verifying TOTP code:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBack = () => {
    setStep(step - 1);
    setError('');
  };

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPasswordId, setCurrentPasswordId] = useState(null);
  const [passwordModalData, setPasswordModalData] = useState(null);

  const handleViewPassword = async (passwordId) => {
    try {
      setLoading(true);
      // Get the password token from localStorage
      const passwordToken = localStorage.getItem('passwordToken');
      
      if (!passwordToken) {
        setError('Authentication token not found. Please login again.');
        setStep(1);
        return;
      }
      
      // Store the current password ID
      setCurrentPasswordId(passwordId);
      
      // Fetch the password from the API
      const response = await portalPasswordService.getPassword(clientId, passwordId, passwordToken);
      
      // Prepare data for the modal
      setPasswordModalData({
        password: response.data.password,
        serviceName: response.data.serviceName
      });
      
      // Show the secure password modal
      setShowPasswordModal(true);
      
      // Log the access
      toast.info('Password access has been logged for security purposes');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Your session has expired. Please login again.');
        setStep(1);
      } else {
        setError('Failed to retrieve password. Please try again.');
        console.error('Error fetching password:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordModalClose = async () => {
    try {
      // Only revoke the password if we have a current password ID
      if (currentPasswordId) {
        // Get the password token from localStorage
        const passwordToken = localStorage.getItem('passwordToken');
        
        if (passwordToken) {
          // Revoke the password after viewing
          await portalPasswordService.revokePassword(clientId, currentPasswordId, passwordToken);
          console.log(`Password ${currentPasswordId} revoked after viewing`);
          
          // Refresh the password list to show the updated status
          await fetchPasswords();
          
          toast.info('Password has been revoked for security purposes');
        }
      }
    } catch (error) {
      console.error('Error revoking password:', error);
      // Don't show error to user, just log it
    } finally {
      // Clean up state variables
      setShowPasswordModal(false);
      setPasswordModalData(null);
      setCurrentPasswordId(null);
    }
  };

  const handleCopyToClipboard = () => {
    if (selectedPassword?.password) {
      navigator.clipboard.writeText(selectedPassword.password)
        .then(() => {
          toast.success('Password copied to clipboard! It will be cleared in 60 seconds.');
          
          // Auto-clear clipboard after 60 seconds
          setTimeout(() => {
            navigator.clipboard.writeText(' ')
              .then(() => console.log('Clipboard cleared for security'))
              .catch(err => console.error('Failed to clear clipboard:', err));
          }, 60000);
        })
        .catch(err => {
          toast.error('Failed to copy password to clipboard');
          console.error('Clipboard error:', err);
        });
    }
  };

  const renderStep1 = () => (
    <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md mx-auto`}>
      <h2 className="text-2xl font-bold mb-6 text-center dark:text-white">Enter Your Client ID</h2>
      <p className="mb-4 text-gray-600 dark:text-gray-300">Please enter the client ID provided by your IT administrator.</p>
      
      {error && <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-900 dark:text-red-200">{error}</div>}
      
      <div className="mb-4">
        <label htmlFor="clientId" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">Client ID</label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaLock className="text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            id="clientId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            placeholder="Enter your client ID"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            required
          />
        </div>
      </div>
      
      <button
        onClick={handleNext}
        className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      >
        Next
      </button>
    </div>
  );

  const renderStep2 = () => {
    // If client is already registered, show TOTP verification screen
    if (isRegistered) {
      return (
        <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md mx-auto`}>
          <h2 className="text-2xl font-bold mb-6 text-center dark:text-white">Enter TOTP Code</h2>
          <p className="mb-4 text-gray-600 dark:text-gray-300">Open your authenticator app and enter the 6-digit code for Is IT Support.</p>
          
          {error && <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-900 dark:text-red-200">{error}</div>}
          
          <div className="mb-4">
            <label htmlFor="totpCode" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">TOTP Code</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FaQrcode className="text-gray-500 dark:text-gray-400" />
              </div>
              <input
                type="text"
                id="totpCode"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Enter 6-digit code"
                value={totpCode}
                onChange={(e) => setTotpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
                maxLength={6}
                required
              />
            </div>
          </div>
          
          <div className="flex justify-between">
            <button
              onClick={handleBack}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            >
              Verify
            </button>
          </div>
        </div>
      );
    }
    
    // If client is not registered, show TOTP registration screen
    return (
      <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md mx-auto`}>
        <h2 className="text-2xl font-bold mb-6 text-center dark:text-white">Set Up Two-Factor Authentication</h2>
        <p className="mb-4 text-gray-600 dark:text-gray-300">Welcome {clientName}! You need to set up two-factor authentication to access your passwords.</p>
        
        {error && <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-900 dark:text-red-200">{error}</div>}
        
        <div className="mb-6 flex justify-center">
          {otpauthUrl && (
            <div className="p-4 bg-white rounded-lg">
              <img 
                src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(otpauthUrl)}`} 
                alt="TOTP QR Code" 
                className="mx-auto"
              />
            </div>
          )}
        </div>
        
        <div className="mb-6">
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">1. Scan this QR code with your authenticator app (Google Authenticator, Microsoft Authenticator, etc.)</p>
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">2. Enter the 6-digit code shown in your app below</p>
        </div>
        
        <div className="mb-4">
          <label htmlFor="totpCode" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">TOTP Code</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FaQrcode className="text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="text"
              id="totpCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Enter 6-digit code"
              value={totpCode}
              onChange={(e) => setTotpCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 6))}
              maxLength={6}
              required
            />
          </div>
        </div>
        
        <div className="flex justify-between">
          <button
            onClick={handleBack}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            Register & Verify
          </button>
        </div>
      </div>
    );
  };

  const renderStep3 = () => (
    <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-4xl mx-auto`}>
      <h2 className="text-2xl font-bold mb-6 text-center dark:text-white">Your Passwords</h2>
      <p className="mb-4 text-gray-900 dark:text-gray-300">Select a password to view. For security, passwords will be shown only once in a secure window with a timer, and will be automatically revoked after viewing. You will need to request a new password if you need to access it again.</p>
      
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Service</th>
              <th scope="col" className="px-6 py-3">Created</th>
              <th scope="col" className="px-6 py-3">Expires</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {passwords.map((password) => (
              <tr key={password.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{password.serviceName}</td>
                <td className="px-6 py-4">{password.createdAt}</td>
                <td className="px-6 py-4">{password.expiresAt}</td>
                <td className="px-6 py-4">
                  <span className="px-2 py-1 text-xs font-semibold rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200">
                    {password.status}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleViewPassword(password.id)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    View Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* We no longer show the password inline - it's displayed in the modal */}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Secure Password Modal */}
      {showPasswordModal && passwordModalData && (
        <SecurePasswordModal
          isOpen={showPasswordModal}
          onClose={handlePasswordModalClose}
          password={passwordModalData.password}
          serviceName={passwordModalData.serviceName}
          onPasswordRetrieved={handlePasswordModalClose}
        />
      )}
      
      <div className="mb-8">
        <h1 className="text-3xl font-bold dark:text-gray-900 text-white">Secure Password Retrieval</h1>
        <p className="dark:text-gray-900 text-white mt-2">
          Access your passwords securely using two-factor authentication.
        </p>
      </div>

      <div className="mb-8">
        <div className="flex items-center">
          <div className={`flex items-center justify-center w-8 h-8 rounded-full ${step >= 1 ? 'bg-green-600' : 'bg-gray-300 dark:bg-gray-700'} text-white`}>
            1
          </div>
          <div className={`h-1 w-16 ${step >= 2 ? 'bg-green-600' : 'bg-gray-300 dark:bg-gray-700'}`}></div>
          <div className={`flex items-center justify-center w-8 h-8 rounded-full ${step >= 2 ? 'bg-green-600' : 'bg-gray-300 dark:bg-gray-700'} text-white`}>
            2
          </div>
          <div className={`h-1 w-16 ${step >= 3 ? 'bg-green-600' : 'bg-gray-300 dark:bg-gray-700'}`}></div>
          <div className={`flex items-center justify-center w-8 h-8 rounded-full ${step >= 3 ? 'bg-green-600' : 'bg-gray-300 dark:bg-gray-700'} text-white`}>
            3
          </div>
        </div>
      </div>

      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
    </div>
  );
};

export default PortalPasswordSystem;
