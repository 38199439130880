import axios from 'axios';
import deviceFingerprint from '../utils/deviceFingerprint';
import deviceTokenManager from '../utils/deviceTokenManager';

const API_URL = '/api/auth';

/**
 * Service for authentication operations
 */
class AuthService {
  /**
   * Register a new user
   * @param {Object} userData - User registration data
   * @returns {Promise} - Promise with registration result
   */
  register(userData) {
    return axios.post(`${API_URL}/register`, userData)
      .then(response => {
        if (response.data && response.data.token) {
          // Store token and user data consistently
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.data.user));
          console.log('Registration successful - token stored:', response.data.token);
        }
        return response;
      });
  }

  /**
   * Log in a user
   * @param {string} email - User email
   * @param {string} password - User password
   * @returns {Promise} - Promise with login result
   */
  async login(email, password) {
    try {
      console.log('========== LOGIN FLOW START ==========');
      console.log('Starting login process for email:', email);
      
      // Collect device fingerprint information
      console.log('Collecting device fingerprint information...');
      const deviceInfo = await deviceFingerprint.collect();
      console.log('Device fingerprint collected with components:', Object.keys(deviceInfo.components || {}));
      
      // Add existing device token if available
      console.log('Checking for existing device token...');
      const deviceToken = deviceTokenManager.getToken();
      if (deviceToken) {
        deviceInfo.deviceToken = deviceToken;
        deviceInfo.tokenSource = deviceTokenManager.getTokenSource();
        console.log(`Including existing device token in login request: ${deviceToken.substring(0, 10)}...`);
        console.log(`Token source: ${deviceInfo.tokenSource}`);
      } else {
        console.log('No existing device token found for login request');
        console.log('Cookies available:', document.cookie ? 'Yes' : 'No');
        console.log('LocalStorage available:', localStorage ? 'Yes' : 'No');
      }
      
      // Add device name if not provided
      if (!deviceInfo.name) {
        const platform = deviceInfo.components?.platform || 'Unknown';
        const browser = this.getBrowserName(deviceInfo.components?.userAgent || '');
        deviceInfo.name = `${platform} - ${browser}`;
      }
      
      console.log('Sending login request with device info:', {
        deviceName: deviceInfo.name,
        hasDeviceToken: !!deviceInfo.deviceToken,
        tokenSource: deviceInfo.tokenSource,
        fingerprintComponents: Object.keys(deviceInfo.components || {}).length + ' components'
      });
      
      // Add device token to headers explicitly
      const headers = {};
      if (deviceToken) {
        headers['X-Device-Token'] = deviceToken;
        console.log('Added device token to request headers');
      }
      
      console.log('Sending login request with device info:', {
        email,
        deviceName: deviceInfo.name,
        hasDeviceToken: !!deviceInfo.deviceToken,
        tokenSource: deviceInfo.tokenSource,
        components: Object.keys(deviceInfo.components || {})
      });
      
      const response = await axios.post(`${API_URL}/login`, { 
        email, 
        password,
        deviceInfo
      }, { headers });
      
      console.log('Login response received:', {
        status: response.status,
        hasToken: !!response.data.token,
        hasDeviceToken: !!response.data.deviceToken,
        requires2FA: !!response.data.requires2FA,
        userId: response.data.userId,
        responseHeaders: Object.keys(response.headers || {})
      });
      
      // Extract device token from response headers
      const headerNames = Object.keys(response.headers || {});
      const deviceTokenHeader = headerNames.find(h => h.toLowerCase() === 'x-device-token');
      if (deviceTokenHeader) {
        const headerToken = response.headers[deviceTokenHeader];
        console.log(`Device token found in response headers: ${headerToken.substring(0, 10)}...`);
        deviceTokenManager.storeToken(headerToken, 'response-header');
      }
      
      // Check for device token in cookies after login
      setTimeout(() => {
        const newToken = deviceTokenManager.getToken();
        if (newToken) {
          console.log(`Device token after login: ${newToken.substring(0, 10)}...`);
          console.log(`Token source: ${deviceTokenManager.getTokenSource()}`);
        } else {
          console.log('No device token found after login');
        }
      }, 100);
      
      // Handle device token from response data
      if (response.data.deviceToken) {
        console.log(`Storing device token from login response data: ${response.data.deviceToken.substring(0, 10)}...`);
        deviceTokenManager.storeToken(response.data.deviceToken, 'response-data');
      }
      
      // Store device ID and status if available
      if (response.data.deviceId) {
        localStorage.setItem('deviceId', response.data.deviceId);
        console.log(`Stored device ID: ${response.data.deviceId}`);
      }
      
      if (response.data.deviceStatus) {
        localStorage.setItem('deviceStatus', response.data.deviceStatus);
        console.log(`Stored device status: ${response.data.deviceStatus}`);
      }
      
      if (response.data.token) {
        // Store user details and JWT token in local storage
        const user = response.data.data.user;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', response.data.token);
        console.log('Login successful - token stored:', response.data.token);
      }
      return response;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  /**
   * Verify login with 2FA code
   * @param {string} userId - User ID
   * @param {string} verificationCode - 2FA verification code
   * @returns {Promise} - Promise with verification result
   */
  async verifyLogin(userId, verificationCode) {
    try {
      console.log('========== VERIFICATION FLOW START ==========');
      console.log('Starting 2FA verification for user ID:', userId);
      
      // Collect device fingerprint information
      console.log('Collecting device fingerprint information for verification...');
      const deviceInfo = await deviceFingerprint.collect();
      console.log('Device fingerprint collected with components:', Object.keys(deviceInfo.components || {}));
      
      // Log all cookies before request for debugging
      console.log('Cookies before verification request:', document.cookie);
      
      // Add existing device token if available (check multiple sources)
      console.log('Checking for existing device token for verification...');
      let deviceToken = deviceTokenManager.getToken();
      
      // Log token source and value for debugging
      if (deviceToken) {
        console.log(`Using existing device token for verification: ${deviceToken.substring(0, 10)}...`);
        console.log('Token source:', deviceTokenManager.getTokenSource());
        deviceInfo.deviceToken = deviceToken;
        deviceInfo.tokenSource = deviceTokenManager.getTokenSource();
      } else {
        console.log('No existing device token found for verification request');
        console.log('Checking alternative sources for device token...');
        
        // Check cookies directly as a fallback - check both case variations
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        
        // Try exact match first
        let deviceTokenCookie = cookies.find(cookie => cookie.startsWith('deviceToken='));
        
        // If not found, try case-insensitive match
        if (!deviceTokenCookie) {
          deviceTokenCookie = cookies.find(cookie => cookie.toLowerCase().startsWith('devicetoken='));
        }
        
        // Also check for capitalized version
        if (!deviceTokenCookie) {
          deviceTokenCookie = cookies.find(cookie => cookie.startsWith('DeviceToken='));
        }
        
        if (deviceTokenCookie) {
          deviceToken = deviceTokenCookie.split('=')[1];
          console.log(`Found device token in cookies: ${deviceToken.substring(0, 10)}...`);
          deviceInfo.deviceToken = deviceToken;
          deviceInfo.tokenSource = 'cookie-direct';
          deviceTokenManager.storeToken(deviceToken, 'cookie-direct');
        }
      }
      
      // Add device name if not provided
      if (!deviceInfo.name) {
        const platform = deviceInfo.components?.platform || 'Unknown';
        const browser = this.getBrowserName(deviceInfo.components?.userAgent || '');
        deviceInfo.name = `${platform} - ${browser}`;
      }
      
      console.log('Sending device info with verification:', {
        hasDeviceToken: !!deviceInfo.deviceToken,
        deviceName: deviceInfo.name,
        components: Object.keys(deviceInfo.components || {}).length + ' components'
      });
      
      // Add device token to headers explicitly
      const headers = {};
      if (deviceToken) {
        headers['X-Device-Token'] = deviceToken;
      }
      
      const response = await axios.post(`${API_URL}/verify-login`, { 
        userId, 
        verificationCode,
        deviceInfo
      }, { headers });
      
      console.log('Verification response status:', response.status);
      console.log('Verification response headers:', Object.keys(response.headers || {}));
      
      // Process device token from multiple sources
      // 1. Check response headers (case-insensitive)
      const headerNames = Object.keys(response.headers || {});
      const deviceTokenHeader = headerNames.find(h => h.toLowerCase() === 'x-device-token');
      
      if (deviceTokenHeader) {
        const headerToken = response.headers[deviceTokenHeader];
        console.log(`Device token found in response headers: ${headerToken.substring(0, 10)}...`);
        deviceTokenManager.storeToken(headerToken, 'response-header');
      }
      
      // Check for device ID and status in headers
      const deviceIdHeader = headerNames.find(h => h.toLowerCase() === 'x-device-id');
      if (deviceIdHeader) {
        const deviceId = response.headers[deviceIdHeader];
        console.log(`Device ID found in response headers: ${deviceId}`);
        localStorage.setItem('deviceId', deviceId);
      }
      
      const deviceStatusHeader = headerNames.find(h => h.toLowerCase() === 'x-device-status');
      if (deviceStatusHeader) {
        const deviceStatus = response.headers[deviceStatusHeader];
        console.log(`Device status found in response headers: ${deviceStatus}`);
        localStorage.setItem('deviceStatus', deviceStatus);
      }
      
      // 2. Check for device token in cookies with a delay to ensure they're set
      setTimeout(() => {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        console.log('Cookies after verification:', cookies);
        
        // Try multiple cookie name variations
        let deviceTokenCookie = cookies.find(cookie => cookie.startsWith('deviceToken='));
        
        if (!deviceTokenCookie) {
          deviceTokenCookie = cookies.find(cookie => cookie.toLowerCase().startsWith('devicetoken='));
        }
        
        if (!deviceTokenCookie) {
          deviceTokenCookie = cookies.find(cookie => cookie.startsWith('DeviceToken='));
        }
        
        if (deviceTokenCookie) {
          const token = deviceTokenCookie.split('=')[1];
          console.log(`Found device token in cookies after verification: ${token.substring(0, 10)}...`);
          deviceTokenManager.storeToken(token, 'cookie-after-verify');
        } else if (cookies.length === 1 && cookies[0].includes('=')) {
          // If there's only one cookie, try to use it (helpful in development)
          const parts = cookies[0].split('=');
          if (parts.length >= 2) {
            const token = parts.slice(1).join('=');
            if (token && token.length > 20) { // Only use if it looks like a token
              console.log(`Using single available cookie as device token: ${token.substring(0, 10)}...`);
              deviceTokenManager.storeToken(token, 'cookie-single');
            }
          }
        }
      }, 500);
      
      // 3. Check response data for device token and device info
      console.log('Checking response data for device token and info...');
      console.log('Response data keys:', Object.keys(response.data || {}));
      
      if (response.data.deviceToken) {
        console.log(`Found device token in response data: ${response.data.deviceToken.substring(0, 10)}...`);
        deviceTokenManager.storeToken(response.data.deviceToken, 'response-data');
        console.log('Device token stored in localStorage and cookies');
      } else {
        console.log('No device token found in response data');
      }
      
      // Store device ID and status if available in response data
      if (response.data.deviceId) {
        localStorage.setItem('deviceId', response.data.deviceId);
        console.log(`Stored device ID: ${response.data.deviceId}`);
      } else {
        console.log('No device ID found in response data');
      }
      
      if (response.data.deviceStatus) {
        localStorage.setItem('deviceStatus', response.data.deviceStatus);
        console.log(`Stored device status: ${response.data.deviceStatus}`);
      } else {
        console.log('No device status found in response data');
      }
      
      console.log('========== VERIFICATION FLOW COMPLETE ==========');
      
      if (response.data.token) {
        // Store user details and JWT token in local storage
        const user = response.data.data.user;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', response.data.token);
        console.log('2FA verification successful - token stored:', response.data.token);
      }
      return response;
    } catch (error) {
      console.error('2FA verification error:', error);
      throw error;
    }
  }

  /**
   * Log out the current user
   */
  logout() {
    // Remove both user and token from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    // Don't remove device token on logout to maintain device trust
    console.log('User logged out - cleared authentication data');
  }

  /**
   * Get the current user from local storage
   * @returns {Object|null} - Current user or null if not logged in
   */
  getCurrentUser() {
    const userStr = localStorage.getItem('user');
    console.log('User string from localStorage:', userStr);
    if (!userStr) {
      console.log('No user found in localStorage');
      return null;
    }
    
    try {
      const user = JSON.parse(userStr);
      console.log('Parsed user object:', user);
      return user;
    } catch (e) {
      console.error('Error parsing user from localStorage:', e);
      return null;
    }
  }


  /**
   * Check if user is logged in
   * @returns {boolean} - True if user is logged in
   */
  isLoggedIn() {
    const user = this.getCurrentUser();
    const token = localStorage.getItem('token');
    return !!user && !!token;
  }

  /**
   * Check if current user has admin role
   * @returns {boolean} - True if user is admin
   */
  isAdmin() {
    const user = this.getCurrentUser();
    return !!user && user.role === 'admin';
  }
  
  /**
   * Get browser name from user agent
   * @param {string} userAgent - User agent string
   * @returns {string} - Browser name
   */
  getBrowserName(userAgent) {
    if (!userAgent) return 'Unknown';
    
    if (userAgent.includes('Firefox')) return 'Firefox';
    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) return 'Chrome';
    if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) return 'Safari';
    if (userAgent.includes('Edg')) return 'Edge';
    if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) return 'Internet Explorer';
    
    return 'Unknown Browser';
  }

  /**
   * Request password reset
   * @param {string} email - User email
   * @returns {Promise} - Promise with reset request result
   */
  forgotPassword(email) {
    return axios.post(`${API_URL}/forgot-password`, { email });
  }

  /**
   * Reset password with token
   * @param {string} token - Reset token
   * @param {string} password - New password
   * @returns {Promise} - Promise with reset result
   */
  resetPassword(token, password) {
    return axios.patch(`${API_URL}/reset-password/${token}`, { password })
      .then(response => {
        // Check if response includes a new auth token (some APIs return a new token after password reset)
        if (response.data && response.data.token) {
          // Store token and user data consistently
          localStorage.setItem('token', response.data.token);
          
          if (response.data.data && response.data.data.user) {
            localStorage.setItem('user', JSON.stringify(response.data.data.user));
            console.log('User data updated in localStorage after password reset');
          }
          
          console.log('Password reset successful - new token stored');
        }
        return response;
      });
  }

  /**
   * Update current user's password
   * @param {string} currentPassword - Current password
   * @param {string} newPassword - New password
   * @returns {Promise} - Promise with update result
   */
  updatePassword(currentPassword, newPassword) {
    // Check if token exists before making the request
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. User must be logged in to update password.');
      return Promise.reject(new Error('Authentication required. Please log in to update your password.'));
    }
    
    return axios.patch(
      `${API_URL}/update-password`,
      { currentPassword, newPassword },
      { headers: this.authHeader() }
    )
    .then(response => {
      // Check if response includes a new auth token (some APIs return a new token after password update)
      if (response.data && response.data.token) {
        // Store token and user data consistently
        localStorage.setItem('token', response.data.token);
        
        if (response.data.data && response.data.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.data.user));
          console.log('User data updated in localStorage after password update');
        }
        
        console.log('Password update successful - new token stored');
      }
      return response;
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        // Clear invalid token and user data
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
      return Promise.reject(error);
    });
  }

  /**
   * Register a new admin user (only available to existing admins)
   * @param {Object} adminData - Admin registration data
   * @returns {Promise} - Promise with registration result
   */
  registerAdmin(adminData) {
    // Check if token exists before making the request
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No authentication token found. User must be logged in to register an admin.');
      return Promise.reject(new Error('Authentication required. Please log in to register an admin.'));
    }
    
    return axios.post(`${API_URL}/register-admin`, adminData, { headers: this.authHeader() })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Clear invalid token and user data
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        return Promise.reject(error);
      });
  }

  /**
   * Get auth header for authenticated requests
   * @returns {Object} - Header object with Authorization
   */
  authHeader() {
    // First try to get token directly from localStorage (preferred method)
    const token = localStorage.getItem('token');
    if (token) {
      console.log('Using token from localStorage:', token);
      return { Authorization: `Bearer ${token}` };
    }
    
    // Fallback to checking user object (legacy method)
    const user = this.getCurrentUser();
    console.log('getCurrentUser result:', user);
    if (user && user.token) {
      console.log('Using token from user object:', user.token);
      return { Authorization: `Bearer ${user.token}` };
    }
    
    console.log('No authentication token available');
    return {};
  }

  /**
   * Send verification code to email without registering
   * @param {string} email - User email
   * @returns {Promise} - Promise with verification code sending result
   */
  sendVerificationCode(email) {
    console.log('Sending verification code to:', email);
    console.log('API_URL:', API_URL);
    console.log('Full URL:', `${API_URL}/send-verification-code`);
    
    // Support both direct URL and proxy approaches to work in different browsers
    const useProxy = true; // Set to false to use direct URL
    const url = useProxy ? `${API_URL}/send-verification-code` : 'http://localhost:5000/api/auth/send-verification-code';
    console.log('Using URL:', url);
    
    return axios.post(url, { email }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      withCredentials: true // This helps with CORS in some browsers
    })
      .then(response => {
        console.log('Verification code response:', response.data);
        return response;
      })
      .catch(error => {
        console.error('Verification code error:', error);
        if (error.response) {
          console.error('Error response:', error.response.status, error.response.data);
        }
        throw error;
      });
  }

  /**
   * Verify email with code
   * @param {string} email - User email
   * @param {string} code - Verification code
   * @returns {Promise} - Promise with verification result
   */
  verifyEmail(email, code) {
    return axios.post(`${API_URL}/verify-email`, { email, verificationCode: code });
  }

  /**
   * Register user after successful payment
   * @param {Object} data - Registration data with email
   * @returns {Promise} - Promise with registration result
   */
  registerAfterPayment(data) {
    return axios.post(`${API_URL}/register-after-payment`, data)
      .then(response => {
        console.log('Raw registerAfterPayment response:', response);
        
        if (response.data && response.data.token) {
          // Store token and user data consistently
          localStorage.setItem('token', response.data.token);
          
          // Check if user data exists in the expected location
          if (response.data.data && response.data.data.user) {
            localStorage.setItem('user', JSON.stringify(response.data.data.user));
            console.log('User data stored in localStorage:', response.data.data.user);
          } else {
            console.error('User data missing from response:', response.data);
          }
          
          console.log('Registration after payment successful - token stored:', response.data.token);
        } else {
          console.error('Token missing from response:', response.data);
        }
        
        return response;
      })
      .catch(error => {
        console.error('Registration after payment error:', error);
        throw error;
      });
  }
}

// Create a singleton instance of the auth service
const authService = new AuthService();

// Export the singleton instance
export default authService;
