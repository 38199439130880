import React from 'react';
import Landing from './components/landing/Landing';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignupPage from './components/signup/SignupPage';
import { AuthProvider } from './context/auth.context';
import TestApi from './components/test/TestApi';
import ImageTest from './components/test/ImageTest';

// Import components
import Layout from './components/layout/Layout';
import PortalLayout from './components/layout/PortalLayout';
import DeviceList from './components/devices/DeviceList';
import DeviceDetail from './components/devices/DeviceDetail';
import DeviceSync from './components/devices/DeviceSync';
import Login from './components/auth/Login';
import Register from './components/auth/Register';

// Import Dashboard component
import Dashboard from './components/dashboard/Dashboard';

// Import Profile component
import Profile from './components/profile/Profile';

// Import Ticket components
import TicketList from './components/tickets/TicketList';
import TicketDetail from './components/tickets/TicketDetail';

// Import CreateTicket component
import CreateTicket from './components/tickets/CreateTicket';

// Import Billing components
import BillingDashboard from './components/billing/BillingDashboard';
import ClientBillingRedirect from './components/billing/ClientBillingRedirect';
import PaymentHistory from './components/billing/PaymentHistory';
import StripeCheckout from './components/billing/StripeCheckout';
import PaymentSuccess from './components/billing/PaymentSuccess';
import PaymentCancel from './components/billing/PaymentCancel';
import PaymentMethodUpdate from './components/billing/PaymentMethodUpdate';

// Import Client components
import Clients from './components/clients/Clients';
import ClientAddressBook from './components/clients/ClientAddressBook';
import ClientDetail from './components/clients/ClientDetail';
import LinkRMM from './components/clients/LinkRMM';

// Import Portal components
import PortalDashboard from './components/portal/PortalDashboard';
import PortalTickets from './components/portal/PortalTickets';
import PortalDownloads from './components/portal/PortalDownloads';
import PortalHelp from './components/portal/PortalHelp';
import PortalBilling from './components/portal/PortalBilling';
import PortalPaymentMethodUpdate from './components/portal/PortalPaymentMethodUpdate';
import PortalStripeCheckout from './components/portal/PortalStripeCheckout';
import PortalPaymentSuccess from './components/portal/PortalPaymentSuccess';
import PortalPaymentCancel from './components/portal/PortalPaymentCancel';
import PortalPaymentHistory from './components/portal/PortalPaymentHistory';
import PortalPasswordSystem from './components/portal/PortalPasswordSystem';
import PasswordManagement from './components/admin/PasswordManagement';

// Import Admin components
import AdminDashboard from './components/admin/AdminDashboard';
import UserManagement from './components/admin/UserManagement';
import TacticalRMMSettings from './components/admin/TacticalRMMSettings';
import ClientManagement from './components/admin/ClientManagement';
import AdminRegistration from './components/admin/AdminRegistration';
import EsetManagement from './components/admin/EsetManagement';

// Import Monitoring component
import MonitoringDashboard from './components/monitoring/MonitoringDashboard';

// Import Test Pages
import TestPage from './TestPage';
import TestCSSPage from './TestCSSPage';

// Protected route component
const ProtectedRoute = ({ children, redirectPath }) => {
  // Check for both user and token in localStorage
  const hasUser = localStorage.getItem('user') !== null;
  const hasToken = localStorage.getItem('token') !== null;
  const isAuthenticated = hasUser && hasToken;
  
  if (!isAuthenticated) {
    console.log('Authentication check failed - redirecting to login');
    // Clear any partial auth data
    if (!hasUser || !hasToken) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
    return <Navigate to="/login" />;
  }
  
  // If redirectPath is provided, use it for redirection logic
  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }
  
  return children;
};

// Admin route component
const AdminRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const hasUser = localStorage.getItem('user') !== null;
  const hasToken = localStorage.getItem('token') !== null;
  const isAuthenticated = hasUser && hasToken;
  const isAdmin = user && user.role === 'admin';
  
  if (!isAuthenticated) {
    console.log('Admin authentication check failed - redirecting to login');
    // Clear any partial auth data
    if (!hasUser || !hasToken) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    }
    return <Navigate to="/login" />;
  }
  
  return isAdmin ? children : <Navigate to="/dashboard" />;
};

// Inline client billing redirect component
const InlineClientBillingRedirect = ({ path }) => {
  // Check for proper authentication first
  const hasUser = localStorage.getItem('user') !== null;
  const hasToken = localStorage.getItem('token') !== null;
  const isAuthenticated = hasUser && hasToken;
  
  if (!isAuthenticated) {
    // Don't redirect here - let the ProtectedRoute handle it
    return null;
  }
  
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const isClient = user && user.role === 'client';
  
  // If user is a client, redirect to the portal version of the billing page
  if (isClient) {
    const portalPath = path.replace('/billing', '/portal/billing');
    return <Navigate to={portalPath} />;
  }
  
  // For non-client users, proceed to the regular billing page
  return null;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <ToastContainer position="top-right" autoClose={5000} />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/test-api" element={<TestApi />} />
          <Route path="/test-image" element={<ImageTest />} />
          
          {/* Protected routes */}
          <Route path="/dashboard" element={<ProtectedRoute><Layout><Dashboard /></Layout></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Layout><Profile /></Layout></ProtectedRoute>} />
          
          {/* Ticket routes */}
          <Route path="/tickets" element={<ProtectedRoute><Layout><TicketList /></Layout></ProtectedRoute>} />
          <Route path="/tickets/new" element={<ProtectedRoute><Layout><CreateTicket /></Layout></ProtectedRoute>} />
          <Route path="/tickets/:id" element={<ProtectedRoute><Layout><TicketDetail /></Layout></ProtectedRoute>} />
          
          {/* Billing routes */}
          <Route path="/billing" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing" />
              <Layout><BillingDashboard /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/history" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/history" />
              <Layout><PaymentHistory /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/checkout" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/checkout" />
              <Layout><StripeCheckout /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/checkout/additional" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/checkout/additional" />
              <Layout><StripeCheckout /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/success" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/success" />
              <Layout><PaymentSuccess /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/cancel" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/cancel" />
              <Layout><PaymentCancel /></Layout>
            </ProtectedRoute>
          } />
          <Route path="/billing/payment-method" element={
            <ProtectedRoute>
              <InlineClientBillingRedirect path="/billing/payment-method" />
              <Layout><PaymentMethodUpdate /></Layout>
            </ProtectedRoute>
          } />
          
          {/* Device routes */}
          <Route path="/devices" element={<ProtectedRoute><Layout><DeviceList /></Layout></ProtectedRoute>} />
          <Route path="/devices/sync" element={<ProtectedRoute><Layout><DeviceSync /></Layout></ProtectedRoute>} />
          <Route path="/devices/:id" element={<ProtectedRoute><Layout><DeviceDetail /></Layout></ProtectedRoute>} />
          
          {/* Client routes */}
          <Route path="/clients" element={<ProtectedRoute><Layout><ClientAddressBook /></Layout></ProtectedRoute>} />
          <Route path="/clients/list" element={<ProtectedRoute><Layout><Clients /></Layout></ProtectedRoute>} />
          <Route path="/clients/new" element={<ProtectedRoute><Layout><ClientDetail isNew={true} /></Layout></ProtectedRoute>} />
          <Route path="/clients/:id" element={<ProtectedRoute><Layout><ClientDetail /></Layout></ProtectedRoute>} />
          <Route path="/clients/:id/link-rmm" element={<ProtectedRoute><Layout><LinkRMM /></Layout></ProtectedRoute>} />
          
          {/* Admin routes */}
          <Route path="/admin" element={<AdminRoute><Layout><AdminDashboard /></Layout></AdminRoute>} />
          <Route path="/admin/users" element={<AdminRoute><Layout><UserManagement /></Layout></AdminRoute>} />
          <Route path="/admin/register-admin" element={<AdminRoute><Layout><AdminRegistration /></Layout></AdminRoute>} />
          <Route path="/admin/tactical-rmm" element={<AdminRoute><Layout><TacticalRMMSettings /></Layout></AdminRoute>} />
          <Route path="/admin/clients" element={<AdminRoute><Layout><ClientManagement /></Layout></AdminRoute>} />
          <Route path="/admin/sync" element={<AdminRoute><Layout><DeviceSync /></Layout></AdminRoute>} />
          <Route path="/admin/monitoring" element={<AdminRoute><Layout><MonitoringDashboard /></Layout></AdminRoute>} />
          <Route path="/admin/passwords" element={<AdminRoute><Layout><PasswordManagement /></Layout></AdminRoute>} />
          <Route path="/admin/eset" element={<AdminRoute><Layout><EsetManagement /></Layout></AdminRoute>} />
          
          {/* Test routes */}
          <Route path="/test-tactical" element={<TestPage />} />
          <Route path="/test-css" element={<TestCSSPage />} />
          
          {/* Client Portal routes */}
          <Route path="/portal" element={<ProtectedRoute><PortalLayout><PortalDashboard /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/tickets" element={<ProtectedRoute><PortalLayout><PortalTickets /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/tickets/new" element={<ProtectedRoute><PortalLayout><CreateTicket /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/tickets/:id" element={<ProtectedRoute><PortalLayout><TicketDetail /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/downloads" element={<ProtectedRoute><PortalLayout><PortalDownloads /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/help" element={<ProtectedRoute><PortalLayout><PortalHelp /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing" element={<ProtectedRoute><PortalLayout><PortalBilling /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/history" element={<ProtectedRoute><PortalLayout><PortalPaymentHistory /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/checkout" element={<ProtectedRoute><PortalLayout><PortalStripeCheckout /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/checkout/additional" element={<ProtectedRoute><PortalLayout><PortalStripeCheckout /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/success" element={<ProtectedRoute><PortalLayout><PortalPaymentSuccess /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/cancel" element={<ProtectedRoute><PortalLayout><PortalPaymentCancel /></PortalLayout></ProtectedRoute>} />
          <Route path="/portal/billing/payment-method" element={<ProtectedRoute><PortalLayout><PortalPaymentMethodUpdate /></PortalLayout></ProtectedRoute>} />
          
          {/* Password System routes */}
          <Route path="/portal/passwords" element={<ProtectedRoute><PortalLayout><PortalPasswordSystem /></PortalLayout></ProtectedRoute>} />

          {/* Profile route */}
          <Route path="/portal/profile" element={<ProtectedRoute><PortalLayout><Profile /></PortalLayout></ProtectedRoute>} />

          {/* Redirect to appropriate dashboard based on role if path doesn't match */}
          <Route path="*" element={
            (() => {
              const user = JSON.parse(localStorage.getItem('user') || '{}');
              const isClient = user && user.role === 'client';
              return <Navigate to={isClient ? '/portal' : '/dashboard'} />;
            })()
          } />
        </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
