/**
 * Authentication header utility
 * Returns the Authorization header with JWT token for API requests
 */
export default function authHeader() {
  let token = null;
  let tokenSource = 'none';
  const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEV_MODE === 'true';
  
  // Try multiple storage locations to find a valid token
  
  // 1. First try to get token directly from localStorage (preferred method)
  try {
    token = localStorage.getItem('token');
    if (token) {
      tokenSource = 'localStorage-direct';
    }
  } catch (e) {
    console.warn('Error accessing token from localStorage:', e);
  }
  
  // 2. If not found, try sessionStorage
  if (!token) {
    try {
      token = sessionStorage.getItem('token');
      if (token) {
        tokenSource = 'sessionStorage-direct';
        // Store back in localStorage for future use
        try {
          localStorage.setItem('token', token);
        } catch (e) {
          console.warn('Error storing token to localStorage:', e);
        }
      }
    } catch (e) {
      console.warn('Error accessing token from sessionStorage:', e);
    }
  }
  
  // 3. Fallback: Check if token is in user object (legacy method)
  if (!token) {
    try {
      const userStr = localStorage.getItem('user');
      if (userStr) {
        const user = JSON.parse(userStr);
        if (user && user.token) {
          token = user.token;
          tokenSource = 'user-object';
          console.log('Using legacy token from user object');
          
          // Store directly in localStorage/sessionStorage for future use
          try {
            localStorage.setItem('token', token);
          } catch (e) {
            try {
              sessionStorage.setItem('token', token);
            } catch (se) {
              console.warn('Failed to store token in any storage:', e, se);
            }
          }
        }
      }
    } catch (e) {
      console.error('Error parsing user from localStorage:', e);
      try {
        localStorage.removeItem('user'); // Remove invalid user object
      } catch (re) {
        console.warn('Error removing invalid user object:', re);
      }
    }
  }
  
  // 4. Process the token if found
  if (token) {
    // Check if it's a development token (starts with 'dev-token-')
    const isDevToken = token.startsWith('dev-token-');
    
    // In production, validate token format - basic check that it's a string with reasonable length
    // In development, allow dev tokens
    if (!isDev && !isDevToken && (typeof token !== 'string' || token.length < 10)) {
      console.error('Invalid token format found in', tokenSource);
      // Clear invalid token
      try {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
      } catch (e) {
        console.warn('Error removing invalid token:', e);
      }
      return {};
    }
    
    // If in development mode and using a dev token, use a special header format
    // that the server will recognize as a development token
    if (isDev && isDevToken) {
      console.log('Using development auth token');
      return { 'X-Dev-Auth': token, 'Authorization': `Bearer ${token}` };
    }
    
    // Log the token for debugging (only show first few characters)
    const maskedToken = token.substring(0, 10) + '...' + token.substring(token.length - 5);
    console.log(`Using auth token from ${tokenSource}:`, maskedToken);
    
    // Ensure proper Bearer format
    return { 
      'Authorization': `Bearer ${token}`,
      'x-access-token': token  // Add this for backward compatibility
    };
  }
  
  console.log('No authentication token found in any storage location');
  return {};
}
