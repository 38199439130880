import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTicketAlt, FaPlus, FaSync, FaFilter, FaSearch, FaCircle } from 'react-icons/fa';

const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  // Import ticket service
  const ticketService = require('../../services/ticket.service').default;

  // Function to fetch tickets
  const fetchTickets = async () => {
    setLoading(true);
    try {
      console.log('Fetching tickets...');
      const response = await ticketService.getTickets();
      
      // Log the entire response for debugging
      console.log('API Response:', response);
      
      // Check if this is a mock response
      const isMockResponse = response.data?.data?.tickets?.some(ticket => 
        ticket.id?.startsWith('mock-') || ticket._id?.startsWith('mock-')
      );
      
      console.log('Is mock response:', isMockResponse);
      
      // Check for different possible response formats
      if (response.data && response.data.data && response.data.data.tickets) {
        // Format: { data: { data: { tickets: [...] } } }
        console.log('Tickets fetched successfully (format 1):', response.data.data.tickets);
        setTickets(response.data.data.tickets);
        setError(null);
      } else if (response.data && response.data.tickets) {
        // Format: { data: { tickets: [...] } }
        console.log('Tickets fetched successfully (format 2):', response.data.tickets);
        setTickets(response.data.tickets);
        setError(null);
      } else if (response.data && Array.isArray(response.data.data)) {
        // Format: { data: { data: [...] } }
        console.log('Tickets fetched successfully (format 3):', response.data.data);
        setTickets(response.data.data);
        setError(null);
      } else if (response.data && Array.isArray(response.data)) {
        // Format: { data: [...] }
        console.log('Tickets fetched successfully (format 4):', response.data);
        setTickets(response.data);
        setError(null);
      } else {
        // No tickets found or unexpected format
        console.log('No tickets found or unexpected API response format:', response.data);
        setTickets([]);
        setError('No tickets found or unexpected API response format');
      }
    } catch (err) {
      setError('Failed to load tickets. Please try again later.');
      console.error('Error fetching tickets:', err);
      setTickets([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch tickets when component mounts
  useEffect(() => {
    fetchTickets();
    
    // Set up an interval to refresh tickets every 60 seconds when the component is mounted
    // Using a longer interval to reduce API load
    const intervalId = setInterval(() => {
      console.log('Auto-refreshing tickets');
      fetchTickets();
    }, 60000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  
  // Refresh tickets when the location changes (user navigates back to this page)
  useEffect(() => {
    console.log('Location changed, refreshing tickets');
    fetchTickets();
  }, [location.pathname]);

  const handleRefresh = () => {
    console.log('Manual refresh requested');
    fetchTickets();
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTickets = tickets.filter(ticket => {
    // Apply status filter
    if (filter !== 'all' && ticket.status !== filter) {
      return false;
    }
    
    // Apply search filter
    if (searchTerm && !ticket.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    
    return true;
  });

  const getStatusColor = (status) => {
    switch(status) {
      case 'open':
        return 'text-blue-500';
      case 'in-progress':
        return 'text-yellow-500';
      case 'closed':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const getPriorityColor = (priority) => {
    switch(priority) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-yellow-500';
      case 'low':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Show loading indicator only on initial load, not on refresh
  if (loading && tickets.length === 0) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Support Tickets</h1>
          <Link
            to="/tickets/new"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <FaPlus className="-ml-1 mr-2 h-4 w-4" />
            New Ticket
          </Link>
        </div>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Support Tickets</h1>
        <Link
          to="/tickets/new"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <FaPlus className="-ml-1 mr-2 h-4 w-4" />
          New Ticket
        </Link>
      </div>

      {/* Filters and Search */}
      <div className="bg-white shadow rounded-lg p-4 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => handleFilterChange('all')}
            className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'all' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterChange('open')}
            className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'open' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
          >
            Open
          </button>
          <button
            onClick={() => handleFilterChange('in-progress')}
            className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'in-progress' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
          >
            In Progress
          </button>
          <button
            onClick={() => handleFilterChange('closed')}
            className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'closed' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
          >
            Closed
          </button>
        </div>
        <div className="mt-4 sm:mt-0 flex items-center">
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaSearch className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search tickets"
              value={searchTerm}
              onChange={handleSearch}
              className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <button
            onClick={handleRefresh}
            className="ml-3 inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <FaSync className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Tickets Table */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ticket
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Device
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Priority
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assigned To
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredTickets.length > 0 ? (
                filteredTickets.map((ticket) => (
                  <tr key={ticket.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Link to={`/tickets/${ticket.id}`} className="text-blue-600 hover:text-blue-900">
                        <div className="font-medium text-gray-900">{ticket.title}</div>
                        <div className="text-sm text-gray-500 truncate max-w-xs">{ticket.description}</div>
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {ticket.device ? (
                        <Link to={`/devices/${ticket.device.id}`} className="text-blue-600 hover:text-blue-900">
                          {ticket.device.name}
                        </Link>
                      ) : (
                        <span className="text-gray-500">N/A</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaCircle className={`mr-2 h-2 w-2 ${getStatusColor(ticket.status)}`} />
                        <span className="capitalize">{ticket.status.replace('-', ' ')}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getPriorityColor(ticket.priority)} bg-opacity-10`}>
                        {ticket.priority}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(ticket.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {ticket.assignedTo ? ticket.assignedTo.name : 'Unassigned'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-sm text-gray-500">
                    No tickets found matching your criteria
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TicketList;
