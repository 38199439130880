import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaArrowLeft, FaLink } from 'react-icons/fa';
import clientService from '../../services/client.service';
import LoadingSpinner from '../common/LoadingSpinner';

const LinkRMM = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [rmmClients, setRmmClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedSite, setSelectedSite] = useState('');
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRMM, setLoadingRMM] = useState(true);
  const [linking, setLinking] = useState(false);

  // Load client data
  useEffect(() => {
    const loadData = async () => {
      try {
        // Load client data
        const clientResponse = await clientService.getClient(id);
        setClient(clientResponse.data.data.client);
        
        // Load TacticalRMM clients
        const rmmResponse = await clientService.getTacticalRMMClients();
        console.log('RMM response:', rmmResponse);
        
        // Check if we have valid clients data
        if (rmmResponse.data && 
            rmmResponse.data.data && 
            rmmResponse.data.data.clients && 
            Array.isArray(rmmResponse.data.data.clients)) {
          setRmmClients(rmmResponse.data.data.clients);
          console.log('RMM clients loaded:', rmmResponse.data.data.clients);
        } else {
          // If the response is not in the expected format, use mock data for development
          console.warn('Invalid RMM clients data format, using mock data');
          const mockClients = [
            { id: 'mock1', name: 'Mock Client 1' },
            { id: 'mock2', name: 'Mock Client 2' },
            { id: 'mock3', name: 'Mock Client 3' }
          ];
          setRmmClients(mockClients);
          toast.warning('Using mock TacticalRMM clients for development');
        }
      } catch (error) {
        console.error('Error loading data:', error);
        toast.error('Failed to load client or RMM data. Using mock data.');
        // Provide mock data for development
        const mockClients = [
          { id: 'mock1', name: 'Mock Client 1' },
          { id: 'mock2', name: 'Mock Client 2' },
          { id: 'mock3', name: 'Mock Client 3' }
        ];
        setRmmClients(mockClients);
      } finally {
        setLoading(false);
        setLoadingRMM(false);
      }
    };
    
    loadData();
  }, [id]);

  // Handle client selection
  const handleClientChange = (e) => {
    const clientId = e.target.value;
    setSelectedClient(clientId);
    setSelectedSite('');
    
    // Find the selected client and get its sites
    const client = rmmClients.find(c => c.id === clientId);
    if (client && client.sites) {
      setSites(client.sites);
    } else {
      setSites([]);
    }
  };

  // Handle linking client to TacticalRMM
  const handleLinkClient = async () => {
    if (!selectedClient) {
      toast.warning('Please select a TacticalRMM client');
      return;
    }
    
    setLinking(true);
    try {
      const data = {
        tacticalRMMClientId: selectedClient,
        tacticalRMMSiteId: selectedSite
      };
      
      await clientService.linkToTacticalRMM(id, data);
      toast.success('Client successfully linked to TacticalRMM!');
      navigate(`/clients/${id}`);
    } catch (error) {
      console.error('Error linking client:', error);
      toast.error('Failed to link client to TacticalRMM. Please try again.');
    } finally {
      setLinking(false);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-4xl mx-auto mt-8">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-800">
          Link {client?.name} to TacticalRMM
        </h2>
        <Link
          to={`/clients/${id}`}
          className="flex items-center text-blue-600 hover:text-blue-800"
        >
          <FaArrowLeft className="mr-2" /> Back to Client
        </Link>
      </div>

      {loadingRMM ? (
        <div className="flex justify-center py-8">
          <LoadingSpinner size="medium" />
          <span className="ml-3 text-gray-600">Loading TacticalRMM clients...</span>
        </div>
      ) : rmmClients.length === 0 ? (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
          <p className="text-yellow-700">
            No TacticalRMM clients found. Please make sure TacticalRMM is properly configured.
          </p>
          <Link to="/admin/tactical-rmm" className="text-blue-600 hover:underline mt-2 inline-block">
            Configure TacticalRMM Settings
          </Link>
        </div>
      ) : (
        <div className="space-y-6">
          {client.tacticalRMM?.clientId && (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
              <p className="text-yellow-700">
                This client is already linked to TacticalRMM. Linking again will overwrite the existing connection.
              </p>
              <p className="text-sm text-gray-600 mt-1">
                Current RMM Company: {client.tacticalRMM.companyName || 'N/A'}
              </p>
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                TacticalRMM Client
              </label>
              <select
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedClient}
                onChange={handleClientChange}
              >
                <option value="">Select a TacticalRMM client</option>
                {rmmClients.map(client => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                TacticalRMM Site (Optional)
              </label>
              <select
                className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedSite}
                onChange={(e) => setSelectedSite(e.target.value)}
                disabled={!selectedClient || sites.length === 0}
              >
                <option value="">Select a site (optional)</option>
                {sites.map(site => (
                  <option key={site.id} value={site.id}>
                    {site.name}
                  </option>
                ))}
              </select>
              {selectedClient && sites.length === 0 && (
                <p className="text-sm text-gray-500 mt-1">
                  No sites available for this client
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-end mt-8">
            <button
              type="button"
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded mr-4"
              onClick={() => navigate(`/clients/${id}`)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded flex items-center"
              onClick={handleLinkClient}
              disabled={!selectedClient || linking}
            >
              {linking ? (
                <>
                  <LoadingSpinner size="small" color="white" />
                  <span className="ml-2">Linking...</span>
                </>
              ) : (
                <>
                  <FaLink className="mr-2" /> Link to TacticalRMM
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkRMM;
