/**
 * Axios configuration for API requests
 */

import axios from 'axios';
import deviceTokenManager from './deviceTokenManager';

// Configure axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';

// Configure axios to handle both development and production environments
if (process.env.NODE_ENV === 'development') {
  // In development, use relative URLs which will be handled by the proxy
  axios.defaults.baseURL = '';
} else {
  // In production, use the full API URL
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
}

// Add a request interceptor to include device token in all requests
axios.interceptors.request.use(
  (config) => {
    // Get token from deviceTokenManager
    const token = deviceTokenManager.getToken();
    
    if (token) {
      console.log(`Adding device token to request: ${token.substring(0, 10)}...`);
      console.log('Request URL:', config.url);
      
      // Ensure headers object exists
      if (!config.headers) {
        config.headers = {};
      }
      
      // Add token to headers (case-sensitive as expected by server)
      config.headers['X-Device-Token'] = token;
      
      // For login and verification endpoints, also include in request body if it's a POST request
      if (config.method?.toLowerCase() === 'post' && 
          (config.url?.includes('/login') || config.url?.includes('/verify'))) {
        
        // Ensure data object exists
        if (!config.data) {
          config.data = {};
        } else if (typeof config.data === 'string') {
          // If data is a string (JSON), parse it first
          try {
            config.data = JSON.parse(config.data);
          } catch (e) {
            console.error('Error parsing request data:', e);
          }
        }
        
        // Add deviceToken to deviceInfo if it exists
        if (config.data.deviceInfo) {
          config.data.deviceInfo.deviceToken = token;
          console.log('Added device token to deviceInfo in request body');
        }
        
        // Convert back to string if it was a string
        if (typeof config.data === 'object' && config.headers['Content-Type'] === 'application/json') {
          config.data = JSON.stringify(config.data);
        }
      }
    } else {
      console.log('No device token available for request to:', config.url);
    }
    
    return config;
  },
  (error) => {
    console.error('Axios request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle device tokens in responses
axios.interceptors.response.use(
  (response) => {
    console.log('Processing response from:', response.config?.url);
    
    // Check for device token in response headers (case-insensitive)
    const headerNames = Object.keys(response.headers || {});
    const deviceTokenHeader = headerNames.find(h => h.toLowerCase() === 'x-device-token');
    
    if (deviceTokenHeader) {
      const headerToken = response.headers[deviceTokenHeader];
      console.log(`Device token found in response headers: ${headerToken.substring(0, 10)}...`);
      deviceTokenManager.storeToken(headerToken);
    }
    
    // Check for device token in response body
    if (response.data && response.data.deviceToken) {
      console.log(`Device token found in response body: ${response.data.deviceToken.substring(0, 10)}...`);
      deviceTokenManager.storeToken(response.data.deviceToken);
    }
    
    // Check for Set-Cookie header (case-insensitive)
    const setCookieHeaderName = headerNames.find(h => h.toLowerCase() === 'set-cookie');
    if (setCookieHeaderName) {
      console.log('Found Set-Cookie header in response');
      const setCookieValue = response.headers[setCookieHeaderName];
      console.log('Set-Cookie value:', setCookieValue);
    }
    
    // Use the enhanced extractTokenFromResponse method to check cookies
    deviceTokenManager.extractTokenFromResponse(response);
    
    // Check for device token in cookies after response is processed with a longer timeout
    setTimeout(() => {
      const cookies = document.cookie.split(';').map(cookie => cookie.trim());
      console.log('Cookies after response processing:', cookies);
      
      const token = deviceTokenManager.getToken();
      if (token) {
        console.log(`Device token available after response processing: ${token.substring(0, 10)}...`);
      } else {
        console.log('No device token found after response processing');
      }
    }, 500); // Longer timeout to ensure cookies are set
    
    return response;
  },
  (error) => {
    console.error('Axios response error:', error.message);
    if (error.response) {
      console.error('Response status:', error.response.status);
      
      // Try to extract device token even from error responses
      try {
        deviceTokenManager.extractTokenFromResponse(error.response);
      } catch (extractError) {
        console.error('Error extracting token from error response:', extractError);
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
