import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaDownload, FaSearch, FaFilter } from 'react-icons/fa';
import { useAuth } from '../../context/auth.context';

const PaymentHistory = () => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const { user } = useAuth();
  
  // Check if user is a client and redirect to portal if needed
  useEffect(() => {
    if (user && user.role === 'client') {
      navigate('/portal/billing/history');
    }
  }, [user, navigate]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Mock invoices data with more entries
  const mockInvoices = [
    {
      id: 'INV-2023-001',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-002',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-003',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-004',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 120 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-005',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 150 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-006',
      amount: 49.99,
      status: 'paid',
      date: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Professional Plan - Monthly', amount: 49.99 }
      ]
    },
    {
      id: 'INV-2023-007',
      amount: 29.99,
      status: 'paid',
      date: new Date(Date.now() - 210 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Basic Plan - Monthly', amount: 29.99 }
      ]
    },
    {
      id: 'INV-2023-008',
      amount: 29.99,
      status: 'paid',
      date: new Date(Date.now() - 240 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Basic Plan - Monthly', amount: 29.99 }
      ]
    },
    {
      id: 'INV-2023-009',
      amount: 29.99,
      status: 'paid',
      date: new Date(Date.now() - 270 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Basic Plan - Monthly', amount: 29.99 }
      ]
    },
    {
      id: 'INV-2023-010',
      amount: 0,
      status: 'paid',
      date: new Date(Date.now() - 300 * 24 * 60 * 60 * 1000).toISOString(),
      pdfUrl: '#',
      items: [
        { description: 'Free Trial', amount: 0 }
      ]
    }
  ];

  useEffect(() => {
    // In a real implementation, we would fetch invoices from an API
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        // Simulate API call
        setTimeout(() => {
          setInvoices(mockInvoices);
          setError(null);
          setLoading(false);
        }, 1000);
      } catch (err) {
        setError('Failed to load invoices. Please try again later.');
        console.error('Error fetching invoices:', err);
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setDateRange({
      ...dateRange,
      [name]: value
    });
  };

  const filteredInvoices = invoices.filter(invoice => {
    // Apply status filter
    if (filter !== 'all' && invoice.status !== filter) {
      return false;
    }
    
    // Apply search filter
    if (searchTerm && !invoice.id.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    
    // Apply date range filter
    const invoiceDate = new Date(invoice.date);
    if (dateRange.startDate && new Date(dateRange.startDate) > invoiceDate) {
      return false;
    }
    if (dateRange.endDate && new Date(dateRange.endDate) < invoiceDate) {
      return false;
    }
    
    return true;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'paid':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'overdue':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={() => navigate(user && user.role === 'client' ? '/portal/billing' : '/billing')}
          className="mr-4 text-gray-500 hover:text-gray-700"
        >
          <FaArrowLeft className="h-5 w-5" />
        </button>
        <h1 className="text-2xl font-semibold text-gray-900">Payment History</h1>
      </div>

      {/* Filters and Search */}
      <div className="bg-white shadow rounded-lg p-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <div>
            <label htmlFor="filter" className="block text-sm font-medium text-gray-700 mb-1">Status</label>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleFilterChange('all')}
                className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'all' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
              >
                All
              </button>
              <button
                onClick={() => handleFilterChange('paid')}
                className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'paid' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
              >
                Paid
              </button>
              <button
                onClick={() => handleFilterChange('pending')}
                className={`px-3 py-1 rounded-md text-sm font-medium ${filter === 'pending' ? 'bg-blue-100 text-blue-800' : 'text-gray-700 hover:bg-gray-100'}`}
              >
                Pending
              </button>
            </div>
          </div>
          
          <div>
            <label htmlFor="dateRange" className="block text-sm font-medium text-gray-700 mb-1">Date Range</label>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <input
                  type="date"
                  name="startDate"
                  value={dateRange.startDate}
                  onChange={handleDateRangeChange}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div>
                <input
                  type="date"
                  name="endDate"
                  value={dateRange.endDate}
                  onChange={handleDateRangeChange}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
          
          <div>
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">Search</label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Search by invoice number"
                value={searchTerm}
                onChange={handleSearch}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Invoices Table */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice) => (
                  <tr key={invoice.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {invoice.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(invoice.date)}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {invoice.items.map((item, index) => (
                        <div key={index}>{item.description}</div>
                      ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatCurrency(invoice.amount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getStatusColor(invoice.status)}`}>
                        {invoice.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        href={invoice.pdfUrl}
                        className="text-blue-600 hover:text-blue-900 inline-flex items-center"
                      >
                        <FaDownload className="mr-1 h-4 w-4" />
                        PDF
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="px-6 py-4 text-center text-sm text-gray-500">
                    No invoices found matching your criteria
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
