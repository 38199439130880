import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCreditCard, FaArrowLeft } from 'react-icons/fa';
import billingService from '../../services/billing.service';

const PortalStripeCheckout = () => {
  const [selectedPlan, setSelectedPlan] = useState('basic');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    // Check for success or cancel query params
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const minutes = queryParams.get('minutes');
    
    if (sessionId) {
      setSuccess(true);
    }
    
    // If minutes parameter is present, automatically process additional minutes purchase
    if (minutes && !sessionId) {
      handleAdditionalMinutes(parseInt(minutes, 10));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
  // We're intentionally not including handleAdditionalMinutes in the dependency array to avoid infinite loops
  
  const plans = [
    {
      id: 'basic',
      name: 'Basic Support',
      price: '£29.99',
      features: [
        '30 minutes of support per month',
        'Email support',
        'Phone support during business hours',
        'Remote assistance',
      ],
    },
    {
      id: 'standard',
      name: 'Standard Support',
      price: '£49.99',
      features: [
        '60 minutes of support per month',
        'Email support',
        'Phone support during business hours',
        'Remote assistance',
        'Priority response',
      ],
    },
    {
      id: 'premium',
      name: 'Premium Support',
      price: '£99.99',
      features: [
        '120 minutes of support per month',
        'Email support',
        'Phone support 24/7',
        'Remote assistance',
        'Priority response',
        'Dedicated support agent',
      ],
    },
  ];
  
  const handlePlanSelect = (planId) => {
    setSelectedPlan(planId);
  };
  
  const handleCheckout = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Create a checkout session for the selected plan
      const response = await billingService.createCheckoutSession(selectedPlan);
      
      if (response.status === 'success' && response.data && response.data.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        setError('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Checkout error:', err);
      setError(err.response?.data?.message || 'An error occurred during checkout');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAdditionalMinutes = async (minutes) => {
    setLoading(true);
    setError(null);
    
    try {
      // Create a checkout session for additional minutes
      const response = await billingService.purchaseAdditionalMinutes(minutes);
      
      if (response.status === 'success' && response.data && response.data.url) {
        // Redirect to Stripe Checkout
        window.location.href = response.data.url;
      } else {
        setError('Failed to create checkout session for additional minutes');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred during checkout');
    } finally {
      setLoading(false);
    }
  };
  
  if (success) {
    return (
      <div className="bg-white shadow rounded-lg p-6 text-center">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
          <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">Payment Successful!</h3>
        <p className="text-gray-500 mb-4">Your payment has been processed successfully.</p>
        <button
          onClick={() => navigate('/portal/billing')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Return to Billing
        </button>
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Choose Your Support Plan</h1>
        <button
          onClick={() => navigate('/portal/billing')}
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <FaArrowLeft className="-ml-0.5 mr-2 h-4 w-4" />
          Back
        </button>
      </div>
      
      {error && (
        <div className="bg-red-50 text-red-800 p-4 rounded-md">{error}</div>
      )}
      
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {plans.map((plan) => (
          <div 
            key={plan.id}
            className={`border rounded-lg overflow-hidden ${selectedPlan === plan.id ? 'ring-2 ring-blue-500' : ''}`}
          >
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900">{plan.name}</h2>
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl font-extrabold tracking-tight">{plan.price}</span>
                <span className="ml-1 text-xl font-semibold">/month</span>
              </p>
              <ul className="mt-6 space-y-4">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex">
                    <svg className="flex-shrink-0 h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-8">
                <button
                  type="button"
                  className={`w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm ${selectedPlan === plan.id ? 'text-white bg-blue-600 hover:bg-blue-700' : 'text-blue-700 bg-blue-100 hover:bg-blue-200'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  onClick={() => handlePlanSelect(plan.id)}
                >
                  {selectedPlan === plan.id ? 'Selected' : 'Select Plan'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="flex justify-end">
        <button
          onClick={handleCheckout}
          disabled={loading}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {loading ? (
            <>
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </>
          ) : (
            <>
              <FaCreditCard className="-ml-1 mr-2 h-5 w-5" />
              Proceed to Checkout
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default PortalStripeCheckout;
