import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';

/**
 * Component that redirects client users from /billing/* routes to /portal/billing/* routes
 * This ensures that client users can only access billing features through the portal
 */
const ClientBillingRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    // Only redirect if the user is a client
    if (user && user.role === 'client') {
      // Get the current path and replace /billing with /portal/billing
      const currentPath = location.pathname;
      const portalPath = currentPath.replace('/billing', '/portal/billing');
      
      // Preserve any query parameters
      const queryParams = location.search;
      
      // Redirect to the portal path
      navigate(`${portalPath}${queryParams}`);
    }
  }, [location, navigate, user]);

  // This component doesn't render anything, it just redirects
  return null;
};

export default ClientBillingRedirect;
