import axios from 'axios';

const API_URL = '/api/portal/passwords';

/**
 * Service for client portal password management
 */
class PortalPasswordService {
  /**
   * Verify a client ID
   * @param {string} clientId - The client ID to verify
   * @returns {Promise} - Promise with verification result
   */
  verifyClientId(clientId) {
    return axios.post(`${API_URL}/verify-client`, { clientId });
  }

  /**
   * Verify a TOTP code for password access
   * @param {string} clientId - The client ID
   * @param {string} totpCode - The TOTP code
   * @returns {Promise} - Promise with verification result and token
   */
  verifyTotpCode(clientId, totpCode) {
    return axios.post(`${API_URL}/verify-totp`, { clientId, totpCode });
  }

  /**
   * Get passwords for a client
   * @param {string} clientId - The client ID
   * @param {string} token - The password access token
   * @returns {Promise} - Promise with passwords data
   */
  getClientPasswords(clientId, token) {
    return axios.get(`${API_URL}/${clientId}`, {
      headers: {
        'X-Password-Token': token
      }
    });
  }

  /**
   * Get a specific password
   * @param {string} clientId - The client ID
   * @param {string} passwordId - The password ID
   * @param {string} token - The password access token
   * @returns {Promise} - Promise with password data
   */
  getPassword(clientId, passwordId, token) {
    return axios.get(`${API_URL}/${clientId}/${passwordId}`, {
      headers: {
        'X-Password-Token': token
      }
    });
  }

  /**
   * Revoke a password after viewing (client portal only)
   * @param {string} clientId - The client ID
   * @param {string} passwordId - The password ID
   * @param {string} token - The password access token
   * @returns {Promise} - Promise with revocation result
   */
  revokePassword(clientId, passwordId, token) {
    return axios.put(`${API_URL}/${clientId}/${passwordId}/revoke`, {}, {
      headers: {
        'X-Password-Token': token
      }
    });
  }
}

export default new PortalPasswordService();
