import axios from 'axios';
import authHeader from '../utils/auth-header';

const API_URL = '/api/devices';

/**
 * Normalize a TacticalRMM API URL to handle standard ports correctly
 * @param {string} url - The URL to normalize
 * @returns {string} - The normalized URL
 */
const normalizeApiUrl = (url) => {
  if (!url) return url;
  
  try {
    // First, trim any whitespace
    url = url.trim();
    
    // Ensure URL has a protocol
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
      console.log('Added https:// protocol to URL:', url);
    }
    
    const parsedUrl = new URL(url);
    
    // Remove explicit port specifications if they're the standard ports
    if (parsedUrl.protocol === 'http:' && parsedUrl.port === '80') {
      parsedUrl.port = ''; // Standard HTTP port, remove it from URL
    } else if (parsedUrl.protocol === 'https:' && parsedUrl.port === '443') {
      parsedUrl.port = ''; // Standard HTTPS port, remove it from URL
    }
    
    // Remove trailing slash if present
    let normalizedUrl = parsedUrl.toString();
    if (normalizedUrl.endsWith('/')) {
      normalizedUrl = normalizedUrl.slice(0, -1);
      console.log('Removed trailing slash from URL:', normalizedUrl);
    }
    
    console.log('Normalized URL:', normalizedUrl);
    return normalizedUrl;
  } catch (error) {
    console.error('Invalid URL format:', error);
    return url; // Return original URL if parsing fails
  }
};

/**
 * Service for device management and TacticalRMM integration
 */
class DeviceService {
  /**
   * Get all devices for the current user
   * @returns {Promise} - Promise with devices data
   */
  getMyDevices() {
    return axios.get(`${API_URL}/my-devices`, { headers: authHeader() });
  }
  
  /**
   * Get all devices (alias for getMyDevices)
   * @returns {Promise} - Promise with devices data
   */
  getDevices() {
    return this.getMyDevices();
  }

  /**
   * Get a specific device by ID
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with device data
   */
  getDevice(id) {
    return axios.get(`${API_URL}/${id}`, { headers: authHeader() });
  }

  /**
   * Sync devices with TacticalRMM
   * @returns {Promise} - Promise with sync results
   */
  syncDevices() {
    // Always use the authHeader utility to get the authentication headers
    // This ensures we're consistently handling authentication across the application
    const headers = authHeader();
    
    // Log detailed information for debugging
    console.log('Syncing devices with auth headers present:', !!headers.Authorization);
    
    // If we don't have authorization headers, log a warning
    if (!headers.Authorization) {
      console.warn('No authorization headers available for sync operation. User may need to log in again.');
    }
    
    // Make the API request with proper error handling
    return axios.post(`${API_URL}/sync`, {}, { headers })
      .catch(error => {
        // Log detailed error information
        console.error('Error during device sync:', error.message);
        if (error.response) {
          console.error('Response status:', error.response.status);
          console.error('Response data:', error.response.data);
          
          // If we get a 401 or 403 error, the token might be invalid or expired
          if (error.response.status === 401 || error.response.status === 403) {
            console.error('Authentication error during sync. Token may be invalid or expired.');
          }
        }
        
        // Re-throw the error to be handled by the calling code
        throw error;
      });
  }

  /**
   * Sync devices with TacticalRMM with advanced options
   * @param {Object} options - Sync options
   * @param {boolean} options.includeOffline - Whether to include offline devices
   * @param {boolean} options.forceUpdate - Whether to force update all devices
   * @param {boolean} options.syncAllClients - Whether to sync all clients
   * @param {Array} options.selectedClients - Array of client IDs to sync
   * @returns {Promise} - Promise with sync results
   */
  syncDevicesAdvanced(options) {
    return axios.post(`${API_URL}/sync/advanced`, options, { headers: authHeader() });
  }

  /**
   * Start a remote session with a device
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with session data
   */
  startRemoteSession(id) {
    return axios.post(`${API_URL}/${id}/remote-session`, {}, { headers: authHeader() });
  }

  /**
   * Send a command to a device
   * @param {string} id - Device ID
   * @param {string} command - Command to execute
   * @param {string} shell - Shell to use (cmd, powershell, bash)
   * @param {number} timeout - Command timeout in seconds
   * @returns {Promise} - Promise with command result
   */
  sendCommand(id, command, shell = 'cmd', timeout = 30) {
    return axios.post(
      `${API_URL}/${id}/command`,
      { command, shell, timeout },
      { headers: authHeader() }
    );
  }

  /**
   * Get all devices (admin only)
   * @param {Object} params - Query parameters for filtering and pagination
   * @returns {Promise} - Promise with devices data
   */
  getAllDevices(params = {}) {
    return axios.get(API_URL, {
      headers: authHeader(),
      params,
    });
  }

  /**
   * Sync all client devices (admin only)
   * @returns {Promise} - Promise with sync results
   */
  syncAllDevices() {
    return axios.post(`${API_URL}/sync-all`, {}, { headers: authHeader() });
  }

  /**
   * Get TacticalRMM settings
   * @returns {Promise} - Promise with TacticalRMM settings
   */
  getTacticalRMMSettings() {
    return axios.get(`${API_URL}/tactical-rmm/settings`, { headers: authHeader() });
  }

  /**
   * Update TacticalRMM settings
   * @param {Object} settings - TacticalRMM settings
   * @returns {Promise} - Promise with updated TacticalRMM settings
   */
  updateTacticalRMMSettings(settings) {
    // Normalize the API URL if present
    const normalizedSettings = {
      ...settings,
      apiUrl: settings.apiUrl ? normalizeApiUrl(settings.apiUrl) : settings.apiUrl
    };
    
    // Log the settings being sent (no masking for personal use)
    console.log('Sending TacticalRMM settings update:', normalizedSettings);
    
    return axios.put(`${API_URL}/tactical-rmm/settings`, normalizedSettings, { headers: authHeader() });
  }

  /**
   * Test TacticalRMM connection
   * @param {Object} settings - TacticalRMM connection settings
   * @returns {Promise} - Promise with connection test results
   */
  testTacticalRMMConnection(settings) {
    // Normalize the API URL if present
    const normalizedSettings = {
      ...settings,
      apiUrl: settings.apiUrl ? normalizeApiUrl(settings.apiUrl) : settings.apiUrl
    };
    
    // Don't use authHeader for test connection to allow testing without authentication
    // This is safe because we're only testing the connection, not accessing any sensitive data
    return axios.post(`${API_URL}/tactical-rmm/test-connection`, normalizedSettings);
  }

  /**
   * Get all clients from TacticalRMM
   * @returns {Promise} - Promise with clients data
   */
  getTacticalRMMClients() {
    return axios.get(`${API_URL}/tactical-rmm/clients`, { headers: authHeader() });
  }

  /**
   * Clear all TacticalRMM device data
   * @returns {Promise} - Promise with operation result
   */
  clearTacticalRMMDevices() {
    return axios.delete(`${API_URL}/tactical-rmm/devices`, { headers: authHeader() });
  }

  /**
   * Reset TacticalRMM integration
   * @returns {Promise} - Promise with operation result
   */
  resetTacticalRMMIntegration() {
    return axios.post(`${API_URL}/tactical-rmm/reset`, {}, { headers: authHeader() });
  }

  /**
   * Get device statistics (admin only)
   * @returns {Promise} - Promise with device statistics
   */
  getDeviceStats() {
    return axios.get(`${API_URL}/stats/summary`, { headers: authHeader() });
  }

  /**
   * Sync a specific device with TacticalRMM
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with sync results
   */
  syncDevice(id) {
    return axios.post(`${API_URL}/${id}/sync`, {}, { headers: authHeader() });
  }

  /**
   * Reboot a device
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with reboot result
   */
  rebootDevice(id) {
    return axios.post(`${API_URL}/${id}/reboot`, {}, { headers: authHeader() });
  }

  /**
   * Get available scripts for a device
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with available scripts
   */
  getAvailableScripts(id) {
    return axios.get(`${API_URL}/${id}/scripts`, { headers: authHeader() });
  }

  /**
   * Run a script on a device
   * @param {string} id - Device ID
   * @param {string} scriptId - Script ID to run
   * @param {Object} params - Optional parameters for the script
   * @returns {Promise} - Promise with script execution result
   */
  runScript(id, scriptId, params = {}) {
    return axios.post(
      `${API_URL}/${id}/scripts/${scriptId}/run`,
      { params },
      { headers: authHeader() }
    );
  }

  /**
   * Sync devices from TacticalRMM for a specific client
   * @param {string} clientId - Client ID to sync devices for
   * @returns {Promise} - Promise with sync results
   */
  syncDevicesFromRMM(clientId) {
    return axios.post(
      `/api/clients/${clientId}/sync-devices`,
      {},
      { headers: authHeader() }
    );
  }

  /**
   * Install ESET on a device
   * @param {string} id - Device ID
   * @returns {Promise} - Promise with installation result
   */
  installEset(id) {
    return axios.post(`${API_URL}/${id}/install-eset`, {}, { headers: authHeader() });
  }
}

export default new DeviceService();
