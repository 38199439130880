/**
 * Authentication header utility
 * Returns the Authorization header with JWT token for API requests
 */
export default function authHeader() {
  // First try to get token directly from localStorage (preferred method)
  const token = localStorage.getItem('token');
  const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEV_MODE === 'true';
  
  if (token) {
    // Check if it's a development token (starts with 'dev-token-')
    const isDevToken = token.startsWith('dev-token-');
    
    // In production, validate token format - basic check that it's a string with reasonable length
    // In development, allow dev tokens
    if (!isDev && !isDevToken && (typeof token !== 'string' || token.length < 10)) {
      console.error('Invalid token format in localStorage');
      // Clear invalid token
      localStorage.removeItem('token');
      return {};
    }
    
    // If in development mode and using a dev token, use a special header format
    // that the server will recognize as a development token
    if (isDev && isDevToken) {
      console.log('Using development auth token');
      return { 'X-Dev-Auth': token, 'Authorization': `Bearer ${token}` };
    }
    
    // Log the token for debugging (only show first few characters)
    const maskedToken = token.substring(0, 10) + '...' + token.substring(token.length - 5);
    console.log('Using auth token:', maskedToken);
    
    // Ensure proper Bearer format
    return { 
      'Authorization': `Bearer ${token}`,
      'x-access-token': token  // Add this for backward compatibility
    };
  }
  
  // Fallback: Check if token is in user object (legacy method)
  const userStr = localStorage.getItem('user');
  if (userStr) {
    try {
      const user = JSON.parse(userStr);
      if (user && user.token) {
        console.log('Using legacy token from user object');
        return { Authorization: `Bearer ${user.token}` };
      }
    } catch (e) {
      console.error('Error parsing user from localStorage:', e);
      // Clear corrupted user data
      localStorage.removeItem('user');
    }
  }
  
  console.log('No authentication token found in localStorage');
  return {};
}
