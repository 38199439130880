import React, { useState, useEffect } from 'react';
import { 
  FaUsersCog, 
  FaSync, 
  FaPlus, 
  FaEdit, 
  FaTrash, 
  FaSearch,
  FaBuilding,
  FaServer,
  FaExclamationTriangle,
  FaCheck
} from 'react-icons/fa';
import { toast } from 'react-toastify';

// Import the real client service
import clientService from '../../services/client.service';

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');

  // Listen for changes to dark mode from the Layout component
  useEffect(() => {
    const handleDarkModeChange = (event) => {
      setDarkMode(event.detail.darkMode);
    };
    
    document.addEventListener('darkModeChange', handleDarkModeChange);
    
    return () => {
      document.removeEventListener('darkModeChange', handleDarkModeChange);
    };
  }, []);

  // Load clients on component mount
  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = async () => {
    setLoading(true);
    try {
      const data = await clientService.getClients();
      setClients(data);
    } catch (error) {
      console.error('Error loading clients:', error);
      toast.error('Failed to load clients');
    } finally {
      setLoading(false);
    }
  };

  const handleSync = async () => {
    setSyncing(true);
    try {
      const result = await clientService.syncWithTacticalRMM();
      if (result.success) {
        toast.success(result.message);
        loadClients(); // Reload clients after sync
      } else {
        toast.error(result.message || 'Sync failed');
      }
    } catch (error) {
      console.error('Error syncing with TacticalRMM:', error);
      toast.error('Failed to sync with TacticalRMM');
    } finally {
      setSyncing(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter(client =>
    client.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectClient = (client) => {
    setSelectedClient(client);
  };

  const handleAddClient = () => {
    setShowAddModal(true);
  };

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setShowEditModal(true);
  };

  const handleDeleteClient = (client) => {
    setSelectedClient(client);
    setShowDeleteModal(true);
  };

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-800'}`}>
      <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="mb-4 md:mb-0">
          <h1 className="text-2xl font-bold flex items-center">
            <FaUsersCog className="mr-2" /> Client Management
          </h1>
          <p className="mt-1 text-sm opacity-75">
            Manage clients synchronized with TacticalRMM
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-3">
          <button
            onClick={handleSync}
            disabled={syncing}
            className={`flex items-center justify-center px-4 py-2 rounded-md ${darkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-blue-600 hover:bg-blue-700'} text-white transition-colors duration-200`}
          >
            {syncing ? (
              <>
                <FaSync className="mr-2 animate-spin" /> Syncing...
              </>
            ) : (
              <>
                <FaSync className="mr-2" /> Sync with TacticalRMM
              </>
            )}
          </button>
          <button
            onClick={handleAddClient}
            className={`flex items-center justify-center px-4 py-2 rounded-md ${darkMode ? 'bg-green-700 hover:bg-green-600' : 'bg-green-600 hover:bg-green-700'} text-white transition-colors duration-200`}
          >
            <FaPlus className="mr-2" /> Add Client
          </button>
        </div>
      </div>

      <div className={`p-4 rounded-lg mb-6 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-500" />
          </div>
          <input
            type="text"
            placeholder="Search clients..."
            className={`pl-10 pr-4 py-2 w-full rounded-md ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'} border focus:outline-none focus:ring-2 focus:ring-blue-500`}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-12">
          <FaSync className="animate-spin text-3xl mr-3" />
          <span className="text-xl">Loading clients...</span>
        </div>
      ) : (
        <div className={`overflow-x-auto rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} shadow`}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className={darkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Client Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Sites
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Devices
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                  TOTP Setup
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {filteredClients.length === 0 ? (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center">
                    <div className="flex flex-col items-center justify-center py-6">
                      <FaExclamationTriangle className="text-3xl mb-2 text-yellow-500" />
                      <p>No clients found matching your search criteria.</p>
                    </div>
                  </td>
                </tr>
              ) : (
                filteredClients.map((client) => (
                  <tr 
                    key={client.id} 
                    className={`${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} cursor-pointer transition-colors duration-150`}
                    onClick={() => handleSelectClient(client)}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className={`flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center ${darkMode ? 'bg-gray-600' : 'bg-blue-100'}`}>
                          <FaBuilding className={darkMode ? 'text-blue-400' : 'text-blue-600'} />
                        </div>
                        <div className="ml-4">
                          <div className="font-medium">{client.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaServer className="mr-2" />
                        <span>{client.siteCount}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {client.deviceCount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${client.status === 'active' ? (darkMode ? 'bg-green-800 text-green-100' : 'bg-green-100 text-green-800') : (darkMode ? 'bg-red-800 text-red-100' : 'bg-red-100 text-red-800')}`}>
                        {client.status === 'active' ? (
                          <>
                            <FaCheck className="mr-1" /> Active
                          </>
                        ) : (
                          <>
                            <FaExclamationTriangle className="mr-1" /> Inactive
                          </>
                        )}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${client.passwordAccess?.registered ? (darkMode ? 'bg-green-800 text-green-100' : 'bg-green-100 text-green-800') : (darkMode ? 'bg-yellow-800 text-yellow-100' : 'bg-yellow-100 text-yellow-800')}`}>
                        {client.passwordAccess?.registered ? (
                          <>
                            <FaCheck className="mr-1" /> Registered
                          </>
                        ) : (
                          <>
                            <FaExclamationTriangle className="mr-1" /> Not Registered
                          </>
                        )}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClient(client);
                        }}
                        className={`inline-flex items-center px-3 py-1 rounded-md mr-2 ${darkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-blue-100 hover:bg-blue-200 text-blue-800'}`}
                      >
                        <FaEdit className="mr-1" /> Edit
                      </button>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClient(client);
                        }}
                        className={`inline-flex items-center px-3 py-1 rounded-md ${darkMode ? 'bg-red-700 hover:bg-red-600' : 'bg-red-100 hover:bg-red-200 text-red-800'}`}
                      >
                        <FaTrash className="mr-1" /> Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* This would be expanded to include actual modals for adding, editing, and deleting clients */}
      {/* For now, just placeholders */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`p-6 rounded-lg shadow-lg max-w-md w-full ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <h2 className="text-xl font-bold mb-4">Add New Client</h2>
            <p>This would be a form to add a new client that syncs with TacticalRMM.</p>
            <div className="mt-4 flex justify-end">
              <button 
                onClick={() => setShowAddModal(false)}
                className={`px-4 py-2 rounded-md ${darkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'} mr-2`}
              >
                Cancel
              </button>
              <button 
                className={`px-4 py-2 rounded-md ${darkMode ? 'bg-blue-700 hover:bg-blue-600' : 'bg-blue-600 hover:bg-blue-700'} text-white`}
              >
                Add Client
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientManagement;
