import axios from 'axios';
import authHeader from './auth-header';
import passwordSystemConfig from '../config/password-system.config';

const API_URL = '/api';

class PasswordService {
  /**
   * Authenticate a client with their ID
   * @param {string} clientId - The client's unique identifier
   * @returns {Promise} - The response from the API
   */
  authenticateClient(clientId) {
    return axios.post(`${API_URL}/clients/authenticate`, { clientId });
  }

  /**
   * Verify a TOTP code for a client
   * @param {string} clientId - The client's unique identifier
   * @param {string} totpCode - The 6-digit TOTP code
   * @returns {Promise} - The response from the API
   */
  verifyTotpCode(clientId, totpCode) {
    return axios.post(`${API_URL}/clients/verify-totp`, { clientId, totpCode });
  }

  /**
   * Get all passwords for a client
   * @param {string} clientId - The client's unique identifier
   * @param {string} token - The JWT token from successful authentication
   * @returns {Promise} - The response from the API
   */
  getPasswords(clientId, token) {
    return axios.get(`${API_URL}/passwords`, {
      headers: { ...authHeader(), 'X-Password-Token': token },
      params: { clientId }
    });
  }
  
  /**
   * Get all passwords for a client (admin function)
   * @param {string} clientId - The client's unique identifier
   * @returns {Promise} - The response from the API
   */
  getClientPasswords(clientId) {
    // Get the token directly to ensure it's included
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required'));
    }
    
    return axios.get(`${API_URL}/passwords`, {
      headers: {
        ...authHeader(),
        'Authorization': `Bearer ${token}`,
        'x-access-token': token
      },
      params: { clientId }
    });
  }
  
  /**
   * Create a new password for a client (admin function)
   * @param {Object} passwordData - The password data
   * @returns {Promise} - The response from the API
   */
  createPassword(passwordData) {
    // Get the token directly to ensure it's included
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required'));
    }
    
    console.log('Creating password with data:', passwordData);
    console.log('Using API URL:', `${API_URL}/passwords`);
    
    return axios.post(`${API_URL}/passwords`, passwordData, {
      headers: {
        ...authHeader(),
        'Authorization': `Bearer ${token}`,
        'x-access-token': token
      }
    });
  }
  
  /**
   * Revoke a password (admin function)
   * @param {string} passwordId - The password's unique identifier
   * @returns {Promise} - The response from the API
   */
  revokePassword(passwordId) {
    // Get the token directly to ensure it's included
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required'));
    }
    
    return axios.put(`${API_URL}/passwords/${passwordId}/revoke`, {}, {
      headers: {
        ...authHeader(),
        'Authorization': `Bearer ${token}`,
        'x-access-token': token
      }
    });
  }
  
  /**
   * Generate TOTP setup for a client (admin function)
   * @param {string} clientId - The client's unique identifier
   * @returns {Promise} - The response from the API with QR code and secret
   */
  generateTotpSetup(clientId) {
    // Get the token directly to ensure it's included
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required'));
    }
    
    return axios.get(`/api/clients/${clientId}/totp-setup`, {
      headers: {
        ...authHeader(),
        'Authorization': `Bearer ${token}`,
        'x-access-token': token
      }
    });
  }

  /**
   * Get a specific password
   * @param {string} passwordId - The password's unique identifier
   * @param {string} token - The JWT token from successful authentication
   * @returns {Promise} - The response from the API
   */
  getPassword(passwordId, token) {
    return axios.get(`${API_URL}/passwords/${passwordId}`, {
      headers: { ...authHeader(), 'X-Password-Token': token }
    });
  }

  /**
   * Get a specific password (admin function)
   * @param {string} passwordId - The password's unique identifier
   * @returns {Promise} - The response from the API
   */
  getPassword(passwordId) {
    // Get the token directly to ensure it's included
    const token = localStorage.getItem('token');
    if (!token) {
      return Promise.reject(new Error('Authentication required'));
    }
    
    // Use the admin-specific endpoint that doesn't require a password token
    return axios.get(`${API_URL}/passwords/${passwordId}/admin`, {
      headers: {
        ...authHeader(),
        'Authorization': `Bearer ${token}`,
        'x-access-token': token
      }
    });
  }

  /**
   * Record a password access event
   * @param {string} passwordId - The password's unique identifier
   * @param {string} token - The JWT token from successful authentication
   * @returns {Promise} - The response from the API
   */
  recordAccess(passwordId, token) {
    return axios.post(`${API_URL}/passwords/${passwordId}/access`, {}, {
      headers: { ...authHeader(), 'X-Password-Token': token }
    });
  }


}

export default new PasswordService();
