import React, { useState, useEffect } from 'react';
import { 
  FaSync, 
  FaServer, 
  FaExclamationTriangle, 
  FaCheck, 
  FaInfoCircle, 
  FaCog, 
  FaLink, 
  FaUnlink, 
  FaTrash, 
  FaRedo, 
  FaClock, 
  FaShieldAlt,
  FaDesktop
} from 'react-icons/fa';
import deviceService from '../../services/device.service';
import { toast } from 'react-toastify';

const TacticalRMMSettings = () => {
  const [settings, setSettings] = useState({
    apiUrl: '',
    apiKey: '',
    companyName: '',
    enabled: false,
    autoSync: true,
    syncInterval: 30, // minutes
    lastSync: null,
    status: 'disconnected' // connected, disconnected, error
  });
  const [loading, setLoading] = useState(true);
  const [testingConnection, setTestingConnection] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [deviceCount, setDeviceCount] = useState(0);
  const [showClearConfirm, setShowClearConfirm] = useState(false);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  // Get dark mode state from localStorage (controlled by Layout component)
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');

  // Listen for changes to dark mode from the Layout component
  useEffect(() => {
    // Handle the custom darkModeChange event
    const handleDarkModeChange = (event) => {
      setDarkMode(event.detail.darkMode);
    };
    
    // Handle storage changes (for when dark mode is toggled in another tab)
    const handleStorageChange = (event) => {
      if (event.key === 'tacticalRmmDarkMode') {
        setDarkMode(event.newValue === 'true');
      }
    };
    
    // Add event listeners
    document.addEventListener('darkModeChange', handleDarkModeChange);
    window.addEventListener('storage', handleStorageChange);
    
    // Clean up event listeners
    return () => {
      document.removeEventListener('darkModeChange', handleDarkModeChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    console.log('TacticalRMMSettings component loaded - TEST MESSAGE');
    const fetchSettings = async () => {
      setLoading(true);
      
      // Check for authentication before proceeding (try both methods)
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if ((!token) && (!user || !user.token)) {
        console.log('No authentication token found. Switching to demo mode.');
        setDemoMode(true);
        setError('Authentication error: Please log in to access TacticalRMM settings. Currently showing demo mode.');
        setSettings({
          apiUrl: 'https://rmm.example.com',
          apiKey: '••••••••••••••••••••••••••••••',
          companyName: 'Acme IT Solutions',
          enabled: true,
          autoSync: true,
          syncInterval: 30,
          lastSync: new Date().toISOString(),
          status: 'connected'
        });
        setDeviceCount(12); // Demo device count
        setLoading(false);
        return;
      }
      
      try {
        const response = await deviceService.getTacticalRMMSettings();
        console.log('Settings response:', response.data);
        
        // Check for success based on the server response format
        // Server returns { status: 'success', data: { settings: {...} } }
        if (response.data && 
            ((response.data.status === 'success' || response.data.success) && 
             response.data.data && response.data.data.settings)) {
          
          const { settings: fetchedSettings } = response.data.data;
          console.log('Fetched settings:', fetchedSettings);
          
          setSettings({
            apiUrl: fetchedSettings.apiUrl || '',
            apiKey: fetchedSettings.apiKey || '', // No masking - show the actual API key
            companyName: fetchedSettings.companyName || '',
            enabled: fetchedSettings.integrationEnabled || false,
            autoSync: fetchedSettings.autoSync !== false, // Default to true if not set
            syncInterval: fetchedSettings.syncInterval || 30,
            lastSync: fetchedSettings.lastSync || null,
            status: fetchedSettings.status || (fetchedSettings.integrationEnabled ? 'connected' : 'disconnected')
          });
          setError(null);
          console.log('Settings loaded successfully');
          
          // If we have an API URL and key, consider it connected
          if (fetchedSettings.apiUrl && fetchedSettings.apiKey) {
            setDemoMode(false);
          }
          
          // Fetch device count if integration is enabled
          if (fetchedSettings.integrationEnabled) {
            try {
              const devicesResponse = await deviceService.getDevices();
              if (devicesResponse.data && devicesResponse.data.data) {
                setDeviceCount(devicesResponse.data.data.length || 0);
              }
            } catch (devErr) {
              console.error('Error fetching device count:', devErr);
              // Don't set an error for this, as it's not critical
              setDeviceCount(0);
            }
          }
        } else {
          // Handle unexpected response format
          console.warn('Unexpected response format:', response);
          setDemoMode(true);
          setSettings({
            apiUrl: 'https://rmm.example.com',
            apiKey: '••••••••••••••••••••••••••••••',
            companyName: 'Acme IT Solutions',
            enabled: true,
            autoSync: true,
            syncInterval: 30,
            lastSync: new Date().toISOString(),
            status: 'connected'
          });
          setDeviceCount(12); // Demo device count
        }
      } catch (err) {
        console.error('Error fetching TacticalRMM settings:', err);
        // If we get a 401 Unauthorized error, it means the user is not authenticated
        // or the token is invalid. In this case, we'll show a demo mode
        if (err.response && err.response.status === 401) {
          console.log('Authentication error detected. Switching to demo mode.');
          setDemoMode(true);
          setSettings({
            apiUrl: 'https://rmm.example.com',
            apiKey: '••••••••••••••••••••••••••••••',
            companyName: 'Acme IT Solutions',
            enabled: true,
            autoSync: true,
            syncInterval: 30,
            lastSync: new Date().toISOString(),
            status: 'connected'
          });
          setDeviceCount(12); // Demo device count
          setError('Authentication error: Please log in to access TacticalRMM settings. Currently showing demo mode.');
        } else {
          // Fallback to default settings if API is not available
          setSettings({
            apiUrl: '',
            apiKey: '',
            companyName: '',
            enabled: false,
            autoSync: true,
            syncInterval: 30,
            lastSync: null,
            status: 'disconnected'
          });
          setDeviceCount(0);
          setError('Failed to load TacticalRMM settings: ' + (err.message || 'Unknown error'));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleTestConnection = async () => {
    setTestingConnection(true);
    setTestResult(null);
    try {
      // Ensure the API URL uses standard ports (80/443)
      let apiUrl = settings.apiUrl;
      let portNormalized = false;
      
      if (apiUrl) {
        try {
          // Add protocol if missing
          if (!apiUrl.startsWith('http://') && !apiUrl.startsWith('https://')) {
            apiUrl = 'https://' + apiUrl;
            console.log('Added https protocol to API URL:', apiUrl);
          }
          
          // Parse the URL to normalize it
          const url = new URL(apiUrl);
          
          // Check for TacticalRMM's non-standard ports and normalize to standard ports
          if (url.port === '9080') {
            url.port = '80'; // Use standard HTTP port
            portNormalized = true;
            console.log('Normalized port from 9080 to 80 (nginx mapped to internal 8080)');
          } else if (url.port === '9443') {
            url.port = '443'; // Use standard HTTPS port
            portNormalized = true;
            console.log('Normalized port from 9443 to 443 (nginx mapped to internal 4443)');
          }
          
          // Remove explicit port specifications if they're the standard ports
          if (url.protocol === 'http:' && url.port === '80') {
            url.port = ''; // Standard HTTP port, remove it from URL
          } else if (url.protocol === 'https:' && url.port === '443') {
            url.port = ''; // Standard HTTPS port, remove it from URL
          }
          
          // Remove trailing slash to avoid double slashes
          let normalizedUrl = url.toString();
          if (normalizedUrl.endsWith('/')) {
            normalizedUrl = normalizedUrl.slice(0, -1);
          }
          
          // Use the normalized URL
          apiUrl = normalizedUrl;
          console.log('Using normalized API URL for connection test:', apiUrl);
        } catch (urlError) {
          console.error('Invalid URL format:', urlError);
          setTestResult({
            success: false,
            message: `Invalid URL format: ${urlError.message}. Please enter a valid URL.`
          });
          toast.error('Invalid URL format');
          setTestingConnection(false);
          return;
        }
      } else {
        setTestResult({
          success: false,
          message: 'API URL is required. Please enter a valid TacticalRMM API URL.'
        });
        toast.error('API URL required');
        setTestingConnection(false);
        return;
      }
      
      // Check if API key is provided
      if (!settings.apiKey) {
        setTestResult({
          success: false,
          message: 'API Key is required. Please enter your TacticalRMM API key.'
        });
        toast.error('API Key required');
        setTestingConnection(false);
        return;
      }
      
      const response = await deviceService.testTacticalRMMConnection({
        apiUrl: apiUrl, // Use the normalized URL
        apiKey: settings.apiKey
      });
      
      console.log('Test connection response:', response.data);
      
      // Check for success in either format (response.data.success or response.data.status === 'success')
      if (response.data && (response.data.success || response.data.status === 'success')) {
        let successMessage = response.data.message || 'Connection successful. TacticalRMM API is accessible.';
        
        // Add a note about port normalization if it occurred
        if (portNormalized) {
          successMessage += ' Note: Your URL was automatically adjusted to use standard ports (80/443) instead of TacticalRMM default ports (9080/9443).';
        }
        
        setTestResult({
          success: true,
          message: successMessage
        });
        toast.success('Connection successful!');
      } else {
        // Handle error response
        let errorMessage = response.data?.message || response.data?.error?.details || 'Connection failed. Please check your API URL and API Key.';
        
        // Add specific guidance for connection errors
        if (response.data?.error?.type === 'connection_refused') {
          errorMessage += ' Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.';
        }
        
        setTestResult({
          success: false,
          message: errorMessage,
          details: response.data?.error?.details || null
        });
        toast.error('Connection failed');
      }
    } catch (err) {
      console.error('Error testing TacticalRMM connection:', err);
      
      // Extract error details if available
      let errorMessage = 'Connection failed. ';
      
      if (err.response && err.response.data) {
        errorMessage += err.response.data.message || err.response.data.error?.details || 'Please check your API URL and API Key.';
        
        // Add hint about port configuration
        if (err.response.data.error?.type === 'connection_refused' || 
            (err.message && err.message.includes('ECONNREFUSED'))) {
          errorMessage += ' Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.';
        }
      } else if (err.message) {
        errorMessage += err.message;
        
        // Add specific guidance based on common error messages
        if (err.message.includes('Network Error') || err.message.includes('ECONNREFUSED')) {
          errorMessage += ' This may be due to incorrect port configuration. Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.';
        }
      } else {
        errorMessage += 'Please check your API URL and API Key.';
      }
      
      setTestResult({
        success: false,
        message: errorMessage
      });
      toast.error('Connection failed');
    } finally {
      setTestingConnection(false);
    }
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      // Normalize the API URL to handle standard ports correctly
      let apiUrl = settings.apiUrl;
      let portNormalized = false;
      
      if (apiUrl) {
        try {
          // Add protocol if missing
          if (!apiUrl.startsWith('http://') && !apiUrl.startsWith('https://')) {
            apiUrl = 'https://' + apiUrl;
            console.log('Added https protocol to API URL:', apiUrl);
          }
          
          // Parse the URL to normalize it
          const url = new URL(apiUrl);
          
          // Check for TacticalRMM's non-standard ports and normalize to standard ports
          if (url.port === '9080') {
            url.port = '80'; // Use standard HTTP port
            portNormalized = true;
            console.log('Normalized port from 9080 to 80 (nginx mapped to internal 8080)');
          } else if (url.port === '9443') {
            url.port = '443'; // Use standard HTTPS port
            portNormalized = true;
            console.log('Normalized port from 9443 to 443 (nginx mapped to internal 4443)');
          }
          
          // Remove explicit port specifications if they're the standard ports
          if (url.protocol === 'http:' && url.port === '80') {
            url.port = ''; // Standard HTTP port, remove it from URL
          } else if (url.protocol === 'https:' && url.port === '443') {
            url.port = ''; // Standard HTTPS port, remove it from URL
          }
          
          // Remove trailing slash to avoid double slashes
          let normalizedUrl = url.toString();
          if (normalizedUrl.endsWith('/')) {
            normalizedUrl = normalizedUrl.slice(0, -1);
          }
          
          // Use the normalized URL
          apiUrl = normalizedUrl;
          console.log('Using normalized API URL for saving settings:', apiUrl);
          
          // If the URL was normalized, show a notification to the user
          if (portNormalized) {
            toast.info('Your API URL has been adjusted to use standard ports (80/443) instead of TacticalRMM default ports (9080/9443).');
          }
        } catch (urlError) {
          console.error('Invalid URL format:', urlError);
          toast.error(`Invalid URL format: ${urlError.message}. Please enter a valid URL.`);
          setIsSaving(false);
          return;
        }
      } else {
        toast.error('API URL is required');
        setIsSaving(false);
        return;
      }
      
      // Prepare settings object for API
      const settingsToSave = {
        ...settings,
        apiUrl: apiUrl, // Use the normalized URL
      };
      
      // Always send the API key as is - no masking for personal use
      if (settings.apiKey) {
        console.log('Sending API key to server');
        settingsToSave.apiKey = settings.apiKey;
      }
      
      const response = await deviceService.updateTacticalRMMSettings(settingsToSave);
      
      // Check for success based on the server response format
      // Server returns { status: 'success', data: { settings: {...} } }
      if (response.data && (response.data.success || response.data.status === 'success')) {
        // Update settings with response data
        if (response.data.data && response.data.data.settings) {
          const { settings: updatedSettings } = response.data.data;
          setSettings({
            ...settings,
            status: updatedSettings.status || 'connected',
            lastSync: updatedSettings.lastSync || new Date().toISOString(),
            // Always show the full API key - no masking for personal use
            apiKey: updatedSettings.apiKey || '',
            // Ensure we keep the normalized API URL
            apiUrl: updatedSettings.apiUrl || settings.apiUrl
          });
        }
        toast.success('Settings saved successfully');
        console.log('Settings saved successfully:', response.data);
      } else {
        setError(response.data?.message || 'Failed to save settings');
        toast.error('Failed to save settings');
        console.error('Failed to save settings:', response.data);
      }
    } catch (err) {
      console.error('Error saving TacticalRMM settings:', err);
      
      // Extract the error message from the error object
      let errorMessage = 'Failed to save settings';
      if (err.response && err.response.data) {
        errorMessage = err.response.data.message || err.response.data.error || errorMessage;
      } else if (err.message) {
        errorMessage = err.message;
      }
      
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSyncNow = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      // Check if TacticalRMM integration is enabled
      if (!settings.enabled) {
        setError('TacticalRMM integration is not enabled. Please enable the integration in settings first.');
        toast.error('Integration not enabled');
        setLoading(false);
        return;
      }
      
      // Check if API URL is configured
      if (!settings.apiUrl) {
        setError('TacticalRMM API URL is not configured. Please enter a valid API URL in the settings.');
        toast.error('API URL missing');
        setLoading(false);
        return;
      }
      
      // Check if API key is configured
      if (!settings.apiKey) {
        setError('TacticalRMM API key is not configured. Please enter a valid API key in the settings.');
        toast.error('API key missing');
        setLoading(false);
        return;
      }
      
      // Check if user is authenticated (try both direct token and user object)
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));
      
      if ((!token) && (!user || !user.token)) {
        setError('Authentication error: Please log in to access TacticalRMM settings. Currently showing demo mode.');
        toast.error('Authentication required');
        setDemoMode(true);
        setLoading(false);
        return;
      }
      
      const authToken = token || (user && user.token);
      console.log('Syncing devices with authentication:', token ? 'Using direct token' : 'Using token from user object');
      
      // Force a refresh of the authentication token before syncing
      localStorage.setItem('user', JSON.stringify(user));
      
      // Log the current settings to help with debugging
      console.log('Current TacticalRMM settings:', {
        apiUrl: settings.apiUrl,
        apiKeyExists: !!settings.apiKey,
        enabled: settings.enabled
      });
      
      toast.info('Starting device synchronization with TacticalRMM...');
      
      const response = await deviceService.syncDevices();
      
      // Check for success in either format (response.data.success or response.data.status === 'success')
      if (response.data && (response.data.success || response.data.status === 'success')) {
        setSettings({
          ...settings,
          lastSync: new Date().toISOString(),
          status: 'connected'
        });
        
        // Update device count after successful sync
        try {
          const devicesResponse = await deviceService.getDevices();
          if (devicesResponse.data && devicesResponse.data.data) {
            const devices = devicesResponse.data.data;
            setDeviceCount(devices.length || 0);
            console.log(`Updated device count: ${devices.length} devices found`);
          }
        } catch (devErr) {
          console.error('Error fetching device count after sync:', devErr);
          // Don't set an error for this, as it's not critical
        }
        
        // Display sync results
        if (response.data.data && response.data.data.syncResults) {
          const syncResults = response.data.data.syncResults;
          const devicesFound = syncResults.devicesFound || 0;
          const devicesAdded = syncResults.devicesAdded || syncResults.created || 0;
          const devicesUpdated = syncResults.devicesUpdated || syncResults.updated || 0;
          const devicesRemoved = syncResults.devicesRemoved || 0;
          
          let successMessage = `Sync completed: ${devicesFound} devices found`;
          if (devicesAdded > 0) successMessage += `, ${devicesAdded} added`;
          if (devicesUpdated > 0) successMessage += `, ${devicesUpdated} updated`;
          if (devicesRemoved > 0) successMessage += `, ${devicesRemoved} removed`;
          
          toast.success(successMessage);
          console.log('Sync results:', syncResults);
        } else {
          toast.success('Sync completed successfully');
        }
      } else {
        // Handle error response
        const errorMessage = response.data?.message || 'Failed to sync with TacticalRMM';
        const errorDetails = response.data?.details || '';
        
        setError(`${errorMessage}${errorDetails ? ` - ${errorDetails}` : ''}`);
        toast.error('Failed to sync with TacticalRMM');
        console.error('Sync failed:', response.data);
      }
    } catch (err) {
      console.error('Error syncing with TacticalRMM:', err);
      
      // Set status to disconnected on error
      setSettings(prev => ({
        ...prev,
        status: 'disconnected'
      }));
      
      // Check if this is an authentication error (401)
      if (err.response && err.response.status === 401) {
        setError('Authentication error: Please log in to access TacticalRMM settings. Currently showing demo mode.');
        toast.error('Authentication required');
        setDemoMode(true);
      } 
      // Check if this is a permission error (403)
      else if (err.response && err.response.status === 403) {
        // Check if there's a specific message from the server
        const serverMessage = err.response.data?.message || '';
        const serverDetails = err.response.data?.details || '';
        
        if (serverMessage.includes('TacticalRMM integration is not enabled')) {
          setError(`TacticalRMM integration is not enabled for your account. ${serverDetails || 'Please enable it in your settings first.'}`);
          toast.error('Integration not enabled');
        } else if (serverMessage.includes('API key not configured')) {
          setError(`TacticalRMM API key is not configured. ${serverDetails || 'Please enter a valid API key in the settings.'}`);
          toast.error('API key missing');
        } else if (serverMessage.includes('API URL not configured')) {
          setError(`TacticalRMM API URL is not configured. ${serverDetails || 'Please enter a valid API URL in the settings.'}`);
          toast.error('API URL missing');
          
          // Display a specific reminder about port configuration if not in the details
          if (!serverDetails.includes('standard ports')) {
            setError(prev => prev + ' Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.');
          }
        } else {
          setError(`Permission error: You do not have sufficient permissions to access TacticalRMM settings. ${serverMessage} ${serverDetails ? `

Details: ${serverDetails}` : ''}`);
          toast.error('Permission denied');
        }
      }
      // Check if this is a URL or connection error
      else if (err.message && (
        err.message.includes('Network Error') || 
        err.message.includes('ECONNREFUSED') || 
        err.message.includes('ENOTFOUND') || 
        err.message.includes('ETIMEDOUT') || 
        err.message.includes('Invalid URL')
      )) {
        // Provide specific guidance based on the error type
        let errorMessage = `Connection error: Unable to connect to TacticalRMM server at ${settings.apiUrl}. `;
        
        if (err.message.includes('ECONNREFUSED')) {
          errorMessage += 'The server actively refused the connection. Please verify the server is running and the port is correct. ';
          errorMessage += 'Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.';
        } else if (err.message.includes('ENOTFOUND')) {
          errorMessage += 'The hostname could not be resolved. Please check if the domain name is correct.';
        } else if (err.message.includes('ETIMEDOUT')) {
          errorMessage += 'The connection attempt timed out. Please check your network connection and firewall settings.';
        } else if (err.message.includes('Invalid URL')) {
          errorMessage += 'The API URL format is invalid. Please enter a valid URL including the protocol (http:// or https://).';
        } else {
          errorMessage += 'Please check your API URL and ensure the server is accessible. Remember that TacticalRMM is configured to use standard ports 80/443 instead of 9080/9443.';
        }
        
        setError(errorMessage);
        toast.error('Connection error');
      } 
      // Handle specific HTTP error codes
      else if (err.response && err.response.status) {
        const status = err.response.status;
        const serverMessage = err.response.data?.message || '';
        const serverDetails = err.response.data?.details || '';
        
        let errorMessage = `Server error (${status}): `;
        
        switch (status) {
          case 404:
            errorMessage += 'The requested resource was not found. Please check your API URL.';
            break;
          case 500:
            errorMessage += 'Internal server error. Please check the TacticalRMM server logs for more details.';
            break;
          case 502:
            errorMessage += 'Bad gateway. There might be a proxy or network configuration issue.';
            break;
          case 503:
            errorMessage += 'Service unavailable. The TacticalRMM server might be down or overloaded.';
            break;
          default:
            errorMessage += serverMessage || 'An error occurred while communicating with the TacticalRMM server.';
        }
        
        if (serverDetails) {
          errorMessage += `

Details: ${serverDetails}`;
        }
        
        setError(errorMessage);
        toast.error(`Server error (${status})`);
      } 
      // Handle any other errors
      else {
        const errorMessage = err.response?.data?.message || err.message || 'An unknown error occurred';
        setError(`Error syncing with TacticalRMM: ${errorMessage}`);
        toast.error('Failed to sync with TacticalRMM');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClearDeviceData = async () => {
    setIsClearing(true);
    try {
      const response = await deviceService.clearTacticalRMMDevices();
      
      if (response.data && response.data.success) {
        toast.success('Device data cleared successfully');
        setShowClearConfirm(false);
      } else {
        setError(response.data?.message || 'Failed to clear device data');
        toast.error('Failed to clear device data');
      }
    } catch (err) {
      console.error('Error clearing device data:', err);
      setError('Failed to clear device data');
      toast.error('Failed to clear device data');
    } finally {
      setIsClearing(false);
    }
  };

  const handleResetIntegration = async () => {
    setIsResetting(true);
    try {
      const response = await deviceService.resetTacticalRMMIntegration();
      
      if (response.data && response.data.success) {
        // Reset settings to default values
        setSettings({
          apiUrl: '',
          apiKey: '',
          enabled: false,
          autoSync: true,
          syncInterval: 30,
          lastSync: null,
          status: 'disconnected'
        });
        toast.success('Integration reset successfully');
        setShowResetConfirm(false);
      } else {
        setError(response.data?.message || 'Failed to reset integration');
        toast.error('Failed to reset integration');
      }
    } catch (err) {
      console.error('Error resetting integration:', err);
      setError('Failed to reset integration');
      toast.error('Failed to reset integration');
    } finally {
      setIsResetting(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getTimeSince = (dateString) => {
    if (!dateString) return '';
    
    const now = new Date();
    const past = new Date(dateString);
    const diffMs = now - past;
    
    // Convert to seconds, minutes, hours, days
    const diffSecs = Math.floor(diffMs / 1000);
    const diffMins = Math.floor(diffSecs / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    
    if (diffDays > 0) {
      return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`;
    } else if (diffHours > 0) {
      return diffHours === 1 ? '1 hour ago' : `${diffHours} hours ago`;
    } else if (diffMins > 0) {
      return diffMins === 1 ? '1 minute ago' : `${diffMins} minutes ago`;
    } else {
      return 'Just now';
    }
  };

  const getStatusBadge = () => {
    switch(settings.status) {
      case 'connected':
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-green-100 to-green-200 text-green-800 border border-green-300 shadow-sm transform transition-all duration-200 hover:scale-105">
            <FaCheck className="mr-1.5 h-3 w-3 text-green-600" />
            Connected
          </span>
        );
      case 'disconnected':
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-gray-100 to-gray-200 text-gray-800 border border-gray-300 shadow-sm transform transition-all duration-200 hover:scale-105">
            <FaUnlink className="mr-1.5 h-3 w-3 text-gray-600" />
            Disconnected
          </span>
        );
      case 'error':
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-red-100 to-red-200 text-red-800 border border-red-300 shadow-sm transform transition-all duration-200 hover:scale-105">
            <FaExclamationTriangle className="mr-1.5 h-3 w-3 text-red-600" />
            Error
          </span>
        );
      default:
        return null;
    }
  };

  if (loading && !isSaving) {
    return (
      <div className={`flex flex-col justify-center items-center h-64 rounded-lg shadow-sm p-6 ${
        darkMode ? 'bg-gray-800' : 'bg-white'
      }`}>
        <div className={`animate-spin rounded-full h-12 w-12 border-b-2 mb-4 ${
          darkMode ? 'border-purple-500' : 'border-blue-600'
        }`}></div>
        <p className={`font-medium ${
          darkMode ? 'text-gray-300' : 'text-gray-600'
        }`}>Loading settings...</p>
      </div>
    );
  }

  return (
    <div className={`tactical-rmm-container space-y-8 p-4 rounded-lg transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-gray-100' : 'bg-purple-200 text-gray-800'}`}>
      {/* Dark Mode Notice */}
      <div className="flex justify-center mb-8">
        <div className={`flex flex-col items-center justify-center p-6 rounded-xl transition-all duration-300 border-2 ${
          darkMode 
            ? 'bg-gray-800 border-yellow-400' 
            : 'bg-white border-purple-500'
        }`}>
          <h2 className={`text-xl font-bold mb-4 ${darkMode ? 'text-yellow-300' : 'text-purple-700'}`}>DARK MODE AVAILABLE</h2>
          
          <p className={`text-center ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            You can toggle dark mode using the button in the top navigation bar.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <h1 className={`text-4xl font-bold text-center ${darkMode ? 'text-purple-300' : 'text-purple-800'}`}>TACTICAL RMM SETTINGS - TEST</h1>
        <div className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium border-2 ${
            darkMode 
              ? 'bg-gray-700 text-yellow-300 border-yellow-500' 
              : 'bg-purple-100 text-gray-700 border-purple-300'
          }`}>
          {darkMode ? (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              <span>Currently in Dark Mode</span>
            </>
          ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              </svg>
              <span>Currently in Light Mode</span>
            </>
          )}
        </div>
      </div>
      {/* Demo Mode Banner - TEST CHANGE */}
      {demoMode && (
        <div className={`p-4 mb-6 rounded shadow-md border-l-4 ${
          darkMode 
            ? 'bg-yellow-900 border-yellow-600 text-yellow-200' 
            : 'bg-yellow-100 border-yellow-500 text-yellow-700'
        }`} role="alert">
          <div className="flex items-center">
            <div className="py-1">
              <svg className={`fill-current h-6 w-6 mr-4 ${darkMode ? 'text-yellow-400' : 'text-yellow-500'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Demo Mode Active</p>
              <p className="text-sm">You're viewing a demo of the TacticalRMM settings page. The backend server is not properly authenticated. This is a preview of the enhanced UI design.</p>
            </div>
          </div>
        </div>
      )}
      
      <div className={`rounded-lg shadow-lg p-8 text-white relative overflow-hidden mb-8 ${
        darkMode 
          ? 'bg-gradient-to-r from-indigo-900 to-purple-900' 
          : 'bg-gradient-to-r from-blue-600 to-blue-900'
      }`}>
        <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-10 rounded-full -mt-12 -mr-12 animate-pulse"></div>
        <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-10 rounded-full -mb-12 -ml-12 animate-pulse"></div>
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center relative z-10">
          <div className="flex items-center">
            <div className="p-4 bg-white bg-opacity-20 rounded-lg mr-5 shadow-inner backdrop-blur-sm">
              <FaServer className="h-10 w-10 text-blue-100" />
            </div>
            <div>
              <h1 className="text-3xl font-bold tracking-wide text-shadow">TacticalRMM Integration</h1>
              <p className="text-blue-100 mt-2 max-w-xl font-light">
                Connect and manage your remote devices through TacticalRMM. Monitor system health, run commands, and provide remote support.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-4 mt-4 md:mt-0">
            <div className="flex items-center px-4 py-2 bg-white bg-opacity-10 rounded-lg">
              <div className="mr-2">
                <div className={`h-3 w-3 rounded-full ${
                  settings.status === 'connected' ? 'bg-green-400 animate-pulse' : 
                  (settings.status === 'error' ? 'bg-red-400' : 'bg-gray-400')
                }`}></div>
              </div>
              <span className="text-sm font-medium">
                {settings.status === 'connected' ? 'Connected' : 
                 (settings.status === 'error' ? 'Error' : 'Disconnected')}
              </span>
            </div>
            <div className="hidden md:block">
              {getStatusBadge()}
            </div>
          </div>
        </div>
        
        <div className="flex flex-wrap items-center mt-6 pt-4 border-t border-blue-500 border-opacity-30 text-sm text-blue-100 relative z-10">
          <div className="flex items-center mr-6 mb-2 md:mb-0">
            <div className="p-1.5 bg-white bg-opacity-10 rounded-md mr-2">
              <FaClock className="h-4 w-4" />
            </div>
            <span>
              {settings.lastSync 
                ? `Last sync: ${formatDate(settings.lastSync)} (${getTimeSince(settings.lastSync)})` 
                : 'No synchronization yet'}
            </span>
          </div>
          <div className="flex items-center mr-6 mb-2 md:mb-0">
            <div className="p-1.5 bg-gradient-to-br from-green-500 to-green-600 rounded-md mr-2 shadow-sm">
              <FaDesktop className="h-4 w-4 text-white" />
            </div>
            <span>
              {deviceCount > 0 
                ? <span className="flex items-center"><span className="font-medium text-green-700">{deviceCount}</span><span className="ml-1 text-gray-600">{deviceCount === 1 ? 'device' : 'devices'} connected</span></span> 
                : <span className="text-gray-500">No devices connected</span>}
            </span>
          </div>
          <div className="flex items-center">
            <div className="p-1.5 bg-gradient-to-br from-purple-500 to-purple-600 rounded-md mr-2 shadow-sm">
              <FaLink className="h-4 w-4 text-white" />
            </div>
            <span>
              {settings.apiUrl ? 
                <span className="flex items-center">
                  <span className="font-medium text-purple-700">{settings.apiUrl.replace(new RegExp('^(https?://[^/]+).*$'), '$1')}</span>
                </span> : 
                <span className="text-gray-500">No API URL configured</span>
              }
            </span>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-gradient-to-r from-red-50 to-red-100 border-l-4 border-red-500 p-4 rounded-md mb-6 flex items-start shadow-md transform transition-all duration-200">
          <FaExclamationTriangle className="text-red-600 h-5 w-5 mr-3 mt-0.5 flex-shrink-0" />
          <div>
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <p className="text-sm text-red-700 mt-1">{error}</p>
          </div>
        </div>
      )}

      {testResult && (
        <div className={`border-l-4 p-4 rounded-md mb-6 flex items-start shadow-md transform transition-all duration-200 ${
          testResult.success 
            ? 'bg-gradient-to-r from-green-50 to-green-100 border-green-500' 
            : 'bg-gradient-to-r from-red-50 to-red-100 border-red-500'
        }`}>
          {testResult.success ? (
            <FaCheck className="text-green-600 h-5 w-5 mr-3 mt-0.5 flex-shrink-0" />
          ) : (
            <FaExclamationTriangle className="text-red-600 h-5 w-5 mr-3 mt-0.5 flex-shrink-0" />
          )}
          <div>
            <h3 className={`text-sm font-medium ${testResult.success ? 'text-green-800' : 'text-red-800'}`}>
              {testResult.success ? 'Connection Successful' : 'Connection Failed'}
            </h3>
            <p className={`text-sm mt-1 ${testResult.success ? 'text-green-700' : 'text-red-700'}`}>
              {testResult.message}
            </p>
          </div>
        </div>
      )}

      <div className={`shadow-md rounded-lg overflow-hidden border hover:shadow-lg transition-all duration-300 ${
        darkMode 
          ? 'bg-gray-800 border-gray-700' 
          : 'bg-white border-gray-100'
      }`}>
        <div className="p-6">
          <div className="flex items-center mb-6">
            <div className={`p-2 rounded-md mr-3 shadow-md ${
              darkMode 
                ? 'bg-gradient-to-br from-purple-600 to-indigo-800' 
                : 'bg-gradient-to-br from-blue-500 to-blue-700'
            }`}>
              <FaLink className="text-white h-5 w-5" />
            </div>
            <h2 className={`text-lg font-semibold pb-1 border-b-2 ${
              darkMode 
                ? 'text-gray-100 border-indigo-400' 
                : 'text-gray-900 border-blue-200'
            }`}>Connection Settings</h2>
          </div>

          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    name="enabled"
                    id="enabled"
                    checked={settings.enabled}
                    onChange={handleChange}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer transition-transform duration-200 shadow-md"
                    style={{ transform: settings.enabled ? 'translateX(100%)' : 'translateX(0)', borderColor: settings.enabled ? '#3B82F6' : '#D1D5DB' }}
                  />
                  <label
                    htmlFor="enabled"
                    className="toggle-label block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 shadow-inner"
                    style={{ backgroundColor: settings.enabled ? 'rgba(59, 130, 246, 0.9)' : 'rgba(209, 213, 219, 0.9)' }}
                  ></label>
                </div>
                <div>
                  <label htmlFor="enabled" className={`text-sm font-medium cursor-pointer ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                    Enable TacticalRMM Integration
                  </label>
                  <p className={`text-xs mt-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {settings.enabled ? 'Integration is currently active' : 'Enable to connect with TacticalRMM'}
                  </p>
                </div>
              </div>
              <div className="md:hidden">
                {getStatusBadge()}
              </div>
            </div>

            <div className={`p-4 rounded-lg border shadow-sm hover:shadow-md transition-all duration-300 ${
              darkMode 
                ? 'bg-gradient-to-br from-gray-800 to-gray-700 border-gray-600' 
                : 'bg-gradient-to-br from-gray-50 to-gray-100 border-gray-200'
            }`}>
              <label htmlFor="companyName" className={`block text-sm font-medium mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-700'}`}>Company Name</label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className={`h-4 w-4 ${darkMode ? 'text-gray-500' : 'text-gray-400'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                </div>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={settings.companyName}
                  onChange={handleChange}
                  className={`pl-10 block w-full sm:text-sm rounded-lg shadow-sm transition-all duration-200 focus:shadow-md ${
                    darkMode
                      ? 'bg-gray-700 border-gray-600 text-gray-100 focus:ring-purple-500 focus:border-purple-500 hover:border-purple-400'
                      : 'bg-white border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400'
                  }`}
                  placeholder="Your Business Name"
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-500 flex items-center">
                <FaInfoCircle className="h-3 w-3 mr-1" />
                Your company name for TacticalRMM integration
              </p>
            </div>

            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <label htmlFor="apiUrl" className="block text-sm font-medium text-gray-700 mb-2">TacticalRMM API URL</label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaServer className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="apiUrl"
                  id="apiUrl"
                  value={settings.apiUrl}
                  onChange={handleChange}
                  disabled={!settings.enabled}
                  className="pl-10 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-lg shadow-sm disabled:opacity-60 disabled:bg-gray-100 transition-all duration-200 hover:border-blue-400 focus:shadow-md"
                  placeholder="https://tacticalrmm.yourdomain.com"
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-500 flex items-center">
                <FaInfoCircle className="h-3 w-3 mr-1" />
                The URL of your TacticalRMM instance
              </p>
            </div>

            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700 mb-2">API Key</label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaShieldAlt className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="apiKey"
                  id="apiKey"
                  value={settings.apiKey}
                  onChange={handleChange}
                  disabled={!settings.enabled}
                  className="pl-10 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-lg shadow-sm disabled:opacity-60 disabled:bg-gray-100 transition-all duration-200 hover:border-blue-400 focus:shadow-md"
                  placeholder="Enter your TacticalRMM API key"
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-500 flex items-center">
                <FaInfoCircle className="h-3 w-3 mr-1" />
                Your TacticalRMM API key (keep this secure)
              </p>
            </div>

            <div>
              <button
                type="button"
                onClick={handleTestConnection}
                disabled={!settings.enabled || !settings.apiUrl || !settings.apiKey || testingConnection}
                className="inline-flex items-center px-4 py-2.5 border border-blue-300 shadow-lg text-sm font-medium rounded-md text-blue-700 bg-gradient-to-r from-blue-50 to-blue-100 hover:from-blue-100 hover:to-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:-translate-y-0.5"
              >
                {testingConnection ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-700 mr-2"></div>
                    Testing Connection...
                  </>
                ) : (
                  <>
                    <FaLink className="mr-2 h-4 w-4" />
                    Test Connection
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300">
        <div className="p-6">
          <div className="flex items-center mb-6">
            <div className="p-2 bg-gradient-to-br from-green-500 to-green-700 rounded-md mr-3 shadow-md">
              <FaSync className="text-white h-5 w-5" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900 border-b-2 border-green-200 pb-1">Sync Settings</h2>
          </div>

          <div className="space-y-8">
            <div className="flex items-center justify-between bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex items-center">
                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                    type="checkbox"
                    name="autoSync"
                    id="autoSync"
                    checked={settings.autoSync}
                    onChange={handleChange}
                    disabled={!settings.enabled}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer transition-transform duration-200 disabled:opacity-50 shadow-md"
                    style={{ transform: settings.autoSync ? 'translateX(100%)' : 'translateX(0)', borderColor: settings.autoSync ? '#10B981' : '#D1D5DB' }}
                  />
                  <label
                    htmlFor="autoSync"
                    className="toggle-label block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 shadow-inner"
                    style={{ backgroundColor: settings.autoSync ? 'rgba(16, 185, 129, 0.9)' : 'rgba(209, 213, 219, 0.9)' }}
                  ></label>
                </div>
                <div>
                  <label htmlFor="autoSync" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Automatic Synchronization
                  </label>
                  <p className="text-xs text-gray-500 mt-1">
                    {settings.autoSync ? 'Device data will sync automatically' : 'Manual sync only'}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex items-center justify-between mb-2">
                <label htmlFor="syncInterval" className="block text-sm font-medium text-gray-700">Sync Interval</label>
                <span className="text-sm font-medium px-2.5 py-1 bg-blue-100 text-blue-700 rounded-md shadow-sm">{settings.syncInterval} minutes</span>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="syncInterval"
                  id="syncInterval"
                  min="5"
                  max="1440"
                  step="5"
                  value={settings.syncInterval}
                  onChange={handleChange}
                  disabled={!settings.enabled || !settings.autoSync}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer disabled:opacity-50 shadow-inner"
                  style={{
                    background: `linear-gradient(to right, #3B82F6 0%, #60A5FA ${(settings.syncInterval - 5) / (1440 - 5) * 100}%, #E5E7EB ${(settings.syncInterval - 5) / (1440 - 5) * 100}%, #F3F4F6 100%)`
                  }}
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>5 min</span>
                  <span>6 hours</span>
                  <span>24 hours</span>
                </div>
              </div>
              <p className="mt-3 text-xs text-gray-500 flex items-center">
                <FaInfoCircle className="h-3 w-3 mr-1" />
                How often to automatically sync device data with TacticalRMM
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-100 mb-8">
        <div className="p-6">
          <div className="flex items-center mb-6">
            <div className="p-2 bg-gradient-to-br from-purple-500 to-purple-600 rounded-md mr-3 shadow-md">
              <FaClock className="text-white h-5 w-5" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900">Synchronization Status</h2>
          </div>

          <div className="space-y-6">
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
                <div className="flex items-center">
                  <div className="p-2 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full shadow-md mr-3">
                    <FaSync className={`h-5 w-5 text-white ${loading ? 'animate-spin' : ''}`} />
                  </div>
                  <div>
                    <span className="text-sm font-medium text-gray-700 block">Last Synchronization</span>
                    <span className="text-sm text-gray-500 block mt-1">
                      {settings.lastSync ? (
                        <span className="flex items-center">
                          <span className="font-medium text-blue-700">{formatDate(settings.lastSync)}</span>
                          <span className="ml-2 px-2.5 py-0.5 bg-gradient-to-r from-blue-100 to-blue-200 text-blue-800 text-xs rounded-full border border-blue-300 shadow-sm">
                            {getTimeSince(settings.lastSync)}
                          </span>
                        </span>
                      ) : (
                        <span className="italic text-gray-500">No synchronization yet</span>
                      )}
                    </span>
                  </div>
                </div>
                
                <button
                  onClick={handleSyncNow}
                  disabled={!settings.enabled || loading}
                  className="inline-flex items-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-md shadow-lg text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:-translate-y-0.5"
                >
                  <FaSync className={`mr-2 h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
                  {loading ? 'Syncing...' : 'Sync Now'}
                </button>
              </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex items-center mb-2">
                <div className="p-1.5 bg-gradient-to-br from-green-100 to-green-200 rounded-full shadow-md mr-3">
                  <FaServer className="h-4 w-4 text-green-600" />
                </div>
                <span className="text-sm font-medium text-gray-700">Connection Status</span>
                <div className="ml-auto">
                  {getStatusBadge()}
                </div>
              </div>
              <p className="text-xs text-gray-500 mt-2 ml-9">
                {settings.status === 'connected' 
                  ? 'Successfully connected to TacticalRMM API' 
                  : settings.status === 'error' 
                    ? 'There was an error connecting to TacticalRMM' 
                    : 'Not currently connected to TacticalRMM'}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300">
        <div className="p-6">
          <div className="flex items-center mb-6">
            <div className="p-2 bg-gradient-to-br from-red-500 to-red-700 rounded-md mr-3 shadow-md">
              <FaCog className="text-white h-5 w-5" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900 border-b-2 border-red-200 pb-1">Advanced Settings</h2>
          </div>

          <div className="space-y-6">
            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="p-1.5 bg-gradient-to-br from-red-400 to-red-600 rounded-md mr-3 mt-0.5 shadow-md">
                    <FaTrash className="text-white h-4 w-4" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-700">Clear All Device Data</h3>
                    <p className="text-xs text-gray-500 mt-1">Remove all synchronized device data from the platform</p>
                  </div>
                </div>
                {showClearConfirm ? (
                  <div className="flex items-center space-x-2">
                    <button
                      type="button"
                      onClick={() => setShowClearConfirm(false)}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-md text-sm leading-4 font-medium rounded-md text-gray-700 bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 transform hover:-translate-y-0.5"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleClearDeviceData}
                      disabled={isClearing}
                      className="inline-flex items-center px-3 py-2 border border-red-300 shadow-md text-sm leading-4 font-medium rounded-md text-red-700 bg-gradient-to-r from-white to-red-50 hover:from-red-50 hover:to-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 transition-all duration-200 transform hover:-translate-y-0.5"
                    >
                      {isClearing ? (
                        <>
                          <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-red-700 mr-1"></div>
                          Clearing...
                        </>
                      ) : (
                        'Confirm'
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowClearConfirm(true)}
                    className="inline-flex items-center px-3 py-2 border border-red-300 shadow-md text-sm leading-4 font-medium rounded-md text-red-700 bg-gradient-to-r from-white to-red-50 hover:from-red-50 hover:to-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200 transform hover:-translate-y-0.5"
                  >
                    <FaTrash className="mr-1.5 h-3.5 w-3.5" />
                    Clear Data
                  </button>
                )}
              </div>
            </div>

            <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="p-1.5 bg-gradient-to-br from-red-400 to-red-600 rounded-md mr-3 mt-0.5 shadow-md">
                    <FaRedo className="text-white h-4 w-4" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-700">Reset Integration</h3>
                    <p className="text-xs text-gray-500 mt-1">Reset all TacticalRMM integration settings</p>
                  </div>
                </div>
                {showResetConfirm ? (
                  <div className="flex items-center space-x-2">
                    <button
                      type="button"
                      onClick={() => setShowResetConfirm(false)}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-md text-sm leading-4 font-medium rounded-md text-gray-700 bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 transform hover:-translate-y-0.5"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleResetIntegration}
                      disabled={isResetting}
                      className="inline-flex items-center px-3 py-2 border border-red-300 shadow-md text-sm leading-4 font-medium rounded-md text-red-700 bg-gradient-to-r from-white to-red-50 hover:from-red-50 hover:to-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 transition-all duration-200 transform hover:-translate-y-0.5"
                    >
                      {isResetting ? (
                        <>
                          <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-red-700 mr-1"></div>
                          Resetting...
                        </>
                      ) : (
                        'Confirm'
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => setShowResetConfirm(true)}
                    className="inline-flex items-center px-3 py-2 border border-red-300 shadow-md text-sm leading-4 font-medium rounded-md text-red-700 bg-gradient-to-r from-white to-red-50 hover:from-red-50 hover:to-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200 transform hover:-translate-y-0.5"
                  >
                    <FaRedo className="mr-1.5 h-3.5 w-3.5" />
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-8">
        <button
          type="button"
          onClick={handleSaveSettings}
          disabled={isSaving}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-lg text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:-translate-y-0.5"
        >
          {isSaving ? (
            <>
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
              Saving...
            </>
          ) : (
            <>
              <FaCheck className="mr-2 h-5 w-5" />
              Save Settings
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default TacticalRMMSettings;
