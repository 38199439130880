import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  FaCircle, FaUser, FaDesktop, FaPaperPlane, FaEdit, FaTrash, FaArrowLeft,
  FaRobot, FaFileUpload, FaDownload, FaClock, FaBrain, FaCheckCircle, FaTimesCircle,
  FaFileAlt, FaPoundSign
} from 'react-icons/fa';

const TicketDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    title: '',
    description: '',
    status: '',
    priority: ''
  });
  
  // AI triage state
  const [aiTriageLoading, setAiTriageLoading] = useState(false);
  const [aiTriageResult, setAiTriageResult] = useState(null);
  
  // AI summary state
  const [aiSummaryLoading, setAiSummaryLoading] = useState(false);
  const [aiSummaryResult, setAiSummaryResult] = useState(null);
  
  // File upload state
  const [fileUploading, setFileUploading] = useState(false);
  const fileInputRef = useRef(null);
  
  // Status change state
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  
  // Time tracking state
  const [timeSpent, setTimeSpent] = useState(0);
  const [timeInputMode, setTimeInputMode] = useState(false);
  
  // Specialist fee state
  const [showFeeForm, setShowFeeForm] = useState(false);
  const [feeData, setFeeData] = useState({ amount: '', description: '' });

  // Import ticket service
  const ticketService = require('../../services/ticket.service').default;

  useEffect(() => {
    // Fetch the ticket from the API
    const fetchTicket = async () => {
      setLoading(true);
      try {
        const response = await ticketService.getTicket(id);
        console.log('Ticket response:', response);
        
        if (response.data && response.data.data && response.data.data.ticket) {
          const ticketData = response.data.data.ticket;
          
          // For mock tickets, ensure we have the expected structure
          if (id.startsWith('mock-')) {
            // Add _id property for mock tickets if it doesn't exist
            if (!ticketData._id) {
              ticketData._id = ticketData.id;
            }
            
            // Ensure comments array exists
            if (!ticketData.comments) {
              ticketData.comments = [];
            }
            
            // Ensure timestamps exist
            if (!ticketData.updatedAt) {
              ticketData.updatedAt = ticketData.createdAt;
            }
          }
          
          setTicket(ticketData);
          setEditData({
            title: ticketData.title,
            description: ticketData.description,
            status: ticketData.status,
            priority: ticketData.priority
          });
          setError(null);
        } else {
          setError('Ticket not found or unexpected API response format');
        }
      } catch (err) {
        setError('Failed to load ticket. Please try again later.');
        console.error('Error fetching ticket:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [id]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      // Send the comment to the API
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.addComment(ticketId, { content: newComment });
      
      if (response.data && response.data.success) {
        // Refresh the ticket to get the updated comments
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
        }
      } else {
        console.error('Failed to add comment:', response.data?.message || 'Unknown error');
      }
    } catch (err) {
      console.error('Error adding comment:', err);
    }

    setNewComment('');
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    try {
      console.log('Submitting ticket update:', editData);
      
      // Send the updated ticket to the API
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.updateTicket(ticketId, {
        title: editData.title,
        description: editData.description,
        status: editData.status,
        priority: editData.priority
      });
      
      console.log('Update ticket response:', response);
      
      // Check for success in the response
      if (response.data && (response.data.success || response.data.status === 'success')) {
        // Refresh the ticket to get the updated data
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          console.log('Ticket updated successfully:', updatedTicketResponse.data.data.ticket);
        }
      } else {
        const errorMsg = response.data?.message || 'Unknown error';
        console.error('Failed to update ticket:', errorMsg);
        alert(`Failed to update ticket: ${errorMsg}`);
      }
    } catch (err) {
      console.error('Error updating ticket:', err);
      alert(`Error updating ticket: ${err.message || 'Unknown error'}`);
    }

    setEditMode(false);
  };

  const handleDeleteTicket = async () => {
    if (window.confirm('Are you sure you want to delete this ticket? This action cannot be undone.')) {
      try {
        // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
        const ticketId = ticket._id || ticket.id;
        console.log('Attempting to delete ticket:', ticketId);
        
        // Send delete request to the API
        const response = await ticketService.deleteTicket(ticketId);
        console.log('Delete ticket response:', response);
        
        // Check for success in the response
        if (response.data && (response.data.success || response.data.status === 'success')) {
          console.log('Ticket deleted successfully');
          alert('Ticket deleted successfully');
          navigate('/tickets');
        } else {
          const errorMsg = response.data?.message || 'Unknown error';
          console.error('Failed to delete ticket:', errorMsg);
          alert(`Failed to delete ticket: ${errorMsg}`);
        }
      } catch (err) {
        console.error('Error deleting ticket:', err);
        alert(`Error deleting ticket: ${err.message || 'Unknown error'}`);
      }
    }
  };

  // Handle AI triage request
  const handleAITriage = async () => {
    setAiTriageLoading(true);
    setAiTriageResult(null);
    
    try {
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.getAITriage(ticketId);
      
      if (response.data && response.data.status === 'success') {
        setAiTriageResult(response.data.data);
      } else {
        console.error('Failed to get AI triage:', response.data?.message || 'Unknown error');
        alert('Failed to get AI triage. Please try again later.');
      }
    } catch (err) {
      console.error('Error getting AI triage:', err);
      alert('Failed to get AI triage. Please try again later.');
    } finally {
      setAiTriageLoading(false);
    }
  };
  
  // Apply AI suggested priority
  const applyAIPriority = async () => {
    if (!aiTriageResult || !aiTriageResult.suggestedPriority) return;
    
    try {
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.updateTicket(ticketId, {
        priority: aiTriageResult.suggestedPriority
      });
      
      if (response.data && response.data.status === 'success') {
        // Refresh the ticket to get the updated data
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          setAiTriageResult(null); // Clear the triage result after applying
        }
      } else {
        console.error('Failed to update ticket priority:', response.data?.message || 'Unknown error');
      }
    } catch (err) {
      console.error('Error updating ticket priority:', err);
    }
  };
  
  // Handle AI summary request
  const handleAISummary = async () => {
    setAiSummaryLoading(true);
    setAiSummaryResult(null);
    
    try {
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.getAISummary(ticketId);
      
      if (response.data && response.data.status === 'success') {
        setAiSummaryResult(response.data.data);
      } else {
        console.error('Failed to get AI summary:', response.data?.message || 'Unknown error');
        alert('Failed to get AI summary. Please try again later.');
      }
    } catch (err) {
      console.error('Error getting AI summary:', err);
      alert('Failed to get AI summary. Please try again later.');
    } finally {
      setAiSummaryLoading(false);
    }
  };
  
  // Handle file upload
  const handleFileUpload = async (e) => {
    e.preventDefault();
    
    const file = fileInputRef.current.files[0];
    if (!file) {
      alert('Please select a file to upload');
      return;
    }
    
    // Check file size (max 10MB)
    if (file.size > 10 * 1024 * 1024) {
      alert('File size exceeds 10MB limit');
      return;
    }
    
    setFileUploading(true);
    
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.uploadAttachment(ticketId, formData);
      
      if (response.data && response.data.status === 'success') {
        // Refresh the ticket to get the updated attachments
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          // Reset file input
          fileInputRef.current.value = '';
        }
      } else {
        console.error('Failed to upload file:', response.data?.message || 'Unknown error');
        alert('Failed to upload file. Please try again later.');
      }
    } catch (err) {
      console.error('Error uploading file:', err);
      alert('Failed to upload file. Please try again later.');
    } finally {
      setFileUploading(false);
    }
  };
  
  // Trigger file input click
  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };
  
  // Toggle time input mode
  const toggleTimeInputMode = () => {
    setTimeInputMode(!timeInputMode);
    if (!timeInputMode) {
      // Set initial value to current time spent
      setTimeSpent(ticket.timeSpent || 0);
    }
  };
  
  // Handle time spent change
  const handleTimeSpentChange = (e) => {
    setTimeSpent(parseInt(e.target.value, 10) || 0);
  };
  
  // Save time spent
  const saveTimeSpent = async () => {
    try {
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.updateTimeSpent(ticketId, timeSpent);
      
      if (response.data && response.data.status === 'success') {
        // Refresh the ticket to get the updated data
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          setTimeInputMode(false);
        }
      } else {
        console.error('Failed to update time spent:', response.data?.message || 'Unknown error');
      }
    } catch (err) {
      console.error('Error updating time spent:', err);
    }
  };
  
  // Handle specialist fee form
  const toggleFeeForm = () => {
    setShowFeeForm(!showFeeForm);
    setFeeData({ amount: '', description: '' });
  };
  
  // Handle fee data change
  const handleFeeDataChange = (e) => {
    const { name, value } = e.target;
    setFeeData({
      ...feeData,
      [name]: value
    });
  };
  
  // Add specialist fee
  const addSpecialistFee = async (e) => {
    e.preventDefault();
    
    if (!feeData.amount || !feeData.description) {
      alert('Please enter both amount and description');
      return;
    }
    
    try {
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.addSpecialistFee(ticketId, feeData);
      
      if (response.data && response.data.status === 'success') {
        // Refresh the ticket to get the updated fees
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          setShowFeeForm(false);
          setFeeData({ amount: '', description: '' });
        }
      } else {
        console.error('Failed to add specialist fee:', response.data?.message || 'Unknown error');
        alert('Failed to add specialist fee. Please try again later.');
      }
    } catch (err) {
      console.error('Error adding specialist fee:', err);
      alert('Failed to add specialist fee. Please try again later.');
    }
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'open':
        return 'text-blue-500';
      case 'in-progress':
        return 'text-yellow-500';
      case 'closed':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const getPriorityColor = (priority) => {
    switch(priority) {
      case 'high':
        return 'text-red-500 bg-red-100';
      case 'medium':
        return 'text-yellow-500 bg-yellow-100';
      case 'low':
        return 'text-green-500 bg-green-100';
      default:
        return 'text-gray-500 bg-gray-100';
    }
  };

  // Handle status change
  const handleStatusChange = async (newStatus) => {
    console.log('Changing ticket status to:', newStatus);
    setStatusChangeLoading(true);
    
    try {
      // Direct API call to update just the status
      // Use ticket._id if available, otherwise use ticket.id (for mock tickets)
      const ticketId = ticket._id || ticket.id;
      const response = await ticketService.updateTicket(ticketId, {
        status: newStatus
      });
      
      console.log('Status update response:', response);
      
      // Check for success in the response
      if (response.data && (response.data.success || response.data.status === 'success')) {
        // Refresh the ticket to get the updated data
        const updatedTicketResponse = await ticketService.getTicket(id);
        if (updatedTicketResponse.data && updatedTicketResponse.data.data && updatedTicketResponse.data.data.ticket) {
          setTicket(updatedTicketResponse.data.data.ticket);
          setEditData({
            ...editData,
            status: newStatus
          });
          console.log('Ticket status updated successfully:', updatedTicketResponse.data.data.ticket);
        }
      } else {
        const errorMsg = response.data?.message || 'Unknown error';
        console.error('Failed to update ticket status:', errorMsg);
        alert(`Failed to update ticket status: ${errorMsg}`);
      }
    } catch (err) {
      console.error('Error updating ticket status:', err);
      alert(`Error updating ticket status: ${err.message || 'Unknown error'}`);
    } finally {
      setStatusChangeLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={() => navigate('/tickets')}
          className="mr-4 text-gray-500 hover:text-gray-700"
        >
          <FaArrowLeft className="h-5 w-5" />
        </button>
        <h1 className="text-2xl font-semibold text-gray-900">Ticket #{ticket.id}</h1>
      </div>

      {editMode ? (
        // Edit Form
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6">
            <form onSubmit={handleEditSubmit}>
              <div className="space-y-4">
                <div>
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                  <input
                    type="text"
                    id="title"
                    name="title"
                    value={editData.title}
                    onChange={handleEditChange}
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                  <textarea
                    id="description"
                    name="description"
                    rows="4"
                    value={editData.description}
                    onChange={handleEditChange}
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    required
                  ></textarea>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={editData.status}
                      onChange={handleEditChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      <option value="open">Open</option>
                      <option value="in-progress">In Progress</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="priority" className="block text-sm font-medium text-gray-700">Priority</label>
                    <select
                      id="priority"
                      name="priority"
                      value={editData.priority}
                      onChange={handleEditChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setEditMode(false)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        // Ticket Details
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-xl font-medium text-gray-900">{ticket.title}</h2>
                <div className="mt-1 flex items-center flex-wrap">
                  <FaCircle className={`mr-2 h-2 w-2 ${getStatusColor(ticket.status)}`} />
                  <span className="text-sm text-gray-500 capitalize mr-4">{ticket.status.replace('-', ' ')}</span>
                  
                  {/* Status change buttons */}
                  <div className="flex space-x-2 mr-4">
                    {ticket.status !== 'open' && (
                      <button
                        onClick={() => handleStatusChange('open')}
                        disabled={statusChangeLoading}
                        className="inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                      >
                        Set Open
                      </button>
                    )}
                    {ticket.status !== 'in-progress' && (
                      <button
                        onClick={() => handleStatusChange('in-progress')}
                        disabled={statusChangeLoading}
                        className="inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-yellow-700 bg-yellow-50 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-50"
                      >
                        Set In Progress
                      </button>
                    )}
                    {ticket.status !== 'closed' && (
                      <button
                        onClick={() => handleStatusChange('closed')}
                        disabled={statusChangeLoading}
                        className="inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-green-700 bg-green-50 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                      >
                        Set Closed
                      </button>
                    )}
                  </div>
                  
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getPriorityColor(ticket.priority)}`}>
                    {ticket.priority} Priority
                  </span>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setEditMode(true)}
                  className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaEdit className="h-4 w-4" />
                </button>
                <button
                  onClick={handleDeleteTicket}
                  className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <FaTrash className="h-4 w-4" />
                </button>
              </div>
            </div>
            <div className="mt-4 text-sm text-gray-700">
              <p className="whitespace-pre-line">{ticket.description}</p>
            </div>
            <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Created</h3>
                <p className="mt-1 text-sm text-gray-900">{formatDate(ticket.createdAt)}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Last Updated</h3>
                <p className="mt-1 text-sm text-gray-900">{formatDate(ticket.updatedAt)}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Assigned To</h3>
                <p className="mt-1 text-sm text-gray-900 flex items-center">
                  <FaUser className="mr-1 h-4 w-4 text-gray-400" />
                  {ticket.assignedTo ? ticket.assignedTo.name : 'Unassigned'}
                </p>
              </div>
              {ticket.device && (
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Device</h3>
                  <p className="mt-1 text-sm text-gray-900 flex items-center">
                    <FaDesktop className="mr-1 h-4 w-4 text-gray-400" />
                    {ticket.device.name} ({ticket.device.os})
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Comments Section */}
          <div className="p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Comments</h3>
            <div className="space-y-4">
              {ticket.comments.map((comment) => (
                <div key={comment.id} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                      <div className={`h-8 w-8 rounded-full flex items-center justify-center ${comment.user.role === 'support' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}>
                        {comment.user.name.charAt(0)}
                      </div>
                      <div className="ml-2">
                        <div className="text-sm font-medium text-gray-900">{comment.user.name}</div>
                        <div className="text-xs text-gray-500 capitalize">{comment.user.role}</div>
                      </div>
                    </div>
                    <div className="text-xs text-gray-500">{formatDate(comment.createdAt)}</div>
                  </div>
                  <div className="text-sm text-gray-700 whitespace-pre-line">{comment.content}</div>
                </div>
              ))}
            </div>

            {/* Add Comment Form */}
            <form onSubmit={handleCommentSubmit} className="mt-6">
              <div>
                <label htmlFor="comment" className="sr-only">Add a comment</label>
                <textarea
                  id="comment"
                  rows="3"
                  value={newComment}
                  onChange={handleCommentChange}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Add a comment..."
                  required
                ></textarea>
              </div>
              <div className="mt-3 flex justify-end">
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaPaperPlane className="-ml-1 mr-2 h-4 w-4" />
                  Post Comment
                </button>
              </div>
            </form>
          </div>
          
          {/* AI Features, File Upload, and Time Tracking Section */}
          {ticket.status !== 'closed' && (
            <div className="p-6 border-t border-gray-200">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* AI Triage Section */}
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                    <FaRobot className="mr-2 h-5 w-5 text-blue-500" />
                    AI Triage
                  </h3>
                  <p className="text-sm text-gray-600 mb-4">
                    Let AI analyze this ticket and suggest a priority level based on the content.
                  </p>
                  
                  {aiTriageLoading ? (
                    <div className="flex justify-center items-center py-4">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                  ) : aiTriageResult ? (
                    <div className="bg-blue-50 p-4 rounded-md mb-4">
                      <div className="flex items-center mb-2">
                        <FaBrain className="mr-2 h-5 w-5 text-blue-600" />
                        <h4 className="text-md font-medium text-blue-800">AI Suggestion</h4>
                      </div>
                      <p className="text-sm text-blue-700 mb-2">
                        <span className="font-medium">Suggested Priority:</span>{' '}
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${getPriorityColor(aiTriageResult.suggestedPriority)}`}>
                          {aiTriageResult.suggestedPriority}
                        </span>
                      </p>
                      <p className="text-sm text-blue-700 mb-3">
                        <span className="font-medium">Reasoning:</span>{' '}
                        {aiTriageResult.reasoning}
                      </p>
                      <div className="flex justify-end">
                        <button
                          onClick={applyAIPriority}
                          className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <FaCheckCircle className="mr-1.5 h-4 w-4" />
                          Apply Suggestion
                        </button>
                        <button
                          onClick={() => setAiTriageResult(null)}
                          className="ml-2 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <FaTimesCircle className="mr-1.5 h-4 w-4" />
                          Dismiss
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={handleAITriage}
                      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FaRobot className="mr-2 h-5 w-5" />
                      Generate AI Triage
                    </button>
                  )}
                </div>
                
                {/* File Upload Section */}
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                    <FaFileUpload className="mr-2 h-5 w-5 text-green-500" />
                    Attachments
                  </h3>
                  
                  {/* File List */}
                  {ticket.attachments && ticket.attachments.length > 0 ? (
                    <div className="mb-4">
                      <ul className="divide-y divide-gray-200">
                        {ticket.attachments.map((file, index) => (
                          <li key={index} className="py-2 flex justify-between items-center">
                            <div className="flex items-center">
                              <FaFileAlt className="mr-2 h-4 w-4 text-gray-400" />
                              <span className="text-sm text-gray-700 truncate" style={{ maxWidth: '180px' }}>
                                {file.originalName}
                              </span>
                            </div>
                            <a
                              href={file.url}
                              download
                              className="text-blue-600 hover:text-blue-800"
                            >
                              <FaDownload className="h-4 w-4" />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-600 mb-4">No attachments yet. Upload files related to this ticket.</p>
                  )}
                  
                  {/* File Upload Form */}
                  <form onSubmit={handleFileUpload} className="mt-2">
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      onChange={(e) => e.target.files.length > 0 && handleFileUpload(e)}
                    />
                    <button
                      type="button"
                      onClick={triggerFileUpload}
                      disabled={fileUploading}
                      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {fileUploading ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                          Uploading...
                        </>
                      ) : (
                        <>
                          <FaFileUpload className="mr-2 h-5 w-5" />
                          Upload File
                        </>
                      )}
                    </button>
                    <p className="mt-1 text-xs text-gray-500">Max file size: 10MB</p>
                  </form>
                </div>
              </div>
              
              {/* Time Tracking Section */}
              <div className="mt-6 bg-white rounded-lg border border-gray-200 p-4">
                <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                  <FaClock className="mr-2 h-5 w-5 text-purple-500" />
                  Time Tracking
                </h3>
                
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-600">
                      Time spent on this ticket: <span className="font-medium text-gray-900">{ticket.timeSpent || 0} minutes</span>
                    </p>
                    {ticket.client && ticket.client.subscription && (
                      <p className="text-xs text-gray-500 mt-1">
                        Client subscription: {ticket.client.subscription.minutesUsed} / {ticket.client.subscription.minutesIncluded} minutes used
                      </p>
                    )}
                  </div>
                  
                  {!timeInputMode ? (
                    <button
                      onClick={toggleTimeInputMode}
                      className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <FaClock className="mr-1.5 h-4 w-4 text-purple-500" />
                      Update Time
                    </button>
                  ) : (
                    <div className="flex items-center">
                      <input
                        type="number"
                        min="0"
                        value={timeSpent}
                        onChange={handleTimeSpentChange}
                        className="max-w-[100px] shadow-sm focus:ring-purple-500 focus:border-purple-500 block sm:text-sm border-gray-300 rounded-md"
                      />
                      <span className="mx-2 text-sm text-gray-500">minutes</span>
                      <button
                        onClick={saveTimeSpent}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Save
                      </button>
                      <button
                        onClick={toggleTimeInputMode}
                        className="ml-2 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
                
                {/* Specialist Fee Section */}
                <div className="mt-4 pt-4 border-t border-gray-200">
                  <div className="flex justify-between items-center">
                    <h4 className="text-md font-medium text-gray-900 flex items-center">
                      <FaPoundSign className="mr-2 h-4 w-4 text-gray-500" />
                      Specialist Fees
                    </h4>
                    
                    {!showFeeForm && (
                      <button
                        onClick={toggleFeeForm}
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      >
                        Add Fee
                      </button>
                    )}
                  </div>
                  
                  {/* Fee List */}
                  {ticket.specialistFees && ticket.specialistFees.length > 0 ? (
                    <div className="mt-2">
                      <ul className="divide-y divide-gray-200">
                        {ticket.specialistFees.map((fee, index) => (
                          <li key={index} className="py-2 flex justify-between">
                            <div>
                              <p className="text-sm font-medium text-gray-900">{fee.description}</p>
                              <p className="text-xs text-gray-500">{formatDate(fee.createdAt)}</p>
                            </div>
                            <span className="text-sm font-medium text-gray-900">£{fee.amount.toFixed(2)}</span>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-2 pt-2 border-t border-gray-200 flex justify-between">
                        <span className="text-sm font-medium text-gray-700">Total Specialist Fees:</span>
                        <span className="text-sm font-medium text-gray-900">
                          £{ticket.specialistFees.reduce((sum, fee) => sum + fee.amount, 0).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-600 mt-2">No specialist fees added yet.</p>
                  )}
                  
                  {/* Fee Form */}
                  {showFeeForm && (
                    <form onSubmit={addSpecialistFee} className="mt-3">
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div>
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                          <input
                            type="text"
                            id="description"
                            name="description"
                            value={feeData.description}
                            onChange={handleFeeDataChange}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount (£)</label>
                          <input
                            type="number"
                            id="amount"
                            name="amount"
                            min="0"
                            step="0.01"
                            value={feeData.amount}
                            onChange={handleFeeDataChange}
                            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            required
                          />
                        </div>
                      </div>
                      <div className="mt-3 flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={toggleFeeForm}
                          className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex items-center px-3 py-1.5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Add Fee
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {/* AI Summary Section - Only for closed tickets */}
          {ticket.status === 'closed' && (
            <div className="p-6 border-t border-gray-200">
              <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                <FaRobot className="mr-2 h-5 w-5 text-blue-500" />
                AI Summary
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                Generate an AI summary of this ticket conversation to capture key points and resolution.
              </p>
              
              {aiSummaryLoading ? (
                <div className="flex justify-center items-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                </div>
              ) : aiSummaryResult ? (
                <div className="bg-blue-50 p-4 rounded-md">
                  <div className="flex items-center mb-2">
                    <FaBrain className="mr-2 h-5 w-5 text-blue-600" />
                    <h4 className="text-md font-medium text-blue-800">AI Summary</h4>
                  </div>
                  <div className="text-sm text-blue-700 whitespace-pre-line">
                    <p className="font-medium mb-2">Issue:</p>
                    <p className="mb-4">{aiSummaryResult.issue}</p>
                    
                    <p className="font-medium mb-2">Resolution:</p>
                    <p className="mb-4">{aiSummaryResult.resolution}</p>
                    
                    <p className="font-medium mb-2">Key Points:</p>
                    <ul className="list-disc pl-5 space-y-1">
                      {aiSummaryResult.keyPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-3 flex justify-end">
                    <button
                      onClick={() => setAiSummaryResult(null)}
                      className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FaTimesCircle className="mr-1.5 h-4 w-4" />
                      Dismiss
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={handleAISummary}
                  className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FaRobot className="mr-2 h-5 w-5" />
                  Generate AI Summary
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TicketDetail;
