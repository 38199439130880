import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaLock, FaEnvelope, FaCheck, FaShieldAlt, FaArrowRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import authService from '../../services/auth.service';

const LoginOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(5px);
`;

const LoginContainer = styled.div`
  background: rgba(25, 55, 100, 0.85);
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  position: relative;
  transform: ${props => props.show ? 'scale(1)' : 'scale(0.9)'};
  opacity: ${props => props.show ? 1 : 0};
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  color: white;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, #FFD700, #FFC000);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: color 0.2s;
  
  &:hover {
    color: white;
  }
`;

const LoginTitle = styled.h2`
  color: white;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const LoginField = styled.div`
  margin-bottom: 1.25rem;
  position: relative;
`;

const LoginLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
`;

const LoginInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.3s;
  color: #1e293b;
  background-color: rgba(255, 255, 255, 0.9);
  
  &:focus {
    outline: none;
    border-color: #FFD700;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.3);
  }
  
  &::placeholder {
    color: #94a3b8;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  background: linear-gradient(135deg, #FFD700 0%, #FFC000 100%);
  color: #1e293b;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  &:hover {
    background: linear-gradient(135deg, #FFC000 0%, #FFB000 100%);
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #3a5a8c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputGroup = styled.div`
  position: relative;
`;

const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  user-select: none;
`;

const RememberMeLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  margin-left: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 18px;
  height: 18px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: ${props => props.checked ? 'linear-gradient(135deg, #FFD700 0%, #FFC000 100%)' : 'rgba(255, 255, 255, 0.9)'};
  border: 1px solid ${props => props.checked ? '#FFC000' : 'rgba(255, 255, 255, 0.3)'};
  border-radius: 4px;
  transition: all 0.2s;
  
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.3);
  }
  
  ${FaCheck} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'};
    color: #1e293b;
    font-size: 12px;
  }
`;

// Step indicator component for multi-step form
const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const Step = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#FFD700' : 'rgba(255, 255, 255, 0.3)'};
  margin: 0 5px;
  transition: all 0.3s ease;
`;

// Verification code input styling
const VerificationCodeInput = styled(LoginInput)`
  letter-spacing: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

// Back button for multi-step form
const BackButton = styled.button`
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  transition: all 0.2s;
  
  &:hover {
    color: white;
  }
`;

const LoginPopup = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    verificationCode: ''
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // 1: Login, 2: 2FA Verification
  const [userId, setUserId] = useState(null);

  // Check for saved email in localStorage when component mounts
  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setFormData(prev => ({ ...prev, email: savedEmail }));
      setRememberMe(true);
    }
  }, []);
  
  // Reset form when popup is closed
  useEffect(() => {
    if (!show) {
      setStep(1);
      setUserId(null);
      setFormData(prev => ({
        ...prev,
        password: '',
        verificationCode: ''
      }));
    }
  }, [show]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      if (step === 1) {
        // Step 1: Initial login with email and password
        const response = await authService.login(formData.email, formData.password);
        
        // Check if 2FA is required
        if (response.data && response.data.requires2FA) {
          // Save the user ID for verification
          setUserId(response.data.userId);
          
          // Move to step 2 (2FA verification)
          setStep(2);
          toast.info('Please enter the verification code sent to your email.');
        } else if (response.data && response.data.token) {
          // No 2FA required, login successful
          handleLoginSuccess(response);
        } else {
          toast.error('Login failed. Please check your credentials.');
        }
      } else if (step === 2) {
        // Step 2: Verify 2FA code
        const response = await authService.verifyLogin(userId, formData.verificationCode);
        
        if (response.data && response.data.token) {
          // 2FA verification successful
          handleLoginSuccess(response);
        } else {
          toast.error('Verification failed. Please check your code and try again.');
        }
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // Check if this is an email verification error
      if (error.response?.data?.needsVerification) {
        toast.warning('Please verify your email before logging in.');
      } else {
        // Show appropriate error message
        const errorMessage = error.response?.data?.message || 'Login failed. Please try again.';
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle successful login after all steps are completed
  const handleLoginSuccess = (response) => {
    // Store auth token
    if (response.data && response.data.token) {
      localStorage.setItem('token', response.data.token);
      
      // Store user data if available
      if (response.data.data && response.data.data.user) {
        localStorage.setItem('user', JSON.stringify(response.data.data.user));
      }
    } else {
      console.error('No token received in login response');
      toast.error('Login failed: No authentication token received');
      return;
    }

    // Handle remember me functionality
    if (rememberMe) {
      localStorage.setItem('rememberedEmail', formData.email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }
    
    toast.success('Login successful!');
    
    // Navigate to dashboard
    window.location.href = '/dashboard';
  };
  
  // Go back to the previous step
  const handleBack = () => {
    setStep(1);
  };

  // Handle click outside to close
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <LoginOverlay show={show} onClick={handleOverlayClick}>
      <LoginContainer show={show} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>✕</CloseButton>
        <LoginTitle>Client Login</LoginTitle>
        
        <StepIndicator>
          <Step active={step === 1} />
          <Step active={step === 2} />
        </StepIndicator>
        
        <form onSubmit={handleSubmit}>
          {step === 1 ? (
            // Step 1: Email and Password
            <>
              <LoginField>
                <LoginLabel htmlFor="email">Email Address</LoginLabel>
                <InputGroup>
                  <IconWrapper>
                    <FaEnvelope />
                  </IconWrapper>
                  <LoginInput
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                    required
                  />
                </InputGroup>
              </LoginField>
              
              <LoginField>
                <LoginLabel htmlFor="password">Password</LoginLabel>
                <InputGroup>
                  <IconWrapper>
                    <FaLock />
                  </IconWrapper>
                  <LoginInput
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    required
                  />
                </InputGroup>
              </LoginField>
              
              <RememberMeContainer>
                <CheckboxContainer>
                  <HiddenCheckbox 
                    checked={rememberMe} 
                    onChange={handleRememberMeChange} 
                    id="rememberMe"
                  />
                  <StyledCheckbox checked={rememberMe}>
                    <FaCheck />
                  </StyledCheckbox>
                </CheckboxContainer>
                <RememberMeLabel htmlFor="rememberMe">
                  Remember me
                </RememberMeLabel>
              </RememberMeContainer>
              
              <LoginButton type="submit" disabled={loading}>
                {loading ? 'Signing in...' : 'Sign in'}
              </LoginButton>
            </>
          ) : (
            // Step 2: 2FA Verification
            <>
              <LoginField>
                <LoginLabel htmlFor="verificationCode">Verification Code</LoginLabel>
                <InputGroup>
                  <IconWrapper>
                    <FaShieldAlt />
                  </IconWrapper>
                  <VerificationCodeInput
                    type="text"
                    id="verificationCode"
                    name="verificationCode"
                    value={formData.verificationCode}
                    onChange={handleChange}
                    placeholder="000000"
                    maxLength="6"
                    required
                  />
                </InputGroup>
              </LoginField>
              
              <LoginButton type="submit" disabled={loading}>
                {loading ? 'Verifying...' : 'Verify Code'}
              </LoginButton>
              
              <BackButton type="button" onClick={handleBack}>
                <FaArrowRight style={{ transform: 'rotate(180deg)' }} /> Back to Login
              </BackButton>
            </>
          )}
        </form>
      </LoginContainer>
    </LoginOverlay>
  );
};

export default LoginPopup;
