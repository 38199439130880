/**
 * Client-side utility for collecting device fingerprint information
 */

class DeviceFingerprint {
  /**
   * Collect all available device fingerprinting information
   * @returns {Promise<Object>} - Device fingerprint components
   */
  static async collect() {
    try {
      // Basic browser information
      const components = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        colorDepth: window.screen.colorDepth,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezoneOffset: new Date().getTimezoneOffset(),
      };
      
      // CPU and memory information (if available)
      if (navigator.hardwareConcurrency) {
        components.hardwareConcurrency = navigator.hardwareConcurrency;
      }
      
      if (navigator.deviceMemory) {
        components.deviceMemory = navigator.deviceMemory;
      }
      
      // Canvas fingerprinting (creates a unique image based on hardware/software)
      try {
        const canvasData = this.getCanvasFingerprint();
        components.canvasFingerprint = canvasData;
      } catch (e) {
        console.warn('Canvas fingerprinting not available', e);
      }
      
      // WebGL information (if available)
      try {
        const webglData = this.getWebGLFingerprint();
        components.webglRenderer = webglData.renderer;
        components.webglVendor = webglData.vendor;
      } catch (e) {
        console.warn('WebGL fingerprinting not available', e);
      }
      
      // Installed fonts detection (limited but useful)
      try {
        const fontList = await this.detectFonts();
        components.fontFingerprint = this.hashFonts(fontList);
      } catch (e) {
        console.warn('Font detection not available', e);
      }
      
      // Audio fingerprinting
      try {
        const audioFingerprint = await this.getAudioFingerprint();
        components.audioFingerprint = audioFingerprint;
      } catch (e) {
        console.warn('Audio fingerprinting not available', e);
      }
      
      return components;
    } catch (error) {
      console.error('Error collecting device fingerprint:', error);
      // Return basic information if advanced methods fail
      return {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
      };
    }
  }
  
  /**
   * Get a fingerprint from canvas rendering
   * @returns {String} - Hashed canvas data
   */
  static getCanvasFingerprint() {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set canvas dimensions
    canvas.width = 200;
    canvas.height = 50;
    
    // Draw text with specific styling
    ctx.textBaseline = 'top';
    ctx.font = '14px Arial';
    ctx.fillStyle = '#1a73e8';
    ctx.fillText('Is IT Support Fingerprint', 2, 2);
    
    // Add a colored rectangle
    ctx.fillStyle = 'rgba(250, 128, 114, 0.5)';
    ctx.fillRect(100, 20, 80, 20);
    
    // Get the canvas data and hash it
    return canvas.toDataURL().split(',')[1].substring(0, 32);
  }
  
  /**
   * Get WebGL renderer information
   * @returns {Object} - WebGL vendor and renderer
   */
  static getWebGLFingerprint() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    
    if (!gl) {
      return { vendor: 'unknown', renderer: 'unknown' };
    }
    
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    
    if (!debugInfo) {
      return { vendor: 'unknown', renderer: 'unknown' };
    }
    
    const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || 'unknown';
    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'unknown';
    
    return { vendor, renderer };
  }
  
  /**
   * Detect available fonts
   * @returns {Promise<Array>} - List of detected fonts
   */
  static async detectFonts() {
    // List of fonts to test
    const fontList = [
      'Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana',
      'Helvetica', 'Tahoma', 'Trebuchet MS', 'Impact', 'Comic Sans MS',
      'Calibri', 'Cambria', 'Segoe UI', 'Roboto', 'Open Sans'
    ];
    
    const baseFonts = ['monospace', 'sans-serif', 'serif'];
    const testString = 'mmmmmmmmmmlli';
    
    // Create a detection element
    const d = document.createElement('div');
    d.style.position = 'absolute';
    d.style.left = '-9999px';
    d.style.fontSize = '72px';
    document.body.appendChild(d);
    
    const detectedFonts = [];
    
    // Check each font
    for (const font of fontList) {
      let isDetected = false;
      
      for (const baseFont of baseFonts) {
        d.style.fontFamily = `'${font}', ${baseFont}`;
        const baseWidth = d.clientWidth;
        
        d.style.fontFamily = baseFont;
        const fallbackWidth = d.clientWidth;
        
        if (baseWidth !== fallbackWidth) {
          isDetected = true;
          break;
        }
      }
      
      if (isDetected) {
        detectedFonts.push(font);
      }
    }
    
    document.body.removeChild(d);
    return detectedFonts;
  }
  
  /**
   * Hash the list of fonts to create a fingerprint
   * @param {Array} fonts - List of detected fonts
   * @returns {String} - Hashed font fingerprint
   */
  static hashFonts(fonts) {
    return fonts.sort().join(',').substring(0, 100);
  }
  
  /**
   * Get audio fingerprint based on AudioContext behavior
   * @returns {Promise<String>} - Audio fingerprint hash
   */
  static async getAudioFingerprint() {
    return new Promise((resolve, reject) => {
      try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const oscillator = audioContext.createOscillator();
        const dynamicsCompressor = audioContext.createDynamicsCompressor();
        
        // Set specific values for the dynamics compressor
        dynamicsCompressor.threshold.value = -50;
        dynamicsCompressor.knee.value = 40;
        dynamicsCompressor.ratio.value = 12;
        dynamicsCompressor.attack.value = 0;
        dynamicsCompressor.release.value = 0.25;
        
        oscillator.type = 'triangle';
        oscillator.frequency.value = 10000;
        
        // Connect the nodes
        oscillator.connect(dynamicsCompressor);
        dynamicsCompressor.connect(analyser);
        analyser.connect(audioContext.destination);
        
        oscillator.start(0);
        
        // Wait for processing
        setTimeout(() => {
          const dataArray = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(dataArray);
          
          // Convert to string and hash
          const audioData = Array.from(dataArray)
            .slice(0, 30)
            .join(',');
          
          oscillator.stop();
          audioContext.close();
          resolve(audioData);
        }, 100);
      } catch (e) {
        reject('AudioContext not supported');
      }
    });
  }
  
  /**
   * Store the device token in local storage
   * @param {String} token - The device token to store
   */
  static storeDeviceToken(token) {
    localStorage.setItem('deviceToken', token);
  }
  
  /**
   * Get the stored device token
   * @returns {String|null} - The stored device token or null
   */
  static getDeviceToken() {
    return localStorage.getItem('deviceToken');
  }
}

export default DeviceFingerprint;
