import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaLock, FaEye, FaTrash, FaPlus, FaQrcode } from 'react-icons/fa';
import PasswordService from '../../services/password.service';
import ClientService from '../../services/client.service';
import SecurePasswordModal from '../common/SecurePasswordModal';

const PasswordManagement = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [passwords, setPasswords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  
  // Secure password modal state
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordModalData, setPasswordModalData] = useState(null);
  
  // Form data for new password
  const [formData, setFormData] = useState({
    serviceName: '',
    password: '',
    expiryDays: 30
  });

  // Load clients on component mount
  useEffect(() => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication required. Please log in.');
      return;
    }
    
    loadClients();
  }, []);

  // Load passwords when a client is selected
  useEffect(() => {
    if (selectedClient) {
      loadPasswords(selectedClient);
    }
  }, [selectedClient]);

  const loadClients = async () => {
    try {
      // Check if user is authenticated
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Authentication required. Please log in.');
        setClients([]);
        setLoading(false);
        return;
      }
      
      setLoading(true);
      const response = await ClientService.getClients();
      
      // Handle the specific response format we're seeing:
      // { status: 'success', results: X, data: { clients: [...] } }
      if (response.data && response.data.data && response.data.data.clients && Array.isArray(response.data.data.clients)) {
        setClients(response.data.data.clients);
      }
      // Fallback to other formats if the specific format isn't found
      else if (response.data && Array.isArray(response.data)) {
        setClients(response.data);
      } else if (response.data && typeof response.data === 'object') {
        // Check if it has a clients property directly
        if (response.data.clients && Array.isArray(response.data.clients)) {
          setClients(response.data.clients);
        } else {
          // If no clients array found, set an empty array
          console.warn('Unexpected response format from API:', response.data);
          setClients([]);
        }
      } else {
        // If response.data is neither an array nor an object, set an empty array
        console.warn('Unexpected response format from API:', response.data);
        setClients([]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading clients:', error);
      
      // Handle authentication errors specifically
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        toast.error('Authentication error. Please log in again.');
        // Could redirect to login here if needed
      } else {
        toast.error('Failed to load clients');
      }
      
      setClients([]); // Set empty array on error
      setLoading(false);
    }
  };

  const loadPasswords = async (clientId) => {
    try {
      // Check if user is authenticated
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error('Authentication required. Please log in.');
        setPasswords([]);
        setLoading(false);
        return;
      }
      
      setLoading(true);
      console.log('Loading passwords for client:', clientId);
      console.log('Using token:', token.substring(0, 10) + '...');
      
      const response = await PasswordService.getClientPasswords(clientId);
      console.log('Password response:', response);
      
      // Handle the specific response format we might see:
      // { status: 'success', results: X, data: { passwords: [...] } }
      if (response.data && response.data.data && response.data.data.passwords && Array.isArray(response.data.data.passwords)) {
        setPasswords(response.data.data.passwords);
      }
      // Fallback to other formats if the specific format isn't found
      else if (response.data && Array.isArray(response.data)) {
        setPasswords(response.data);
      } else if (response.data && typeof response.data === 'object') {
        // Check if it has a passwords property directly
        if (response.data.passwords && Array.isArray(response.data.passwords)) {
          setPasswords(response.data.passwords);
        } else {
          // If no passwords array found, set an empty array
          console.warn('Unexpected password response format from API:', response.data);
          setPasswords([]);
        }
      } else {
        // If response.data is neither an array nor an object, set an empty array
        console.warn('Unexpected password response format from API:', response.data);
        setPasswords([]);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading passwords:', error);
      
      // Handle authentication errors specifically
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.error('Authentication error details:', error.response);
        toast.error('Authentication error. Please log in again.');
        // Could redirect to login here if needed
      } else {
        toast.error('Failed to load passwords');
      }
      
      setPasswords([]); // Set empty array on error
      setLoading(false);
    }
  };

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    console.log('Selected client ID:', clientId);
    
    // Find the client object for debugging
    const selectedClientObj = clients.find(c => {
      const userIds = c.users ? c.users.map(u => u._id) : [];
      return c._id === clientId || userIds.includes(clientId);
    });
    
    console.log('Selected client object:', selectedClientObj);
    setSelectedClient(clientId);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddPassword = async (e) => {
    e.preventDefault();
    
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication required. Please log in.');
      return;
    }
    
    if (!selectedClient || !formData.serviceName || !formData.password) {
      toast.error('Please fill in all required fields');
      return;
    }
    
    try {
      setLoading(true);
      console.log('Creating password for client:', selectedClient);
      console.log('Using token:', token);
      console.log('API URL being used:', '/api/passwords');
      
      const passwordData = {
        clientId: selectedClient,
        serviceName: formData.serviceName,
        password: formData.password,
        expiryDays: parseInt(formData.expiryDays)
      };
      
      console.log('Submitting password data:', passwordData);
      
      await PasswordService.createPassword(passwordData);
      
      // Reset form and reload passwords
      setFormData({
        serviceName: '',
        password: '',
        expiryDays: 30
      });
      setShowAddForm(false);
      loadPasswords(selectedClient);
      toast.success('Password created successfully');
    } catch (error) {
      console.error('Error creating password:', error);
      
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        
        // Handle different error types
        if (error.response.status === 401 || error.response.status === 403) {
          console.error('Authentication error details:', error.response);
          toast.error('Authentication error. Please log in again.');
        } else if (error.response.status === 404) {
          console.error('404 Not Found: The API endpoint does not exist or is not accessible');
          toast.error('API endpoint not found. Please check server configuration.');
        } else {
          toast.error(error.response.data?.message || 'Failed to create password');
        }
      } else if (error.request) {
        console.error('Request was made but no response received:', error.request);
        toast.error('No response from server. Please try again later.');
      } else {
        toast.error(error.message || 'Failed to create password');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRevokePassword = async (passwordId) => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication required. Please log in.');
      return;
    }
    
    if (!window.confirm('Are you sure you want to revoke this password?')) {
      return;
    }
    
    try {
      setLoading(true);
      console.log('Revoking password:', passwordId);
      
      await PasswordService.revokePassword(passwordId);
      loadPasswords(selectedClient);
      toast.success('Password revoked successfully');
    } catch (error) {
      console.error('Error revoking password:', error);
      
      // Handle authentication errors specifically
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.error('Authentication error details:', error.response);
        toast.error('Authentication error. Please log in again.');
      } else {
        toast.error('Failed to revoke password');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewPassword = async (passwordId) => {
    // Check if user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Authentication required. Please log in.');
      return;
    }
    
    try {
      setLoading(true);
      console.log('Viewing password:', passwordId);
      
      // Get the password details from the API
      const response = await PasswordService.getPassword(passwordId);
      
      // Prepare data for the modal
      setPasswordModalData({
        password: response.data.password,
        serviceName: response.data.serviceName
      });
      
      // Show the secure password modal
      setShowPasswordModal(true);
      
      toast.info('Password access has been logged for security purposes');
    } catch (error) {
      console.error('Error viewing password:', error);
      
      // Handle authentication errors specifically
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.error('Authentication error details:', error.response);
        toast.error('Authentication error. Please log in again.');
      } else {
        toast.error('Failed to view password');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordModalClose = () => {
    setShowPasswordModal(false);
    setPasswordModalData(null);
    // Reload passwords to refresh access count and last accessed date
    loadPasswords(selectedClient);
  };

  const handleGenerateTotpSetup = async () => {
    if (!selectedClient) {
      toast.error('Please select a client first');
      return;
    }
    
    try {
      setLoading(true);
      const response = await PasswordService.generateTotpSetup(selectedClient);
      
      // Handle different response formats
      let qrCodeUrl, secret;
      
      if (response.data && response.data.data) {
        // Nested format: { data: { qrCodeUrl, secret } }
        qrCodeUrl = response.data.data.qrCodeUrl;
        secret = response.data.data.secret;
      } else {
        // Direct format: { qrCodeUrl, secret }
        qrCodeUrl = response.data.qrCodeUrl;
        secret = response.data.secret;
      }
      
      setQrCodeData({
        qrCodeUrl,
        secret
      });
      setShowQrCode(true);
    } catch (error) {
      console.error('Error generating TOTP setup:', error);
      toast.error('Failed to generate TOTP setup');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Secure Password Modal */}
      {showPasswordModal && passwordModalData && (
        <SecurePasswordModal
          isOpen={showPasswordModal}
          onClose={handlePasswordModalClose}
          password={passwordModalData.password}
          serviceName={passwordModalData.serviceName}
          onPasswordRetrieved={handlePasswordModalClose}
        />
      )}
      
      <h1 className="text-3xl font-bold mb-6">Password Management</h1>
      
      <div className="mb-6">
        <label htmlFor="clientSelect" className="block mb-2 text-sm font-medium text-gray-700">
          Select Client
        </label>
        <select
          id="clientSelect"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          value={selectedClient}
          onChange={handleClientChange}
          disabled={loading}
        >
          <option value="">-- Select a client --</option>
          {Array.isArray(clients) && clients.length > 0 ? (
            clients.map((client) => (
              <option key={client._id} value={client.users && client.users.length > 0 ? client.users[0]._id : client._id}>
                {client.name} ({client.contactPerson?.email || 'No email'})
              </option>
            ))
          ) : (
            <option value="">No clients available</option>
          )}
        </select>
      </div>
      
      {selectedClient && (
        <div className="flex justify-between mb-6">
          <button
            onClick={() => setShowAddForm(true)}
            className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            disabled={loading}
          >
            <FaPlus className="mr-2" /> Add Password
          </button>
          
          <button
            onClick={handleGenerateTotpSetup}
            className="flex items-center bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
            disabled={loading}
          >
            <FaQrcode className="mr-2" /> Generate TOTP Setup
          </button>
        </div>
      )}
      
      {showAddForm && (
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Add New Password</h2>
          <form onSubmit={handleAddPassword}>
            <div className="mb-4">
              <label htmlFor="serviceName" className="block mb-2 text-sm font-medium text-gray-700">
                Service Name *
              </label>
              <input
                type="text"
                id="serviceName"
                name="serviceName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={formData.serviceName}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-700">
                Password *
              </label>
              <input
                type="text"
                id="password"
                name="password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div className="mb-4">
              <label htmlFor="expiryDays" className="block mb-2 text-sm font-medium text-gray-700">
                Expiry (Days)
              </label>
              <input
                type="number"
                id="expiryDays"
                name="expiryDays"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                value={formData.expiryDays}
                onChange={handleInputChange}
                min="1"
                max="365"
              />
            </div>
            
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setShowAddForm(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Password'}
              </button>
            </div>
          </form>
        </div>
      )}
      
      {showQrCode && qrCodeData && (
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">TOTP Setup</h2>
            <button
              onClick={() => setShowQrCode(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
          
          <div className="flex flex-col items-center mb-4">
            <p className="mb-4 text-gray-700">
              Scan this QR code with the client's authenticator app (Google Authenticator, Authy, etc.)
            </p>
            <img src={qrCodeData.qrCodeUrl} alt="TOTP QR Code" className="mb-4" />
          </div>
          
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Secret Key (if QR code cannot be scanned)
            </label>
            <div className="flex">
              <input
                type="text"
                value={qrCodeData.secret}
                readOnly
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(qrCodeData.secret);
                  toast.success('Secret copied to clipboard');
                }}
                className="ml-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Copy
              </button>
            </div>
          </div>
          
          <p className="text-sm text-gray-500">
            <strong>Important:</strong> Store this secret securely. You will not be able to view it again.
          </p>
        </div>
      )}
      
      {selectedClient && (
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mb-4">Client Passwords</h2>
          
          {loading ? (
            <p>Loading passwords...</p>
          ) : !Array.isArray(passwords) || passwords.length === 0 ? (
            <p>No passwords found for this client.</p>
          ) : (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3">Service</th>
                  <th scope="col" className="px-6 py-3">Created</th>
                  <th scope="col" className="px-6 py-3">Expires</th>
                  <th scope="col" className="px-6 py-3">Status</th>
                  <th scope="col" className="px-6 py-3">Access Count</th>
                  <th scope="col" className="px-6 py-3">Last Accessed</th>
                  <th scope="col" className="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(passwords) && passwords.map((password) => (
                  <tr key={password.id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaLock className="mr-2 text-gray-500" />
                        {password.serviceName}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      {new Date(password.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4">
                      {new Date(password.expiresAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4">
                      <span className={`px-2 py-1 text-xs font-semibold rounded-full ${
                        password.status === 'Active' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {password.status}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      {password.accessCount || 0}
                    </td>
                    <td className="px-6 py-4">
                      {password.lastAccessedAt 
                        ? new Date(password.lastAccessedAt).toLocaleString() 
                        : 'Never'}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleViewPassword(password.id)}
                          className="font-medium text-blue-600 hover:underline"
                        >
                          <FaEye className="inline mr-1" /> View
                        </button>
                        <button
                          onClick={() => handleRevokePassword(password.id)}
                          className="font-medium text-red-600 hover:underline"
                          disabled={password.status !== 'Active'}
                        >
                          <FaTrash className="inline mr-1" /> Revoke
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default PasswordManagement;
