import React from 'react';
import styled from 'styled-components';
import SignupForm from './SignupForm';
import backgroundImage from '../../assets/background.jpg';
import logo from '../../IsIT Logo.png';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  position: relative;
  
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const Logo = styled.img`
  height: 50px;
  cursor: pointer;
`;

const BackButton = styled.button`
  background: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.6rem 1.2rem;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const SignupPage = () => {
  const handleBackToHome = () => {
    window.location.href = '/';
  };
  
  return (
    <PageContainer>
      <Header>
        <Logo src={logo} alt="Is IT Support Logo" onClick={handleBackToHome} />
        <BackButton onClick={handleBackToHome}>Back to Home</BackButton>
      </Header>
      
      <ContentContainer>
        <SignupForm />
      </ContentContainer>
    </PageContainer>
  );
};

export default SignupPage;
