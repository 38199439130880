import React, { useState, useEffect } from 'react';
import TacticalRMMSettings from './components/admin/TacticalRMMSettings';
// Import some additional styling
import 'react-toastify/dist/ReactToastify.css';

const TestPage = () => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('tacticalRmmDarkMode') === 'true');

  // Effect to save dark mode preference to localStorage
  useEffect(() => {
    localStorage.setItem('tacticalRmmDarkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  return (
    <div className={`p-8 min-h-screen transition-colors duration-300 ${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      <h1 className="text-3xl font-bold mb-6">Test Page for TacticalRMM Settings</h1>
      
      <div className={`border-2 p-6 mb-8 rounded-lg shadow-lg ${darkMode ? 'border-purple-500 bg-gray-800' : 'border-blue-500 bg-blue-50'}`}>
        <h2 className="text-xl font-bold mb-4">Dark Mode Toggle Test</h2>
        <p className="mb-4">Click the button below to toggle dark mode for the entire page:</p>
        
        <div className="flex items-center justify-center p-4 bg-yellow-100 border-2 border-yellow-400 rounded-lg animate-pulse">
          <button
            onClick={toggleDarkMode}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg text-base font-medium transition-all duration-300 transform hover:scale-105 ${
              darkMode 
                ? 'bg-purple-600 text-white hover:bg-purple-700' 
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {darkMode ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              )}
            </svg>
          </button>
        </div>
      </div>
      
      <div className="border-2 border-red-500 p-4 mb-6">
        <p className="text-red-600 font-bold">This is a test page that directly renders the TacticalRMMSettings component</p>
        <p className="text-red-600">Look for the dark mode toggle at the top of the component below!</p>
      </div>

      {/* SUPER OBVIOUS DARK MODE CONTROL */}
      <div className="fixed top-1/2 right-0 transform -translate-y-1/2 z-50">
        <div className="bg-yellow-300 border-4 border-yellow-500 p-4 rounded-l-xl shadow-2xl">
          <h3 className="text-black font-bold text-xl mb-3">DARK MODE</h3>
          <div className="flex flex-col items-center gap-4">
            <button
              onClick={() => {
                setDarkMode(true);
                localStorage.setItem('tacticalRmmDarkMode', 'true');
                window.location.reload();
              }}
              className="w-full bg-gray-800 text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-900 transition-all duration-200 flex items-center justify-center gap-2"
            >
              <span>DARK</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              </svg>
            </button>
            
            <button
              onClick={() => {
                setDarkMode(false);
                localStorage.setItem('tacticalRmmDarkMode', 'false');
                window.location.reload();
              }}
              className="w-full bg-blue-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
            >
              <span>LIGHT</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      
      <TacticalRMMSettings />
    </div>
  );
};

export default TestPage;
