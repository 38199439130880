import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaClock, FaCalendarAlt, FaChartBar } from 'react-icons/fa';
import clientService from '../../services/client.service';
import LoadingSpinner from '../common/LoadingSpinner';

const ClientTimeUsage = ({ clientId }) => {
  const [timeUsage, setTimeUsage] = useState(null);
  const [loading, setLoading] = useState(true);

  // Load time usage data on component mount
  useEffect(() => {
    loadTimeUsage();
  }, [clientId]);

  // Load time usage data from API
  const loadTimeUsage = async () => {
    setLoading(true);
    try {
      const response = await clientService.getClientTimeUsage(clientId);
      setTimeUsage(response.data.data);
    } catch (error) {
      console.error('Error loading time usage:', error);
      toast.error('Failed to load time usage data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Format time (minutes to hours and minutes)
  const formatTime = (minutes) => {
    if (!minutes && minutes !== 0) return 'N/A';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  if (loading) {
    return <LoadingSpinner message="Loading time usage data..." />;
  }

  if (!timeUsage) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded mb-4">
          No time usage data available for this client.
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* Time Usage Summary */}
      <div className="bg-white rounded-lg shadow-md p-6 col-span-1">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FaClock className="mr-2 text-blue-600" /> Time Usage Summary
        </h2>
        
        <div className="space-y-4">
          {/* Contract Details */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Support Contract</h3>
            <p className="capitalize">{timeUsage.supportContract?.type || 'None'}</p>
            {timeUsage.supportContract?.startDate && (
              <p className="text-sm">
                <span className="text-gray-600">Start Date:</span> {formatDate(timeUsage.supportContract.startDate)}
              </p>
            )}
            {timeUsage.supportContract?.endDate && (
              <p className="text-sm">
                <span className="text-gray-600">End Date:</span> {formatDate(timeUsage.supportContract.endDate)}
              </p>
            )}
          </div>
          
          {/* Time Allocation */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Time Allocation</h3>
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-blue-50 p-3 rounded-lg">
                <p className="text-sm text-gray-600">Allocated</p>
                <p className="text-xl font-semibold text-blue-700">
                  {formatTime(timeUsage.supportContract?.minutesIncluded || 0)}
                </p>
              </div>
              <div className="bg-green-50 p-3 rounded-lg">
                <p className="text-sm text-gray-600">Remaining</p>
                <p className="text-xl font-semibold text-green-700">
                  {formatTime(timeUsage.remainingTime || 0)}
                </p>
              </div>
              <div className="bg-yellow-50 p-3 rounded-lg">
                <p className="text-sm text-gray-600">Used</p>
                <p className="text-xl font-semibold text-yellow-700">
                  {formatTime(timeUsage.totalTimeSpent || 0)}
                </p>
              </div>
              <div className="bg-purple-50 p-3 rounded-lg">
                <p className="text-sm text-gray-600">Usage</p>
                <p className="text-xl font-semibold text-purple-700">
                  {timeUsage.usagePercentage || 0}%
                </p>
              </div>
            </div>
          </div>
          
          {/* Usage Progress Bar */}
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-2">Usage Progress</h3>
            <div className="w-full bg-gray-200 rounded-full h-4">
              <div
                className={`h-4 rounded-full ${timeUsage.usagePercentage > 90 ? 'bg-red-600' : timeUsage.usagePercentage > 75 ? 'bg-yellow-500' : 'bg-green-600'}`}
                style={{ width: `${Math.min(timeUsage.usagePercentage || 0, 100)}%` }}
              ></div>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              {timeUsage.usagePercentage || 0}% of allocated time used
            </p>
          </div>
        </div>
      </div>

      {/* Time Usage Chart */}
      <div className="bg-white rounded-lg shadow-md p-6 col-span-2">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FaChartBar className="mr-2 text-blue-600" /> Monthly Time Usage
        </h2>
        
        {timeUsage.timeUsageChart && timeUsage.timeUsageChart.length > 0 ? (
          <div className="h-64">
            <div className="flex h-full items-end">
              {timeUsage.timeUsageChart.map((item, index) => {
                const heightPercentage = Math.max(
                  5,
                  (item.minutes / Math.max(...timeUsage.timeUsageChart.map(i => i.minutes))) * 100
                );
                
                return (
                  <div key={index} className="flex-1 flex flex-col items-center mx-1">
                    <div 
                      className="w-full bg-blue-500 rounded-t-sm" 
                      style={{ height: `${heightPercentage}%` }}
                      title={`${item.month}: ${formatTime(item.minutes)}`}
                    ></div>
                    <div className="text-xs mt-1 text-gray-600 w-full text-center truncate">
                      {item.month}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="bg-gray-50 p-6 rounded-lg flex items-center justify-center h-64">
            <p className="text-gray-500">No monthly usage data available</p>
          </div>
        )}
      </div>

      {/* Recent Tickets */}
      <div className="bg-white rounded-lg shadow-md p-6 col-span-3">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FaCalendarAlt className="mr-2 text-blue-600" /> Recent Time Entries
        </h2>
        
        {timeUsage.tickets && timeUsage.tickets.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ticket
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Priority
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time Spent
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {timeUsage.tickets.map((ticket) => (
                  <tr key={ticket._id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a 
                        href={`/tickets/${ticket._id}`} 
                        className="text-blue-600 hover:text-blue-900 font-medium"
                      >
                        {ticket.title}
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        ticket.status === 'open' ? 'bg-green-100 text-green-800' :
                        ticket.status === 'in-progress' ? 'bg-blue-100 text-blue-800' :
                        ticket.status === 'waiting-for-client' ? 'bg-yellow-100 text-yellow-800' :
                        ticket.status === 'resolved' ? 'bg-purple-100 text-purple-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {ticket.status.replace(/-/g, ' ')}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                        ticket.priority === 'low' ? 'bg-green-100 text-green-800' :
                        ticket.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                        ticket.priority === 'high' ? 'bg-orange-100 text-orange-800' :
                        ticket.priority === 'urgent' ? 'bg-red-100 text-red-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {ticket.priority}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(ticket.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {formatTime(ticket.timeSpent)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="bg-gray-50 p-6 rounded-lg flex items-center justify-center">
            <p className="text-gray-500">No ticket data available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientTimeUsage;
