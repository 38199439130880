import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTicketAlt, FaPlus, FaSync, FaFilter, FaSearch, FaCircle } from 'react-icons/fa';
import { Card, Button, Spin, Alert, Tag, Space, Divider } from 'antd';

const PortalTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();

  // Get dark mode preference
  const darkMode = localStorage.getItem('tacticalRmmDarkMode') === 'true';

  // Import ticket service
  const ticketService = require('../../services/ticket.service').default;

  // Function to fetch tickets
  const fetchTickets = async () => {
    setLoading(true);
    try {
      console.log('Fetching client tickets...');
      // We'll use the same service but in the future we might want to add a filter for client-only tickets
      const response = await ticketService.getTickets({ clientOnly: true });
      
      // Log the entire response for debugging
      console.log('API Response:', response);
      
      // Check for different possible response formats
      if (response.data && response.data.data && response.data.data.tickets) {
        // Format: { data: { data: { tickets: [...] } } }
        console.log('Tickets fetched successfully (format 1):', response.data.data.tickets);
        setTickets(response.data.data.tickets);
        setError(null);
      } else if (response.data && response.data.tickets) {
        // Format: { data: { tickets: [...] } }
        console.log('Tickets fetched successfully (format 2):', response.data.tickets);
        setTickets(response.data.tickets);
        setError(null);
      } else if (response.data && Array.isArray(response.data.data)) {
        // Format: { data: { data: [...] } }
        console.log('Tickets fetched successfully (format 3):', response.data.data);
        setTickets(response.data.data);
        setError(null);
      } else if (response.data && Array.isArray(response.data)) {
        // Format: { data: [...] }
        console.log('Tickets fetched successfully (format 4):', response.data);
        setTickets(response.data);
        setError(null);
      } else {
        // No tickets found or unexpected format
        console.log('No tickets found or unexpected API response format:', response.data);
        setTickets([]);
        setError('No tickets found or unexpected API response format');
      }
    } catch (err) {
      setError('Failed to load tickets. Please try again later.');
      console.error('Error fetching tickets:', err);
      setTickets([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch tickets when component mounts
  useEffect(() => {
    fetchTickets();
    
    // Set up an interval to refresh tickets every 60 seconds when the component is mounted
    const intervalId = setInterval(() => {
      console.log('Auto-refreshing tickets');
      fetchTickets();
    }, 60000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  
  // Refresh tickets when the location changes (user navigates back to this page)
  useEffect(() => {
    console.log('Location changed, refreshing tickets');
    fetchTickets();
  }, [location.pathname]);

  const handleRefresh = () => {
    console.log('Manual refresh requested');
    fetchTickets();
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredTickets = tickets.filter(ticket => {
    // Apply status filter
    if (filter !== 'all' && ticket.status !== filter) {
      return false;
    }
    
    // Apply search filter
    if (searchTerm && !ticket.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    
    return true;
  });

  const getStatusColor = (status) => {
    switch(status) {
      case 'open':
        return 'text-blue-500';
      case 'in-progress':
        return 'text-yellow-500';
      case 'closed':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const getPriorityColor = (priority) => {
    switch(priority) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-yellow-500';
      case 'low':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Show loading indicator only on initial load, not on refresh
  if (loading && tickets.length === 0) {
    return (
      <div className="space-y-6">
        <div className="page-header mb-6">
          <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Support Tickets</h1>
          <p className={`mt-2 ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
            View and manage your support requests
          </p>
        </div>
        <div className="flex justify-center items-center h-64">
          <Spin size="large" tip="Loading tickets..." />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="page-header mb-6">
        <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Support Tickets</h1>
        <p className={`mt-2 ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
          View and manage your support requests
        </p>
      </div>

      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          className="mb-6"
        />
      )}

      <Card 
        className={`shadow-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : ''}`}
        bordered={!darkMode}
      >
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <div className="flex flex-wrap items-center gap-2 mb-4 sm:mb-0">
            <Button 
              type={filter === 'all' ? 'primary' : 'default'} 
              onClick={() => handleFilterChange('all')}
            >
              All
            </Button>
            <Button 
              type={filter === 'open' ? 'primary' : 'default'} 
              onClick={() => handleFilterChange('open')}
            >
              Open
            </Button>
            <Button 
              type={filter === 'in-progress' ? 'primary' : 'default'} 
              onClick={() => handleFilterChange('in-progress')}
            >
              In Progress
            </Button>
            <Button 
              type={filter === 'closed' ? 'primary' : 'default'} 
              onClick={() => handleFilterChange('closed')}
            >
              Closed
            </Button>
          </div>
          
          <div className="flex items-center w-full sm:w-auto">
            <div className="relative flex-grow mr-2">
              <input
                type="text"
                placeholder="Search tickets"
                value={searchTerm}
                onChange={handleSearch}
                className={`w-full px-4 py-2 rounded-md border ${darkMode ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' : 'bg-white border-gray-300'} focus:outline-none focus:ring-2 focus:ring-green-500`}
              />
              <FaSearch className="absolute right-3 top-3 text-gray-400" />
            </div>
            <Button 
              icon={<FaSync />} 
              onClick={handleRefresh} 
              loading={loading}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className={`min-w-full divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
            <thead className={darkMode ? 'bg-gray-700' : 'bg-gray-50'}>
              <tr>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>
                  Ticket
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>
                  Status
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>
                  Priority
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>
                  Created
                </th>
                <th scope="col" className={`px-6 py-3 text-left text-xs font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'} uppercase tracking-wider`}>
                  Last Updated
                </th>
              </tr>
            </thead>
            <tbody className={`divide-y ${darkMode ? 'divide-gray-700 bg-gray-800' : 'divide-gray-200 bg-white'}`}>
              {filteredTickets.length > 0 ? (
                filteredTickets.map((ticket) => (
                  <tr key={ticket.id} className={darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'}>
                    <td className="px-6 py-4">
                      <Link to={`/portal/tickets/${ticket.id}`} className={darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-900'}>
                        <div className={`font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>{ticket.title}</div>
                        <div className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} truncate max-w-xs`}>{ticket.description}</div>
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <FaCircle className={`mr-2 h-2 w-2 ${getStatusColor(ticket.status)}`} />
                        <span className={`capitalize ${darkMode ? 'text-gray-300' : ''}`}>{ticket.status.replace('-', ' ')}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Tag color={ticket.priority === 'high' ? 'red' : ticket.priority === 'medium' ? 'orange' : 'green'} className="capitalize">
                        {ticket.priority}
                      </Tag>
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                      {formatDate(ticket.createdAt)}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>
                      {formatDate(ticket.updatedAt || ticket.createdAt)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className={`px-6 py-4 text-center text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    No tickets found matching your criteria
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Card>

      <div className="flex justify-center mt-6">
        <Link to="/portal/tickets/new">
          <Button 
            type="primary" 
            size="large" 
            icon={<FaPlus className="mr-2" />}
          >
            Create New Support Ticket
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default PortalTickets;
