import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { useAuth } from '../../context/auth.context';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  useEffect(() => {
    // Check if we have a session_id in the URL
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    
    if (!sessionId) {
      // If no session_id, redirect to billing dashboard
      navigate(user && user.role === 'client' ? '/portal/billing' : '/billing');
    }
    
    // You could verify the session with the server here if needed
  }, [location, navigate]);
  
  return (
    <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow rounded-lg overflow-hidden p-6 text-center">
        <div className="rounded-full bg-green-100 p-3 mx-auto w-16 h-16 flex items-center justify-center mb-4">
          <FaCheckCircle className="h-8 w-8 text-green-600" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">Payment Successful!</h2>
        <p className="text-gray-600 mb-6">Your payment has been processed successfully.</p>
        
        <div className="bg-gray-50 p-4 rounded-md mb-6">
          <p className="text-sm text-gray-600 mb-2">What happens next?</p>
          <ul className="text-sm text-gray-700 list-disc list-inside space-y-1 text-left">
            {location.search.includes('additional') ? (
              <>
                <li>Your additional support minutes have been added to your account</li>
                <li>You'll receive a confirmation email shortly</li>
                <li>Your invoice is available in the billing history</li>
                <li>You can now use your additional support minutes</li>
              </>
            ) : (
              <>
                <li>Your subscription has been activated</li>
                <li>You'll receive a confirmation email shortly</li>
                <li>Your invoice is available in the billing history</li>
                <li>You can now access all features included in your plan</li>
              </>
            )}
          </ul>
        </div>
        
        <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
          <button
            onClick={() => navigate(user && user.role === 'client' ? '/portal/billing' : '/billing')}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Go to Billing Dashboard
          </button>
          <button
            onClick={() => navigate(user && user.role === 'client' ? '/portal/dashboard' : '/dashboard')}
            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
