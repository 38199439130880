import React from 'react';

const TestCSSPage = () => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold text-red-600 mb-6">Test CSS Page</h1>
      
      <div className="bg-purple-200 p-4 rounded-lg mb-6">
        <h2 className="text-2xl font-bold text-purple-800 mb-4">Purple Background Test</h2>
        <p className="text-purple-700">This text should be purple on a light purple background.</p>
      </div>
      
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6 rounded shadow-md">
        <div className="flex items-center">
          <div className="py-1">
            <svg className="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">Demo Mode Banner Test</p>
            <p className="text-sm">This should look like our demo mode banner.</p>
          </div>
        </div>
      </div>
      
      <div className="space-y-4">
        <div className="bg-gradient-to-br from-gray-50 to-gray-100 p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300">
          <p className="text-gray-700">This should have a gradient background.</p>
        </div>
        
        <div className="flex items-center">
          <div className="p-2 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full shadow-md mr-3">
            <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          </div>
          <span className="text-sm font-medium text-gray-700">Icon with gradient background</span>
        </div>
      </div>
    </div>
  );
};

export default TestCSSPage;
