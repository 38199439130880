import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaPlus, FaSync, FaServer, FaDesktop, FaLaptop, FaMobile, FaQuestion, FaCircle } from 'react-icons/fa';
import clientService from '../../services/client.service';
import deviceService from '../../services/device.service';
import LoadingSpinner from '../common/LoadingSpinner';
import EmptyState from '../common/EmptyState';

const ClientDevices = ({ clientId }) => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncingDevices, setSyncingDevices] = useState(false);
  const [client, setClient] = useState(null);

  // Load devices on component mount
  useEffect(() => {
    loadDevices();
    loadClient();
  }, [clientId]);

  // Load devices from API
  const loadDevices = async () => {
    setLoading(true);
    try {
      const response = await clientService.getClientDevices(clientId);
      setDevices(response.data.data.devices || []);
    } catch (error) {
      console.error('Error loading devices:', error);
      toast.error('Failed to load devices. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Load client data
  const loadClient = async () => {
    try {
      const response = await clientService.getClient(clientId);
      setClient(response.data.data.client);
    } catch (error) {
      console.error('Error loading client:', error);
    }
  };

  // Sync devices with TacticalRMM
  const handleSyncDevices = async () => {
    if (!client?.tacticalRMM?.clientId) {
      toast.warning('This client is not linked to TacticalRMM yet.');
      return;
    }

    setSyncingDevices(true);
    try {
      toast.info('Syncing devices with TacticalRMM...');
      await clientService.syncClientDevices(clientId);
      toast.success('Successfully synced devices with TacticalRMM.');
      loadDevices(); // Reload devices after sync
    } catch (error) {
      console.error('Error syncing devices:', error);
      toast.error('Failed to sync devices. Please try again.');
    } finally {
      setSyncingDevices(false);
    }
  };

  // Get device icon based on type
  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 'desktop':
        return <FaDesktop className="text-blue-500" />;
      case 'laptop':
        return <FaLaptop className="text-green-500" />;
      case 'server':
        return <FaServer className="text-purple-500" />;
      case 'mobile':
        return <FaMobile className="text-yellow-500" />;
      default:
        return <FaQuestion className="text-gray-500" />;
    }
  };

  // Get status indicator based on device status
  const getStatusIndicator = (device) => {
    if (!device.isOnline) {
      return <FaCircle className="text-red-500" title="Offline" />;
    }
    
    const status = device.tacticalRMM?.status || 'unknown';
    switch (status) {
      case 'healthy':
        return <FaCircle className="text-green-500" title="Healthy" />;
      case 'warning':
        return <FaCircle className="text-yellow-500" title="Warning" />;
      case 'critical':
        return <FaCircle className="text-red-500" title="Critical" />;
      default:
        return <FaCircle className="text-gray-500" title="Unknown" />;
    }
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-4 border-b border-gray-200 flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
        <h2 className="text-xl font-semibold flex items-center">
          <FaServer className="mr-2 text-blue-600" /> Client Devices
        </h2>
        
        <div className="flex items-center space-x-2">
          {client?.tacticalRMM?.clientId && (
            <button
              onClick={handleSyncDevices}
              disabled={syncingDevices}
              className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FaSync className={`mr-2 ${syncingDevices ? 'animate-spin' : ''}`} />
              {syncingDevices ? 'Syncing...' : 'Sync with RMM'}
            </button>
          )}
          
          <Link
            to={`/devices/new?client=${clientId}`}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center"
          >
            <FaPlus className="mr-2" /> Add Device
          </Link>
        </div>
      </div>

      {loading ? (
        <LoadingSpinner message="Loading devices..." />
      ) : devices.length === 0 ? (
        <EmptyState
          title="No devices found"
          message={client?.tacticalRMM?.clientId 
            ? "This client doesn't have any devices yet. You can sync with TacticalRMM or add devices manually." 
            : "This client doesn't have any devices yet. You can add devices manually or link the client to TacticalRMM first."}
          actionText={client?.tacticalRMM?.clientId ? "Sync with RMM" : "Add Device"}
          actionLink={client?.tacticalRMM?.clientId ? "#" : `/devices/new?client=${clientId}`}
          actionOnClick={client?.tacticalRMM?.clientId ? handleSyncDevices : null}
          icon={<FaServer className="w-12 h-12 text-gray-400" />}
        />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {devices.map((device) => (
            <div key={device._id} className="border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow">
              <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-gray-50">
                <div className="flex items-center">
                  {getDeviceIcon(device.deviceType)}
                  <Link
                    to={`/devices/${device._id}`}
                    className="ml-2 text-blue-600 hover:text-blue-900 font-medium"
                  >
                    {device.name}
                  </Link>
                </div>
                <div className="flex items-center">
                  {getStatusIndicator(device)}
                </div>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div className="text-gray-500">Type:</div>
                  <div className="capitalize">{device.deviceType}</div>
                  
                  <div className="text-gray-500">OS:</div>
                  <div>{device.operatingSystem || 'N/A'}</div>
                  
                  <div className="text-gray-500">IP Address:</div>
                  <div>{device.ipAddress || 'N/A'}</div>
                  
                  <div className="text-gray-500">Last Seen:</div>
                  <div>{device.lastSeen ? formatDate(device.lastSeen) : 'N/A'}</div>
                  
                  {device.tacticalRMM?.agentId && (
                    <>
                      <div className="text-gray-500">RMM Status:</div>
                      <div className="capitalize">{device.tacticalRMM.status || 'unknown'}</div>
                      
                      {device.tacticalRMM.needsReboot && (
                        <>
                          <div className="text-gray-500">Reboot:</div>
                          <div className="text-yellow-600">Required</div>
                        </>
                      )}
                      
                      {device.tacticalRMM.pendingActions > 0 && (
                        <>
                          <div className="text-gray-500">Pending:</div>
                          <div className="text-blue-600">{device.tacticalRMM.pendingActions} actions</div>
                        </>
                      )}
                    </>
                  )}
                </div>
                
                <div className="mt-4 flex justify-end">
                  <Link
                    to={`/devices/${device._id}`}
                    className="text-sm text-blue-600 hover:text-blue-900"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ClientDevices;
