import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSync, FaDesktop, FaServer, FaLaptop, FaMobileAlt, FaQuestion } from 'react-icons/fa';
import deviceService from '../../services/device.service';
import { toast } from 'react-toastify';

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState(null);

  // Load devices on component mount
  useEffect(() => {
    fetchDevices();
  }, []);

  // Fetch devices from API
  const fetchDevices = async () => {
    setLoading(true);
    try {
      const response = await deviceService.getMyDevices();
      setDevices(response.data.data.devices);
      setError(null);
    } catch (err) {
      setError('Failed to load devices. Please try again later.');
      console.error('Error fetching devices:', err);
    } finally {
      setLoading(false);
    }
  };

  // Sync devices with TacticalRMM
  const handleSync = async () => {
    setSyncing(true);
    try {
      const response = await deviceService.syncDevices();
      toast.success(
        `Devices synced successfully! Created: ${response.data.data.syncResults.created}, Updated: ${response.data.data.syncResults.updated}`
      );
      fetchDevices(); // Refresh the device list
    } catch (err) {
      toast.error('Failed to sync devices. Please try again later.');
      console.error('Error syncing devices:', err);
    } finally {
      setSyncing(false);
    }
  };

  // Get appropriate icon for device type
  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 'desktop':
        return <FaDesktop className="text-blue-500" />;
      case 'laptop':
        return <FaLaptop className="text-green-500" />;
      case 'server':
        return <FaServer className="text-purple-500" />;
      case 'mobile':
        return <FaMobileAlt className="text-orange-500" />;
      default:
        return <FaQuestion className="text-gray-500" />;
    }
  };

  // Get status badge class
  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'healthy':
        return 'bg-green-100 text-green-800';
      case 'warning':
        return 'bg-yellow-100 text-yellow-800';
      case 'critical':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800">My Devices</h2>
        <div className="flex space-x-3">
          <Link
            to="/devices/sync"
            className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            <FaSync className="mr-2" />
            Advanced Sync
          </Link>
          <button
            onClick={handleSync}
            disabled={syncing}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            <FaSync className={`mr-2 ${syncing ? 'animate-spin' : ''}`} />
            {syncing ? 'Syncing...' : 'Quick Sync'}
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{error}</div>
      ) : devices.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500 mb-4">No devices found.</p>
          <p className="text-sm text-gray-400">
            If you have TacticalRMM agents installed, click the Sync Devices button to import them.
          </p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Device
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Operating System
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Last Seen
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Health
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {devices.map((device) => (
                <tr key={device._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center">
                        {getDeviceIcon(device.deviceType)}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">{device.name}</div>
                        <div className="text-sm text-gray-500">{device.ipAddress}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${device.isOnline ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}
                    >
                      {device.isOnline ? 'Online' : 'Offline'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {device.operatingSystem || 'Unknown'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {device.lastSeen ? new Date(device.lastSeen).toLocaleString() : 'Never'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {device.tacticalRMM && device.tacticalRMM.status ? (
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(
                          device.tacticalRMM.status
                        )}`}
                      >
                        {device.tacticalRMM.status.charAt(0).toUpperCase() +
                          device.tacticalRMM.status.slice(1)}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">Unknown</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <Link
                      to={`/devices/${device._id}`}
                      className="text-blue-600 hover:text-blue-900 mr-4"
                    >
                      Details
                    </Link>
                    {device.isOnline && (
                      <button
                        onClick={() => {
                          deviceService
                            .startRemoteSession(device._id)
                            .then((response) => {
                              // Open remote session URL in new tab
                              window.open(response.data.data.sessionData.url, '_blank');
                            })
                            .catch((err) => {
                              toast.error('Failed to start remote session');
                              console.error('Error starting remote session:', err);
                            });
                        }}
                        className="text-green-600 hover:text-green-900"
                      >
                        Connect
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DeviceList;
