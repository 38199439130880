/**
 * Branding and contact information configuration for the frontend
 * Centralized location for all branding-related settings
 */

const branding = {
  // Company information
  company: {
    name: 'Is IT Support', // Full company name
    shortName: 'Is IT', // Abbreviated name for UI space constraints
    legalName: 'Is IT Support Ltd', // Legal company name for invoices, etc.
  },
  
  // Contact information
  contact: {
    email: 'support@isitsupport.co.uk',
    phone: '+44 1234 567890', // Replace with actual phone number
    address: '123 Tech Street, London, UK', // Replace with actual address
  },
  
  // Website and social media
  web: {
    domain: 'isitsupport.co.uk',
    url: 'https://www.isitsupport.co.uk',
    supportUrl: 'https://support.isitsupport.co.uk',
  },
  
  // Application naming
  application: {
    name: 'Is IT Support Remote PC Support Platform',
    shortName: 'Is IT Support',
    description: 'Remote PC support and management platform',
  },
};

export default branding;
