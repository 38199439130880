import React, { useState, useEffect } from 'react';
import { FaSync, FaServer, FaCheck, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import deviceService from '../../services/device.service';
import { toast } from 'react-toastify';

const DeviceSync = () => {
  const [syncStatus, setSyncStatus] = useState({
    lastSync: null,
    inProgress: false,
    results: null,
    error: null
  });
  const [syncOptions, setSyncOptions] = useState({
    includeOffline: true,
    forceUpdate: false,
    syncAllClients: true,
    selectedClients: []
  });
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch clients and last sync info
    const fetchData = async () => {
      setLoading(true);
      try {
        // Get TacticalRMM clients
        const clientsResponse = await deviceService.getTacticalRMMClients();
        if (clientsResponse.data && clientsResponse.data.data && clientsResponse.data.data.clients) {
          setClients(clientsResponse.data.data.clients);
        } else {
          // No fallback to mock data - use empty array if API is not available
          setClients([]);
          toast.warning('No clients found or unexpected API response format');
        }
        
        // Get TacticalRMM settings to get last sync time
        const settingsResponse = await deviceService.getTacticalRMMSettings();
        if (settingsResponse.data && settingsResponse.data.data && settingsResponse.data.data.settings) {
          setSyncStatus({
            ...syncStatus,
            lastSync: settingsResponse.data.data.settings.lastSync
          });
        } else {
          // No fallback to mock data
          setSyncStatus({
            ...syncStatus,
            lastSync: null
          });
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching sync data:', err);
        setSyncStatus({
          ...syncStatus,
          error: 'Failed to load sync information'
        });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOptionChange = (e) => {
    const { name, checked, type, value } = e.target;
    setSyncOptions({
      ...syncOptions,
      [name]: type === 'checkbox' ? checked : value
    });

    // If syncAllClients is checked, clear selectedClients
    if (name === 'syncAllClients' && checked) {
      setSyncOptions({
        ...syncOptions,
        syncAllClients: true,
        selectedClients: []
      });
    }
  };

  const handleClientSelection = (clientId) => {
    const isSelected = syncOptions.selectedClients.includes(clientId);
    let newSelectedClients;

    if (isSelected) {
      newSelectedClients = syncOptions.selectedClients.filter(id => id !== clientId);
    } else {
      newSelectedClients = [...syncOptions.selectedClients, clientId];
    }

    setSyncOptions({
      ...syncOptions,
      selectedClients: newSelectedClients,
      syncAllClients: newSelectedClients.length === 0
    });
  };

  const handleSync = async () => {
    setSyncStatus({
      ...syncStatus,
      inProgress: true,
      results: null,
      error: null
    });

    try {
      // Call the API with advanced sync options
      const response = await deviceService.syncDevicesAdvanced({
        includeOffline: syncOptions.includeOffline,
        forceUpdate: syncOptions.forceUpdate,
        syncAllClients: syncOptions.syncAllClients,
        selectedClients: syncOptions.selectedClients
      });
      
      if (response.data && response.data.data && response.data.data.syncResults) {
        const results = response.data.data.syncResults;
        
        setSyncStatus({
          inProgress: false,
          lastSync: new Date().toISOString(),
          results,
          error: null
        });
        
        toast.success(`Sync completed: ${results.created} created, ${results.updated} updated`);
      } else {
        // No fallback to mock data
        setSyncStatus({
          inProgress: false,
          lastSync: new Date().toISOString(),
          results: { created: 0, updated: 0, unchanged: 0, failed: 0, total: 0 },
          error: 'Unexpected API response format'
        });
        
        toast.warning('Sync completed but returned an unexpected response format');
      }
    } catch (err) {
      console.error('Error syncing devices:', err);
      setSyncStatus({
        ...syncStatus,
        inProgress: false,
        error: 'Failed to sync devices. Please try again.'
      });
      toast.error('Failed to sync devices');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Device Synchronization</h2>
        <div className="flex items-center">
          <span className="text-sm text-gray-500 mr-2">Last sync:</span>
          <span className="text-sm font-medium">{formatDate(syncStatus.lastSync)}</span>
        </div>
      </div>

      {syncStatus.error && (
        <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">{syncStatus.error}</div>
      )}

      {syncStatus.results && (
        <div className="bg-green-50 p-4 rounded-md mb-6">
          <div className="flex items-start">
            <FaCheck className="h-5 w-5 text-green-500 mt-0.5" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Sync Completed Successfully</h3>
              <div className="mt-2 text-sm text-green-700">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div>
                    <span className="font-medium">Created:</span> {syncStatus.results.created}
                  </div>
                  <div>
                    <span className="font-medium">Updated:</span> {syncStatus.results.updated}
                  </div>
                  <div>
                    <span className="font-medium">Unchanged:</span> {syncStatus.results.unchanged}
                  </div>
                  <div>
                    <span className="font-medium">Failed:</span> {syncStatus.results.failed}
                  </div>
                </div>
                <div className="mt-2">
                  <span className="font-medium">Total devices processed:</span> {syncStatus.results.total}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-50 p-4 rounded-md">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sync Options</h3>
          
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                id="includeOffline"
                name="includeOffline"
                type="checkbox"
                checked={syncOptions.includeOffline}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="includeOffline" className="ml-2 block text-sm text-gray-700">
                Include offline devices
              </label>
            </div>
            
            <div className="flex items-center">
              <input
                id="forceUpdate"
                name="forceUpdate"
                type="checkbox"
                checked={syncOptions.forceUpdate}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="forceUpdate" className="ml-2 block text-sm text-gray-700">
                Force update all devices (ignores last modified date)
              </label>
            </div>
            
            <div className="flex items-center">
              <input
                id="syncAllClients"
                name="syncAllClients"
                type="checkbox"
                checked={syncOptions.syncAllClients}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="syncAllClients" className="ml-2 block text-sm text-gray-700">
                Sync all clients
              </label>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-50 p-4 rounded-md">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Client Selection</h3>
          
          {syncOptions.syncAllClients ? (
            <p className="text-sm text-gray-500 italic">All clients will be synchronized</p>
          ) : (
            <div className="space-y-2 max-h-60 overflow-y-auto">
              {clients.map(client => (
                <div key={client.id} className="flex items-center justify-between p-2 hover:bg-gray-100 rounded">
                  <div className="flex items-center">
                    <input
                      id={`client-${client.id}`}
                      type="checkbox"
                      checked={syncOptions.selectedClients.includes(client.id)}
                      onChange={() => handleClientSelection(client.id)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor={`client-${client.id}`} className="ml-2 block text-sm text-gray-700">
                      {client.name}
                    </label>
                  </div>
                  <div className="text-xs text-gray-500">
                    {client.deviceCount} devices • Last sync: {formatDate(client.lastSync)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      
      <div className="mt-6 flex justify-end">
        <button
          onClick={handleSync}
          disabled={syncStatus.inProgress}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {syncStatus.inProgress ? (
            <>
              <FaSync className="mr-2 animate-spin" />
              Syncing Devices...
            </>
          ) : (
            <>
              <FaSync className="mr-2" />
              Start Synchronization
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default DeviceSync;
