import React from 'react';
import PortalBillingDashboard from './PortalBillingDashboard';

const PortalBilling = () => {
  return (
    <div className="container mx-auto px-4 py-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">Billing & Subscription</h1>
        <p className="text-gray-600 dark:text-gray-300">
          Manage your subscription, payment methods, and view billing history
        </p>
      </div>
      
      {/* Using our portal-specific billing dashboard with correct routes */}
      <PortalBillingDashboard />
    </div>
  );
};

export default PortalBilling;
