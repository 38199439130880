import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaUser, FaEnvelope, FaKey, FaDesktop, FaSave, FaShieldAlt } from 'react-icons/fa';
import ChangePassword from './ChangePassword';
import TrustedDeviceManager from '../TrustedDevices/TrustedDeviceManager';
import './profile.css';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    tacticalRMM: {
      integrationEnabled: false,
      apiKey: '',
      clientId: '',
      siteId: ''
    }
  });

  useEffect(() => {
    // Load user data from localStorage (in a real app, this would come from an API)
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    setUser(userData);
    
    // Initialize form with user data
    setFormData({
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      tacticalRMM: {
        integrationEnabled: userData.tacticalRMM?.integrationEnabled || false,
        apiKey: userData.tacticalRMM?.apiKey || '',
        clientId: userData.tacticalRMM?.clientId || '',
        siteId: userData.tacticalRMM?.siteId || ''
      }
    });
    
    setLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.startsWith('tacticalRMM.')) {
      const tacticalField = name.split('.')[1];
      setFormData({
        ...formData,
        tacticalRMM: {
          ...formData.tacticalRMM,
          [tacticalField]: type === 'checkbox' ? checked : value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSaving(true);
    
    try {
      // In a real app, this would be an API call to update the user profile
      // For now, we'll just update localStorage
      setTimeout(() => {
        const updatedUser = {
          ...user,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          tacticalRMM: formData.tacticalRMM
        };
        
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
        
        toast.success('Profile updated successfully');
        setSaving(false);
      }, 1000);
    } catch (error) {
      toast.error('Failed to update profile');
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Profile Settings</h1>
      
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <form onSubmit={handleSubmit}>
          {/* Personal Information */}
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Personal Information</h2>
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  First name
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaUser className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  Last name
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaUser className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaEnvelope className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Change Password Component */}
          <div className="p-6 border-b border-gray-200">
            <ChangePassword />
          </div>

          {/* Device Security */}
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center mb-4">
              <FaShieldAlt className="h-5 w-5 text-blue-500 mr-2" />
              <h2 className="text-lg font-medium text-gray-900">Device Security</h2>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Manage your trusted devices and enhance your account security by controlling which devices can access your account.
            </p>
            <TrustedDeviceManager />
          </div>
          
          {/* TacticalRMM Integration */}
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium text-gray-900">TacticalRMM Integration</h2>
              <div className="flex items-center">
                <label htmlFor="tacticalRMM.integrationEnabled" className="mr-2 text-sm font-medium text-gray-700">
                  Enable Integration
                </label>
                <div className="relative inline-block w-10 mr-2 align-middle select-none">
                  <input
                    type="checkbox"
                    name="tacticalRMM.integrationEnabled"
                    id="tacticalRMM.integrationEnabled"
                    checked={formData.tacticalRMM.integrationEnabled}
                    onChange={handleChange}
                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                  />
                  <label
                    htmlFor="tacticalRMM.integrationEnabled"
                    className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${formData.tacticalRMM.integrationEnabled ? 'bg-blue-500' : 'bg-gray-300'}`}
                  ></label>
                </div>
              </div>
            </div>
            
            {formData.tacticalRMM.integrationEnabled && (
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="tacticalRMM.apiKey" className="block text-sm font-medium text-gray-700">
                    API Key
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaKey className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="password"
                      name="tacticalRMM.apiKey"
                      id="tacticalRMM.apiKey"
                      value={formData.tacticalRMM.apiKey}
                      onChange={handleChange}
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      required={formData.tacticalRMM.integrationEnabled}
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Your TacticalRMM API key for authentication</p>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="tacticalRMM.clientId" className="block text-sm font-medium text-gray-700">
                    Client ID
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaDesktop className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="tacticalRMM.clientId"
                      id="tacticalRMM.clientId"
                      value={formData.tacticalRMM.clientId}
                      onChange={handleChange}
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      required={formData.tacticalRMM.integrationEnabled}
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Your TacticalRMM client ID</p>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="tacticalRMM.siteId" className="block text-sm font-medium text-gray-700">
                    Site ID
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaDesktop className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="tacticalRMM.siteId"
                      id="tacticalRMM.siteId"
                      value={formData.tacticalRMM.siteId}
                      onChange={handleChange}
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      required={formData.tacticalRMM.integrationEnabled}
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Your TacticalRMM site ID</p>
                </div>
              </div>
            )}
          </div>

          {/* Save Button */}
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              disabled={saving}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {saving ? (
                <>
                  <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                  Saving...
                </>
              ) : (
                <>
                  <FaSave className="mr-2 h-4 w-4" />
                  Save Changes
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
